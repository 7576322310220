export const userServiceConfig = {
    getEmailServiceUrl: function() {
        return window.location.hostname ===  "arda.vanthiel.com" || 
               window.location.hostname === "arda-vanthiel.azurewebsites.net"
                ? 'https://vanthiel-integration.azurewebsites.net/'
                : window.location.hostname ===  "dev-arda.vanthiel.com" || 
                  window.location.hostname === "arda-vanthiel-dev.azurewebsites.net"
                ? 'https://vanthiel-integration-dev.azurewebsites.net/'
                : 'http://localhost:5000/'
    },
    getUserDatabases: 'UserDatabase/list',
    userAuthorized: 'UserProfile/ardaauthorized',
    getUserAccess: 'UserAccess/rights',
}