
import GanttChart from "smart-webcomponents-react/ganttchart";
import { useProdFilterStore } from "../../../../store/ProdFilterStore";
import { useProdPlanningFilterDrawerStore } from "../../../../store/ProdPlanningFilterDrawerStore";
import { useProductionPlanningStore } from "../../../../store/ProductionPlanningStore"
import { ProductionOrder } from "../../../../Types/ProductionOrder";
import { TaskItem } from "../../../../Types/TaskItem";
import { useSearchHelper } from "../../toolbar/items/hooks/SearchHelper";
import { useProductionPlanningScheduleGanttChart } from "./ProductionPlanningScheduleGanttChart";

export const useProductionPlanningScheduleResizingHelpers = () => {
    const {setTaskItems,taskItems
         ,setProductionOrders
         ,productionOrders
         ,setSelectedTaskId
         ,setSelectedTaskIds
         ,allTaskItems
         ,setRefreshChart
         ,setResetGanttChart
         ,batchTaskItems
         ,setBatchTaskItems
         ,setIsResizing
         ,filteredTaskItems
         ,updatedTaskItems
         ,setFilteredTaskItems
         ,setAllTaskItems
         ,rollbackAllTaskItems
         ,setRollbackAllTaskItems
         ,targetDB
    } = useProductionPlanningStore();
    const {formatTimelineWeekAndDay,formatTaskWithPendingForResize} = useProductionPlanningScheduleGanttChart();
    const {searchProd} = useSearchHelper();
    const {searchText} = useProdFilterStore();
    const {itemIds,statusIds,prodOrderIds,showRelated} = useProdPlanningFilterDrawerStore();
    let startTaskItem: TaskItem = {} as TaskItem;
    let endTaskItem: TaskItem = {} as TaskItem;
    let resizedTasks: ProductionOrder[] = [];
    let allTaskItemsRef: TaskItem[] = structuredClone(allTaskItems);
    const convertDates = (taskItem: TaskItem | null) => {
        if (taskItem){
            if (!(taskItem.dateEnd instanceof Date)){

                const utcEndDate = new Date(taskItem.dDateEnd);
                const localEndDate = new Date(utcEndDate.getTime() + (utcEndDate.getTimezoneOffset() * 6000));

                taskItem.dateEnd = localEndDate; //new Date(taskItem.dDateEnd);
                taskItem.dateEnd = new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate(),23,59,59);
            }
            if (!(taskItem.dateStart instanceof Date)){
                const utcStartDate = new Date(taskItem.dDateStart);
                const localStartDate = new Date(utcStartDate.getTime() + (utcStartDate.getTimezoneOffset() * 6000));

                taskItem.dateStart = localStartDate; //new Date(taskItem.dDateStart);
                taskItem.dateStart = new Date(taskItem.dateStart.getFullYear(),taskItem.dateStart.getMonth(),taskItem.dateStart.getDate(),0,0,0);
            }
            taskItem.dateEnd = new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate(),23,59,59);
            taskItem.dateStart = new Date(taskItem.dateStart.getFullYear(),taskItem.dateStart.getMonth(),taskItem.dateStart.getDate(),0,0,0);
        }
    }
    const checkForSunday = (isAdd: boolean, task: TaskItem) => {
        if (task.dateStart.getDay() === 0 || task.dateEnd.getDay() === 0){
            if (isAdd){
                task.dateStart.setDate(task.dateStart.getDate() + 1);
                task = {...task, dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate())};
                task.dateEnd.setDate(task.dateEnd.getDate() + 1);
                task = {...task, dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};                            
            } else {
                task.dateStart.setDate(task.dateStart.getDate() - 1);
                task = {...task, dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate())};
                task.dateEnd.setDate(task.dateEnd.getDate() - 1);
                task = {...task, dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
            }
        }
    }
    const isDetailFiltered = () => {
        return (
                (showRelated===false) || 
                (
                    (statusIds ?? []).length>0 || 
                    (itemIds ?? []).length>0 || 
                    (prodOrderIds ?? []).length>0
                )
        );
    }
    const setStartTaskItem = (taskItem: TaskItem) => {
        startTaskItem = structuredClone(taskItem);
    }
    const getStartTaskItem = () => {
        return startTaskItem;
    }
    const doResizingCascade = (props: {
        ganttChartRef: React.RefObject<GanttChart | null>,
        callback: (updatedProdOrders?: ProductionOrder[] | null) => void,
        project: TaskItem | null,
        isEnd: boolean,
        isStart: boolean,
        }) => {
        const {ganttChartRef, callback, project, isEnd, isStart} = props;
        const totalUpdates = (resizedTasks ?? []).length;

        let count = 0;
        resizedTasks.forEach(prod => {
            const taskItem = prod.taskItem;
            startTaskItem = structuredClone(prod.origTaskItem);
            count++;
            if (!prod.skip && count<=resizedTasks.length && project){
                resizingEndDate({
                    ganttChartRef, 
                    taskItem, 
                    callback, 
                    runningCascade: count<=resizedTasks.length, 
                    updateMainTask: true,
                    pIsStart: isStart,
                    pIsEnd: isEnd});
                prod = {...prod,skip:true};
            }
        });
        if (totalUpdates<resizedTasks.length){
            doResizingCascade({ganttChartRef,callback,project,isEnd,isStart});
        } else {

            updateGanttChart(ganttChartRef);

            resizedTasks.map(x => x.skip = false);

            let updatedProdOrders: ProductionOrder[] = productionOrders.filter(x => resizedTasks.filter(r => r.productionOrder===x.productionOrder).length===0); 
            resizedTasks.forEach(x => updatedProdOrders.push(x));
            setProductionOrders(updatedProdOrders);

            setTimeout(()=>{
                formatTimelineWeekAndDay();
                // format also for selected row pending for resize
                formatTaskWithPendingForResize(updatedProdOrders, ganttChartRef);
            },100);

            setTimeout(() => {
                if (callback)
                    callback(updatedProdOrders);
            },100);

            return true;
        }
    }
    const setResizedTask = (origTaskItem: TaskItem, 
                            taskItem: TaskItem, 
                            skip: boolean) => {
        // let targetDB = "999";
        // const _exist = userDatabases.some( x => x.databaseCode === constants.getProdPlanningTargetDBFromUrl());
        // if (_exist) {
        //     targetDB = constants.getProdPlanningTargetDBFromUrl();
        // }
        const prodOrder = {
            company: targetDB, // targetDB is getting from selected radiobutton
            id: taskItem.id,
            productionOrder: taskItem.project,
            dateStart: `${taskItem.dateStart.getFullYear()}-${taskItem.dateStart.getMonth()+1}-${taskItem.dateStart.getDate()}`,
            dateEnd: `${taskItem.dateEnd.getFullYear()}-${taskItem.dateEnd.getMonth()+1}-${taskItem.dateEnd.getDate()}`,
            origTaskItem: structuredClone(origTaskItem),
            taskItem: structuredClone(taskItem),
            skip,
            resized: false,
        } as ProductionOrder;
        const updatedItem = resizedTasks.filter(x => x.productionOrder === taskItem.project);
            if (updatedItem && updatedItem.length===0){
                resizedTasks.push(structuredClone(prodOrder));
            }
            else {
                resizedTasks.map(p => {
                    if (p.productionOrder === prodOrder.productionOrder){
                        p = prodOrder;
                    }
                    return p;
                });
            }
    }
    const updateSkipFlag = (taskItem: TaskItem) => {
        resizedTasks.map(p => {
            if (p.productionOrder === taskItem.project){
                p.skip = true;
            }
            return p;
        });
    }
    const resizingEndDate = (props: {
        ganttChartRef: React.RefObject<GanttChart | null>,
        taskItem: TaskItem, 
        callback: (updatedProdOrders?: ProductionOrder[] | null) => void,
        runningCascade?: boolean | null,
        updateMainTask?: boolean | null,
        pIsStart?: boolean | null,
        pIsEnd?: boolean | null,
        }):boolean | void =>  {
        const {ganttChartRef, taskItem, callback, runningCascade, updateMainTask, pIsStart, pIsEnd} = props;

        endTaskItem = structuredClone(taskItem);
                
        const endDateTimeDiff = endTaskItem.dateEnd.getTime()-startTaskItem.dateEnd.getTime();
        let isEnd = endDateTimeDiff!==0;

        const startDateTimeDiff = endTaskItem.dateStart.getTime()-startTaskItem.dateStart.getTime();
        let isStart = startDateTimeDiff!==0 && !isEnd;
        
        // check if already resized                        
        // const updatedItem = productionOrders.filter(x => x.taskItem.project === taskItem.project);
        // if (updatedItem.length>0){
        //     const timeDiffEndDate = endTaskItem.dateEnd.getTime()-startTaskItem.dateEnd.getTime();
        //     const diffDaysEndDate = Math.ceil(Math.abs(timeDiffEndDate) / (1000 * 60 * 60 * 24));

        //     const timeDiffStartDate = endTaskItem.dateStart.getTime()-startTaskItem.dateStart.getTime();
        //     const diffDaysStartDate = Math.ceil(Math.abs(timeDiffStartDate) / (1000 * 60 * 60 * 24));

        //     if (diffDaysEndDate>diffDaysStartDate){
        //         isEnd = true;
        //         isStart = false;
        //         taskItem.dateStart = updatedItem[0].taskItem.dateStart;
        //         endTaskItem.dateStart = updatedItem[0].taskItem.dateStart;
        //     }
        //     else {
        //         isStart = true;
        //         isEnd = false;
        //         taskItem.dateEnd = updatedItem[0].taskItem.dateEnd;
        //         endTaskItem.dateEnd = updatedItem[0].taskItem.dateEnd;
        //     }
            
        // }    

        //const isAdd = isStart && startDateTimeDiff > 0;
        const isAdd = isStart // && startDateTimeDiff < 0
                        ? (endTaskItem.dateStart.getTime()-startTaskItem.dateStart.getTime())>0
                        : (endTaskItem.dateEnd.getTime()-startTaskItem.dateEnd.getTime())>0

        if ((pIsEnd ?? false)===true || (pIsStart ?? false)===true){
            isEnd = (pIsEnd ?? false);
            isStart = (pIsStart ?? false);
        }

        if (isEnd){
            // add 23:59:59 to make it up to before midnight
            taskItem.dateEnd = new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate(),23,59,59);
        } else 
        if (isStart){
            // midnight
            taskItem.dateStart = new Date(taskItem.dateStart.getFullYear(),taskItem.dateStart.getMonth(),taskItem.dateStart.getDate(),0,0,0);            
        }

        // get the updated task, this is the main resized task
        if (!(runningCascade ?? false)){
            const resizedTaskItem = resizedTasks.filter(x => x.taskItem.project === taskItem.project);
            if (resizedTaskItem.length === 0){
                setResizedTask(startTaskItem,taskItem,true);
            }
        }

        let project: TaskItem | null = null;
        function updateDates(task: TaskItem){
            convertDates(task);
            const origTaskItem = structuredClone(task);
            let isUpdated = false;

            const dateStart_dateEnd = task.dateStart.getTime()-startTaskItem.dateEnd.getTime();
            const dateStart_dateEnd_diffDays = Math.ceil(Math.abs(dateStart_dateEnd) / (1000 * 60 * 60 * 24)); 

            const dateEnd_dateStart = task.dateEnd.getTime()-startTaskItem.dateStart.getTime();
            const dateEnd_dateStart_diffDays = Math.ceil(Math.abs(dateEnd_dateStart) / (1000 * 60 * 60 * 24)); 

            if (task.project !== taskItem.project && task.class!=='main-root'){
                const isProcessed = resizedTasks.filter(p => p.taskItem.project === task.project).length>0;
                if (
                    isStart && 
                    taskItem.dateStart < new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59) && 
                    startTaskItem.dateStart > new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59) && 
                    (task.flagGoodsIssued ?? false)===false && !isProcessed && 
                    task.currentParent!=taskItem.currentParent){
                        const timeDiffEnd = isAdd
                        ? (new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate()+1,0,0,0)).getTime()-(new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),23,59,59)).getTime()
                        : (new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate()-1,23,59,59)).getTime()-(new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate(),0,0,0)).getTime();
                    const timeDiff = timeDiffEnd; 
                    let diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                    if (isAdd){
                        task.dateStart.setDate(task.dateStart.getDate() + Math.abs(diffDays));
                        task = {...task, dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),0,0,0)};
                    }
                    else {
                        task.dateStart.setDate(task.dateStart.getDate() - Math.abs(diffDays));
                        task = {...task, dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),0,0,0)};
                    }
                    if (isAdd){
                        task.dateEnd.setDate(task.dateEnd.getDate() + Math.abs(diffDays));
                        task = {...task, dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
                    }
                    else{
                        task.dateEnd.setDate(task.dateEnd.getDate() - Math.abs(diffDays));
                        task = {...task, dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
                    }
                    // check for sundays
                    checkForSunday(isAdd,task);
                    isUpdated = true;
                } else 
                // it will push right to the first day 
                // if the end date is greater then first date
                if (
                    isStart && 
                    taskItem.dateStart < new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59) && 
                    taskItem.dateEnd > new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59) && 
                    (task.flagGoodsIssued ?? false)===false && !isProcessed &&
                    task.currentParent!=taskItem.currentParent){
                        const timeDiffEnd = isAdd
                        ? (new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate()+1,0,0,0)).getTime()-(new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),23,59,59)).getTime()
                        : (new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate()-1,23,59,59)).getTime()-(new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate(),0,0,0)).getTime();
                    const timeDiff = timeDiffEnd; 
                    let diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                    if (isAdd){
                        task.dateStart.setDate(task.dateStart.getDate() + Math.abs(diffDays));
                        task = {...task, dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),0,0,0)};
                    }
                    else {
                        task.dateStart.setDate(task.dateStart.getDate() - Math.abs(diffDays));
                        task = {...task, dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),0,0,0)};
                    }
                    if (isAdd){
                        task.dateEnd.setDate(task.dateEnd.getDate() + Math.abs(diffDays));
                        task = {...task, dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
                    }
                    else{
                        task.dateEnd.setDate(task.dateEnd.getDate() - Math.abs(diffDays));
                        task = {...task, dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
                    }
                    // check for sundays
                    checkForSunday(isAdd,task);
                    isUpdated = true;
                } else 
                if (isEnd && 
                         taskItem.dateEnd > new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate()) && 
                         startTaskItem.dateEnd < new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate()) && 
                         (task.flagGoodsIssued ?? false)===false && !isProcessed &&
                         task.currentParent!=taskItem.currentParent) {
                    // get the date difference
                    const timeDiffEnd = isAdd
                                        ? (new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate()+1,0,0,0)).getTime()-(new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),23,59,59)).getTime()
                                        : (new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate()-1,23,59,59)).getTime()-(new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate(),0,0,0)).getTime();
                    const timeDiff = timeDiffEnd; 
                    let diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                    if (isAdd){
                        task.dateEnd.setDate(task.dateEnd.getDate() + Math.abs(diffDays));
                        task = {...task, dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
                    }
                    else {
                        task.dateEnd.setDate(task.dateEnd.getDate() - Math.abs(diffDays));
                        task = {...task, dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
                    }
                    if (isAdd){
                        task.dateStart.setDate(task.dateStart.getDate() + Math.abs(diffDays));
                        task = {...task, dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),0,0,0)};
                    }
                    else {
                        task.dateStart.setDate(task.dateStart.getDate() - Math.abs(diffDays));
                        task = {...task, dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),0,0,0)};
                    }
                    // check for sundays
                    checkForSunday(isAdd,task);
                    isUpdated = true;
                } else
                // it will push right to the last day 
                // if the start date is less then end date
                if (isEnd && 
                    taskItem.dateEnd > new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate()) && 
                    //taskItem.dateStart < new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate()) && 
                    (task.flagGoodsIssued ?? false)===false && !isProcessed && 
                    task.currentParent!=taskItem.currentParent) {
                    // get the date difference
                    const timeDiffEnd = isAdd
                                        ? (new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate()+1,0,0,0)).getTime()-(new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),23,59,59)).getTime()
                                        : (new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate()-1,23,59,59)).getTime()-(new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate(),0,0,0)).getTime();
                    const timeDiff = timeDiffEnd; 
                    let diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                    if (isAdd){
                        task.dateEnd.setDate(task.dateEnd.getDate() + Math.abs(diffDays));
                        task = {...task, dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
                    }
                    else {
                        task.dateEnd.setDate(task.dateEnd.getDate() - Math.abs(diffDays));
                        task = {...task, dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
                    }
                    if (isAdd){
                        task.dateStart.setDate(task.dateStart.getDate() + Math.abs(diffDays));
                        task = {...task, dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),0,0,0)};
                    }
                    else {
                        task.dateStart.setDate(task.dateStart.getDate() - Math.abs(diffDays));
                        task = {...task, dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),0,0,0)};
                    }
                    // check for sundays
                    checkForSunday(isAdd,task);
                    isUpdated = true;
                }
                // end date backward resize  - reduce the duration by changing end date less
                else 
                if (isEnd && taskItem.dateEnd < new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate()) && 
                    startTaskItem.dateEnd <= new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),23,59,59) && 
                    (task.flagGoodsIssued ?? false)===false && !isProcessed &&
                    (dateStart_dateEnd_diffDays > 0 && dateStart_dateEnd_diffDays < 3) &&
                    task.currentParent!=taskItem.currentParent) {
                    // get the date difference
                    const timeDiffEnd = isAdd
                                        ? (new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate()+1,0,0,0)).getTime()-(new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),23,59,59)).getTime()
                                        : (new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),0,0,0)).getTime()-(new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate()+1,23,59,59)).getTime();
                    const timeDiff = timeDiffEnd; 
                    let diffDays = Math.ceil(Math.abs(timeDiff) / (1000 * 60 * 60 * 24));
                    if (isAdd){
                        task.dateEnd.setDate(task.dateEnd.getDate() + Math.abs(diffDays));
                        task = {...task, dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
                    }
                    else {
                        task.dateEnd.setDate(task.dateEnd.getDate() - (Math.abs(diffDays)));
                        task = {...task, dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
                    }
                    if (isAdd){
                        task.dateStart.setDate(task.dateStart.getDate() + Math.abs(diffDays));
                        task = {...task, dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),0,0,0)};
                    }
                    else {
                        task.dateStart.setDate(task.dateStart.getDate() - (Math.abs(diffDays)));
                        task = {...task, dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),0,0,0)};
                    }
                    // check for sundays
                    checkForSunday(isAdd,task);
                    isUpdated = true;
                }
                // start date forward resize - reduce the duration by moving forward the start date
                else 
                if (isStart && taskItem.dateStart > new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),0,0,0) && 
                    startTaskItem.dateStart >= new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),0,0,0) && 
                    (task.flagGoodsIssued ?? false)===false && !isProcessed &&
                    (dateEnd_dateStart_diffDays > 0 && dateEnd_dateStart_diffDays < 3) &&
                    task.currentParent!=taskItem.currentParent) {
                    // get the date difference
                    const timeDiffEnd = isAdd
                                        ? (new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate()+1,0,0,0)).getTime()-(new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),23,59,59)).getTime()
                                        : (new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate()-1,23,59,59)).getTime()-(new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate(),0,0,0)).getTime();
                    const timeDiff = timeDiffEnd; 
                    let diffDays = Math.ceil(Math.abs(timeDiff) / (1000 * 60 * 60 * 24));
                    if (isAdd){
                        task.dateEnd.setDate(task.dateEnd.getDate() + (Math.abs(diffDays)));
                        task = {...task, dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
                    }
                    else {
                        task.dateEnd.setDate(task.dateEnd.getDate() - Math.abs(diffDays));
                        task = {...task, dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
                    }
                    if (isAdd){
                        task.dateStart.setDate(task.dateStart.getDate() + (Math.abs(diffDays)));
                        task = {...task, dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),0,0,0)};
                    }
                    else {
                        task.dateStart.setDate(task.dateStart.getDate() - Math.abs(diffDays));
                        task = {...task, dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),0,0,0)};
                    }
                    // check for sundays
                    checkForSunday(isAdd,task);
                    isUpdated = true;
                }
                // get the updated
                if (isUpdated){
                   if (ganttChartRef.current && task.project!==startTaskItem.project){
                        const resizedTaskItem = resizedTasks.filter(x => x.taskItem.project === task.project);
                        if (resizedTaskItem.length === 0){
                            setResizedTask(origTaskItem,task,false);
                        }
                   }
                   
                }
            } 
            else {
                if (task.project!==taskItem.project){
                    task.dateEnd = new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59);
                }
            }
        }

        function updateTasks(tasks: TaskItem[]){
            if (tasks) {
                tasks.forEach(task => {

                    // check if it's in resized Task and get the latest data
                    const resizedTaskItem = resizedTasks.filter(x => x.taskItem.project === task.project);
                    if (resizedTaskItem.length>0)
                    {
                        updateDates(resizedTaskItem[0].taskItem);
                    } else {
                        updateDates(task);
                    }
                    
                    if ((task.tasks ?? []).length>0){
                        return updateTasks(task.tasks);
                    }
                })
            }            
        }

        if (isEnd || isStart){
            // step 1
            // for now it will be allTaskItems if not showRelated and searchtext is not empty
            const isUseAll = isDetailFiltered();
            project = isUseAll===true
                        ? getCurrentProjectTaskItem(allTaskItemsRef,taskItem.currentParent) //getProject(allTaskItemsRef,taskItem)
                        : getCurrentProjectTaskItem(taskItems, taskItem.currentParent); //getProject(taskItems,taskItem);

            if (project) convertDates(project);
            // step 2
            if (project) updateDates(project);
            // step 3
            // we don't need this anymore as it will not check the same level
            // instead get the parent
            //if (project) updateTasks(project.tasks)
            // step 4
            if ((runningCascade ?? false)===false){
                doResizingCascade({ganttChartRef,callback,project,isEnd,isStart});
            }
        }
    }
    const updateGanttChart = (ganttChartRef: React.RefObject<GanttChart | null>) => {
        if (ganttChartRef.current){
            ganttChartRef.current.beginUpdate();
            resizedTasks.filter(x => (x.resized ?? false)===false).forEach(resized => {
                let task = ganttChartRef?.current?.getTask(resized.taskItem.id) as TaskItem;
                if (task){
                    // if (task.dateEnd===resized.origTaskItem.dateEnd)
                    //     task.dateEnd = resized.taskItem.dateEnd;
                    //task.dateEnd = resized.taskItem.dateEnd;
                    //task.dateStart = resized.taskItem.dateStart;
                    // if (resized.skip===false){
                    //     task.duration = resized.taskItem.duration;
                    //     task.status = resized.taskItem.status;
                    //     //task.duration = resized.taskItem.duration;
                    // } else {
                    //     const timeDiffEnd = task.dateEnd.getTime()-task.dateStart.getTime();
                    //     const timeDiff = timeDiffEnd; 
                    //     const diffDays = Math.ceil(Math.abs(timeDiff) / (1000 * 60 * 60 * 24));
                    //     const sundays = countSundays(task);
                    //     task.duration = diffDays - sundays;
                    // }
                    task = {
                        ...task,
                        dateStart: new Date(resized.taskItem.dateStart.getFullYear(),resized.taskItem.dateStart.getMonth(),resized.taskItem.dateStart.getDate(),0,0,0),
                        dateEnd: new Date(resized.taskItem.dateEnd.getFullYear(),resized.taskItem.dateEnd.getMonth(),resized.taskItem.dateEnd.getDate(),23,59,59),
                        duration: resized.taskItem.duration,
                        status: resized.taskItem.status,
                    }
                    if (ganttChartRef.current){
                        ganttChartRef.current.updateTask(task.id, task);
                        resized.resized = true;
                    }
                }
            });
            ganttChartRef.current.endUpdate();
        }
    }
    const getProject = (taskItems: TaskItem[], taskItem: TaskItem): TaskItem | null => {
        let project: TaskItem | null = null;
        let foundProject = false;
        function findProject(tasks: TaskItem[]) : void {
            tasks.forEach(task => {
                if (task.project === taskItem.project && !foundProject){
                    foundProject = true;
                } else 
                if ((task?.tasks ?? []).length>0){
                    if ((task.parent ?? '').length===0 && !foundProject)
                        project = task;
                    return findProject(task.tasks);
                }
            });
        };
        findProject(taskItems);
        if (!foundProject)
            project = null;
        return project;
    }
    const getCurrentProject = (taskItems: TaskItem[], taskItem: TaskItem): TaskItem | null => {
        let project: TaskItem | null = null;
        let foundProject = false;
        function findProject(tasks: TaskItem[]) : void {
            tasks.forEach(task => {
                if (task.project === taskItem.project && !foundProject){
                    foundProject = true;
                    return task;
                } else 
                if ((task?.tasks ?? []).length>0){
                    if ((task.parent ?? '').length===0 && !foundProject)
                        project = task;
                    return findProject(task.tasks);
                }
            });
        };
        findProject(taskItems);
        if (!foundProject)
            project = null;
        return project;
    }
    const getTaskItemClone = (taskItems: TaskItem[], taskItem: TaskItem): TaskItem | null => {
        let task: TaskItem | null = null;
        let foundTask = false;
        function findTask(tasks: TaskItem[]){
            tasks.forEach(t => {
                if (t.project === taskItem.project &&
                    (t.parent ?? '') === (taskItem.parent ?? '') &&
                    t.item === taskItem.item && 
                    !foundTask){
                        task = structuredClone(t);
                        foundTask = true;
                }
                if (!foundTask && t.tasks.length>0){
                    return findTask(t.tasks);
                }
            });
        }
        findTask(taskItems);
        return task;
    }
    const getTaskItem = (tasks: TaskItem[], taskItem: TaskItem): TaskItem | null => {
        let task: TaskItem | null = null;
        let foundTask = false;
        function findTask(tasks: TaskItem[]){
            tasks.forEach(t => {
                if (taskItem && taskItem.project !== undefined) 
                    if (t.project === taskItem.project &&
                        t.item === taskItem.item && 
                        !foundTask){
                            task = t;
                            foundTask = true;
                    }
                if (!foundTask && t.tasks.length>0){
                    return findTask(t.tasks);
                }
            });
        }
        findTask(tasks);
        return task;
    }
    const getCurrentProjectTaskItem = (tasks: TaskItem[], currentProject: string): TaskItem | null => {
        let task: TaskItem | null = null;
        let foundTask = false;
        function findTask(tasks: TaskItem[]){
            tasks.forEach(t => {
                    if (t.project === currentProject){
                        task = t;
                        return task;
                    }
                if (!foundTask && t.tasks.length>0){
                    return findTask(t.tasks);
                }
            });
        }
        findTask(tasks);
        return task;
    }
    const countSundays = (task: TaskItem): number => {
        let sundays = 0;
        let taskCopy = {
            startDate: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),task.dateStart.getHours(),task.dateStart.getMinutes()),
            endDate: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),task.dateEnd.getHours(),task.dateEnd.getMinutes())
        };
        const endDate = taskCopy.endDate;
        let runningDate = taskCopy.startDate;
        while(runningDate<endDate){
            if (runningDate.getDay() === 0){
                sundays++;
            }
            runningDate.setDate(runningDate.getDate() + 1);
        }
        return sundays;
    }
    const updateProject = (taskItem: TaskItem, callback: () => void) => {
        setTaskItems(prev => {
            prev = prev.map(x => {
                if (x.project === taskItem.project && (x.parent ?? '').length===0){
                    x = taskItem;
                }
                return x;
            });
            return prev;
        });
        if (callback)
            callback();
    }
    const resetGanttChart = () => {
        // reset chart - use the allTaskItems
        if ((searchText ?? '').length>0){
            searchProd(allTaskItems,searchText,showRelated ?? false);
            setResetGanttChart(true);
        }
        else {
            setTaskItems(structuredClone(allTaskItems));
            setRefreshChart(true);
        }
        setProductionOrders([]);
        
        setTimeout(()=>{
            formatTimelineWeekAndDay();
        },100);
    }
    const selectBatchTaskItem = (taskItem: TaskItem, isChecked: boolean) => {
        let project: TaskItem | null = null;
        // find main root project
        const sourceTaskItems = isDetailFiltered()
                                ? structuredClone(allTaskItemsRef)
                                : structuredClone(taskItems);
        project = getProject(sourceTaskItems,taskItem);
        if (project) convertDates(project);
        if (isChecked===true){
            if ((project?.project ?? '').length>0){
                // check if already exists, if not insert it
                const projectBatchSelected = batchTaskItems.filter(x => x.project === (project?.project ?? ''));
                convertDates(taskItem);
                if (projectBatchSelected.length===0){
                    if (project) project.tasks = [structuredClone(taskItem)];
                    setBatchTaskItems([...batchTaskItems,structuredClone(project)]);
                } else {
                    // update the project and add the taskItem
                    projectBatchSelected[0].tasks.push(taskItem);
                }

                let selectedTask = isDetailFiltered()
                                   ? getTaskItem(allTaskItemsRef,taskItem)
                                   : getTaskItem(taskItems,taskItem);
                if (selectedTask && selectedTask.project === taskItem.project && selectedTask.parent === taskItem.parent &&
                    selectedTask.item ===  taskItem.item){
                    selectedTask.batchSelected = true; // = {...selectedTask, batchSelected: true };
                }
            } else {
                // no project tree
                setBatchTaskItems([...batchTaskItems,structuredClone(taskItem)]);
            }
        } else {
            // remove from the list of batch selected
            if ((project?.project ?? '').length>0){
                const projectBatchSelected = batchTaskItems.filter(x => x.project === (project?.project ?? ''));
                if (projectBatchSelected.length>0){
                    const updatedTasks = projectBatchSelected[0].tasks.filter(x => x.project!==taskItem.project && 
                                                                                   x.parent !== taskItem.parent && 
                                                                                   x.item !== taskItem.item);
                    projectBatchSelected[0].tasks = updatedTasks;

                    let selectedTask = isDetailFiltered()
                                       ? getTaskItem(allTaskItemsRef,taskItem)
                                       : getTaskItem(taskItems,taskItem);
                    if (selectedTask && selectedTask.project === taskItem.project && selectedTask.parent === taskItem.parent &&
                        selectedTask.item ===  taskItem.item){
                        selectedTask.batchSelected = false;
                        //selectedTask = {...selectedTask, batchSelected: false };
                    }
                }
            } else {
                let selectedTask = isDetailFiltered()
                                       ? getTaskItem(allTaskItemsRef,taskItem)
                                       : getTaskItem(taskItems,taskItem);
                if (selectedTask && selectedTask.project === taskItem.project && selectedTask.parent === taskItem.parent &&
                    selectedTask.item ===  taskItem.item){
                    selectedTask.batchSelected = false;
                }
            }
        }
    }
    const checkIfBatchSelectedTaskItem = (taskItem: TaskItem): boolean => {
        if (isDetailFiltered()){
            return isBatchSelectedTaskItemFromAll(taskItem);
        } else 
            return isBatchSelectedTaskItem(taskItem);
    }
    const isBatchSelectedTaskItem = (taskItem: TaskItem): boolean => {
        let project: TaskItem | null = null;
        let isSelected: boolean = false;
        const sourceTaskItems = isDetailFiltered()
                                ? structuredClone(allTaskItemsRef)
                                : structuredClone(taskItems);
        project = getProject(sourceTaskItems,taskItem);
        if ((project?.project ?? '').length>0){
            const selectedProject = batchTaskItems.filter(x => x.project === project?.project ?? "");
            if (selectedProject.length>0){
                isSelected = selectedProject[0].tasks.filter(x => x.project === taskItem.project && 
                                                                  x.parent === taskItem.parent &&
                                                                  x.item === taskItem.item).length>0;
            }
        } else {
            const batchedTaskItem = batchTaskItems.filter(x => x.project === taskItem.project);
            isSelected = batchedTaskItem.length>0;
        }
        return isSelected;
    }
    const isBatchSelectedTaskItemFromAll = (taskItem: TaskItem): boolean => {
        let project: TaskItem | null = null;
        let isSelected: boolean = false;
        const sourceTaskItems = structuredClone(allTaskItemsRef);
        project = getProject(sourceTaskItems,taskItem);
        if ((project?.project ?? '').length>0){
            const selectedProject = batchTaskItems.filter(x => x.project === project?.project ?? "");
            if (selectedProject.length>0){
                isSelected = selectedProject[0].tasks.filter(x => x.project === taskItem.project && 
                                                                  x.parent === taskItem.parent &&
                                                                  x.item === taskItem.item).length>0;
            }
        }
        return isSelected;
    }
    const processBatchSelectedTaskItems = (props: {
        ganttChartRef: React.RefObject<GanttChart | null>,
        startTaskItemRef: TaskItem,
        taskItem: TaskItem,
    }) => {
        const {ganttChartRef, startTaskItemRef, taskItem} = props;
        let project: TaskItem | null = null;
        project = isDetailFiltered()
                  ? getProject(allTaskItemsRef,taskItem)
                  : getProject(taskItems,taskItem);

        // resize all selected
        startTaskItem = startTaskItemRef;
        //taskItem.dateStart = dayjs(startTaskItem.dateStart).toDate();
        endTaskItem = structuredClone(taskItem);

        const endDateTimeDiff = endTaskItem.dateEnd.getTime()-startTaskItem.dateEnd.getTime();
        let isEnd = endDateTimeDiff!==0;

        const startDateTimeDiff = endTaskItem.dateEnd.getTime()-startTaskItem.dateStart.getTime();
        let isStart = startDateTimeDiff!==0 && !isEnd;
        const isAdd = isStart 
                        ? (endTaskItem.dateStart.getTime()-startTaskItem.dateStart.getTime())>0
                        : (endTaskItem.dateEnd.getTime()-startTaskItem.dateEnd.getTime())>0

        //const isStart = startTaskItem.dateStart.toString() !== endTaskItem.dateStart.toString();
        //const isEnd = startTaskItem.dateEnd.toString() !== endTaskItem.dateEnd.toString();
        //const isAdd = Math.ceil(startTaskItem.duration) < Math.ceil(endTaskItem.duration);

        if (isEnd){
            // add 23:59:59 to make it up to before midnight
            taskItem.dateEnd = new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate(),23,59,59);
        } else 
        if (isStart){
            // midnight
            taskItem.dateStart = new Date(taskItem.dateStart.getFullYear(),taskItem.dateStart.getMonth(),taskItem.dateStart.getDate(),0,0,0);            
        }
        setSelectedTaskId(taskItem.id);
        const timeDiffEnd = isEnd
                                ? endTaskItem.dateEnd.getTime()-startTaskItem.dateEnd.getTime()
                                : endTaskItem.dateStart.getTime()-startTaskItem.dateStart.getTime()
        const timeDiff = timeDiffEnd; 
        let days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

        setIsResizing(true);
        const allTaskItemsClone = structuredClone(allTaskItems);
        function batchResizeTaskItem() {
            
            let batchSelectedTaskItem = isDetailFiltered()
                                          ? batchTaskItems.filter(x => x.tasks.filter(t => (t.resized ?? false)===false && 
                                                                      ((t.flagGoodsIssued ?? false)===false || 
                                                                       ((t.flagGoodsIssued ?? false)===true && isEnd))).length>0)
                                          : batchTaskItems.filter(x => x.project === (project?.project ?? '') && 
                                                                      (x.parent ?? '') === (project?.parent ?? '') && 
                                                                      ((x.flagGoodsIssued ?? false)===false  ||
                                                                      ((x.flagGoodsIssued ?? false)===true && isEnd)));  
                                                                      
            if (!project){
                // check if there are still available to process 
                // these are the ones without parent tree
                const noParentBatchSelectedTaskItem = batchTaskItems.filter(x => !x.resized);
                if (noParentBatchSelectedTaskItem.length>0){
                    batchSelectedTaskItem = noParentBatchSelectedTaskItem;
                }
            }

            if (batchSelectedTaskItem.length>0){
                const batchTaskItem = (batchSelectedTaskItem[0]?.tasks ?? []).length>0
                                        ? batchSelectedTaskItem[0].tasks.filter(x => (x.resized ?? false)===false)
                                        : [batchSelectedTaskItem[0]];
                if (batchTaskItem.length>0){

                    let selectedTaskItem = getTaskItem(allTaskItemsClone,batchTaskItem[0]);
                    if (selectedTaskItem){
                        convertDatesAndResetDates(selectedTaskItem);
                        // save the original
                        startTaskItem = structuredClone(selectedTaskItem);

                        if (isEnd)
                            selectedTaskItem.dateEnd.setDate(selectedTaskItem.dateEnd.getDate() + days);
                        else 
                        if (isStart)
                            selectedTaskItem.dateStart.setDate(selectedTaskItem.dateStart.getDate() + days);

                        selectedTaskItem = {
                            ...selectedTaskItem, 
                            dateStart: new Date(selectedTaskItem.dateStart.getFullYear(),selectedTaskItem.dateStart.getMonth(),selectedTaskItem.dateStart.getDate(),0,0,0),
                            dateEnd: new Date(selectedTaskItem.dateEnd.getFullYear(),selectedTaskItem.dateEnd.getMonth(),selectedTaskItem.dateEnd.getDate(),23,59,59)
                        };

                        // check for sundays
                        checkForSunday(isAdd, selectedTaskItem);

                        // add in resized tasks
                        setResizedTask(startTaskItem,selectedTaskItem,false);

                        // then resize
                        if (project){
                            const ret = resizingEndDate({
                                ganttChartRef,
                                taskItem: selectedTaskItem as TaskItem, 
                                callback: (updatedProdOrders?: ProductionOrder[] | null) => {
                                    //refreshSelectedTaskItem(ganttChartRef,taskItem.id);
                                    batchTaskItem[0].resized = true;
                                    // run batch resize again until no more items to process
                                    batchResizeTaskItem();
                                },
                                pIsStart: isStart,
                                pIsEnd: isEnd,
                                updateMainTask: true
                            });
                        } else {
                            batchTaskItem[0].resized = true;
                            batchResizeTaskItem();
                        }
                    }
                } else {
                    setIsResizing(false);   
                    if (!project) updateBatchWithoutProjectTree(ganttChartRef);
                }
            } else {
                setIsResizing(false);
                if (!project) updateBatchWithoutProjectTree(ganttChartRef);
            }
        }
        batchResizeTaskItem();
    }

    const updateBatchWithoutProjectTree = (ganttChartRef: React.RefObject<GanttChart | null>) => {
        updateGanttChart(ganttChartRef);

        resizedTasks.map(x => x.skip = false);

        let updatedProdOrders: ProductionOrder[] = productionOrders.filter(x => resizedTasks.filter(r => r.productionOrder===x.productionOrder).length===0); 
        resizedTasks.forEach(x => updatedProdOrders.push(x));
        setProductionOrders(updatedProdOrders);
    }

    const clearAllBatchSelectedTaskItems = () => {
        batchTaskItems.forEach(batch => {
            if (batch.tasks){
                batch.tasks.forEach(task => {
                    let selectedTaskItem = isDetailFiltered()
                                           ? getTaskItem(allTaskItemsRef,task)
                                           : getTaskItem(taskItems,task);
                    if (selectedTaskItem)
                        selectedTaskItem.batchSelected = false;
                });
            }
        });
        setBatchTaskItems([]);
    }

    const convertDatesAndResetDates = (taskItem: TaskItem) => {
        taskItem.dateEnd = new Date(taskItem.dDateEnd);
        taskItem.dateEnd = new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate(),23,59,59);
        taskItem.dateStart = new Date(taskItem.dDateStart);
        taskItem.dateStart = new Date(taskItem.dateStart.getFullYear(),taskItem.dateStart.getMonth(),taskItem.dateStart.getDate(),0,0,0);
    }
    const rollbackResizedTasks = async (ganttChartRef: React.RefObject<GanttChart | null>) => {
        return new Promise((resolve,reject) => {
            if (ganttChartRef.current){
                ganttChartRef.current.beginUpdate();
                (productionOrders ?? []).forEach(resized => {
                    let resizedTask = ganttChartRef.current?.getTask(resized.taskItem.id) as TaskItem;
                    const task = getTaskItem(rollbackAllTaskItems, resizedTask);
                    if (resizedTask && task){

                        const utcStartDate = new Date(task.dDateStart);
                        const utcEndDate = new Date(task.dDateEnd);
                
                        const localStartDate = new Date(utcStartDate.getTime() + (utcStartDate.getTimezoneOffset() * 6000));
                        const localEndDate = new Date(utcEndDate.getTime() + (utcEndDate.getTimezoneOffset() * 6000));


                        resizedTask.dateStart = localStartDate; //moment(task.dDateStart).utc(true).toDate();
                        resizedTask.dateEnd = localEndDate; //moment(task.dDateEnd).utc(true).toDate();
                        //resizedTask.dateStart = new Date(task.dDateStart);
                        //resizedTask.dateEnd = new Date(task.dDateEnd);
                        resizedTask.status = task.status;
                        resizedTask.duration = task.duration;
                        resizedTask = {...resizedTask, 
                                        dateStart: new Date(resizedTask.dateStart.getFullYear(),resizedTask.dateStart.getMonth(),resizedTask.dateStart.getDate(),0,0,0),
                                        dateEnd: new Date(resizedTask.dateEnd.getFullYear(),resizedTask.dateEnd.getMonth(),resizedTask.dateEnd.getDate(),23,59,59)};
                        ganttChartRef.current?.updateTask(resizedTask.id,resizedTask);
                    }
                });
                resizedTasks=[];
                ganttChartRef.current.endUpdate();
            }
            return resolve('ok');
        });
    }
    const rollbackResizedFilteredTaskItems =  async () => {
        return new Promise((resolve,reject) => {
            if (filteredTaskItems.length>0){
                (productionOrders ?? []).forEach(x => {
                    filteredTaskItems.map(task => {
                        if (task.id === x.id){
                            const utcStartDate = new Date(x.origTaskItem.dDateStart);
                            const utcEndDate = new Date(x.origTaskItem.dDateEnd);
                
                            const localStartDate = new Date(utcStartDate.getTime() + (utcStartDate.getTimezoneOffset() * 6000));
                            const localEndDate = new Date(utcEndDate.getTime() + (utcEndDate.getTimezoneOffset() * 6000));

                            task.dateStart = localStartDate;
                            task.dateEnd = localEndDate;
                            task.status = x.origTaskItem.status;
                            task.duration = x.origTaskItem.duration;
                            task = {...task, 
                                       dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),0,0,0),
                                       dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
                        }
                        return task;
                    });
                    setFilteredTaskItems(filteredTaskItems);
                })
            }
            return resolve('ok');
        })
    }
    const rollbackResizedTaskItems =  async () => {
        return new Promise((resolve,reject) => {
            (productionOrders ?? []).forEach(x => {
                let task = getTaskItem(rollbackAllTaskItems,x.origTaskItem);
                if (task){
                    const utcStartDate = new Date(x.origTaskItem.dDateStart);
                    const utcEndDate = new Date(x.origTaskItem.dDateEnd);
        
                    const localStartDate = new Date(utcStartDate.getTime() + (utcStartDate.getTimezoneOffset() * 6000));
                    const localEndDate = new Date(utcEndDate.getTime() + (utcEndDate.getTimezoneOffset() * 6000));

                    task.dateStart = localStartDate;
                    task.dateEnd = localEndDate;
                    task.status = x.origTaskItem.status;
                    task.duration = x.origTaskItem.duration;
                    task = {...task, 
                            dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),0,0,0),
                            dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
                }
            });
            setTaskItems(taskItems);
            return resolve('ok');
        })
    }
    const rollbackResizedAllTaskItems =  async () => {
        return new Promise((resolve,reject) => {
            (productionOrders ?? []).forEach(x => {
                    let task = getTaskItem(rollbackAllTaskItems,x.origTaskItem);
                    if (task){

                        const utcStartDate = new Date(x.origTaskItem.dDateStart);
                        const utcEndDate = new Date(x.origTaskItem.dDateEnd);
                
                        const localStartDate = new Date(utcStartDate.getTime() + (utcStartDate.getTimezoneOffset() * 6000));
                        const localEndDate = new Date(utcEndDate.getTime() + (utcEndDate.getTimezoneOffset() * 6000));

                        task.dateStart = localStartDate; //new Date(x.origTaskItem.dDateStart);
                        task.dateEnd = localEndDate; //new Date(x.origTaskItem.dDateEnd);
                        task.status = x.origTaskItem.status;
                        task.duration = x.origTaskItem.duration;
                        task = {...task, 
                                dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate()),
                                dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)};
                    }
            });
            setAllTaskItems(allTaskItems);
            return resolve('ok');
        });
    }
    const updateGanttChartFromSavedTaskItems = async (ganttChartRef: React.RefObject<GanttChart | null>) => {
        return new Promise((resolve,reject) => {
            if (ganttChartRef.current){
                ganttChartRef.current.beginUpdate();
                let selectedTaskIds = ganttChartRef.current.getSelectedTasks();
                (updatedTaskItems ?? []).forEach(task => {
                    let resizedTask = ganttChartRef.current?.getTask(task.id) as TaskItem;
                    if (resizedTask){
                        //const utcStartDate = new Date(task.dDateStart);
                        // const utcEndDate = new Date(task.dDateEnd);
                
                        // //const localStartDate = new Date(utcStartDate.getTime() + (utcStartDate.getTimezoneOffset() * 6000));
                        // const localEndDate = new Date(utcEndDate.getTime() + (utcEndDate.getTimezoneOffset() * 6000));
                        // resizedTask.dateStart = new Date(task.dDateStart);
                        // resizedTask.dateEnd = localEndDate;
                        // resizedTask.duration = task.duration;
                        // resizedTask = {...resizedTask, 
                        //             dateStart: new Date(resizedTask.dateStart.getFullYear(),resizedTask.dateStart.getMonth(),resizedTask.dateStart.getDate(),0,0,0),
                        //             dateEnd: new Date(resizedTask.dateEnd.getFullYear(),resizedTask.dateEnd.getMonth(),resizedTask.dateEnd.getDate(),23,59,59)};
                        // resizedTask.status = task.status;
                        // ganttChartRef.current?.updateTask(resizedTask.id,resizedTask);
                        selectedTaskIds.push(resizedTask.id);
                    }
                });
                if (selectedTaskIds.length>0){
                    ganttChartRef.current.selectedTaskIds = selectedTaskIds;
                    setSelectedTaskIds(selectedTaskIds);
                }
                ganttChartRef.current.endUpdate();
            }
            setProductionOrders([]);    
            resizedTasks = [];
            return resolve('ok');
        })
    }
    const updateFilteredTaskItems =  async () => {
        return new Promise((resolve,reject) => {
            if (filteredTaskItems.length>0){
                (updatedTaskItems ?? []).forEach(x => {
                    filteredTaskItems.map(task => {
                        if (task.id === x.id){
                            task.dDateStart = x.dDateStart;
                            task.dDateEnd = x.dDateEnd;

                            const utcStartDate = new Date(x.dDateStart);
                            const utcEndDate = new Date(x.dDateEnd);
                    
                            const localStartDate = new Date(utcStartDate.getTime() + (utcStartDate.getTimezoneOffset() * 6000));
                            const localEndDate = new Date(utcEndDate.getTime() + (utcEndDate.getTimezoneOffset() * 6000));

                            task.dateStart = localStartDate;
                            task.dateEnd = localEndDate;
                            task.status = x.status;
                            task.duration = x.duration;
                            task = {...task, 
                                       dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate()),
                                       dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)
                            };
                        }
                        return task;
                    });
                    setFilteredTaskItems(filteredTaskItems);
                })
            }
            return resolve('ok');
        })
    }
    const updateTaskItems =  async () => {
        return new Promise((resolve,reject) => {
            (updatedTaskItems ?? []).forEach(x => {
                let task = getTaskItem(taskItems,x);
                if (task){
                    task.dDateStart = x.dDateStart;
                    task.dDateEnd = x.dDateEnd;

                    //const utcStartDate = new Date(x.dDateStart);
                    const utcEndDate = new Date(x.dDateEnd);
            
                    //const localStartDate = new Date(utcStartDate.getTime() + (utcStartDate.getTimezoneOffset() * 6000));
                    const localEndDate = new Date(utcEndDate.getTime() + (utcEndDate.getTimezoneOffset() * 6000));

                    task.dateStart = new Date(x.dDateStart);
                    task.dateEnd = localEndDate;
                    task.status = x.status;
                    task.duration = x.duration;
                    task = {...task, 
                                dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate()),
                                dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)
                    };
                }
            });
            setTaskItems(taskItems);
            return resolve('ok');
        })
    }
    const updateAllTaskItems =  async () => {
        return new Promise((resolve,reject) => {
            (updatedTaskItems ?? []).forEach(x => {
                    let task = getTaskItem(allTaskItems,x);
                    if (task){
                        task.dDateStart = x.dDateStart;
                        task.dDateEnd = x.dDateEnd;

                        //const utcStartDate = new Date(x.dDateStart);
                        const utcEndDate = new Date(x.dDateEnd);
                
                        //const localStartDate = new Date(utcStartDate.getTime() + (utcStartDate.getTimezoneOffset() * 6000));
                        const localEndDate = new Date(utcEndDate.getTime() + (utcEndDate.getTimezoneOffset() * 6000));

                        task.dateStart = new Date(x.dDateStart);
                        task.dateEnd = localEndDate;
                        task.status = x.status;
                        task.duration = x.duration;
                        task = {...task, 
                                    dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate()),
                                    dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)
                        };
                    }
            });
            setAllTaskItems(allTaskItems);
            return resolve('ok');
        });
    }
    const updateRollbackAllTaskItems =  async () => {
        return new Promise((resolve,reject) => {
            (updatedTaskItems ?? []).forEach(x => {
                    let task = getTaskItem(rollbackAllTaskItems,x);
                    if (task){
                        task.dDateStart = x.dDateStart;
                        task.dDateEnd = x.dDateEnd;

                        //const utcStartDate = new Date(x.dDateStart);
                        const utcEndDate = new Date(x.dDateEnd);
                
                        //const localStartDate = new Date(utcStartDate.getTime() + (utcStartDate.getTimezoneOffset() * 6000));
                        const localEndDate = new Date(utcEndDate.getTime() + (utcEndDate.getTimezoneOffset() * 6000));

                        task.dateStart = new Date(x.dDateStart);
                        task.dateEnd = localEndDate;
                        task.status = x.status;
                        task.duration = x.duration;
                        task = {...task, 
                                    dateStart: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate()),
                                    dateEnd: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),23,59,59)
                        };
                        
                    }
            });

            setRollbackAllTaskItems(rollbackAllTaskItems);
            return resolve('ok');
        });
    }
    const refreshPageTasks = async (ganttChartRef: React.RefObject<GanttChart | null>) => {
        return new Promise((resolve,reject) => {
            if (ganttChartRef.current){
                ganttChartRef.current.beginUpdate();
                const tasks = ganttChartRef.current.getTasks();
                (tasks ?? []).forEach((task: TaskItem) => {
                    const utcStartDate = new Date(task.dDateStart);
                    const utcEndDate = new Date(task.dDateEnd);

                    const localStartDate = new Date(utcStartDate.getTime() + (utcStartDate.getTimezoneOffset() * 6000));
                    const localEndDate = new Date(utcEndDate.getTime() + (utcEndDate.getTimezoneOffset() * 6000));
                    task = {
                        ...task,
                        dateStart: new Date(localStartDate.getFullYear(),localStartDate.getMonth(),localStartDate.getDate(),0,0,0),
                        dateEnd: new Date(localEndDate.getFullYear(),localEndDate.getMonth(),localEndDate.getDate(),23,59,59),
                    }
                    ganttChartRef.current?.updateTask(task.id,task);
                });
                ganttChartRef.current.endUpdate();
            }
            return resolve('ok');
        })
    }
    return {
        resizingEndDate,
        getProject,
        updateProject,
        setStartTaskItem,
        resetGanttChart,
        getStartTaskItem,
        selectBatchTaskItem,
        isBatchSelectedTaskItem,
        processBatchSelectedTaskItems,
        clearAllBatchSelectedTaskItems,
        isBatchSelectedTaskItemFromAll,
        checkIfBatchSelectedTaskItem,
        convertDates,
        setResizedTask,
        rollbackResizedTasks,
        rollbackResizedFilteredTaskItems,
        rollbackResizedTaskItems,
        rollbackResizedAllTaskItems,
        updateGanttChartFromSavedTaskItems,
        updateFilteredTaskItems,
        updateTaskItems,
        updateAllTaskItems,
        updateRollbackAllTaskItems,
        refreshPageTasks,
        getTaskItem,
    }
}