import { FC, ReactNode } from "react";
import { FooterContent, FooterRoot } from "./styles/SharedStyles";



export interface IProductionPlanningFooterProps {    
    children: ReactNode
}

export const ProductionPlanningFooter:FC<IProductionPlanningFooterProps> = (props:IProductionPlanningFooterProps) => {
    const {children} = props;
    return (
        <FooterRoot position="static">
            <FooterContent variant="dense">
                {children}
            </FooterContent>
        </FooterRoot>
    )
}