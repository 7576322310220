import { styled } from "@mui/material/styles"
import { useWindowResize } from "../../hooks/helpers/WindowResize";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useMBOMConversionGridHelpers } from "./hooks/MBOMConversionGridHelpers";
import { useMBOMConversionStore } from "../../../store/MBOMConversionStore";

export const MBOMGridWrapper = styled('div')({
    width: 'auto',
    '& .MuiDataGrid-iconButtonContainer .MuiDataGrid-filterIcon': {
        display: 'none!important',
    },
    '& .MuiDataGrid-menuIcon .MuiDataGrid-menuIconButton': {
        display: 'none!important',
    },
})

export const MBOMConversionGrid = () => {
    const {gridColumns,gridFilterItems} = useMBOMConversionGridHelpers();
    const {exportedBomHeaders,isMBOMLoading} = useMBOMConversionStore();
    const size = useWindowResize({
        xheight: {
            target: '.mbom-conversion-grid',
            addPx: 10,
        }
    }); 
    return (
        <MBOMGridWrapper
            className="mbom-conversion-grid"
            style={size ? {height: `${size.height}px`} : {}} >
            <DataGridPro 
                rowHeight={30}
                columns={gridColumns}
                loading={isMBOMLoading ?? false}
                rows={exportedBomHeaders ?? []}
                filterModel={gridFilterItems}
            />
        </MBOMGridWrapper>
    )
}