import dayjs from "dayjs";
import { useFilterDrawerStore } from "../../../../../store/FilterDrawerStore";
import { useProdFilterStore } from "../../../../../store/ProdFilterStore";
import { useProdPagerStore } from "../../../../../store/ProdPagerStore";
import { useProdPlanningFilterDrawerStore } from "../../../../../store/ProdPlanningFilterDrawerStore";
import { useProductionPlanningStore } from "../../../../../store/ProductionPlanningStore";
import { TaskItem } from "../../../../../Types/TaskItem";
import { IMultiTriggerFilterOptions } from "../../../../hooks/interfaces/IMultiTriggerFilterOptions";
import { IProdPlanningFilterOptions } from "../../../../hooks/interfaces/IProdPlanningFilterOptions";
import { useProductionPlanningFilterOptionsTriggers } from "../../../../hooks/triggers/ProductionPlanningFilterOptionsTriggers";
import { useProductionPlanningTriggers } from "../../../../hooks/triggers/ProductionPlanningTriggers";
import { useProductionPlanningScheduleGanttChart } from "../../../charts/hooks/ProductionPlanningScheduleGanttChart";
import { useSearchHelper } from "../../../toolbar/items/hooks/SearchHelper";

export const useProdPlanningFilterDrawerEvents = () => {
    const {setTriggerLoadFilteredTaskSchedules} = useProductionPlanningTriggers();
    const {setProductionOrders,setExpandedTasks, setReloadDataInProgress, targetDB} = useProductionPlanningStore();
    const {formatTimelineWeekAndDay, displayProgress} = useProductionPlanningScheduleGanttChart();
    const {dateStartFrom,dateStartTo,items,itemIds,statuses,statusIds,customers,customerIds,setEnableDateRange,setFilterSearchText,
           fulfillmentDateFrom, fulfillmentDateTo,setDateStartFrom,setDateStartTo,setProdOrder,setItem,setFilterRows,
           setCustomer,setFulfillmentDateFrom,setFulfillmentDateTo,setItemIds,setStatusIds,setCustomerIds,setSelectedFilter,
           setProdOrderIds,prodOrderIds,prodOrders,setFilterIds,showRelated,setShowRelated,enableDateRange,setSelectedProdOrderCodes,
           setSelectedItemCodes,setSelectedStatusCodes,setSelectedCustomerCodes,warehouses,warehouseIds,setWarehouse,setWarehouseIds,
           setSelectedWarehouseCodes} = useProdPlanningFilterDrawerStore();
    const {setMultiTriggerFilterOptions} = useProductionPlanningFilterOptionsTriggers();
    const {searchText} = useProdFilterStore();
    const {setOpenFilterDrawer} = useFilterDrawerStore();
    const {searchProd} = useSearchHelper();
    const {setResetPages,setRefreshPage,setRefreshPage2} = useProdPagerStore();
    const onClickHandlerFilterDrawerReset = () => {
        const fromDateRange = dayjs(new Date()).subtract(90, 'days').toDate();
        const fromDate = dayjs(new Date(fromDateRange.getFullYear(),fromDateRange.getMonth(), fromDateRange.getDate()));
        const toDateRange = dayjs(new Date()).add(30, 'days').toDate();
        toDateRange.setDate(toDateRange.getDate());
        const toDate = dayjs(new Date(toDateRange.getFullYear(),toDateRange.getMonth(), toDateRange.getDate()));
        setDateStartFrom(fromDate);
        setDateStartTo(toDate);
        setTimeout(() => {
            setProdOrder('');
            setItem('');
            setCustomer('');
            setWarehouse('');
            setFilterSearchText('');
            setFulfillmentDateFrom(null);
            setFulfillmentDateTo(null);
            setItemIds([]);
            setStatusIds([]);
            setCustomerIds([]);
            setWarehouseIds([]);
            setProdOrderIds([]);
            setFilterIds([]);
            setFilterRows([]);
            setShowRelated(true);
            setExpandedTasks([]);
            setEnableDateRange(true);
            setSelectedProdOrderCodes([]);
            setSelectedItemCodes([]);
            setSelectedStatusCodes([]);
            setSelectedCustomerCodes([]);
            setSelectedWarehouseCodes([]);
            setSelectedFilter('');
        },100);
        setTimeout(() => {
            setMultiTriggerFilterOptions({
                multiple: true,
                prodOrders: true,
                items: true,
                customers: true,
                status: true,
                warehouses: true,
                startDateFrom: fromDate,
                startDateTo: toDate,
                targetDB,
            } as IMultiTriggerFilterOptions);  
        },200);
    }
    const onClickHandlerFilterDrawerApply = () => {
        applyFilter();
    }
    const applyFilter = (pTargetDB?: string) => {
        const selectedItems: string[] = items.filter(x => itemIds.filter(i => i===x.id).length>0).map(x => x.code);
        const selectedStatuses: string[] = statuses.filter(x => statusIds.filter(i => i===x.id).length>0).map(x => x.id);
        const selectedCustomers: string[] = customers.filter(x => customerIds.filter(i => i===x.id).length>0).map(x => x.code);
        const selectedProdOrders: string[] = prodOrders.filter(x => prodOrderIds.filter(i => i===x.id).length>0).map(x => x.code);
        const selectedWarehouses: string[] = warehouses.filter(x => warehouseIds.filter(i => i===x.id).length>0).map(x => x.code);
        setRefreshPage(false);
        setRefreshPage2(false);
        setTriggerLoadFilteredTaskSchedules({
            searchText, 
            callback: (data: TaskItem[]) => {
                if ((searchText ?? '').length>0) {
                    searchProd(data,searchText,false); // for now Show Related in search if always off
                }
                setResetPages(true);
                setTimeout(()=>{
                    formatTimelineWeekAndDay();
                },100);
                setFilterIds([]);
                setFilterRows([]);
                setSelectedFilter('');
            },
            filterOptions: {
                dateStartFrom: (enableDateRange ?? false) === true ? dateStartFrom : '',
                dateStartTo: (enableDateRange ?? false) === true ? dateStartTo : '',
                prodOrders: selectedProdOrders,
                items: selectedItems,
                statuses: selectedStatuses,
                customers: selectedCustomers,
                fulfillmentDateFrom,
                fulfillmentDateTo,
                showRelated,
                warehouses: selectedWarehouses,
                targetDB: (pTargetDB ?? targetDB),
            } as IProdPlanningFilterOptions
        });
          setProductionOrders([]);
          setExpandedTasks([]);
          displayProgress(true);
          setOpenFilterDrawer(false);
          setReloadDataInProgress(true);
    }
    return {
        onClickHandlerFilterDrawerReset,
        onClickHandlerFilterDrawerApply,
        applyFilter,
    }
}