import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from "react";
export interface FilterDrawerContextData {
    openFilterDrawer: boolean,
    setOpenFilterDrawer: Dispatch<SetStateAction<boolean>>,
}

export const FilterDrawerDefaultValue: FilterDrawerContextData = {
    openFilterDrawer: false,
    setOpenFilterDrawer: () => {},
}

export const FilterDrawerStore = createContext<FilterDrawerContextData>(FilterDrawerDefaultValue);

export const useFilterDrawerStore = () => useContext(FilterDrawerStore);

export const FilterDrawerProvider: FC = ({children}) => {
    const [openFilterDrawer, setOpenFilterDrawer] = useState<boolean>(false);
    return (
        <FilterDrawerStore.Provider
            value={{
                openFilterDrawer,
                setOpenFilterDrawer,
            }}>
            {children}
        </FilterDrawerStore.Provider>
    )
}