import { FC } from "react";
import { ErrorLog } from "../../../../Types/ErrorLog";
import { useHelpers } from "../../../hooks/helpers/Helpers";
import styled from "@mui/system/styled";

export const SummaryLogViewerWrapper = styled('div')({
    padding: '10px',
});

export interface ILogSummaryProps {
    errorLogs: ErrorLog[]
}

export const LogSummary: FC<ILogSummaryProps> = (props: ILogSummaryProps) => {
    const {errorLogs} = props;
    const { renderHTML } = useHelpers();
    return (
        <SummaryLogViewerWrapper>
            {
                errorLogs && errorLogs.length>0 &&
                errorLogs.map(x => 
                    (
                        <>
                            <div><h4>{`${x.code} / ${x.title}`}</h4></div>
                            <div>{renderHTML(x.errorMessage)}</div>
                            <br /><br />
                        </>
                    )
                )
            }
        </SummaryLogViewerWrapper>
    )
}