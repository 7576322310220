import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import {FC} from "react"
import { useProductionPlanningStore } from "../../../../store/ProductionPlanningStore";
import { useExpandCollapseRadioGroupEvents } from "./hooks/ExpandCollapseRadioGroupEvents";
import { StyledFormControl } from "./styles/sharedStyles";

export const GridChartRadioGroup:FC = () => {    
    const {onChangeHandlerExpandCollapse} = useExpandCollapseRadioGroupEvents();
    const {viewOption} = useProductionPlanningStore();

    return (
        <StyledFormControl>
            <RadioGroup
                aria-labelledby="grid-chart-controlled-radio-buttons-group"
                name="grid-chart-controlled-radio-buttons-group"
                value={viewOption}
                onChange={onChangeHandlerExpandCollapse}
            >
                <FormControlLabel value="grid" control={<Radio inputProps={{ 'aria-label': 'Grid' }} sx={{
                    '& .MuiSvgIcon-root': {
                        fontSize: 12,
                    },
                }} />} label="Show Grid" />
                <FormControlLabel value="chart" control={<Radio inputProps={{ 'aria-label': 'Chart' }} sx={{
                    '& .MuiSvgIcon-root': {
                        fontSize: 12,
                    },
                }} />} label="Show Chart" />
            </RadioGroup>
        </StyledFormControl>
    )
}