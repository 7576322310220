import { constants } from '../../config/settings';
import { getLocaleLanguage } from '../../utils/utilities';

export const getSchedules = async (token) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );

    const options = {
        method: "GET",
        headers: headers,
    };

    const res = await window.fetch(`${constants.getProdPlanningExternalWebUrl()}${constants.getProductionPlanningSchedule}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}

export const getFilteredSchedules = async (token,filterOptions) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );
    headers.append("Content-Type", "application/json");

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(filterOptions),
    };

    const res = await window.fetch(`${constants.getProdPlanningExternalWebUrl()}${constants.getProductionPlanningFilteredSchedule}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}


export const updateSchedules = async (token,productionOrders) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );
    headers.append("Content-Type", "application/json");

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(productionOrders),
    };

    const res = await window.fetch(`${constants.getProdPlanningExternalWebUrl()}${constants.updateProductionPlanningSchedules}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}



export const taskProcess = async (token,processTask) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );
    headers.append("Content-Type", "application/json");

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(processTask)
    };

    const res = await window.fetch(`${constants.getProdPlanningExternalWebUrl()}${constants.taskProcessResize}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}

export const getSavedTaskItems = async (token, productionOrders) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );
    headers.append("Content-Type", "application/json");

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(productionOrders),
    };

    const res = await window.fetch(`${constants.getProdPlanningExternalWebUrl()}${constants.getSavedTaskItems}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}

export const setRequestRefreshData = async (token) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;    

    headers.append("Authorization", bearer);    

    const options = {
        method: "GET",
        headers: headers
    };

    const res = await window.fetch(`${constants.getProdPlanningExternalWebUrl()}${constants.productionPlanningCacheClearAll}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}