import { GridRowId, GridSelectionModel } from "@mui/x-data-grid-pro"
import { constants } from "../../../../../../config/settings";
import { useProdPlanningFilterDrawerStore } from "../../../../../../store/ProdPlanningFilterDrawerStore";
import { useProductionPlanningStore } from "../../../../../../store/ProductionPlanningStore";
import { IMultiTriggerFilterOptions } from "../../../../../hooks/interfaces/IMultiTriggerFilterOptions";
import { useProductionPlanningFilterOptionsTriggers } from "../../../../../hooks/triggers/ProductionPlanningFilterOptionsTriggers";
import { useProdPlanningFilterGridHelpers } from "./ProdPlanningFilterGridHelpers";

export const useProdPlanningFilterGridEvents = () => {
    const {setFilterIds,prodOrderIds,selectedFilter,setItemIds,setStatusIds,setCustomerIds,allWarehouses,setWarehouseIds,
           setProdOrderIds,dateStartTo, dateStartFrom,setSelectedCustomerCodes,allCustomers,enableDateRange,setSelectedWarehouseCodes,
           setSelectedStatusCodes,allStatuses,setSelectedItemCodes,allItems,setSelectedProdOrderCodes,allProdOrders} = useProdPlanningFilterDrawerStore();
    const {removeShowRelated,addShowRelated} = useProdPlanningFilterGridHelpers();
    const {setMultiTriggerFilterOptions} = useProductionPlanningFilterOptionsTriggers();
    const {targetDB} = useProductionPlanningStore();
    const onSelectionModelChangeHandlerFilterGrid = (selectionModel: GridSelectionModel, details?: any) => {
        setFilterIds(selectionModel);
        switch(selectedFilter){
            case constants.filterOptions.name.prodOrder:
                if (allProdOrders.length>0){
                    //setSelectedFilter(constants.filterOptions.type.ProdOrder);
                    setProdOrderIds(selectionModel);
                    setSelectedProdOrderCodes( allProdOrders.filter(x => selectionModel.filter(i => i===x.id).length>0).map(x => x.code));
                    setTimeout(() => {
                        setMultiTriggerFilterOptions({
                            option: constants.filterOptions.type.ProdOrder,
                            multiple: true,
                            prodOrders: false,
                            items: true,
                            customers: true,
                            status: true,
                            warehouses: true,
                            startDateFrom: (enableDateRange === true) ? dateStartFrom : '',
                            startDateTo: (enableDateRange === true) ? dateStartTo : '',
                            selectedIds: selectionModel,
                            targetDB,
                        } as IMultiTriggerFilterOptions);  
                    },200);
                }
                break;
            case constants.filterOptions.name.item:
                if (allItems.length>0){
                    setItemIds(selectionModel);
                    setSelectedItemCodes( allItems.filter(x => selectionModel.filter(i => i===x.id).length>0).map(x => x.code));
                    setTimeout(() => {
                        setMultiTriggerFilterOptions({
                            option: constants.filterOptions.type.Item,
                            multiple: true,
                            prodOrders: true,
                            items: false,
                            customers: true,
                            status: true,
                            warehouses: true,
                            startDateFrom: (enableDateRange === true) ? dateStartFrom : '',
                            startDateTo: (enableDateRange === true) ? dateStartTo : '',
                            selectedIds: selectionModel,
                            targetDB,
                        } as IMultiTriggerFilterOptions);  
                    },200);
                }
                break;
            case constants.filterOptions.name.status:
                if (allStatuses.length>0){
                    setStatusIds(selectionModel);
                    setSelectedStatusCodes( allStatuses.filter(x => selectionModel.filter(i => i===x.id).length>0).map(x => x.code));
                    setTimeout(() => {
                        setMultiTriggerFilterOptions({
                            option: constants.filterOptions.type.Status,
                            multiple: true,
                            prodOrders: true,
                            items: true,
                            customers: true,
                            status: false,
                            warehouses: true,
                            startDateFrom: (enableDateRange === true) ? dateStartFrom : '',
                            startDateTo: (enableDateRange === true) ? dateStartTo : '',
                            selectedIds: selectionModel,
                            targetDB,
                        } as IMultiTriggerFilterOptions);  
                    },200);
                }
                break;
            case constants.filterOptions.name.customer:
                if (allCustomers.length>0){
                    setCustomerIds(selectionModel);
                    setSelectedCustomerCodes( allCustomers.filter(x => selectionModel.filter(i => i===x.id).length>0).map(x => x.code));
                    setTimeout(() => {
                        setMultiTriggerFilterOptions({
                            option: constants.filterOptions.type.Customer,
                            multiple: true,
                            prodOrders: true,
                            items: true,
                            customers: false,
                            status: true,
                            warehouses: true,
                            startDateFrom: (enableDateRange === true) ? dateStartFrom : '',
                            startDateTo: (enableDateRange === true) ? dateStartTo : '',
                            selectedIds: selectionModel,
                            targetDB,
                        } as IMultiTriggerFilterOptions);  
                    },200);
                }
                break;
            case constants.filterOptions.name.warehouse:
                if (allWarehouses.length>0){
                    setWarehouseIds(selectionModel);
                    setSelectedWarehouseCodes( allWarehouses.filter(x => selectionModel.filter(i => i===x.id).length>0).map(x => x.code));
                    setTimeout(() => {
                        setMultiTriggerFilterOptions({
                            option: constants.filterOptions.type.Warehouse,
                            multiple: true,
                            prodOrders: true,
                            items: true,
                            customers: true,
                            status: true,
                            warehouses: false,
                            startDateFrom: (enableDateRange === true) ? dateStartFrom : '',
                            startDateTo: (enableDateRange === true) ? dateStartTo : '',
                            selectedIds: selectionModel,
                            targetDB,
                        } as IMultiTriggerFilterOptions);  
                    },200);
                }
                break;
            default:
                break;
        }
    };
    const findRowIdAndApplyShowRelated = (selectionModel: GridSelectionModel, details?: any) : void => {
        if (selectionModel.length<prodOrderIds.length){
            // unchecked prod order
            const rowId = prodOrderIds.filter(x => selectionModel.indexOf(x) === -1);
            if (rowId.length>0){
                removeShowRelated(rowId[0].toString());
            }
        } else {
            // checked prod order
            // get the last index
            const rowId = ((selectionModel[selectionModel.length-1] as GridRowId) ?? '').toString();
            if (rowId !== ''){
                addShowRelated(rowId);
            }
        }
    };
    return {
        onSelectionModelChangeHandlerFilterGrid
    }
}