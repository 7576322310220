import { IconButton, styled } from "@mui/material";

export const BomGridContainer = styled('div')({
    flex: 1,
    flexGrow: 'inherit',
});

export const BomHeaderGridContainer = styled('div')({
    flex: 1,
    flexGrow: 'inherit',
    height: '400px',
    maxHeight: '400px',
    marginTop: '5px',
});

export const GridWrapper = styled('div')({
    width: '100%',
    height: '84vh',
    '& .MuiDataGrid-root':{
        border: 'none',
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 600,
    },
    '& .view-error': {
        cursor: 'pointer',
        color: 'red',
    },
    '& .ok': {
        color: 'green',
    },
    '& .bom-header .ok': {
        marginLeft: '1px',
    },
    '& .error': {
        color: 'red',
    },
    backgroundColor: '#fff!important',
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#fff!important',
    },
    '& .bom-upload-header .MuiDataGrid-columnHeaderTitle': {
        textAlign: 'center',
        marginTop: 0,   
    },
    '& .bom-steps-header .MuiDataGrid-columnHeaderTitle': {
        textAlign: 'center',
        marginTop: 0,   
    },
    '& .bom-line-header .MuiDataGrid-columnHeaderTitle': {
        textAlign: 'center',
        marginTop: 0,   
    }
});

export const GridHeaderWrapper = styled('div')({
    width: '100%',
    height: '30vh',
    '& .MuiDataGrid-root':{
        border: 'none',
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 600,
    },
    '& .view-error': {
        cursor: 'pointer',
        color: 'red',
    },
    '& .ok': {
        color: 'green',  
    },
    '& .bom-header .ok': {
        marginLeft: '1px',
    },
    '& .error': {
        color: 'red',
    },
    backgroundColor: '#fff!important',
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#fff!important',
    },
    '& .bom-header-grid .MuiDataGrid-columnHeaderTitle': {
        textAlign: 'center',
        marginTop: 0,   
    }
});

export const RowIcon = styled(IconButton)({
    padding: '0 2px 0 2px',
    marginRight: '0 2px 0 2px',
})

export const GridIcons = styled('div')({
    display: 'flex',
    flexFlow: 'row',
    //marginLeft: '-10px',
    '& .delete-checkbox': {
        color: 'red!important',
    },
    '& .checkbox': {
        padding: '0 5px',
    }
});

export const HeaderGridIcons = styled('div')({
    display: 'flex',
    flexFlow: 'row',
    marginLeft: '-10px',
    backgroundColor: '#fff',
    padding: 0,
    '& .delete-checkbox': {
        color: 'red!important',
    },
    '& .checkbox': {
        //padding: '0 5px',
    }
});

export const EmptyCell = styled('div')({
    width: '2px',
});


export const GridContainer = styled('div')({
    width: '100%', 
    height: '84vh', 
    '& .MuiDataGrid-root':{
        border: 'none',
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnsContainer': {
        justifyContent: 'center',
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 600,
    },
    backgroundColor: '#fff',
    '& .MuiDataGrid-viewport .MuiDataGrid-renderingZone': {
        width: '100%!important',
    },
    '& .MuiDataGrid-viewport .MuiDataGrid-row': {
        width: '100%important',
    },
});

export const SummaryLogViewerWrapper = styled('div')({
    padding: '10px',
});