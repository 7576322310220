import { styled } from "@mui/material";

export const UploadContianerRoot = styled('div')({
    flex: 1,
    flexGrow: 'inherit',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: '#f6f6f6',
});

export const UploadContainerBody = styled('div')({
    display: 'flex',
    flexFlow: 'row',
    width: '99%',
    overflow: 'hidden',
    marginTop: '20px',
    marginBottom: '0',
    backgroundColor: '#f6f6f6',
});

export const LeftBody = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '38%',
    marginLeft: '20px'
})

export const RightBody = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '61%',
    rightMargin: '20px'
});

export const LeftGrid = styled('div')({
    width: '100%',
    paddingTop: '5px',
});

export const RightGrid = styled('div')({
    width: '100%',    
    //paddingTop: '5px',    
    '& .MuiBox-root': {
        padding: 'unset!important',
    }
});

export const Grow = styled('div')({
    flexGrow: 1,
});

export const Separator = styled('div')({
    width: '15px',
    backgroundColor: '#f6f6f6',
});