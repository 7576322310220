import { GridLinkOperator } from "@mui/x-data-grid-pro";
import { useExportBOMStore } from "../../../../store/ExportBOMStore";
import { styled } from "@mui/material";
import { usePreviewBOMExportGridEvents } from "./PreviewBOMExportGridEvents";
import { useImportBomFilterStore } from "../../../../store/ImportBomFilterStore";

export const GridIcons = styled('div')({
    display: 'flex',
    flexFlow: 'row',
    '& .delete-checkbox': {
        color: 'red!important',
    },
    '& .checkbox': {
        padding: '0 5px',
    }
});

export const HeaderGridIcons = styled('div')({
    display: 'flex',
    flexFlow: 'row',
    marginLeft: '-10px',
    backgroundColor: '#fff',
    padding: 0,
    '& .delete-checkbox': {
        color: 'red!important',
    },
    '& .checkbox': {
    }
});

export const usePreviewBOMExportGridHelpers = () => {
    const {isSaved} = useExportBOMStore();
    const {searchText} = useImportBomFilterStore();
    const {renderHeaderErrorSummaryViewer,renderCellExportedStatus} = usePreviewBOMExportGridEvents();
    const gridColumns = [
        {
            field: '',
            headerName: '',
            width: 30,
            sortable: false,
            resizable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            cellClassName: 'bom-header',
            hide: !isSaved,
            renderHeader: renderHeaderErrorSummaryViewer,
            renderCell: renderCellExportedStatus
        },
        {field: 'itemType', headerName: 'Part Code', width: 150},
        {field: 'partName', headerName: 'Part Name', width: 180},
        {field: 'qtyFinal', headerName: 'Qty Final', width: 180},
        {field: 'fThick', headerName: 'F/Thick', width: 180},
        {field: 'fWidth', headerName: 'F/Width', width: 180},
        {field: 'fLength', headerName: 'F/Length', width: 180},
        {field: 'materialCode', headerName: 'Material Code', width: 180},
        {field: 'materialName', headerName: 'Material Name', width: 180},
        {field: 'qtyRough', headerName: 'Qty Rough', width: 180},
        {field: 'rThick', headerName: 'R/Thick', width: 180},
        {field: 'rWidth', headerName: 'R/Width', width: 180},
        {field: 'rLength', headerName: 'R/Length', width: 180},
        {field: 'amount', headerName: 'Amount', width: 180},
        {field: 'units', headerName: 'Units', width: 180},
        {field: 'comments', headerName: 'Comments', width: 180},
    ];    
    const gridFilterItems = {
        items:[
            {id:1, columnField: 'itemType', operatorValue: 'contains', value: searchText },
            {id:2, columnField: 'partName', operatorValue: 'contains', value: searchText },
            {id:3, columnField: 'qtyFinal', operatorValue: 'contains', value: searchText },
            {id:4, columnField: 'fThick', operatorValue: 'contains', value: searchText },
            {id:5, columnField: 'fWidth', operatorValue: 'contains', value: searchText },
            {id:6, columnField: 'fLength', operatorValue: 'contains', value: searchText },
            {id:7, columnField: 'materialCode', operatorValue: 'contains', value: searchText },
            {id:8, columnField: 'materialName', operatorValue: 'contains', value: searchText },
            {id:9, columnField: 'rThick', operatorValue: 'contains', value: searchText },
            {id:10, columnField: 'rWidth', operatorValue: 'contains', value: searchText },
            {id:11, columnField: 'rLength', operatorValue: 'contains', value: searchText },
            {id:12, columnField: 'amount', operatorValue: 'contains', value: searchText },
            {id:13, columnField: 'units', operatorValue: 'contains', value: searchText },
            {id:13, columnField: 'comments', operatorValue: 'contains', value: searchText }
        ],
        linkOperator: GridLinkOperator.Or
    }
    return {
        gridColumns,
        gridFilterItems,
    }
}