import { styled } from "@mui/material"
import { UploadToolbar } from "./toolbars/UploadToolbar"
import { EcnGrid } from "./grid/EcnGrid"
import { sitemaps } from "../../config/sitemap"
import { useLocation } from 'react-router-dom';
import { constants } from "../../config/settings";
import { UploadProvider, useUploadStore } from "../../Types/Plm/UploadStore";
import { SCCPCGrid } from "./grid/SCCPCGrid";
// import { SPOQCReportsProvider } from "../store/plm/SPOQCReportsStore"
// import { SPOQCReportsGrid } from "./grid/SPOQCReportsGrid"
// import { UploadToolbar } from "./toolbar/UploadToolbar"

export const UploadGridContainter = styled('div')({
    backgroundColor: '#f6f6f6',
    padding: '20px 0 0 20px',
})

export const UploadsDocContainer = () => {
    const location = useLocation();
    const getCurrentComponentLinks = (link: string) => {
        var module = sitemaps.find(x => x.items?.find(e => e.link === link));
        if (module != null && module != undefined) {
            var component = module.items?.find(e => e.link === link);
            var name = component?.componentCode;
            return name;
        }
    }
    return (
        <UploadProvider>
            <UploadToolbar />
            <UploadGridContainter>
                {getCurrentComponentLinks(location.pathname) == constants.accessRightsComponents.ARDAUploadsESN ? <EcnGrid docCode={constants.ecnCode} /> : <SCCPCGrid docCode={constants.cpspcCode} />}
            </UploadGridContainter>
        </UploadProvider>
    )
}

