import { styled, CircularProgress} from "@mui/material";
import { SxProps } from "@mui/system";
import {FC} from "react";

const StyledContainer = styled('div')({
    display: 'flex',
    height: '736px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& .message-container': {
        position:'relative',
        margin: '20px 20px'
    }
});
interface CircularProgressContainerProps {
    id: string;
    style?: React.CSSProperties;
    sx?: SxProps;
}
export const CircularProgressContainer:FC<CircularProgressContainerProps> = (props:CircularProgressContainerProps) => {
    const {style,sx, id} = props;
    return (
        <StyledContainer id={id} style={style} sx={sx}>
            <CircularProgress size={42} disableShrink />
            <div className="message-container">Reloading data...</div>
        </StyledContainer>
    )
}