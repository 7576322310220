import React, { createContext, useState } from "react";
import { RoleAccess } from "../Types/RoleAccess";

interface IAuth {
  userName: string;
  userEmail: string;
  userToken: string;
  admin: boolean;
}

interface IAuthContext {
  auth: IAuth;
  setAuth: (state: IAuth) => void;
  roleAccess: RoleAccess[];
  setRoleAccess: (state: RoleAccess[]) => void;
  isAuthorizing: boolean | null;
  setIsAuthorizing: (state: boolean) => void;
}

export const AuthContext = createContext<IAuthContext | null>(null);

export const AuthProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [auth, setAuth] = useState({ userName: "", userEmail: "", userToken: "", admin: false });
  const [roleAccess, setRoleAccess] = useState<RoleAccess[]>([])
  const [isAuthorizing, setIsAuthorizing] = useState<boolean | null>(null);
  return (
    <AuthContext.Provider value={{ 
      auth, 
      setAuth, 
      roleAccess, 
      setRoleAccess,
      isAuthorizing,
      setIsAuthorizing,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
