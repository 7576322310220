import { useContext } from 'react';
import { Drawer, styled } from "@mui/material";
import { AuthContext } from '../../context/AuthContext';
import { AppMenu } from '../menu/AppMenu';

const drawerWidth = 228;

const StyledDrawer = styled(Drawer)({
    width: drawerWidth,
    position: 'relative',
    height: 'calc(100vh - 64px)',
    padding: 0,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: { 
        width: drawerWidth, 
        boxSizing: 'border-box', 
        position: 'relative', 
        backgroundColor: '#f5f5f5' 
    }
});




export const SidebarNavigation = () => {    
    const authValue = useContext(AuthContext);
    return (
        <>
        {(authValue?.auth?.userToken ?? '').length>0 && 
            <StyledDrawer
                id='sideNavigationDrawer'
                variant="permanent"
                ModalProps={{
                    keepMounted: true,
                }}
            >
                {!(authValue?.isAuthorizing ?? false) && <AppMenu/>}
            </StyledDrawer>
        }
        </>        
    )
}