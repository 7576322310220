import { useProdFilterStore } from "../../../../../store/ProdFilterStore";
import { useProdPlanningFilterDrawerStore } from "../../../../../store/ProdPlanningFilterDrawerStore";
import { useProductionPlanningStore } from "../../../../../store/ProductionPlanningStore";
import { TaskItem } from "../../../../../Types/TaskItem";
import { useProdPlanningFilterDrawerEvents } from "../../../filters/FilterDrawers/hooks/ProdPlanningFilterDrawerEvents";
import {useSearchHelper} from "../../../toolbar/items/hooks/SearchHelper"

export const useExpandCollapseRadioGroupEvents = () => {
    const {setExpandCollapseRows,searchText} = useProdFilterStore();    
    const {allTaskItems, filteredTaskItems, setExpandOrCollapseAll, setViewOption, setTargetDB} = useProductionPlanningStore();    
    const {showRelated,customerIds,itemIds,statusIds} = useProdPlanningFilterDrawerStore();
    const {applyFilter} = useProdPlanningFilterDrawerEvents();
    const {searchProd} = useSearchHelper();
    const gantt = document.querySelector('smart-gantt-chart');

    const reloadGrids = (status:string) => {
        let _taskItems:TaskItem[] = [] as TaskItem[];        
        let _allItems = structuredClone(allTaskItems ?? []) as TaskItem[];
        _allItems.map(item=>{
            return {...item, expanded: (status === 'expanded' ? true : false)}
        }) ?? [] as TaskItem[];

        if (filteredTaskItems.length > 0) {
            const _filteredTaskItems = structuredClone(filteredTaskItems) as TaskItem[];
            _taskItems = _filteredTaskItems.map(item =>{
                return {...item, expanded: (status === 'expanded' ? true : false)}
            });
        }        

        if (_taskItems.length > 0 && searchText.length === 0 && 
            !showRelated && 
            (
                (customerIds ?? []).length>0 ||
                (itemIds ?? []).length>0 ||
                (statusIds ?? []).length>0
            )) {
            searchProd(_taskItems, searchText, (showRelated ?? false));
        } else {
             searchProd(_allItems, searchText, (showRelated ?? false));
        }
    }

    const onChangeHandlerExpandCollapse = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = (event.target as HTMLInputElement);        
        
        if (value === 'expanded' || value === 'collapsed') {
            setExpandCollapseRows(value.toLowerCase());

            //
            setExpandOrCollapseAll(true);
        }

        if (value === 'grid' || value === 'chart') {            
            setViewOption(value.toLocaleLowerCase());
        }
        
        if (value === '999' || value === '110'){
            setTargetDB(value);
            applyFilter(value);
        } else {
            if (gantt) {
                reloadGrids(value.toLowerCase());
            }
        }

    }
    return {
        onChangeHandlerExpandCollapse,
        reloadGrids
    }
}