import { Box, CircularProgress, IconButton, Toolbar, Tooltip } from "@mui/material"
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import { GridTitle, Icon, Icons, SearchIconWrapper, SearchWrapper, StyledInputBase, UploadAppBar } from "./styles/SharedStyles";
import { Grow } from "../styles/SharedStyles";
import { ChangeEvent } from "react";
import { UploadButtons } from "./buttons/UploadButtons";
import { useDataSearch } from "../hooks/GridSearch";
import { useBomUploadStore } from "../../../store/BomUploadStore";
import { constants } from "../../../config/settings";


export const BomFileToolbar = () => {
    const { uploadingBOM, allBomFiles, setBomFiles, searchTextBomFiles, setSearchTextBomFiles } = useBomUploadStore();
    const { searchBomFiles } = useDataSearch();
    const onChangeHandlerSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTextBomFiles(event.target.value.toLowerCase());
    }
    const onClickHandlerDownload = () => {
        fetch(`${constants.getBOMExternalWebUrl()}${constants.downloadTemplate}`, {
            method: 'GET'
        })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
            new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
            'download',
            `BomTemplate.xlsx`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            if (link && link?.parentNode)
                link.parentNode.removeChild(link);
            });
    }
    const onKeyUpHandlerSearch = (event: React.KeyboardEvent<HTMLInputElement>): any => {
        if (event.code === "Enter" || event.nativeEvent.code === "Enter" || event.code === "NumpadEnter" || event.nativeEvent.code === "NumpadEnter"){
            if (searchTextBomFiles.length > 0){
                searchBomFiles(searchTextBomFiles);
            }
        } else {
            if (searchTextBomFiles === '') {
                setBomFiles(allBomFiles);
            }
        }
    }
    return (
        <Box sx={{ flexGrow: 1, maxHeight: '60px' }}>
            <UploadAppBar 
                position="static" >
                <Toolbar variant="dense">
                    <GridTitle>BOM FILES</GridTitle>
                    <Tooltip title="Download BOM Template">
                        <IconButton 
                            onClick={onClickHandlerDownload}
                            aria-label="download" 
                            className="icon">
                            <GetAppOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <Icons>
                        <Icon>
                        {uploadingBOM && 
                            <CircularProgress style={{width: '30px', height: '30px', padding: '5px', marginBottom: '-5px'}} />}
                        </Icon>
                        <UploadButtons />
                    </Icons>
                    <Grow />
                    <SearchWrapper>
                        <SearchIconWrapper>
                            <SearchOutlinedIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            onChange={onChangeHandlerSearch}
                            onKeyUp={onKeyUpHandlerSearch}
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </SearchWrapper>
                </Toolbar>
            </UploadAppBar>
        </Box>
    )
}