import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useMsal } from '@azure/msal-react';
import { useContext, useEffect } from 'react';
import { AuthContext } from './context/AuthContext';
import { loginRequest } from './auth/authConfig';
import styled from '@mui/styled-engine';
import { Button, ButtonProps, Tooltip } from '@mui/material';
import { getUserAccess, isAuthorized } from './services/userProfile';
import { useSnackbar } from 'notistack';
import { RoleAccess } from './Types/RoleAccess';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';

const CompanyName = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column'
});


const LoginButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#000',
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: 'darkgrey',
  },
}));

export default function MenuAppBar() {
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  let profile = {name: "", token: "", email: ""};

  const onClickHandlerLogout = () => {
    instance.logout()
  }

  const getUserAccessData = (token: string, email: string) => {
    getUserAccess(token, email)
    .then((data: RoleAccess[]) => {
      authContext?.setRoleAccess(data);
    });
  }
  useEffect(() => {
    if (account && authContext?.auth.userToken.trim().length === 0){
      instance.acquireTokenSilent({
        ...loginRequest,
        account: account
      }).then((response) => {

          profile.email = account.username 
          profile.token = response.idToken;
          profile.name = account.name ?? "";

          authContext?.setAuth({
            userEmail: profile.email,
            userName: profile.name,
            userToken: profile.token,
            admin: false
          });

          isAuthorized({token: profile.token, email: profile.email})
          .then(res => {
            if (res.success === false){
              // logout the user
              enqueueSnackbar("You are not authorized, please contact the system adminitrator.  You will be automatically signed out.",{variant: "error", autoHideDuration:10000});
              setTimeout(() => {
                instance.logout()
              },1000);
            } else {
              // update the admin property
              authContext?.setAuth({
                userEmail: profile.email,
                userName: profile.name,
                userToken: profile.token,
                admin: (res.reference ?? 'false') === 'true'
              });
              // get user access here
              getUserAccessData(profile.token, account.username ?? profile.email);
            }
          })

      }).catch((err) => {
        console.log('error');
        console.log(err);
      });;
    }
  },[account]);

  return (
    <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static" style={{backgroundColor: 'black'}}>
      <Toolbar>
        <CompanyName>
          <Typography variant="h6" style={{color:'#fff',fontSize:'1.25rem'}}>
            ARDA CO.
          </Typography>
          <Typography variant="h6" style={{color:'#fff',fontSize:'.8em',fontStyle:'italic'}}>
            VANTHIEL & CO. Web Application Portal
          </Typography>
        </CompanyName>
        <div style={{flexGrow: 1}} />
          <div>
            <AuthenticatedTemplate>
              {authContext?.auth.userEmail}
              <Tooltip title="Sign Out" aria-label="sign out">
                <IconButton
                  style={{
                    backgroundColor: '#000',
                    color: '#fff',
                  }} 
                  onClick={() => onClickHandlerLogout()}>
                  <ExitToAppOutlinedIcon />
                </IconButton>
              </Tooltip>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <LoginButton 
                sx={{
                  backgroundColor: '#000',
                  color: '#fff',
                }}
                
                onClick={() => 
                  //onClickHandlerLogin()
                  instance.loginRedirect(loginRequest)
                  .catch(e => {
                      console.error('loginRedirect',e);
                  })
                }>
                  Login
              </LoginButton>
            </UnauthenticatedTemplate>    
          </div>
      </Toolbar>
    </AppBar>
  </Box>
  );
}