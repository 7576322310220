import { usePopoverBoxStore } from "../../../store/PopoverBoxStore";


export interface IInitializationPopoverBox {
    okCallback: () => void;
    message: string;
    anchorEl: HTMLButtonElement | null;
    closeCallback?: () => void;
    cancelCallback?: () => void;
    okButtonText?: string;
    cancelButtonText?: string;
}

export const usePopoverBoxHelper = () => {
    const {setPopoverId,setMessage,setPopoverOpen,setPopoverAnchorEl,setOnCloseHandler,
           setOkText,setCancelText,setOnClickHandlerOk,setOnClickHandlerCancel} = usePopoverBoxStore();
    const resetPopoverBox = () => {
        setPopoverId('popoverBoxId')        
        setMessage('');
        setPopoverOpen(false);
        setOnCloseHandler(() => {});
        setOkText("Ok");
        setCancelText("Cancel");
        setOnClickHandlerOk(() => {})
        setOnClickHandlerCancel(() => {});
        setPopoverAnchorEl(null);
    }
    const initializationPopoverBox = (props: IInitializationPopoverBox) => {
        const {okCallback,message,closeCallback,cancelCallback,okButtonText,cancelButtonText,anchorEl} = props;
        setPopoverId('popoverBoxId') 
        setMessage(message);
        setPopoverOpen(false);
        if (okButtonText)
            setOkText(okButtonText);
        if (cancelButtonText)
            setCancelText(cancelButtonText);
       
        setOnCloseHandler(() => {
            return () => {

            if (closeCallback)
                closeCallback();

            setPopoverAnchorEl(null);
            setPopoverOpen(false);
        }});
        setOnClickHandlerOk(() => {
            return () => {

            if (okCallback)
                okCallback();

            setPopoverAnchorEl(null);
            setPopoverOpen(false);
        }})
        setOnClickHandlerCancel(() => {
            return () => {

            if (cancelCallback)
                cancelCallback();

            setPopoverAnchorEl(null);
            setPopoverOpen(false);
        }});
        setPopoverAnchorEl(anchorEl);
        setPopoverOpen(Boolean(anchorEl));
    }
    return  {
        resetPopoverBox,
        initializationPopoverBox,
    }
}