import { constants } from '../config/settings';
import { userServiceConfig } from '../config/userService';

export const isAuthorized = async (params) => {
    const { token, email } = params;
    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers
    };

    const response = await window.fetch(`${userServiceConfig.getEmailServiceUrl()}${userServiceConfig.userAuthorized}?email=${email}`, options);
    if (response.ok){
        const data = await response.json();
        return data;
    } else {
        return Promise.reject(response);
    }
}

export const getUserAccess = async (token, email) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers,
    };

    const response = await window.fetch(`${userServiceConfig.getEmailServiceUrl()}${userServiceConfig.getUserAccess}?email=${email}`, options);
    if (response.ok){
        const data = await response.json();
        return data;
    } else {
        return Promise.reject(response);
    }
}