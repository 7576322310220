import { MouseEvent, useContext } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import { useSnackbar } from "notistack";
import { useExportBOMStore } from "../../../../../store/ExportBOMStore";
import { ResultOptions } from "../../../../../Types/ResultOptions";
import { saveBOMExport } from "../../../../../services/inventor/inventor";
import { usePopoverBoxHelper } from "../../../../../ui/popovers/hooks/PopoverBoxHelper";
import { ILoadBOMExport, useInventorLoaders } from "../../../hooks/InventorLoaders";

export const useExportBOMButtonEvents = () => {
    const {initializationPopoverBox} = usePopoverBoxHelper();
    const {loadBOMExport} = useInventorLoaders();
    const authContext = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const {itemCode,setIsSaved} = useExportBOMStore();
    const onClickHandlerExportBOM = (event:MouseEvent<HTMLButtonElement>) => {
        initializationPopoverBox({
            message: "Do you want to export?",
            anchorEl: event.currentTarget,
            okCallback: () => {
                saveBOMExport(authContext?.auth.userToken,itemCode)
                .then((data: ResultOptions) => {
                    if (data.success===true){
                        enqueueSnackbar("Successfully exported records.",{variant: "success", autoHideDuration:5000});
                        setIsSaved(true);
                        loadBOMExport({itemCode,reload: true} as ILoadBOMExport)
                    } else {
                        enqueueSnackbar(data.message,{variant: "error", autoHideDuration:5000});
                    }
                })
            }
        });
        
    }
    return {
        onClickHandlerExportBOM
    }
}