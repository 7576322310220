import { GridSelectionModel } from "@mui/x-data-grid-pro";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { DropDownGridOptions } from "../Types/DropDownGridOptions";

export interface ProdPlanningFilterDrawerContextData {
    filterRows: DropDownGridOptions[],
    setFilterRows: Dispatch<SetStateAction<DropDownGridOptions[]>>,
    filterLoading: boolean,
    setFilterLoading: Dispatch<SetStateAction<boolean>>,
    filterIds: GridSelectionModel,
    setFilterIds: Dispatch<SetStateAction<GridSelectionModel>>,
    selectedFilter: string,
    setSelectedFilter: Dispatch<SetStateAction<string>>,
    filterSearchText: string,
    setFilterSearchText: Dispatch<SetStateAction<string>>,

    dateStartRange: DateRange<Dayjs> | [null,null],
    setDateStartRange: Dispatch<SetStateAction<DateRange<Dayjs> | [null,null]>>,

    prodOrder: string,
    setProdOrder: Dispatch<SetStateAction<string>>,

    dateStartFrom: dayjs.Dayjs | null | undefined,
    setDateStartFrom: Dispatch<SetStateAction<dayjs.Dayjs | null | undefined>>,
    dateStartTo: dayjs.Dayjs | null | undefined,
    setDateStartTo: Dispatch<SetStateAction<dayjs.Dayjs | null | undefined>>,

    item: string,
    setItem: Dispatch<SetStateAction<string>>,
    status: string,
    setStatus: Dispatch<SetStateAction<string>>,
    customer: string,
    setCustomer: Dispatch<SetStateAction<string>>,
    warehouse: string,
    setWarehouse: Dispatch<SetStateAction<string>>,

    prodOrders: DropDownGridOptions[],
    setProdOrders: Dispatch<SetStateAction<DropDownGridOptions[]>>,
    allProdOrders: DropDownGridOptions[],
    setAllProdOrders: Dispatch<SetStateAction<DropDownGridOptions[]>>,
    prodOrderIds: GridSelectionModel,
    setProdOrderIds: Dispatch<SetStateAction<GridSelectionModel>>,
    selectedProdOrderCodes: string[],
    setSelectedProdOrderCodes: Dispatch<SetStateAction<string[]>>,

    items: DropDownGridOptions[],
    setItems: Dispatch<SetStateAction<DropDownGridOptions[]>>,
    allItems: DropDownGridOptions[],
    setAllItems: Dispatch<SetStateAction<DropDownGridOptions[]>>,
    itemIds: GridSelectionModel,
    setItemIds: Dispatch<SetStateAction<GridSelectionModel>>,
    selectedItemCodes: string[],
    setSelectedItemCodes: Dispatch<SetStateAction<string[]>>,

    statuses: DropDownGridOptions[],
    setStatuses: Dispatch<SetStateAction<DropDownGridOptions[]>>,
    allStatuses: DropDownGridOptions[],
    setAllStatuses: Dispatch<SetStateAction<DropDownGridOptions[]>>,
    statusIds: GridSelectionModel,
    setStatusIds: Dispatch<SetStateAction<GridSelectionModel>>,
    selectedStatusCodes: string[],
    setSelectedStatusCodes: Dispatch<SetStateAction<string[]>>,

    customers: DropDownGridOptions[],
    setCustomers: Dispatch<SetStateAction<DropDownGridOptions[]>>,
    allCustomers: DropDownGridOptions[],
    setAllCustomers: Dispatch<SetStateAction<DropDownGridOptions[]>>,
    customerIds: GridSelectionModel,
    setCustomerIds: Dispatch<SetStateAction<GridSelectionModel>>,
    selectedCustomerCodes: string[],
    setSelectedCustomerCodes: Dispatch<SetStateAction<string[]>>,

    warehouses: DropDownGridOptions[],
    setWarehouses: Dispatch<SetStateAction<DropDownGridOptions[]>>,
    allWarehouses: DropDownGridOptions[],
    setAllWarehouses: Dispatch<SetStateAction<DropDownGridOptions[]>>,
    warehouseIds: GridSelectionModel,
    setWarehouseIds: Dispatch<SetStateAction<GridSelectionModel>>,
    selectedWarehouseCodes: string[],
    setSelectedWarehouseCodes: Dispatch<SetStateAction<string[]>>,

    fulfillmentDateFrom: dayjs.Dayjs | null | undefined,
    setFulfillmentDateFrom: Dispatch<SetStateAction<dayjs.Dayjs | null | undefined>>,
    fulfillmentDateTo: dayjs.Dayjs | null | undefined,
    setFulfillmentDateTo: Dispatch<SetStateAction<dayjs.Dayjs | null | undefined>>,

    showRelated: boolean | null | undefined,
    setShowRelated: Dispatch<SetStateAction<boolean | null | undefined>>,

    displayShowRelated: boolean | null | undefined,
    setDisplayShowRelated: Dispatch<SetStateAction<boolean | null |undefined>>,

    enableDateRange: boolean | null | undefined,
    setEnableDateRange: Dispatch<SetStateAction<boolean | null |undefined>>,

    refreshGrid: boolean | null | undefined,
    setRefreshGrid: Dispatch<SetStateAction<boolean | null | undefined>>,

}

export const ProdPlanningFilterDrawerDefaultValue: ProdPlanningFilterDrawerContextData = {
    filterRows: [],
    setFilterRows: () => {},
    filterLoading: false,
    setFilterLoading: () => {},
    filterIds: [],
    setFilterIds: () => {},
    selectedFilter: '',
    setSelectedFilter: () => {},
    filterSearchText: '',
    setFilterSearchText: () => {},

    dateStartRange: [null,null],
    setDateStartRange: () => {},

    prodOrder: '',
    setProdOrder: () => {},

    dateStartFrom: null,
    setDateStartFrom: () => {},
    dateStartTo: null,
    setDateStartTo: () => {},

    prodOrders: [],
    setProdOrders: () => {},
    allProdOrders: [],
    setAllProdOrders: () => {},
    prodOrderIds: [],
    setProdOrderIds: () => {},
    selectedProdOrderCodes: [],
    setSelectedProdOrderCodes: () => {},

    item: '',
    setItem: () => {},
    status: '',
    setStatus: () => {},
    customer: '',
    setCustomer: () => {},
    warehouse: '',
    setWarehouse: () => {},

    items: [],
    setItems: () => {},
    allItems: [],
    setAllItems: () => {},
    itemIds: [],
    setItemIds: () => {},
    selectedItemCodes: [],
    setSelectedItemCodes: () => {},

    statuses: [],
    setStatuses: () => {},
    allStatuses: [],
    setAllStatuses: () => {},
    statusIds: [],
    setStatusIds: () => {},
    selectedStatusCodes: [],
    setSelectedStatusCodes: () => {},

    customers: [],
    setCustomers: () => {},
    allCustomers: [],
    setAllCustomers: () => {},
    customerIds: [],
    setCustomerIds: () => {},
    selectedCustomerCodes: [],
    setSelectedCustomerCodes: () => {},

    warehouses: [],
    setWarehouses: () => {},
    allWarehouses: [],
    setAllWarehouses: () => {},
    warehouseIds: [],
    setWarehouseIds: () => {},
    selectedWarehouseCodes: [],
    setSelectedWarehouseCodes: () => {},

    fulfillmentDateFrom: null,
    setFulfillmentDateFrom: () => {},
    fulfillmentDateTo: null,
    setFulfillmentDateTo: () => {},

    showRelated: true,
    setShowRelated: () => {},

    displayShowRelated: true,
    setDisplayShowRelated: () => {},

    enableDateRange: true,
    setEnableDateRange: () => {},

    refreshGrid: null,
    setRefreshGrid: () => {},
    
}

export const ProdPlanningFilterDrawerStore = createContext<ProdPlanningFilterDrawerContextData>(ProdPlanningFilterDrawerDefaultValue);

export const useProdPlanningFilterDrawerStore = () => useContext(ProdPlanningFilterDrawerStore);

export const ProdPlanningFilterDrawerProvider: FC = ({children}) => {
    const [prodOrder, setProdOrder] = useState<string>('');
    const [prodOrders, setProdOrders] = useState<DropDownGridOptions[]>([]);
    const [allProdOrders, setAllProdOrders] = useState<DropDownGridOptions[]>([]);
    const [prodOrderIds, setProdOrderIds] = useState<GridSelectionModel>([]);
    const [selectedProdOrderCodes,setSelectedProdOrderCodes] = useState<string[]>([]);
    const [filterRows, setFilterRows] = useState<DropDownGridOptions[]>([]);
    const [filterLoading,setFilterLoading] = useState<boolean>(false);
    const [filterIds, setFilterIds] = useState<GridSelectionModel>([]);
    const [selectedFilter,setSelectedFilter] = useState<string>('');
    const [filterSearchText, setFilterSearchText] = useState<string>('');
    const [dateStartRange, setDateStartRange] = useState<DateRange<Dayjs> | [null,null]>([null,null]);
    const [dateStartFrom, setDateStartFrom] = useState<dayjs.Dayjs | null | undefined>(null);
    const [dateStartTo, setDateStartTo] = useState<dayjs.Dayjs | null | undefined>(null);
    const [items, setItems] = useState<DropDownGridOptions[]>([]);
    const [allItems, setAllItems] = useState<DropDownGridOptions[]>([]);
    const [itemIds, setItemIds] = useState<GridSelectionModel>([]);
    const [selectedItemCodes, setSelectedItemCodes] = useState<string[]>([]);
    const [item, setItem] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [customer, setCustomer] = useState<string>('');
    const [statuses, setStatuses] = useState<DropDownGridOptions[]>([]);
    const [allStatuses, setAllStatuses] = useState<DropDownGridOptions[]>([]);
    const [statusIds,setStatusIds] = useState<GridSelectionModel>([]);
    const [selectedStatusCodes,setSelectedStatusCodes] = useState<string[]>([]);
    const [customers, setCustomers] = useState<DropDownGridOptions[]>([]);
    const [allCustomers, setAllCustomers] = useState<DropDownGridOptions[]>([]);
    const [customerIds, setCustomerIds] = useState<GridSelectionModel>([]);
    const [selectedCustomerCodes,setSelectedCustomerCodes] = useState<string[]>([]);
    const [fulfillmentDateFrom, setFulfillmentDateFrom] = useState<dayjs.Dayjs | null | undefined>(null);
    const [fulfillmentDateTo, setFulfillmentDateTo] = useState<dayjs.Dayjs | null | undefined>(null);
    const [displayShowRelated, setDisplayShowRelated] = useState<boolean | null | undefined>(true);
    const [showRelated, setShowRelated] = useState<boolean | null | undefined>(true);
    const [enableDateRange, setEnableDateRange] = useState<boolean | null | undefined>(true);
    const [refreshGrid, setRefreshGrid] = useState<boolean | null | undefined>(false);
    const [warehouses, setWarehouses] = useState<DropDownGridOptions[]>([]);
    const [allWarehouses, setAllWarehouses] = useState<DropDownGridOptions[]>([]);
    const [warehouseIds, setWarehouseIds] = useState<GridSelectionModel>([]);
    const [selectedWarehouseCodes, setSelectedWarehouseCodes] = useState<string[]>([]);
    const [warehouse, setWarehouse] = useState<string>('');
    return (
        <ProdPlanningFilterDrawerStore.Provider
            value={{
                filterRows,
                setFilterRows,
                filterLoading,
                setFilterLoading,
                filterIds,
                setFilterIds,
                selectedFilter,
                setSelectedFilter,
                filterSearchText,
                setFilterSearchText,

                prodOrder,
                setProdOrder,
                prodOrders,
                setProdOrders,
                allProdOrders,
                setAllProdOrders,
                prodOrderIds,
                setProdOrderIds,
                selectedProdOrderCodes,
                setSelectedProdOrderCodes,

                dateStartRange,
                setDateStartRange,
                dateStartFrom,
                setDateStartFrom,
                dateStartTo,
                setDateStartTo,

                item,
                setItem,
                status,
                setStatus,
                customer,
                setCustomer,

                items,
                setItems,
                allItems,
                setAllItems,
                itemIds,
                setItemIds,
                selectedItemCodes,
                setSelectedItemCodes,

                statuses,
                setStatuses,
                allStatuses,
                setAllStatuses,
                statusIds,
                setStatusIds,
                selectedStatusCodes,
                setSelectedStatusCodes,

                customers,
                setCustomers,
                allCustomers,
                setAllCustomers,
                customerIds,
                setCustomerIds,
                selectedCustomerCodes,
                setSelectedCustomerCodes,

                fulfillmentDateFrom,
                setFulfillmentDateFrom,
                fulfillmentDateTo,
                setFulfillmentDateTo,

                showRelated,
                setShowRelated,

                displayShowRelated,
                setDisplayShowRelated,

                enableDateRange,
                setEnableDateRange,

                refreshGrid,
                setRefreshGrid,

                warehouses,
                setWarehouses,
                allWarehouses,
                setAllWarehouses,
                warehouseIds,
                setWarehouseIds,
                selectedWarehouseCodes,
                setSelectedWarehouseCodes,
                warehouse,
                setWarehouse,

            }}>
            {children}
        </ProdPlanningFilterDrawerStore.Provider>
    )
}