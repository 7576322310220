import { GridSelectionModel } from "@mui/x-data-grid-pro";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { getBomFiles } from "../../../services/bom/bomFile";
import { getBomHeadersByFileId, getBomHeadersByFileIds } from "../../../services/bom/bomHeader";
import { getBomLinesByBomHeaderId, getBomLinesByBomHeaderIds } from "../../../services/bom/bomLine";
import { getStepRelationsByBomHeaderId, getStepRelationsByBomHeaderIds } from "../../../services/bom/bomStepRelation";
import { useBomUploadStore } from "../../../store/BomUploadStore";
import { BomFile } from "../../../Types/BomFile";
import { BomHeader } from "../../../Types/BomHeader";
import { BomLine } from "../../../Types/BomLine";
import { BomStepRelation } from "../../../Types/BomStepRelation";

export const useLoadBomFiles = () => {
    const authContext = useContext(AuthContext);
    
    function loadBomFiles (callback: (pdata: BomFile[]) => void) {
        getBomFiles(authContext?.auth.userToken)
        .then((data: BomFile[]) => {
            
            if (callback)
                callback(data);
        })
    }
    return loadBomFiles;
}

export const useLoadBomHeaders = () => {
    const authContext = useContext(AuthContext);
    const {selectedBomHeaderIds } = useBomUploadStore();
    function loadBomHeadersByFileId (selectedBomFileId: number, callback: (pdata: BomHeader[]) => void) {
        getBomHeadersByFileId(authContext?.auth.userToken, selectedBomFileId)
        .then((data: BomHeader[]) => {
            if (callback)
                callback(data);
        })
    }
    function loadBomHeadersByFileIds (selectedBomFileIds: GridSelectionModel, callback: (pdata: BomHeader[]) => void) {
        console.log('selectedBomHeaderIds', selectedBomHeaderIds);
        getBomHeadersByFileIds(authContext?.auth.userToken, selectedBomFileIds)
        .then((data: BomHeader[]) => {
            if (callback)
                callback(data);
        })
    }
    return {
        loadBomHeadersByFileId,
        loadBomHeadersByFileIds
    };
}


export const useLoadBomLines = () => {
    const authContext = useContext(AuthContext);
    function loadBomLinesByBomHeaderId (selectedBomHeaderId: number, callback: (pdata: BomLine[]) => void) {
        getBomLinesByBomHeaderId(authContext?.auth.userToken,selectedBomHeaderId)
        .then((data: BomLine[]) => {
            if (callback)
                callback(data);
        })
    }
    function loadBomLinesByBomHeaderIds (selectedBomHeaderIds: GridSelectionModel, callback: (pdata: BomLine[]) => void) {
        getBomLinesByBomHeaderIds(authContext?.auth.userToken,selectedBomHeaderIds)
        .then((data: BomLine[]) => {
            if (callback)
                callback(data);
        })
    }
    return {
        loadBomLinesByBomHeaderId,
        loadBomLinesByBomHeaderIds
    };
}

export const useLoadBomStepRelations = () => {
    const authContext = useContext(AuthContext);
    function loadBomStepRelationsByBomHeaderId (selectedBomHeaderId: number, callback: (pdata: BomStepRelation[]) => void) {
        getStepRelationsByBomHeaderId(authContext?.auth.userToken,selectedBomHeaderId)
        .then((data: BomStepRelation[]) => {
            if (callback)
                callback(data);
        });
    };
    function loadBomStepRelationsByBomHeaderIds (selectedBomHeaderId: GridSelectionModel, callback: (pdata: BomStepRelation[]) => void) {
        getStepRelationsByBomHeaderIds(authContext?.auth.userToken,selectedBomHeaderId)
        .then((data: BomStepRelation[]) => {
            if (callback)
                callback(data);
        });
    };
    return {
        loadBomStepRelationsByBomHeaderId,
        loadBomStepRelationsByBomHeaderIds
    };
}