import { useSnackbar } from "notistack";
import { MouseEvent, useContext } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import { updateSchedules } from "../../../../../services/productionPlanning/productionPlanning";
import { useProductionPlanningStore } from "../../../../../store/ProductionPlanningStore";
import { ProductionOrder } from "../../../../../Types/ProductionOrder";
import { ResultOptions } from "../../../../../Types/ResultOptions";
import { TaskItem } from "../../../../../Types/TaskItem";
import { usePopoverBoxHelper } from "../../../../../ui/popovers/hooks/PopoverBoxHelper";
import { useProductionPlanningScheduleResizingHelpers } from "../../../charts/hooks/ProductionPlanningScheduleResizingHelper";
import { useSaveCancelHelpers } from "./SaveCancelHelpers";

export const useSaveCancelEvents = () => {
    const {productionOrders,setIsResetResizedTasks,ganttChartRef, selectedTaskId, setIsSaving} = useProductionPlanningStore();
    const authContext = useContext(AuthContext);
    const {clearAllBatchSelectedTaskItems} = useProductionPlanningScheduleResizingHelpers();
    const { enqueueSnackbar } = useSnackbar();
    const {loadSavedTaskItems} = useSaveCancelHelpers();
    const {initializationPopoverBox} = usePopoverBoxHelper();

    const saveResizedSchedules = (resizedProductionOrders: ProductionOrder[], callback?:() => void) => {
        doSave(resizedProductionOrders,callback);
    }
    const refreshSelectedTaskItem = () => {
        if (ganttChartRef && selectedTaskId){
            setTimeout(() => {
                ganttChartRef?.current?.ensureVisible(selectedTaskId ?? 0);
                ganttChartRef?.current?.selectTask(selectedTaskId ?? 0);
            },1000)

            // do not show the triangle/progress indicator
            setTimeout(() => {
                let _progressIndicator = document.querySelector<HTMLElement>('.smart-timeline-task[id="' + selectedTaskId + '"] .smart-timeline-task-progress-thumb');
                if (_progressIndicator){
                    _progressIndicator.style.setProperty('display', 'none');
                    _progressIndicator.style.setProperty('transform', 'scale(0)');
                }
            },1000);
        }
    }
    const onClickHandlerSave = (event:MouseEvent<HTMLButtonElement>) => {
        initializationPopoverBox({
            message: "Please close Production Order in EXACT before saving.",
            anchorEl: event.currentTarget,
            okCallback: () => {
                doSave(productionOrders);            
            }
        });
    }
    const doSave = (resizedProductionOrders: ProductionOrder[],callback?: () => void) => {
        const saveProdOrders = resizedProductionOrders.map(x => {
            x = {...x, taskItem: {} as TaskItem, origTaskItem: {} as TaskItem};
            return x;
        });
        if ((saveProdOrders ?? []).length>0){
            setIsSaving(true);
            updateSchedules(authContext?.auth.userToken,saveProdOrders)
            .then((data: ResultOptions) => {
                //setIsSaving(false);
                if (data && data.success === true){
                    enqueueSnackbar("Successfully updated Production Orders.",{variant: "success", autoHideDuration:5000});

                    //loadTaskItems(callback);
                    setTimeout(() => {
                        loadSavedTaskItems(resizedProductionOrders);
                    },500);

                }
                else{
                    setIsSaving(false);
                    enqueueSnackbar("Error in updating of Production Orders.",{variant: "error", autoHideDuration:5000});
                }
            }).catch((err: any) => {
                setIsSaving(false);
                enqueueSnackbar("There is server error.",{variant: "error", autoHideDuration:5000});
                console.log('error',err)
            })
        }
    }
    const onClickHandlerCancel = () => {
        clearAllBatchSelectedTaskItems();
        setIsResetResizedTasks(true);
    }
    
    return {
        onClickHandlerSave,
        onClickHandlerCancel,
        saveResizedSchedules,
        refreshSelectedTaskItem,
    }
}