import { ChangeEvent } from "react";
import { useImportBomFilterStore } from "../../../../../store/ImportBomFilterStore";

export const usePreviewBOMExportSearchEvents = () => {
    const {setSearchText} = useImportBomFilterStore();
    const onChangeHandlerSearch = (event: ChangeEvent<HTMLInputElement>) => {
        //setSearchText(event.target.value.toLowerCase());
    }
    const onKeyUpHandlerSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === "Enter" || event.nativeEvent.code === "Enter" || event.code === "NumpadEnter" || event.nativeEvent.code === "NumpadEnter"){
            const _input = event.target as HTMLInputElement;
            setSearchText(_input.value?.toLowerCase() ?? '');
        }
    }

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {        

    }
    return {
        onChangeHandlerSearch,
        onKeyUpHandlerSearch,
        onChangeHandler
    }
}