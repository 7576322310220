import { Dialog, Zoom } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions";
import { Dispatch, FC, forwardRef, SetStateAction } from "react"

interface LogDialogProps {
    children?: React.ReactNode;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Zoom ref={ref} {...props} />;
  });

export const CustomDialog: FC<LogDialogProps> = (props: LogDialogProps) => {
    const {children, open, setOpen} = props;
    const onCloseHandlerDialog = () => {
        setOpen(false);
    }
    return (
        <Dialog 
            maxWidth={'lg'}
            TransitionComponent={Transition}
            onClose={onCloseHandlerDialog}
            open={open}>
            {children}
        </Dialog>
    )
}