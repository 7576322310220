import { DataGridPro, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { BomGridContainer, GridIcons, GridWrapper, HeaderGridIcons } from "./styles/SharedStyles";
import { IconButton, Tooltip } from "@mui/material";
import { CustomDialog } from "../dialog/CustomDialog";
import { LogViewer } from "./viewer/LogViewer";
import { CustomDialogToolbar } from "../toolbars/CustomDialogToolbar";
import { LogSummary } from "./viewer/LogSummary";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useLoadBomStepRelations } from "../hooks/DataLoad";
import { BomStepRelation } from "../../../Types/BomStepRelation";
import { useBomUploadStore } from "../../../store/BomUploadStore";
import { ErrorLog } from "../../../Types/ErrorLog";
import { setGridWidth, setHeight } from "../../../utils/utilities";

export const BomStepsRelationGrid = () => {
    //const classes = useStyles();
    const { loadBomStepRelationsByBomHeaderIds } = useLoadBomStepRelations();
    const [errors, setErrors] = useState(false);
    const [open, setOpen] = useState(false);
    const [openSummary, setOpenSummary] = useState(false);
    const {bomStepRelations, setBomStepRelations, selectedBomHeaderIds, loadingDetail, setLoadingDetail,
           setAllBomStepRelations, loadGrid, setErrorLog, errorLogStepRelations, setErrorLogStepRelations} = useBomUploadStore();
    const onClickHandlerLog = (event: React.MouseEvent<HTMLButtonElement | null>, id: string) => {
        const err = errorLogStepRelations.filter(x => x.id.toString() === id.toString());
        if (err.length>0){
            setErrorLog(err[0]);
            setOpen(true);
        }
        setOpen(true);
    }
    const onClickHandlerLogSummary = () => {
        setOpenSummary(true);
    }
    const hasErrors = (stepRelations: BomStepRelation[]) => {
        const foundErrors = stepRelations.filter(x => (x.log ?? '').length>0 && x.status !== 1);
        setErrors((foundErrors && foundErrors.length>0));
    }
    const cols = [
        {
            field: '',
            headerName: '',
            width: 30,
            sortable: false,
            resizable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            cellClassName: 'bom-header',
            renderHeader: (params: GridColumnHeaderParams) => (
                <>
                {errors && 
                    <Tooltip title="Error Summary Viewer">
                        <HeaderGridIcons>
                            <IconButton 
                                onClick={onClickHandlerLogSummary} 
                                style={{paddingLeft: '5px'}}>
                                <ErrorOutlineOutlinedIcon  className="view-error" />
                            </IconButton>
                        </HeaderGridIcons>
                    </Tooltip>}
                </>
            ),
            renderCell: (params: GridRenderCellParams) => (
                <Tooltip title="Error Viewer">
                    <GridIcons>
                        {((params.getValue(params.id,"status")?.toString() !== "1") &&  
                         (params.getValue(params.id,"log") ?? '').toString().length>0 )  
                        ? <IconButton 
                            style={{paddingLeft: '1px'}}
                            onClick={(e) => onClickHandlerLog(e, (params.getValue(params.id,"id")?.toString() ?? ''))}
                            >
                            <ErrorOutlineOutlinedIcon  className="view-error" />
                          </IconButton>
                        : <CheckCircleOutlinedIcon className="ok" />}
                    </GridIcons>
                </Tooltip>
            )
        },
        {field: 'id', hide: true},
        {field: 'headerId', hide: true},
        {field: 'itemCode', headerName: 'Item Code', width: 150, headerClassName: 'bom-steps-header'},
        {field: 'version', headerName: 'BOM Version', width: 170, headerClassName: 'bom-steps-header'},
        {field: 'step', headerName: 'Step', width: 150, headerClassName: 'bom-steps-header'},
        {field: 'predecessor', headerName: 'Predecessor', width: 180, headerClassName: 'bom-steps-header'},
        {field: 'dependecyType', headerName: 'Dependency Type', width: 200, headerClassName: 'bom-steps-header'},
        {field: 'lag', headerName: 'Lag', width: 165, headerClassName: 'bom-steps-header'},
        {field: 'lagType', headerName: 'Lag Type', width: 165, headerClassName: 'bom-steps-header'},
    ];
    useEffect(() => {
        if (loadGrid){
            setLoadingDetail(true);
            loadBomStepRelationsByBomHeaderIds(selectedBomHeaderIds, (data: BomStepRelation[]) => {
                setBomStepRelations(data);
                setAllBomStepRelations(data);
                setLoadingDetail(false);
                hasErrors(data);
                setErrorLogStepRelations(data.filter(x => x.status !== 1 &&  (x.log ?? '').length>0)
                        .map(x => ({
                            id: x.id,
                            code: x.step.toString(),
                            title: `Item Code : ${x.itemCode} ; BOM Version : ${x.version} ; Step # : ${x.step.toString()}`,
                            errorMessage: x.log
                        } as ErrorLog)));
            });
        }
        setHeight({target: '.root-grid'});
        setGridWidth({target: '.root-grid .MuiDataGrid-renderingZone',style: '100%!important'});
    },[selectedBomHeaderIds]);
    return (
        <BomGridContainer>
            <GridWrapper className="root-grid">
                <DataGridPro 
                    rowHeight={30}
                    columns={cols}
                    rows={bomStepRelations}
                    loading={loadingDetail}
                />
            </GridWrapper>
            <CustomDialog 
                open={open} 
                setOpen={setOpen}>
                <CustomDialogToolbar 
                    title="Error Viewer"
                    setOpen={setOpen} />
                <LogViewer />
            </CustomDialog>
            <CustomDialog open={openSummary} setOpen={setOpenSummary}>
                <CustomDialogToolbar 
                    title="Error Summary Viewer"
                    setOpen={setOpenSummary} />
                <LogSummary errorLogs={errorLogStepRelations} />
            </CustomDialog>
        </BomGridContainer>
    )
}