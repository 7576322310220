import { useContext, createContext, useReducer } from 'react';

const TaskItemStore = createContext();

export const useTaskItemStore = () => useContext(TaskItemStore);

export const initTaskItem = {
    id: 0,
    project: '',
    item: '',
    description: '', 
    itemDesc: '',
    projectTooltip: '',
    itemTooltip: '',
    label: '',
    dDateStart: null,
    dDateEnd: null,
    dateStart: null,
    dateEnd: null,
    minDateStart: null,
    maxDateStart: null,
    origMaxDateStart: null,
    parent: '',
    class: '',
    type: '',
    expanded: false,
    synchronized: false,
    dragProject: false,
    duration: 0,
    connections: [],
    tasks: [],
};

export const TaskItemStoreProvider = ({ children }) => {
    const [taskItemInput, setTaskItemInput] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initTaskItem
      );
    
    return (
        <TaskItemStore.Provider
            value={[taskItemInput, setTaskItemInput]}>
                {children}
        </TaskItemStore.Provider>
    )
}