import styled from "@mui/styled-engine";
import { SidebarContent } from "./SidebarContent";

const SidebarContainerBox = styled('div')({
    display: 'flex',
    flex: 1,
    height: '100%',
    backgroundColor: '#f6f6f6',
    padding: '0',
});

const SidebarContainerWrapper = styled('div')({
    display: 'flex',
    flexFlow: 'row',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: '#fff',    
});

const SidebarNavigationContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',    
});



export const SidebarContainer = () => {    
    return (
        <SidebarContainerBox>
            <SidebarContainerWrapper>
                <SidebarNavigationContainer>
                    <SidebarContent/>
                </SidebarNavigationContainer>                
            </SidebarContainerWrapper>
        </SidebarContainerBox>
    )
}