import { AppBar, Box, IconButton, Toolbar } from "@mui/material"
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Dispatch, FC, SetStateAction } from "react";
import { Grow } from "../../styles/SharedStyles";
import styled from "@mui/system/styled";

export const Icons = styled('div')({
    display: 'flex',
    paddingLeft: '10px',
    color: '#1976d2',
    lineHeight: '45px',
    '& .delete': {
      color: 'red',
    },
    '& .hide': {
      display: 'none',
    },
    '& .icon': {
      color: '#1976d2'
    },
    '& .upload-bom: hover': {
      color: '#fff'
    }
})

export const UploadAppBar = styled(AppBar)({
    backgroundColor: '#fff',
    color: '#000',
    paddingTop: '5px',
    paddingBottom: '5px',
    width: 'auto',
    minHeight: '60px',
    maxHeight: '60px',
    '& .icon': {
      color: '#1976d2',
    },
});

interface CustomDialogToolbarProps {
    title: string;
    setOpen: Dispatch<SetStateAction<boolean | null | undefined>>;
}

export const CustomDialogToolbar: FC<CustomDialogToolbarProps> = (props: CustomDialogToolbarProps) => {
    const {setOpen,title} = props;
    const onClickHandlerClose = () => {
        setOpen(false);
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <UploadAppBar 
                position="static" >
                <Toolbar variant="dense">
                    <h3>{title}</h3>
                    <Grow />
                    <Icons>
                        <IconButton 
                            onClick={onClickHandlerClose}>
                            <CloseOutlinedIcon  />
                        </IconButton>
                    </Icons>
                </Toolbar>
            </UploadAppBar>
        </Box>
    )
}