import {FC} from "react";
import moment from "moment";

interface ChartDateInterface {
    label: string,
    startDate: Date,
    endDate: Date,
    rightOrLeft: "left" | "right"
}
interface ChartDateTooltipInterface {
    id: string;
    extraClass?: string;
    title: string;
    dateRange?: ChartDateInterface;
    rightOrLeft?: "left" | "right";
}
export const ChartDateTooltip:FC<ChartDateTooltipInterface> = (props:ChartDateTooltipInterface) => {
    const {id, title, dateRange, extraClass, rightOrLeft} = props;
    return (
        <div id={id} className={"tooltip-content " + (extraClass || "")}>
            <h3>{title}</h3>
            <div className="date-range--container">
                <h4>{dateRange?.label}</h4>
                <div className="date--container">
                    <div className={"start-date" + (dateRange?.rightOrLeft === "left" ? " active": "")}><strong>Start Date:</strong><span>{moment(dateRange?.startDate).format('MM/DD/YYYY ddd')}</span></div>
                    <div className={"end-date" + (dateRange?.rightOrLeft === "right" ? " active": "")}><strong>End Date:</strong><span>{moment(dateRange?.endDate).format('MM/DD/YYYY ddd')}</span></div>
                </div>
            </div>
        </div>
    )
}