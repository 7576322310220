import { createContext, Dispatch, FC, MutableRefObject, SetStateAction, useContext, useState } from "react";
import GanttChart from "smart-webcomponents-react/ganttchart";
import { constants } from "../config/settings";
import { IPlanningOptionType } from "../interfaces/IPlanningOptionType";
import { ProductionOrder } from "../Types/ProductionOrder";
import { ProductionPlanning } from "../Types/ProductionPlanning";
import { TaskItem } from "../Types/TaskItem";
import { UrmUserDatabase } from "../Types/UserService/UrmUserDatabase";

export interface ProductionPlanningContextData {
    loadingProductionPlannings: boolean,
    setLoadingProductionPlannings: Dispatch<SetStateAction<boolean>>,
    productionPlannings: ProductionPlanning[],
    setProductionPlannings:  Dispatch<SetStateAction<ProductionPlanning[]>>,
    productionPlanningTasks: TaskItem[];
    setProductionPlanningTasks: Dispatch<SetStateAction<TaskItem[]>>,
    planningOptionType: IPlanningOptionType | null,
    setPlanningOptionType: Dispatch<SetStateAction<IPlanningOptionType | null>>,
    allTaskItems: TaskItem[],
    setAllTaskItems: Dispatch<SetStateAction<TaskItem[]>>,
    rollbackAllTaskItems: TaskItem[],
    setRollbackAllTaskItems: Dispatch<SetStateAction<TaskItem[]>>,
    taskItems: TaskItem[],
    setTaskItems: Dispatch<SetStateAction<TaskItem[]>>,
    filteredTaskItems: TaskItem[],
    setFilteredTaskItems: Dispatch<SetStateAction<TaskItem[]>>,
    productionOrders: ProductionOrder[],
    setProductionOrders: Dispatch<SetStateAction<ProductionOrder[]>>,
    enableSnapToNearest: boolean,
    setEnableSnapToNearest: Dispatch<SetStateAction<boolean>>,
    gridReady: boolean,
    setGridGready: Dispatch<SetStateAction<boolean>>,
    searchText: string,
    setSearchText: Dispatch<SetStateAction<string>>,
    totalRows:number,
    setTotalRows: Dispatch<SetStateAction<number>>,
    selectedTaskIds: number[],
    setSelectedTaskIds: Dispatch<SetStateAction<number[]>>,
    refreshChart: boolean,
    setRefreshChart: Dispatch<SetStateAction<boolean>>,
    isScheduleDialogOpened: boolean,
    setIsScheduleDialogOpened: Dispatch<SetStateAction<boolean>>,
    selectedTaskId: number,
    setSelectedTaskId: Dispatch<SetStateAction<number>>,
    ganttChartRef: MutableRefObject<GanttChart | null> | undefined,
    setGanttChartRef: Dispatch<SetStateAction<MutableRefObject<GanttChart | null> | undefined>>,
    dataLoadedProductionPlanning: boolean,
    setDataLoadedProductionPlanning: Dispatch<SetStateAction<boolean>>,
    viewLoadedProductionPlanning: boolean,
    setViewLoadedProductionPlanning: Dispatch<SetStateAction<boolean>>,
    searchSelectedTaskIds: number[],
    setSearchSelectedTasksIds:Dispatch<SetStateAction<number[]>>,
    resetGanttChart: boolean,
    setResetGanttChart: Dispatch<SetStateAction<boolean>>,
    pageTaskItems: TaskItem[][] | null,
    setPageTaskItems: Dispatch<SetStateAction<TaskItem[][] | null>>,
    batchTaskItems: TaskItem[],
    setBatchTaskItems: Dispatch<SetStateAction<TaskItem[]>>,
    // showRelated: boolean,
    // setShowRelated: Dispatch<SetStateAction<boolean>>,
    isResizing: boolean,
    setIsResizing: Dispatch<SetStateAction<boolean>>,
    selectedTask:TaskItem,
    setSelectedTask: Dispatch<SetStateAction<TaskItem>>,
    expandedTasks:number[],
    setExpandedTasks: Dispatch<SetStateAction<number[]>>,
    isSaving: boolean | null | undefined,
    setIsSaving: Dispatch<SetStateAction<boolean | null | undefined>>,
    isResetResizedTasks: boolean | null |undefined,
    setIsResetResizedTasks: Dispatch<SetStateAction<boolean | null | undefined>>,
    isRefreshResizedTasks: boolean | null | undefined,
    setIsRefreshResizedTasks: Dispatch<SetStateAction<boolean | null | undefined>>,
    updatedTaskItems: TaskItem[] | null,
    setUpdatedTaskItems: Dispatch<SetStateAction<TaskItem[] | null>>,
    forResizingTasks: TaskItem[],
    setForResizingTasks: Dispatch<SetStateAction<TaskItem[]>>,
    userDatabases: UrmUserDatabase[],
    setUserDatabases: Dispatch<SetStateAction<UrmUserDatabase[]>>,
    expandOrCollapseAll: boolean,
    setExpandOrCollapseAll: Dispatch<SetStateAction<boolean>>,
    viewOption: string | null | undefined,
    setViewOption: Dispatch<SetStateAction<string | null | undefined>>,
    reloadDataInProgress: boolean,
    setReloadDataInProgress: Dispatch<SetStateAction<boolean>>,
    targetDB: string | null | undefined,
    setTargetDB: Dispatch<SetStateAction<string | null | undefined>>,
    chartDateStart: Date | undefined,
    setChartDateStart: Dispatch<SetStateAction<Date | undefined>>,
    chartDateEnd: Date | undefined,
    setChartDateEnd: Dispatch<SetStateAction<Date | undefined>>,
}

export const ProductionPlanningDefaultValue: ProductionPlanningContextData = {
    loadingProductionPlannings: false,
    setLoadingProductionPlannings: () => {},
    productionPlannings: [],
    setProductionPlannings: () => {},
    productionPlanningTasks: [],
    setProductionPlanningTasks: () => {},
    planningOptionType: null,
    setPlanningOptionType: () => {},
    allTaskItems: [],
    setAllTaskItems: () => {},
    rollbackAllTaskItems: [],
    setRollbackAllTaskItems: () => {},
    taskItems: [],
    setTaskItems: () => {},
    filteredTaskItems: [],
    setFilteredTaskItems: () => {},
    productionOrders: [],
    setProductionOrders: () => {},
    gridReady: false,
    setGridGready: () => {},
    enableSnapToNearest: false,
    setEnableSnapToNearest: () => {},
    searchText: '',
    setSearchText: () => {},
    totalRows: 0,
    setTotalRows: () => {},
    selectedTaskIds: [],
    setSelectedTaskIds: () => {},
    refreshChart: false,
    setRefreshChart: () => {},
    isScheduleDialogOpened: false,
    setIsScheduleDialogOpened: () => {},
    selectedTaskId: 0,
    setSelectedTaskId: () => {},
    ganttChartRef: undefined,
    setGanttChartRef: () => {},
    dataLoadedProductionPlanning: false,
    setDataLoadedProductionPlanning: () => {},
    viewLoadedProductionPlanning: false,
    setViewLoadedProductionPlanning: () => {},
    searchSelectedTaskIds:[],
    setSearchSelectedTasksIds: () => {},
    resetGanttChart: false,
    setResetGanttChart: () => {},
    pageTaskItems: null,
    setPageTaskItems: () => {},
    batchTaskItems: [],
    setBatchTaskItems: () => {},
    // showRelated: true,
    // setShowRelated: () => {},
    isResizing: false,
    setIsResizing: () => {},
    selectedTask: {} as TaskItem,
    setSelectedTask: () => {},
    expandedTasks: [],
    setExpandedTasks: () => {},
    isSaving: false,
    setIsSaving: () => {},
    isResetResizedTasks: false,
    setIsResetResizedTasks: () => {},
    isRefreshResizedTasks: false,
    setIsRefreshResizedTasks: () => {},
    updatedTaskItems: [],
    setUpdatedTaskItems: () => {},
    forResizingTasks: [],
    setForResizingTasks: () =>{},
    userDatabases: [],
    setUserDatabases: () => {},
    expandOrCollapseAll: false,
    setExpandOrCollapseAll: () => {},
    viewOption: 'chart',
    setViewOption: () => {},
    reloadDataInProgress: false,
    setReloadDataInProgress: () => {},
    targetDB: '999',
    setTargetDB: () => {},
    chartDateStart: undefined,
    setChartDateStart: () => {},
    chartDateEnd: undefined,
    setChartDateEnd: () => {},
}

export const ProductionPlanningStore = createContext<ProductionPlanningContextData>(ProductionPlanningDefaultValue);

export const useProductionPlanningStore = () => useContext(ProductionPlanningStore);

export const ProductionPlanningProvider: FC = ({children}) => {
    const [loadingProductionPlannings, setLoadingProductionPlannings] = useState<boolean>(false);
    const [productionPlannings, setProductionPlannings] = useState<ProductionPlanning[]>([]);
    const [planningOptionType, setPlanningOptionType] = useState<IPlanningOptionType | null>(null);
    const [productionPlanningTasks, setProductionPlanningTasks] = useState<TaskItem[]>([]);
    const [allTaskItems, setAllTaskItems] = useState<TaskItem[]>([]);
    const [rollbackAllTaskItems, setRollbackAllTaskItems] = useState<TaskItem[]>([]);
    const [taskItems, setTaskItems] = useState<TaskItem[]>([]);
    const [filteredTaskItems, setFilteredTaskItems] = useState<TaskItem[]>([]);
    const [productionOrders, setProductionOrders] = useState<ProductionOrder[]>([]);
    const [enableSnapToNearest, setEnableSnapToNearest] = useState<boolean>(false);
    const [gridReady, setGridGready] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [totalRows, setTotalRows] = useState<number>(0);
    const [selectedTaskIds, setSelectedTaskIds] = useState<number[]>([]);
    const [refreshChart, setRefreshChart] = useState<boolean>(false);
    const [isScheduleDialogOpened, setIsScheduleDialogOpened] = useState<boolean>(false);
    const [selectedTaskId, setSelectedTaskId] = useState<number>(0);
    const [ganttChartRef, setGanttChartRef] = useState<MutableRefObject<GanttChart | null> | undefined>(undefined);
    const [dataLoadedProductionPlanning, setDataLoadedProductionPlanning] = useState<boolean>(false);
    const [viewLoadedProductionPlanning, setViewLoadedProductionPlanning] = useState<boolean>(false);
    const [searchSelectedTaskIds, setSearchSelectedTasksIds] = useState<number[]>([]);
    const [resetGanttChart, setResetGanttChart] = useState<boolean>(false);
    const [pageTaskItems, setPageTaskItems] = useState<TaskItem[][] | null>(null);
    const [batchTaskItems,setBatchTaskItems] = useState<TaskItem[]>([]);
    //const [showRelated, setShowRelated] = useState<boolean>(true);
    const [selectedTask, setSelectedTask] = useState<TaskItem>({} as TaskItem);
    const [expandedTasks, setExpandedTasks] = useState<number[]>([]);

    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean | null | undefined>(false);
    const [isResetResizedTasks, setIsResetResizedTasks] = useState<boolean | null | undefined>(false);
    const [isRefreshResizedTasks, setIsRefreshResizedTasks] = useState<boolean | null | undefined>(false);
    const [updatedTaskItems, setUpdatedTaskItems] = useState<TaskItem[] | null>([]);
    const [forResizingTasks, setForResizingTasks] = useState<TaskItem[]>([]);
    const [userDatabases, setUserDatabases] = useState<UrmUserDatabase[]>([]);
    const [expandOrCollapseAll, setExpandOrCollapseAll] = useState<boolean>(false);
    const [viewOption, setViewOption] = useState<string | null | undefined>('chart');
    const [reloadDataInProgress, setReloadDataInProgress] = useState<boolean>(false);
    //const [targetDB, setTargetDB] = useState<string | null | undefined>(constants.databases.dev);
    // use this is prod
    const [targetDB, setTargetDB] = useState<string | null | undefined>(constants.isDevelopment ? constants.databases.dev : constants.databases.prod);

    const [chartDateStart, setChartDateStart] = useState<Date | undefined>(undefined);
    const [chartDateEnd, setChartDateEnd] = useState<Date | undefined>(undefined);

    return (
        <ProductionPlanningStore.Provider
            value={{
                loadingProductionPlannings, 
                setLoadingProductionPlannings,
                productionPlannings,
                setProductionPlannings,
                productionPlanningTasks,
                setProductionPlanningTasks,
                planningOptionType, 
                setPlanningOptionType,
                allTaskItems,
                setAllTaskItems,
                rollbackAllTaskItems,
                setRollbackAllTaskItems,
                taskItems,
                setTaskItems,
                filteredTaskItems,
                setFilteredTaskItems,
                productionOrders,
                setProductionOrders,
                enableSnapToNearest, 
                setEnableSnapToNearest,
                gridReady,
                setGridGready,
                searchText,
                setSearchText,
                totalRows,
                setTotalRows,
                selectedTaskIds,
                setSelectedTaskIds,
                refreshChart,
                setRefreshChart,
                isScheduleDialogOpened,
                setIsScheduleDialogOpened,
                selectedTaskId,
                setSelectedTaskId,
                ganttChartRef, 
                setGanttChartRef,
                dataLoadedProductionPlanning,
                setDataLoadedProductionPlanning,
                viewLoadedProductionPlanning,
                setViewLoadedProductionPlanning,
                searchSelectedTaskIds,
                setSearchSelectedTasksIds,
                resetGanttChart,
                setResetGanttChart,
                pageTaskItems,
                setPageTaskItems,
                batchTaskItems,
                setBatchTaskItems,
                // showRelated,
                // setShowRelated,
                isResizing,
                setIsResizing,
                selectedTask,
                setSelectedTask,
                expandedTasks,
                setExpandedTasks,
                isSaving,
                setIsSaving,
                isResetResizedTasks,
                setIsResetResizedTasks,
                isRefreshResizedTasks,
                setIsRefreshResizedTasks,
                updatedTaskItems,
                setUpdatedTaskItems,
                forResizingTasks,
                setForResizingTasks,
                userDatabases,
                setUserDatabases,
                expandOrCollapseAll,
                setExpandOrCollapseAll,
                viewOption,
                setViewOption,
                reloadDataInProgress,
                setReloadDataInProgress,
                targetDB,
                setTargetDB,
                chartDateStart,
                setChartDateStart,
                chartDateEnd,
                setChartDateEnd,
            }}>
            {children}
        </ProductionPlanningStore.Provider>
    )
}