import { styled } from "@mui/material"
import { useEffect, useState } from "react"
import Button from "smart-webcomponents-react/button"
import { useProdPlanningFilterDrawerStore } from "../../../../store/ProdPlanningFilterDrawerStore"
import { EnableDatesLabel } from "../FilterDrawers/FilterInputFields/ProdPlanningFilterInputFields"
import { useConfirmationContentEvents } from "./hooks/ConfirmationContentEvents"

export const ConfirmationRoot = styled('div')({
    display: 'flex',
    flexFlow: 'column',
    backgroundColor: '#fff',
    alignItems: 'center',

})

export const ConfirmationBody = styled('div')({
    paddingBottom: '20px',
    paddingLeft: '20px',
})

export const ConfirmationFooter = styled('div')({
    display: 'flex',
    flexFlow: 'row',
    placeItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0,
})


export const ConfirmationContent = () => {
    const {onClickHandlerYes,onClickHandlerNo} = useConfirmationContentEvents();
    return (
        <ConfirmationRoot>
            <ConfirmationBody>
                Selected filteres will be cleared?
            </ConfirmationBody>
            <ConfirmationFooter>
                <Button className="raised primary" onClick={onClickHandlerYes}>Confirm</Button>&nbsp;&nbsp;
                <Button className="raised primary" onClick={onClickHandlerNo}>Cancel</Button>
            </ConfirmationFooter>
        </ConfirmationRoot>
    )
}