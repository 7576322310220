import { useContext } from "react";
import { getBOMExport, setBOMExport } from "../../../services/inventor/inventor";
import { AuthContext } from "../../../context/AuthContext";
import { useExportBOMStore } from "../../../store/ExportBOMStore";
import { InventorBomHeader } from "../../../Types/Inventor/InventorBomHeader";
import { getExportedBOM } from "../../../services/inventor/component";
import { useMBOMConversionStore } from "../../../store/MBOMConversionStore";

export interface ILoadBOMExport {
    itemCode: string;
    reload?: boolean | null | undefined;
    callback?: (version?: string) => void;
}

export const useInventorLoaders = () => {
    const authContext = useContext(AuthContext);
    const {setInventorBomHeader,setIsSaved,setGridHasRecords} = useExportBOMStore();
    const {setExportedBomHeaders,setIsMBOMLoading} = useMBOMConversionStore();
    const loadBOMExport = (props: ILoadBOMExport) : void => {
        const {itemCode, reload, callback} = props;
        if (!(reload ?? false))
            setIsSaved(false);

        getBOMExport(authContext?.auth.userToken,itemCode)
        .then((data: InventorBomHeader) => {
            setInventorBomHeader(data);
            setGridHasRecords((data?.details ?? []).length>0);
            if (callback)
                callback(data.version ?? '');
        })

    }
    const loadExportedBOM = () => {
        setIsMBOMLoading(true);
        getExportedBOM(authContext?.auth.userToken)
        .then((data: InventorBomHeader[]) => {
            setIsMBOMLoading(false);
            setExportedBomHeaders(data);
        })
    }
    return {
        loadBOMExport,
        loadExportedBOM,
    }
}