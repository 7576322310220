import { ChangeEvent, useContext, useState } from "react"
import { Icon } from "../styles/SharedStyles";
import { useSnackbar } from 'notistack';
import { Button, Tooltip } from "@mui/material";
import { useLoadBomFiles } from "../../hooks/DataLoad";
import { AuthContext } from "../../../../context/AuthContext";
import { useBomUploadStore } from "../../../../store/BomUploadStore";
import { ResultOptions } from "../../../../Types/ResultOptions";
import { BomFile } from "../../../../Types/BomFile";
import { constants } from "../../../../config/settings";
import { uploadBOM } from "../../../../services/bom/bomHeader";

export const UploadButtons = () => {
    const authContext = useContext(AuthContext);
    const { userDatabases, uploadingBOM, setUploadingBOM, setLoading, 
            setSelectedBomHeaderId, setBomFiles, setAllBomFiles } = useBomUploadStore();
    const [db, setDb] = useState('');
    const loadBomFiles = useLoadBomFiles();
    const { enqueueSnackbar } = useSnackbar();
    const onChangeHandlerFile = (event: ChangeEvent<HTMLInputElement>) => {
        event.persist();
        if (event.target.files && event.target.files.length>0 && !uploadingBOM) {
            var file: File = event.target.files[0];
            if (file.name.indexOf('.xlsx') > -1 || file.name.indexOf('.xls') > -1)
            {
                setUploadingBOM(true);
                uploadBOM(authContext?.auth.userToken,file,db,authContext?.auth.userName)
                .then((data: ResultOptions) => {                
                    setUploadingBOM(false);
                    switch(data.status)
                    {
                        case 4:
                            enqueueSnackbar(data.reference4 ?? "File processed with errors",{variant: "warning", autoHideDuration:5000});
                            break;
                        default:
                        case 0:
                        case 2:
                        case 3:
                            enqueueSnackbar(data.reference4 ?? "File upload process failed.",{variant: "error", autoHideDuration:5000});    
                            break;
                        case 1:                        
                            enqueueSnackbar("File successfully processed.",{variant: "success", autoHideDuration:5000});
                            break;
                    }                    
                    refreshBomFiles();
                }).catch(err => {                    
                    setUploadingBOM(false);
                    //enqueueSnackbar(`There is error processing BOM File (${file.name}).`,{variant: "error", autoHideDuration:5000});
                    refreshBomFiles();
                    enqueueSnackbar("Successfully processed.",{variant: "success", autoHideDuration:5000});
                });
            } else {
                setUploadingBOM(false);
                enqueueSnackbar(`Invalid filename (${file.name}), only accept .xlsx or .xls file extension.`,{variant: "error", autoHideDuration:5000});
            }
        }            
    }
    const refreshBomFiles = () => {
        setLoading(true);
        loadBomFiles((data: BomFile[]) => {
            setSelectedBomHeaderId(-1);
            setBomFiles(data);
            setAllBomFiles(data);
            setLoading(false);
        });
    }
    return (
        <>
            {userDatabases && userDatabases.length>0 &&
            userDatabases.filter(x => !constants.isDevelopment || (x.databaseCode==='999' && constants.isDevelopment)).map(x => (
                <Tooltip title="Upload BOM">
                    <Icon>
                        <label htmlFor="upload-file">
                            <input
                                style={{ display: "none" }}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                id="upload-file"
                                name="upload-file"
                                type={!uploadingBOM ? "file" : ""}
                                onChange={(e) => onChangeHandlerFile(e)}
                            />
                            <Button className="upload-bom" 
                                    style={{padding: '5px', marginTop: '-2px'}}
                                    variant="contained" 
                                    component="span"
                                    disabled={uploadingBOM}
                                    onClick={() => {
                                        setDb(x.databaseCode);
                                    }}>
                                {x.databaseCode}
                            </Button>{" "}
                        </label>
                    </Icon>
                </Tooltip>
            ))
            }
        </>
    )
}