import { Badge, styled, TextField } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useProdPlanningFilterInputFieldsEvents } from "./hooks/ProdPlanningFilterInputFieldsEvents"
import { Typography } from "@material-ui/core"
import { useProdPlanningFilterDrawerStore } from "../../../../../store/ProdPlanningFilterDrawerStore"
import { FilterInputFields } from "../../../../hooks/ui/FilterInputFields"
import { constants } from "../../../../../config/settings"
import { ResetSelectedIdsIcon } from "./ResetSelectedIdsIcon"
import { ConfirmationBox } from "../../../../hooks/ui/ConfirmationBox"
import { ConfirmationContent } from "../../ConfirmationMessage/ConfirmationContent"
import { CheckboxEnableDates } from "./CheckboxEnabledDates"
import { ShowRelated } from "../FilterToolbar/Items/ShowRelated"

export const DateRangeWrapper = styled('div')({
    dispay: 'flex',
    flexFlow: 'row',
    paddingBottom: '2px',
    width: '230px',
    '& .MuiOutlinedInput-input': {
        //height: '.5em!important',
    }
})

export const StartDateWrapper = styled('div')({
    display: 'flex',
    flexFlow: 'row',
})

export const EnableDatesWrapper = styled('div')({
    display: 'flex',
    flexFlow: 'row',
})

export const EnableDatesLabel = styled('div')({
    marginTop: '3px',
    fontSize: '.9em',
})

export const ProdPlanningFilterInputFields = () => {
    const {dateStartFrom,dateStartTo,prodOrder,item,itemIds,status,statusIds,customerIds,customer,
           fulfillmentDateFrom,fulfillmentDateTo,prodOrderIds,enableDateRange,setEnableDateRange,
           warehouse,warehouseIds} = useProdPlanningFilterDrawerStore();
    const {onChangeHandlerDateStartFrom,onChangeHandlerDateStartTo,onChangeHandlerTextfield,
           onClickHandlerTextfield,onClickHandlerSelectedIDsReset,onChangeHandlerFulfillmentDateFrom,onChangeHandlerFulfillmentDateTo,
           } = useProdPlanningFilterInputFieldsEvents();
    
    
    return (
        <FilterInputFields>
            <DateRangeWrapper>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}>
                    <StartDateWrapper>
                    <Typography 
                        variant="h6" 
                        style={{fontSize: '1em',marginBottom: '5px'}}>
                        Start Date : 
                    </Typography>
                    <EnableDatesWrapper style={{position: 'relative'}}>
                        <CheckboxEnableDates/>
                            <ConfirmationBox style={{left: '-80px'}}>
                                <ConfirmationContent />
                            </ConfirmationBox>
                        <EnableDatesLabel>{enableDateRange ?? false ? 'Enabled' : 'Disabled'} Dates</EnableDatesLabel>
                    </EnableDatesWrapper>
                    </StartDateWrapper>
                     <DatePicker
                        disabled={!enableDateRange}
                        label="From"
                        value={dateStartFrom}
                        onChange={onChangeHandlerDateStartFrom}
                        renderInput={(params) => <TextField {...params} size="small" className='filter-textbox-short' />}
                    /><br /><br />
                    <DatePicker
                        disabled={!enableDateRange}
                        label="To"
                        value={dateStartTo}
                        onChange={onChangeHandlerDateStartTo}
                        renderInput={(params) => <TextField {...params} size="small" className='filter-textbox-short'  />}
                    />
                </LocalizationProvider>
            </DateRangeWrapper>
            <ShowRelated /><br />
            <Badge badgeContent={prodOrderIds.length} color="primary">
                <ResetSelectedIdsIcon   
                    hide={prodOrderIds.length === 0}
                    tooltip="Reset Selected Prod. Orders"
                    onClickHandlerReset={() => onClickHandlerSelectedIDsReset(constants.filterOptions.name.prodOrder)}/>
                <TextField 
                    style={{width: '210px'}}
                    id="outlined-basic" 
                    label="Prod. Order" 
                    variant="outlined" 
                    value={prodOrder}
                    size="small"
                    name={constants.filterOptions.name.prodOrder}
                    onChange={onChangeHandlerTextfield}
                    onClick={() => onClickHandlerTextfield(constants.filterOptions.name.prodOrder)}
                    className='filter-textbox-short'/>
            </Badge><br /><br />
            <Badge badgeContent={itemIds.length} color="primary">
                <ResetSelectedIdsIcon 
                    hide={itemIds.length === 0}
                    tooltip="Reset Selected Items"
                    onClickHandlerReset={() => onClickHandlerSelectedIDsReset(constants.filterOptions.name.item)}/>
                <TextField 
                    style={{width: '210px'}}
                    id="outlined-basic" 
                    label="Item" 
                    variant="outlined" 
                    value={item}
                    name={constants.filterOptions.name.item}
                    size="small"
                    onChange={onChangeHandlerTextfield}
                    onClick={() => onClickHandlerTextfield(constants.filterOptions.name.item)}
                    className='filter-textbox-short'/>
            </Badge><br /><br />
            <Badge badgeContent={statusIds.length} color="primary">
                <ResetSelectedIdsIcon 
                    hide={statusIds.length === 0}
                    tooltip="Reset Selected Statuses"
                    onClickHandlerReset={() => onClickHandlerSelectedIDsReset(constants.filterOptions.name.status)}/>
                <TextField 
                    style={{width: '210px'}}
                    id="outlined-basic" 
                    label="Status" 
                    variant="outlined" 
                    value={status}
                    name={constants.filterOptions.name.status}
                    size="small"
                    onChange={onChangeHandlerTextfield}
                    onClick={() => onClickHandlerTextfield(constants.filterOptions.name.status)}
                    className='filter-textbox-short'/>
            </Badge><br /><br />
            <Badge badgeContent={customerIds.length} color="primary">
                <ResetSelectedIdsIcon 
                    hide={customerIds.length === 0}
                    tooltip="Reset Selected Customers"
                    onClickHandlerReset={() => onClickHandlerSelectedIDsReset(constants.filterOptions.name.customer)}/>
                <TextField 
                    style={{width: '210px'}}
                    id="outlined-basic" 
                    label="Customer" 
                    variant="outlined" 
                    value={customer}
                    name={constants.filterOptions.name.customer}
                    size="small"
                    onChange={onChangeHandlerTextfield}
                    onClick={() => onClickHandlerTextfield(constants.filterOptions.name.customer)}
                    className='filter-textbox-short'/>
            </Badge><br /><br />
            <Badge badgeContent={warehouseIds.length} color="primary">
                <ResetSelectedIdsIcon 
                    hide={warehouseIds.length === 0}
                    tooltip="Reset Selected Warehouses"
                    onClickHandlerReset={() => onClickHandlerSelectedIDsReset(constants.filterOptions.name.warehouse)}/>
                <TextField 
                    style={{width: '210px'}}
                    id="outlined-basic" 
                    label="Warehouse" 
                    variant="outlined" 
                    value={warehouse}
                    name={constants.filterOptions.name.warehouse}
                    size="small"
                    onChange={onChangeHandlerTextfield}
                    onClick={() => onClickHandlerTextfield(constants.filterOptions.name.warehouse)}
                    className='filter-textbox-short'/>
            </Badge><br /><br />
            <DateRangeWrapper>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}>
                    <Typography 
                        variant="h6" 
                        style={{fontSize: '1em',marginBottom: '5px'}}>
                        Fulfillment Date :
                    </Typography>
                     <DatePicker
                        label="From"
                        value={fulfillmentDateFrom}
                        onChange={onChangeHandlerFulfillmentDateFrom}
                        renderInput={(params) => <TextField {...params}  id="fulfillmentDateFrom" size="small" className='filter-textbox-short'  />}
                    /><br /><br />
                    <DatePicker
                        label="To"
                        value={fulfillmentDateTo}
                        onChange={onChangeHandlerFulfillmentDateTo}
                        renderInput={(params) => <TextField {...params} id="fulfillmentDateTo" size="small" className='filter-textbox-short'  />}
                    />
                </LocalizationProvider>
            </DateRangeWrapper><br />
        </FilterInputFields>
    )
}