import { constants } from '../../config/settings';
import { getLocaleLanguage } from '../../utils/utilities';

export const getBomHeaders = async (token) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );

    const options = {
        method: "GET",
        headers: headers,
    };

    const res = await window.fetch(`${constants.getBOMExternalWebUrl()}${constants.getBomHeaders}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}

export const getBomHeadersByFileId = async (token, bomFileId) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );

    const options = {
        method: "GET",
        headers: headers,
    };

    const res = await window.fetch(`${constants.getBOMExternalWebUrl()}${constants.getBomHeadersByBomFileId}?bomFileId=${bomFileId}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}

export const getBomHeadersByFileIds = async (token, bomFileIds) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );

    // let formData = new FormData();
    // formData.append('bomFileIds', bomFileIds);

    const options = {
        method: "POST",
        headers: headers,
        //body: formData,
    };

    const res = await window.fetch(`${constants.getBOMExternalWebUrl()}${constants.getBomHeadersByBomFileIds}?fileIds=${bomFileIds}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}

export const deleteBom = async (token, id) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );

    const options = {
        method: "GET",
        headers: headers,
    };

    const res = await window.fetch(`${constants.getBOMExternalWebUrl()}${constants.deleteBom}?id=${id}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}

export const uploadBOM = async (token, file, databaseCode, userName) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
//    headers.append("Content-Type","multipart/form-data");
    headers.append("Accept-Language", localeLanguage );

    let formData = new FormData();
    formData.append('file', file);

    const options = {
        method: "POST",
        headers: headers,
        body: formData
    };

    const res = await window.fetch(`${constants.getBOMExternalWebUrl()}${constants.uploadBom}?databaseCode=${databaseCode}&userName=${userName}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}