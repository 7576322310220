import { useProdPlanningFilterDrawerEvents } from "./hooks/ProdPlanningFilterDrawerEvents";
import { ProdPlanningFilterGrid } from "./FilterGrids/ProdPlanningFilterGrid";
import { ProdPlanningFilterInputFields } from "./FilterInputFields/ProdPlanningFilterInputFields";
import { FilterDrawer } from "../../../hooks/ui/FilterDrawer";
import { FilterGridToolbar } from "./FilterToolbar/FilterGridToolbar";

export const ProdPlanningFilterDrawer = () => {
    const {onClickHandlerFilterDrawerReset,onClickHandlerFilterDrawerApply} = useProdPlanningFilterDrawerEvents();
    return (
        <FilterDrawer
            onClickHandlerFilterDrawerApply={onClickHandlerFilterDrawerApply}
            onClickHandlerFilterDrawerReset={onClickHandlerFilterDrawerReset}
            filterGrid={<ProdPlanningFilterGrid/>}
            filterGridToolbar={<FilterGridToolbar />}
            filterInputFields={<ProdPlanningFilterInputFields/>} />
    )
}