import { Box, CircularProgress, Paper, styled } from "@mui/material"
import { DataGridPro, GridColumns, GridSelectionModel, useGridApiRef } from "@mui/x-data-grid-pro";
import { FC, useEffect } from "react";
import { DropDownGridOptions } from "../../../Types/DropDownGridOptions";
import { setHeight } from "../../../utils/utilities";

export const FilterGridRoot = styled('div')({
    backgroundColor: '#fff',
})

export const FilterGridBox = styled(Box)({
    width: '100%', 
    '& .MuiDataGrid-root .MuiDataGrid-cell': {
        borderBottom: 'unset!important',
    },
    '& .top': {
        backgroundColor: '#fff',
        zIndex: 9999,
    },
    '& .MuiPaper-root': {
        marginRight: '0!important',
    },
    '& .MuiDataGrid-dataContainer': {
        minWidth: 'unset!important',
    },
    '& .MuiDataGrid-viewport': {
        minWidth: 'unset!important',
        maxWidth: 'unset!important',
    },
    '& .MuiDataGrid-columnHeader--sortable.MuiDataGrid-columnHeader': {
        display: 'none',
    },
})

export interface IFilterGridProps {
    columns: GridColumns;
    rows: DropDownGridOptions[];
    loading: boolean;
    selectedIds: GridSelectionModel;
    gridWrapperWidth: string | null;
    gridHeight: string;
    selectedFilter: string;
    searchText: any;
    onSelectionModelChangeHandlerFilterGrid: (selectionModel: GridSelectionModel, details?: any) => void
}

export const FilterGrid :FC<IFilterGridProps> = (props) => {
    const { columns, rows, loading, selectedIds, gridWrapperWidth, gridHeight,
            selectedFilter, searchText, onSelectionModelChangeHandlerFilterGrid } = props;
    const apiRef = useGridApiRef();
    useEffect(() => {
        setHeight({target: '.filter-grid', lessPx: 20});
    },[])
    return (
        <FilterGridRoot>            
            <Paper 
                sx={{marginRight:3, paddingRight: 0, width: gridWrapperWidth ?? '250px'}}
                elevation={16}>
                <FilterGridBox className="filter-grid">
                    <DataGridPro
                        rowHeight={30}
                        rows={rows}
                        loading={loading}
                        columns={columns}
                        checkboxSelection
                        selectionModel={selectedIds}
                        apiRef={apiRef}
                        componentsProps={{
                            toolbar: { apiRef },
                        }}
                        onSelectionModelChange={onSelectionModelChangeHandlerFilterGrid}
                        filterModel={{
                            items: [
                                { id: 1, columnField: 'description', operatorValue: 'contains', value: searchText}
                            ]
                        }}
                    />
                </FilterGridBox>
            </Paper>
        </FilterGridRoot>
    )
}