import { constants } from '../../config/settings';
import { getLocaleLanguage } from '../../utils/utilities';

export const getStepRelationsByBomHeaderId = async (token,bomHeaderId) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );

    const options = {
        method: "GET",
        headers: headers,
    };

    const res = await window.fetch(`${constants.getBOMExternalWebUrl()}${constants.getStepRelations}?headerId=${bomHeaderId}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}

export const getStepRelationsByBomHeaderIds = async (token,bomHeaderIds) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );

    const options = {
        method: "GET",
        headers: headers,
    };

    const res = await window.fetch(`${constants.getBOMExternalWebUrl()}${constants.getStepRelationsByBomHeaderIds}?bomHeaderIds=${bomHeaderIds}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}

export const getLog = async (token, id) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );

    const options = {
        method: "GET",
        headers: headers,
    };

    const res = await window.fetch(`${constants.getBOMExternalWebUrl()}${constants.getLog}?id=${id}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}