import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import { FC } from "react"
import { useProductionPlanningStore } from "../../../../store/ProductionPlanningStore"
import { OptionBox } from "../styles/SharedStyles"

export interface IGanttViewMode {
    selectionOption: string,
    onChangeHandlerPlanningOption: (
        event: React.MouseEvent<HTMLElement>,
        value: string | null,
      ) => void
}

export const GanttViewMode :FC<IGanttViewMode> = (props:IGanttViewMode) => {
    const {selectionOption,onChangeHandlerPlanningOption} = props;
    const {productionOrders,isResizing,isSaving} = useProductionPlanningStore();
    return (
        <ToggleButtonGroup
            value={selectionOption}
            exclusive
            onChange={onChangeHandlerPlanningOption}
            aria-label="text alignment"
            sx={{
                height: '7ch'
            }}
            >
            <ToggleButton disabled={selectionOption==='month' && (isSaving ?? false)===false && (isResizing ?? false)===false && productionOrders && productionOrders.length>0} 
                          value="week" aria-label="centered" sx={{selected:{'& div':{padding: '11px 11px'}}}}>
                <OptionBox>Week</OptionBox>
            </ToggleButton>
            <ToggleButton  disabled={selectionOption==='week' && (isSaving ?? false)===false && (isResizing ?? false)===false && productionOrders && productionOrders.length>0}
                value="month" aria-label="right aligned" sx={{selected:{'& div':{padding: '11px 11px'}}}}>
                <OptionBox>Month</OptionBox>
            </ToggleButton>
        </ToggleButtonGroup>
    )
}