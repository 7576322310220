import 'smart-webcomponents-react/source/styles/smart.default.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './auth/authConfig';
import { MsalProvider } from '@azure/msal-react';
import { AuthProvider } from './context/AuthContext';
import MenuAppBar from './MenuAppBar';
import { constants } from './config/settings';
import { LicenseInfo } from '@mui/x-license-pro';
import { SnackbarProvider } from 'notistack';
import './App.css';
import { BomUploadProvider } from './store/BomUploadStore';
import { CustomDialogProvider } from './store/CustomDialogStore';
import { Main } from './Main';
import { ProductionPlanningProvider } from './store/ProductionPlanningStore';
import { ProdFilterProvider } from './store/ProdFilterStore';
import { TaskItemStoreProvider } from './store/TaskItemStore';
import { ProdPagerProvider } from './store/ProdPagerStore';
import { FilterDrawerProvider } from './store/FilterDrawerStore';
import { ProdPlanningFilterDrawerProvider } from './store/ProdPlanningFilterDrawerStore';
import { ExportBOMProvider } from './store/ExportBOMStore';
import { PopoverBoxProvider } from './store/PopoverBoxStore';
import { MBOMConversionProvider } from './store/MBOMConversionStore';
import { ImportBomFilterProvider } from './store/ImportBomFilterStore';
import { PopoverBox } from './ui/popovers/PopoverBox';
import { ConfirmationBoxProvider } from './store/ConfirmationBoxStore';
window.Smart.License = constants.smartComponentLicenseKey;
LicenseInfo.setLicenseKey(
  constants.muiLicenseKey,
);


function App() {
  const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <MsalProvider instance={msalInstance}>
    <AuthProvider>
      <SnackbarProvider maxSnack={10}>
      <PopoverBoxProvider>
        <CustomDialogProvider>
            <BomUploadProvider>
            <ProdFilterProvider>
                <ProductionPlanningProvider>
                  <TaskItemStoreProvider>
                    <ProdPagerProvider>
                    <FilterDrawerProvider>
                    <ProdPlanningFilterDrawerProvider>
                    <ExportBOMProvider>
                    <ImportBomFilterProvider>
                    <MBOMConversionProvider>
                    <ConfirmationBoxProvider>
                      <MenuAppBar /> 
                      <Main />
                      <PopoverBox />
                    </ConfirmationBoxProvider>
                    </MBOMConversionProvider>
                    </ImportBomFilterProvider>
                    </ExportBOMProvider>
                    </ProdPlanningFilterDrawerProvider>
                    </FilterDrawerProvider>
                    </ProdPagerProvider>
                  </TaskItemStoreProvider>              
                </ProductionPlanningProvider>
              </ProdFilterProvider>
            </BomUploadProvider>
        </CustomDialogProvider>
        </PopoverBoxProvider>
      </SnackbarProvider>
    </AuthProvider>
  </MsalProvider>
  );
}

export default App;
