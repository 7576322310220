import { useContext } from "react";
import { SidebarContainer } from "./components/sidebar/SidebarContainer";
import { AuthContext } from "./context/AuthContext";
import { PopoverBox } from "./ui/popovers/PopoverBox";

export const Main = () => {
    const authContext = useContext(AuthContext);
    return (
        <>
            {
                authContext?.auth?.userToken && 
                !(authContext?.isAuthorizing === true && authContext?.isAuthorizing === null) && 
                <SidebarContainer />
            }
            <PopoverBox />
        </>
    )
}