import { useEffect } from "react";
import { useProdPagerStore } from "../../../../store/ProdPagerStore";
import { Pager } from "../../../hooks/ui/Pager"
import { useProdPagerEvents } from "../hooks/ProdPagerEvents";
import { useProdPagerHelpers } from "../hooks/ProdPagerHelpers";

export const ProdPager = () => {
    const {resetPages,setResetPages,pageSize,currentPage,totalPages} = useProdPagerStore();
    const {onChangeHandlerPager,onChangeHandlerPageSize} = useProdPagerEvents();
    const {splitIntoPages} = useProdPagerHelpers();
    useEffect(() => {
        if (resetPages===true){
            setResetPages(false);
            splitIntoPages(currentPage);
        }
    },[resetPages])
    return (
        <>
            <Pager 
                onChangeHandlerPager={onChangeHandlerPager}
                onChangeHandlerPageSize={onChangeHandlerPageSize}
                currentPage={currentPage}
                totalPages={totalPages}
                pageSize={pageSize} />
        </>
    )
}