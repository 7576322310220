import { Checkbox, FormControlLabel } from "@mui/material";
import { useProdPlanningFilterDrawerStore } from "../../../../../../store/ProdPlanningFilterDrawerStore";

export const ShowRelated = () => {
    const {displayShowRelated,showRelated,setShowRelated,prodOrders,setFilterRows} = useProdPlanningFilterDrawerStore();
    const onChangeHandlerShowRelated = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setShowRelated(checked);
        // if (!checked){
        //     const list = prodOrders.filter(x => x.code.indexOf('.') === -1);
        //     setFilterRows(list);
        // } else {
        //     setFilterRows(prodOrders);
        // }
        
    }
    return (
        <>
            {displayShowRelated && 
            <FormControlLabel 
                style={{marginTop: '10px', marginLeft: '0', marginBottom: '10px'}}
                control={
                <Checkbox                     
                    checked={(showRelated ?? false)}
                    onChange={onChangeHandlerShowRelated} />
            } label="Show All Prod. Order Levels" />}
        </>
    )
}