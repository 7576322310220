import { useConfirmationBoxStore } from "../../../../../store/ConfirmationBoxStore";
import { useProdPlanningFilterDrawerStore } from "../../../../../store/ProdPlanningFilterDrawerStore";
import { useProductionPlanningStore } from "../../../../../store/ProductionPlanningStore";
import { IMultiTriggerFilterOptions } from "../../../../hooks/interfaces/IMultiTriggerFilterOptions";
import { useProductionPlanningFilterOptionsTriggers } from "../../../../hooks/triggers/ProductionPlanningFilterOptionsTriggers";

export const useConfirmationContentEvents = () => {
    const {setItemIds,setStatusIds,setCustomerIds,setProdOrderIds,setFilterIds,setFilterRows,
           setSelectedItemCodes,setSelectedStatusCodes,setSelectedCustomerCodes,setSelectedProdOrderCodes,
           dateStartFrom,dateStartTo,enableDateRange,setEnableDateRange,setWarehouseIds,setSelectedWarehouseCodes} = useProdPlanningFilterDrawerStore();
    const {targetDB} = useProductionPlanningStore();
    const {setConfirmationBoxOpen} = useConfirmationBoxStore();
    const {setMultiTriggerFilterOptions} = useProductionPlanningFilterOptionsTriggers();
    const processFilter = () => {
        setMultiTriggerFilterOptions({
            multiple: true,
            prodOrders: true,
            items: true,
            customers: true,
            status: true,
            warehouses: true,
            startDateFrom: (enableDateRange === true) === true ? dateStartFrom : null,
            startDateTo: (enableDateRange === true) === true ? dateStartTo : null,
            targetDB,
        } as IMultiTriggerFilterOptions);
    }
    const onClickHandlerYes = () => {
        setTimeout(() => {
            setItemIds([]);
            setSelectedItemCodes([]);
            setStatusIds([]);
            setSelectedStatusCodes([]);
            setCustomerIds([]);
            setSelectedCustomerCodes([]);
            setProdOrderIds([]);
            setWarehouseIds([]);
            setSelectedWarehouseCodes([]);
            setSelectedProdOrderCodes([]);
            setConfirmationBoxOpen(false);
            setFilterIds([]);
            setFilterRows([]);
            setEnableDateRange(!enableDateRange);
        },100);
        setTimeout(() => {
            processFilter();
        },500);
    }
    const onClickHandlerNo = () => {
        processFilter();
        setConfirmationBoxOpen(false);
    }
    return {
        onClickHandlerYes,
        onClickHandlerNo
    }
}