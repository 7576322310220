import { createContext, Dispatch, MouseEventHandler, SetStateAction, useContext, useState } from "react";

export interface ConfirmationBoxContextData {
    confirmationId: string,
    setConfirmationId: Dispatch<SetStateAction<string>>;

    confirmationBoxOpen: boolean;
    setConfirmationBoxOpen: Dispatch<SetStateAction<boolean>>;

    message: string;
    setMessage: Dispatch<SetStateAction<string>>;

    onCloseHandler: () => void;
    setOnCloseHandler: Dispatch<SetStateAction<() => void>>;

    okText: string;
    setOkText: Dispatch<SetStateAction<string>>;

    cancelText: string;
    setCancelText: Dispatch<SetStateAction<string>>;

    onClickHandlerOk:   MouseEventHandler<HTMLButtonElement> | undefined;
    setOnClickHandlerOk: Dispatch<SetStateAction< MouseEventHandler<HTMLButtonElement> | undefined>>;
    
    onClickHandlerCancel:  MouseEventHandler<HTMLButtonElement> | undefined;
    setOnClickHandlerCancel: Dispatch<SetStateAction< MouseEventHandler<HTMLButtonElement> | undefined>>;
}

export const confirmationBoxContextDefaultValue: ConfirmationBoxContextData = {   
    confirmationId: 'confirmationbox',
    setConfirmationId: () => {},
    confirmationBoxOpen: false,
    setConfirmationBoxOpen: () => {},
    message: '',
    setMessage: () => {},
    onCloseHandler: () => {},
    setOnCloseHandler: () => {},
    okText: 'Ok',
    setOkText: () => {},
    cancelText: 'Cancel',
    setCancelText: () => {},
    onClickHandlerOk: undefined,
    setOnClickHandlerOk: () => {},
    onClickHandlerCancel: undefined,
    setOnClickHandlerCancel: () => {},
}

export const ConfirmationBoxStore = createContext<ConfirmationBoxContextData>(confirmationBoxContextDefaultValue);

export const useConfirmationBoxStore = () => useContext(ConfirmationBoxStore);

export const ConfirmationBoxProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = ({ children }) => {
    const [confirmationId, setConfirmationId] = useState<string>('confirmationbox');
    const [confirmationBoxOpen, setConfirmationBoxOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [onCloseHandler, setOnCloseHandler] = useState<() => void>(() => {});
    const [okText, setOkText] = useState<string>('Ok');
    const [cancelText, setCancelText] = useState<string>('Cancel');
    const [onClickHandlerOk, setOnClickHandlerOk] = useState<MouseEventHandler<HTMLButtonElement> | undefined>(undefined);
    const [onClickHandlerCancel, setOnClickHandlerCancel] = useState<MouseEventHandler<HTMLButtonElement> | undefined>(undefined);
    return (
        <ConfirmationBoxStore.Provider
            value={{
                confirmationId,
                setConfirmationId,
                confirmationBoxOpen,
                setConfirmationBoxOpen,
                message,
                setMessage,
                onCloseHandler,
                setOnCloseHandler,
                okText,
                setOkText,
                cancelText,
                setCancelText,
                onClickHandlerOk,
                setOnClickHandlerOk,
                onClickHandlerCancel,
                setOnClickHandlerCancel,
            }}>
                {children}
        </ConfirmationBoxStore.Provider>
    )
}
