import styled from "@mui/system/styled";
import { useHelpers } from "../../../hooks/helpers/Helpers";
import { useExportBOMStore } from "../../../../store/ExportBOMStore";

export const LogViewerWrapper = styled('div')({
    padding: '20px'
})

export const LogViewer = () => {
    const {errorLogs} = useExportBOMStore();
    const { renderHTML } = useHelpers();
    return (
        <LogViewerWrapper>
            <h3>{`${errorLogs[0]?.code ?? ''} / ${errorLogs[0]?.title ?? ''}`}</h3>
            {renderHTML(errorLogs[0]?.errorMessage ?? '')}
        </LogViewerWrapper>
    )
}