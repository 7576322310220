import { useEffect, useState } from "react"
import { IWindowSize } from "./interfaces/IWindowSize";
import { IWindowSizeProps } from "./interfaces/IWindowSizeProps";

export const useWindowResize = (params: IWindowSizeProps) => {
    const {xheight, xwidth} = params;
    const [windowSize, setWindowSize] = useState<IWindowSize>({
        width: null,
        height: null
    } as IWindowSize)
    useEffect(() => {
        function handleResize() {
            let elemHeight = xheight ? document.querySelector<HTMLElement>(xheight.target) : null;
            let elemWidth = xwidth ? document.querySelector<HTMLElement>(xwidth.source) : null;
            setWindowSize({
                height: elemHeight ? window.innerHeight-(elemHeight?.offsetTop ?? 0)-(xheight?.lessPx ?? 0)+(xheight?.addPx ?? 0) : null,
                width: elemWidth ? window.innerWidth - (xwidth?.lessPx ?? 0)+(xwidth?.addPx ?? 0) : null
            })
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize',handleResize);
    },[]);
    return windowSize;
}