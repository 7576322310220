import { GridLinkOperator } from "@mui/x-data-grid-pro";
import { useImportBomFilterStore } from "../../../../store/ImportBomFilterStore";

export const useMBOMConversionGridHelpers = () => {
    const {searchText} = useImportBomFilterStore();
    const gridColumns = [
        {field: 'itemCode', headerName: 'Item Code', width: 150},
        {field: 'itemDescription', headerName: 'Item Description and Name', width: 350},
        {field: 'legacySKU', headerName: 'Legacy SKU', width: 200},
        {field: 'version', headerName: 'Version', width: 200},
        {field: 'madeBy', headerName: 'Made By', width: 250},
        {field: 'madeOn', headerName: 'Made On', width: 250},
    ]
    const gridFilterItems = {
        items: [
            {id:1, columnField: 'itemCode', operatorValue: 'contains', value: searchText },
            {id:2, columnField: 'itemDescription', operatorValue: 'contains', value: searchText },
            {id:3, columnField: 'legacySKU', operatorValue: 'contains', value: searchText },
            {id:4, columnField: 'version', operatorValue: 'contains', value: searchText },
            {id:5, columnField: 'madeBy', operatorValue: 'contains', value: searchText },
            {id:6, columnField: 'madeOn', operatorValue: 'contains', value: searchText },
        ],
        linkOperator: GridLinkOperator.Or
    }
    return {
        gridColumns,
        gridFilterItems,
    }
}