import { FormControl, InputLabel, MenuItem, Pagination, Select, SelectChangeEvent, Stack } from "@mui/material"
import styled from "@mui/system/styled";
import { FC } from "react";
import { constants } from "../../../config/settings";

export const PagerRoot = styled('div')({
    display: 'flex', 
    flexFlow:'row',
    '& .MuiList-root .MuiButtonBase-root': {
        display: 'unset!important',
    }
})

export const PageSizeDropdown = styled('div')({
    width: '75px',
})
export interface IPager {
    onChangeHandlerPager: (event: React.ChangeEvent<unknown>, page: number) => void,
    onChangeHandlerPageSize: (event: SelectChangeEvent) => void,
    currentPage: number,
    totalPages: number,
    pageSize: number,
}

export const Pager: FC<IPager> = (props: IPager) => {
    const {onChangeHandlerPager,onChangeHandlerPageSize,currentPage,totalPages,pageSize} = props;    
    return (
        <PagerRoot>
            {/* <PageSizeDropdown>
                <InputLabel id="page-size-select-label">Page Size</InputLabel>
                <Select
                    size="small"
                    labelId="page-size-select-label"
                    id="page-size-select"
                    value={pageSize.toString()}
                    label="Page Size"
                    onChange={onChangeHandlerPageSize}
                    >
                    {constants.paging.pageSize.map(size => 
                        <MenuItem value={size}>{size}</MenuItem>
                    )}
                </Select>
            </PageSizeDropdown> */}
            <Stack>
                {totalPages>0 &&
                <Pagination 
                    onChange={onChangeHandlerPager}
                    page={currentPage}
                    count={totalPages}
                    showFirstButton
                    showLastButton />}
            </Stack>
        </PagerRoot>
    )
}