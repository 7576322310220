import { Checkbox, styled } from "@mui/material";
import { useProdPlanningFilterDrawerStore } from "../../../../../store/ProdPlanningFilterDrawerStore";
import { useCheckboxEnabledDatesEvents } from "./hooks/CheckboxEnabledDatesEvents";

export const CheckboxWrapper = styled('div')({
    
})

export const CheckboxEnableDates = () => {
    const {onClickCheckboxEnableDates} = useCheckboxEnabledDatesEvents();
    const {enableDateRange} = useProdPlanningFilterDrawerStore();
    return (  
        <CheckboxWrapper>
            <Checkbox 
                checked={enableDateRange ?? false} 
                onClick={onClickCheckboxEnableDates}
            />
        </CheckboxWrapper>            
    )
}