import { IconButton, Tooltip } from "@mui/material"
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { useFilterDrawerIconEvents } from "./hooks/FilterDrawerIconEvents";

export const FilterDrawerIcon = () => {
    const {onClickHandlerFilterDrawer} = useFilterDrawerIconEvents();
    return (
        <div>
            <Tooltip title="Filter" >
                <IconButton onClick={onClickHandlerFilterDrawer}>
                    <TuneOutlinedIcon />
                </IconButton>
            </Tooltip>
        </div>
    )
}