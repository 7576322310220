import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { FC } from 'react';
import { ISearchProps } from '../interfaces/ISearchProps';
import { SearchIconWrapper, SearchWrapper, StyledInputBase,GroupIconWrapper } from './styles/SharedStyles';
import {FormGroup} from '@mui/material';

export const Search : FC<ISearchProps> = (props) => {
    const {onChangeHandlerSearch,onKeyUpHandlerSearch, value, checkBox} = props;
    return (
        <FormGroup>
            <SearchWrapper>            
                <GroupIconWrapper>
                    <SearchIconWrapper style={{position:'relative'}}>
                        <SearchOutlinedIcon />
                    </SearchIconWrapper>
                    {/* <CheckboxWrapper>
                        <FormControlLabel control={<CheckBox id={checkBox?.id} checked={checkBox?.checked} onChange={checkBox?.onChange} />} 
                            sx={{
                                "& .MuiFormControlLabel-label": {
                                    marginTop: '2px',
                                    color:  "rgba(0,0,0,0.6)",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "no-wrap"
                                }
                            }} 
                            label="Show Related" />
                    </CheckboxWrapper> */}
                </GroupIconWrapper>
                <StyledInputBase
                    className='prod-search-text'
                    defaultValue={value}
                    onChange={onChangeHandlerSearch}
                    onKeyUp={onKeyUpHandlerSearch}
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                    autoFocus={false}
                    />            
            </SearchWrapper>
        </FormGroup>
    )
}