import { useContext } from "react";

import { AuthContext } from "../../../../context/AuthContext";
import { DocumentFile } from "../../../../Types/Plm/DocumentFile";
import { useUploadStore } from "../../../../Types/Plm/UploadStore";
import { useUploadDataLoaders } from "../../hooks/UploadDataLoaders";

export const useUploadGridLoaders = () => {
    const authContext = useContext(AuthContext);
    const { loadEcnDoc } = useUploadDataLoaders();
    const { setDocumentFiles, setLoadingDocs, currentComponent } = useUploadStore();
    const getUploadDocsByCode = (docCode: any) => {
        setLoadingDocs(true);
        loadEcnDoc(
            authContext?.auth.userToken ?? '',
            docCode,
            (data: DocumentFile[]) => {
                setLoadingDocs(false);
                setDocumentFiles(data);
            },
            (err: any) => {
                console.log('err', err);
            })
    }
    return {
        getUploadDocsByCode,
    }
}