import styled from "@mui/system/styled"
import { useEffect } from "react"
import { IWindowSizeProps } from "../hooks/interfaces/IWindowSizeProps"
import { useWindowResize } from "../hooks/helpers/WindowResize"
import { MBOMConversionGrid } from "./grids/MBOMConversionGrid"
import { useInventorLoaders } from "./hooks/InventorLoaders"
import { MBOMConversionToobar } from "./toolbars/MBOMConversionToolbar"

export const MBOMConversionContainerRoot = styled('div')({
    padding: '20px',
    backgroundColor: '#f6f6f6',
})

export const MBOMConversionContainerWrapper = styled('div')({
    backgroundColor: '#fff',
    width: '100%',
    '& #productionPlanner .smart-timeline-cell[nonworking]': {
        backgroundColor: '#FFE799!important'
    }
})


export const MBOMConversionContainer = () => {
    const _main = document.querySelector<HTMLDivElement>('#root'),
          _width = `calc(${_main?.offsetWidth} - 270px)`;
    const {loadExportedBOM} = useInventorLoaders();
    const size = useWindowResize({
        xheight: {
            target: '.mbom-conversion-container',
            lessPx: 20,
        }
    } as IWindowSizeProps);
    useEffect(() => {
        loadExportedBOM();
    },[])
    return (
        <MBOMConversionContainerRoot>
            <MBOMConversionContainerWrapper
                style={size ? {height: `${size.height}px`, width: `${_width}`} : {}}
                className='mbom-conversion-container'>
                <MBOMConversionToobar />
                <MBOMConversionGrid />
            </MBOMConversionContainerWrapper>
        </MBOMConversionContainerRoot>
    )
}