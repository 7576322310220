import { styled } from "@mui/material/styles";
import { useExportBOMStore } from "../../../../store/ExportBOMStore";
import { GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Tooltip } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { ErrorLog } from "../../../../Types/ErrorLog";

export const GridIcons = styled('div')({
    display: 'flex',
    flexFlow: 'row',
    '& .delete-checkbox': {
        color: 'red!important',
    },
    '& .checkbox': {
        padding: '0 5px',
    }
});

export const HeaderGridIcons = styled('div')({
    display: 'flex',
    flexFlow: 'row',
    marginLeft: '-10px',
    backgroundColor: '#fff',
    padding: 0,
    '& .delete-checkbox': {
        color: 'red!important',
    },
    '& .checkbox': {
    }
});

export const usePreviewBOMExportGridEvents = () => {
    const {inventorBomHeader,isSaved,setOpenLogs,setOpenSummaryLogs,setErrorLogs} = useExportBOMStore();
    const onClickHandlerLogSummary = () => {
        let errors: ErrorLog[]=[];
        (inventorBomHeader?.details ?? []).map((x,i) => {
            if ((x.hasError ?? false)===true){
                errors.push({
                    id: x.id,
                    code: x.itemType ?? '',
                    title: x.partName ?? '',
                    errorMessage: x.errorMessage,
                } as ErrorLog);
            }
        });
        setErrorLogs(errors);
        setOpenSummaryLogs(true);
    }
    const onClickHandlerLog = (event: React.MouseEvent<HTMLButtonElement | null>, id: string) => {
        const err = (inventorBomHeader?.details ?? []).filter(x => x.id.toString() === id.toString());
        if (err.length>0){
            const errorLog: ErrorLog = {
                id: parseInt(id),
                code: err[0].itemType ?? '',
                title: err[0].partName ?? '',
                errorMessage: err[0].errorMessage ?? ''
             }
            setErrorLogs([errorLog]);
            setOpenLogs(true);
        }
    }
    const renderHeaderErrorSummaryViewer = (params: GridColumnHeaderParams) => (
        <>
            {isSaved===true && (inventorBomHeader?.hasErrors ?? false) && 
            <GridIcons>
                <Tooltip title="Error Summary Viewer">
                    <HeaderGridIcons>
                        <IconButton 
                            onClick={() => onClickHandlerLogSummary()} 
                            style={{paddingLeft: '5px'}}>
                            <ErrorOutlineOutlinedIcon  className="view-error error" />
                        </IconButton>
                    </HeaderGridIcons>
                </Tooltip>
            </GridIcons>}
        </>
    )
    const renderCellExportedStatus = (params: GridRenderCellParams) => (
        <>
        {isSaved === true && <GridIcons>
            {(params.getValue(params.id,"hasError")?.toString().toLowerCase() === "true")
            ? <Tooltip title="Error Viewer">
                <IconButton 
                    style={{paddingLeft: '1px'}}
                    onClick={(e) => onClickHandlerLog(e, (params.getValue(params.id,"id")?.toString() ?? ''))}
                    >
                    <ErrorOutlineOutlinedIcon  className="view-error error" />
                </IconButton>
              </Tooltip>
            : <CheckCircleOutlinedIcon className="ok" />}
        </GridIcons>}
        </>
    )
    return {
        renderHeaderErrorSummaryViewer,
        renderCellExportedStatus,
    }
}