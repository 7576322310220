import { Box, CircularProgress, Drawer, FormControl, Paper, styled, Typography } from "@mui/material"
import { FC, ReactNode } from "react";
import { useFilterDrawerStore } from "../../../store/FilterDrawerStore"
import { useFilterDrawerEvents } from "../events/FilterDrawerEvents";
import { Button } from 'smart-webcomponents-react/button';
import { Grow } from "../../styles/SharedStyles";
import { useProdPlanningFilterDrawerStore } from "../../../store/ProdPlanningFilterDrawerStore";

const RootForm = styled('form')({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    padding: '15px',
    backgroundColor: '#fff',
    '& h5 ': {
        paddingLeft: '8px',
    }
});

const FilterButtons = styled('div')({
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    marginBottom: '5px',
});

const FormControlButton = styled(FormControl)({
    marginLeft: '10px!important',
})

const FilterBody = styled('div')({
    display: 'flex',
    flexFlow: 'row',
})

const FilterRight = styled('div')({
    flex: 1,
    backgroundColor: '#fff',
})

const FilterLeft = styled('div')({
    display: 'flex',
    flexFlow: 'column',
})

const FilterFields = styled(Paper)({
    textAlign: 'center',
    padding: '20px 20px', 
    overflowY: 'auto',
    '& .MuiPaper-root': {
        height: '95%',
    }
})

export interface IFilterDrawerProps {
    onClickHandlerFilterDrawerApply: () => void,
    onClickHandlerFilterDrawerReset: () => void,
    filterGrid: ReactNode,
    filterGridToolbar?: ReactNode,
    filterInputFields: ReactNode,
}

export const FilterDrawer :FC<IFilterDrawerProps> = (props) => {
    const {onClickHandlerFilterDrawerApply,onClickHandlerFilterDrawerReset,filterGrid,filterGridToolbar,filterInputFields} = props;
    const {openFilterDrawer} = useFilterDrawerStore();
    const {onCloseHandlerFilterDrawer} = useFilterDrawerEvents();
    const {filterLoading} = useProdPlanningFilterDrawerStore();
    return (
        <Drawer 
            anchor="right"
            open={openFilterDrawer}
            onClose={onCloseHandlerFilterDrawer}>
                {filterLoading ??<CircularProgress style={{position: 'absolute', top: '50%', left: '30%', zIndex:99999}} size={34} disableShrink /> }
            <RootForm>
                <Typography variant="h5">
                    Filter
                </Typography>
                <FilterButtons>
                    <Box style={{marginTop: '20px', marginLeft: '20px'}}>
                        {filterGridToolbar && filterGridToolbar}
                        {/*  */}
                    </Box>
                    <Grow />
                    <Box style={{marginBottom: '5px'}}>
                        <FormControlButton 
                            variant="outlined">
                            <Button  
                                onClick={onClickHandlerFilterDrawerApply}
                                className="raised primary">
                                Apply
                            </Button>
                        </FormControlButton>
                        <FormControlButton 
                            variant="outlined">
                            <Button 
                                className="raised primary"
                                onClick={onClickHandlerFilterDrawerReset}>
                                Reset
                            </Button>
                        </FormControlButton>
                    </Box>
                </FilterButtons>
                <FilterBody>
                    <FilterLeft>
                        {filterGrid}
                    </FilterLeft>
                    <FilterRight>
                        {filterInputFields}
                    </FilterRight>
                </FilterBody>
            </RootForm>
        </Drawer>
    )
}