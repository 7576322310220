import {constants} from '../config/settings';

export const getLocaleLanguage = () => {
    const locales = constants.locales;
    let localeLanguage = "en-us";
    const options = { year: "numeric", month: "numeric", day: "numeric" }
    if (locales && locales.length>0){
        const localDateString = (new Date()).toLocaleDateString();
        let found = false;
        locales.map(loc => {
            if (localDateString === (new Date().toLocaleDateString(loc,options)) && !found){
                localeLanguage = loc;
                found = true;
            }
            return loc;
        })
    }
    return localeLanguage;
}

export const isPowerUser = (roleAccess, componentCode, admin) => {
    const hasAccessRights = (roleAccess.filter(a => 
        a.roleCode === 'POWERUSER' &&
        a.componentCode === componentCode )?.length ?? 0) > 0 ||
        (admin ?? false);
    return hasAccessRights;
}

export const hasAccessRights = (roleAccess, componentCode, actionCode, admin) => {
    const hasAccessRights = (roleAccess.filter(a => 
        a.componentCode === componentCode &&
        a.actionCode === actionCode )?.length ?? 0) > 0 ||
        (admin ?? false);
    return hasAccessRights;
}

export const setHeight = (params) => {
    const {target, lessPx, addPx} = params;
    let elem = document.querySelector(target);
    if (elem){
        elem.style.height = `${window.innerHeight-(elem.offsetTop)-(lessPx ?? 0)+(addPx ?? 0)}px`;
    }
}

export const setWidth = (params) => {
    const {target, source, lessPx} = params;
    let elem = document.querySelector(target);
    if (elem)
        elem.style.width = `${document.querySelector(source).offsetWidth - (lessPx ?? 0)}px`;
}

export const setGridWidth = (params) => {
    const {target, style } = params;
    let elem = document.querySelector(target);
    if (elem){
        elem.style.width = style;
    }
}

export const waitForElement = selector => new Promise((resolve, reject) => {
    const element = document.querySelector(selector);

    
    if (element) {      
      return resolve(element);
    }
  
    //const timeout = setTimeout(() => reject(new Error('element not found')), 20000);
  
    const walkDOM = (node, fn) => {
      if (node.matches && node.matches(selector)) {
        return fn(node);
      }
      node = node.firstChild;
      while (node) {
        walkDOM(node, fn);
        node = node.nextSibling;
      }
    };
  
    try {      
      const observer = new MutationObserver((mutations => {
        mutations.forEach(mutation => {
          const nodes = Array.from(mutation.addedNodes);
          return nodes.forEach(node => walkDOM(node, match => {
            observer.disconnect();
            //clearTimeout(timeout);
            return resolve(match);
          }));
        });
      }));
  
      observer.observe(document.documentElement, { childList: true, subtree: true });
    } catch (err) {
      console.debug('waitForElement, error:', err);
      return reject(err);
    }
});

export const isEmpty = (value) => {   
  const type = typeof val;
   if ((value !== null && type === 'object') || type === 'function') {
     const properties = Object.keys(value);
      if (properties.length === 0 || properties.size === 0) { 
        return true;
      } 
    } 
    return !value;
}

export const fixDateTimePicker = (params) => {
  const {target, attribute} = params;
  if (target && attribute){
    let targetElem = document.querySelector(target);
    if (targetElem){
      attribute.forEach(attr => {
        targetElem.setAttribute(attr.name,attr.value);
      })
    }
  }

}

export const setAsyncTimeout = (cb, timeout = 0) => new Promise(resolve => {
  setTimeout(() => {
      cb();
      resolve();
  }, timeout);
});
