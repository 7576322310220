import { GridRowId } from "@mui/x-data-grid-pro";
import { useContext } from "react";
import { constants } from "../../../config/settings";
import { AuthContext } from "../../../context/AuthContext";
import { getFilterOptions, getFilterOptionsByDates } from "../../../services/productionPlanning/filterOptions"
import { useProdPlanningFilterDrawerStore } from "../../../store/ProdPlanningFilterDrawerStore";
import { useProductionPlanningStore } from "../../../store/ProductionPlanningStore";
import { DropDownGridOptions } from "../../../Types/DropDownGridOptions";
import { IProductionPlanningFilterOptionsTrigger } from "../interfaces/IProductionPlanningFilterOptionsTriggers";

export const useProductionPlanningFilterOptionsLoaders = () => {
    const authContext = useContext(AuthContext);
    const {targetDB} = useProductionPlanningStore();
    const {setAllItems,setItems,setAllCustomers,setCustomers,setAllProdOrders,setProdOrders,setAllStatuses,setStatuses,
           prodOrderIds,itemIds,statusIds,customerIds,items,statuses,customers,prodOrders,warehouses,
           warehouseIds,setAllWarehouses,setWarehouses} = useProdPlanningFilterDrawerStore();
    let selectedItems: string[] = [];
    let selectedStatuses: string[] = [];
    let selectedCustomers: string[] = [];
    let selectedProdOrders: string[] = [];
    let selectedWarehouses: string[] = [];
    const getIDS = () => {
        selectedItems = items.filter(x => itemIds.filter(i => i===x.id).length>0).map(x => x.code);
        selectedStatuses = statuses.filter(x => statusIds.filter(i => i===x.id).length>0).map(x => x.id);
        selectedCustomers = customers.filter(x => customerIds.filter(i => i===x.id).length>0).map(x => x.code);
        selectedProdOrders = prodOrders.filter(x => prodOrderIds.filter(i => i===x.id).length>0).map(x => x.code);
        selectedWarehouses = warehouses.filter(x => warehouseIds.filter(i => i===x.id).length>0).map(x => x.code);
    }
    const getSelectedIDS = (ids: GridRowId[], option: string) => {
        switch (option){
            case constants.filterOptions.type.ProdOrder: 
                return prodOrders.filter(x => ids.filter(i => i===x.id).length>0).map(x => x.code);
            case constants.filterOptions.type.Item:
                return items.filter(x => ids.filter(i => i===x.id).length>0).map(x => x.code);
            case constants.filterOptions.type.Status:
                return statuses.filter(x => ids.filter(i => i===x.id).length>0).map(x => x.code);
            case constants.filterOptions.type.Customer:
                return customers.filter(x => ids.filter(i => i===x.id).length>0).map(x => x.code);
            case constants.filterOptions.type.Warehouse:
                return warehouses.filter(x => ids.filter(i => i===x.id).length>0).map(x => x.code);
        }
    }
    const loadFilterOptionCustomers = (productionPlanningFilterOptionTriggers: IProductionPlanningFilterOptionsTrigger | null) => {
        return new Promise((resolve) => {
            if (productionPlanningFilterOptionTriggers !== null){
            getIDS();
            getFilterOptionsByDates(authContext?.auth.userToken,{
                //selectedOption: productionPlanningFilterOptionTriggers.option,
                option: constants.filterOptions.type.Customer,
                prodOrders: selectedProdOrders,
                items: selectedItems,
                statusCodes: selectedStatuses,
                customers: productionPlanningFilterOptionTriggers.option === constants.filterOptions.type.Customer
                             ? getSelectedIDS(productionPlanningFilterOptionTriggers.selectedIds as GridRowId[],productionPlanningFilterOptionTriggers.option) 
                             : selectedCustomers,
                warehouses: selectedWarehouses,
                startDateFromStr: productionPlanningFilterOptionTriggers.startDateFrom,
                startDateToStr: productionPlanningFilterOptionTriggers.startDateTo,
                targetDB: productionPlanningFilterOptionTriggers.targetDB,
            })
            .then((data: DropDownGridOptions[]) => {
                (data ?? []).map(x => {
                    x.id = x.code;
                    return x;
                });
                setAllCustomers(data);
                setCustomers(data)
                return resolve(true);
                //setCustomerIds( data.filter(x => selectedCustomerCodes.filter(i => i===x.code).length>0).map(x => x.id));
            });
        }
        else
            getFilterOptions(authContext?.auth.userToken,constants.filterOptions.type.Customer,targetDB)
            .then((data: DropDownGridOptions[]) => {
                (data ?? []).map(x => {
                    x.id = x.code;
                    return x;
                });
                setAllCustomers(data);
                setCustomers(data)
                return resolve(true);
            });
        });
    }
    const loadFilterOptionStatus = (productionPlanningFilterOptionTriggers: IProductionPlanningFilterOptionsTrigger | null) => {
        return new Promise((resolve) => {
            if (productionPlanningFilterOptionTriggers !== null){
                getIDS();
                getFilterOptionsByDates(authContext?.auth.userToken,{
                    //selectedOption: productionPlanningFilterOptionTriggers.option,
                    option: constants.filterOptions.type.Status,
                    prodOrders: selectedProdOrders,
                    items: selectedItems,
                    statusCodes: productionPlanningFilterOptionTriggers.option === constants.filterOptions.type.Status
                                ? getSelectedIDS(productionPlanningFilterOptionTriggers.selectedIds as GridRowId[],productionPlanningFilterOptionTriggers.option) 
                                : selectedStatuses,
                    customers: selectedCustomers,
                    warehouses: selectedWarehouses,
                    startDateFromStr: productionPlanningFilterOptionTriggers.startDateFrom,
                    startDateToStr: productionPlanningFilterOptionTriggers.startDateTo,
                    targetDB: productionPlanningFilterOptionTriggers.targetDB,
                })
                .then((data: DropDownGridOptions[]) => {
                    data.map(x => {
                        x.id = x.code;
                        return x;
                    })
                    setAllStatuses(data);
                    setStatuses(data);
                    return resolve(true);
                    //setStatusIds( data.filter(x => selectedStatusCodes.filter(i => i===x.code).length>0).map(x => x.id));
                });
            }
            else
                getFilterOptions(authContext?.auth.userToken,constants.filterOptions.type.Status,targetDB)
                .then((data: DropDownGridOptions[]) => {
                    (data ?? []).map(x => {
                        x.id = x.code;
                        return x;
                    });
                    setAllStatuses(data);
                    setStatuses(data);
                    resolve(true);
                });
        })
    };
    const loadFilterOptionItems = (productionPlanningFilterOptionTriggers: IProductionPlanningFilterOptionsTrigger | null) => {
        return new Promise((resolve) => {
            if (productionPlanningFilterOptionTriggers !== null){
                getIDS();
                getFilterOptionsByDates(authContext?.auth.userToken,{
                    //selectedOption: productionPlanningFilterOptionTriggers.option,
                    option: constants.filterOptions.type.Item,
                    prodOrders: selectedProdOrders,
                    items: productionPlanningFilterOptionTriggers.option === constants.filterOptions.type.Item
                            ? getSelectedIDS(productionPlanningFilterOptionTriggers.selectedIds as GridRowId[],productionPlanningFilterOptionTriggers.option) 
                            : selectedItems,
                    statusCodes: selectedStatuses,
                    customers: selectedCustomers,
                    warehouses: selectedWarehouses,
                    startDateFromStr: productionPlanningFilterOptionTriggers.startDateFrom,
                    startDateToStr: productionPlanningFilterOptionTriggers.startDateTo,
                    targetDB: productionPlanningFilterOptionTriggers.targetDB,
                })
                .then((data: DropDownGridOptions[]) => {
                    (data ?? []).map(x => {
                        x.id = x.code;
                        return x;
                    });
                    setAllItems(data);
                    setItems(data);
                    return resolve(true);
                    //setItemIds( data.filter(x => selectedItemCodes.filter(i => i===x.code).length>0).map(x => x.id));
                });
            }
            else
                getFilterOptions(authContext?.auth.userToken,constants.filterOptions.type.Item,targetDB)
                .then((data: DropDownGridOptions[]) => {
                    (data ?? []).map(x => {
                        x.id = x.code;
                        return x;
                    });
                    setAllItems(data);
                    setItems(data);
                    return resolve(true);
                });
        })
    };
    const loadFilterOptionProdOrders = (productionPlanningFilterOptionTriggers: IProductionPlanningFilterOptionsTrigger | null) => {
        return new Promise((resolve) => {
            if (productionPlanningFilterOptionTriggers !== null){
                getIDS();
                getFilterOptionsByDates(authContext?.auth.userToken,{
                    //selectedOption: productionPlanningFilterOptionTriggers.option,
                    option: constants.filterOptions.type.ProdOrder,
                    prodOrders: productionPlanningFilterOptionTriggers.option === constants.filterOptions.type.ProdOrder
                                ? getSelectedIDS(productionPlanningFilterOptionTriggers.selectedIds as GridRowId[],productionPlanningFilterOptionTriggers.option) 
                                : selectedProdOrders,
                    items: selectedItems,
                    statusCodes: selectedStatuses,
                    customers: selectedCustomers,
                    warehouses: selectedWarehouses,
                    startDateFromStr: productionPlanningFilterOptionTriggers.startDateFrom,
                    startDateToStr: productionPlanningFilterOptionTriggers.startDateTo,
                    targetDB: productionPlanningFilterOptionTriggers.targetDB,
                })
                .then((data: DropDownGridOptions[]) => {
                    (data ?? []).map(x => {
                        x.id = x.code;
                        return x;
                    });
                    setAllProdOrders(data);
                    setProdOrders(data);
                    return resolve(true);
                    //setProdOrderIds( data.filter(x => selectedProdOrderCodes.filter(i => i===x.code).length>0).map(x => x.id));
                });
            }
            else
                getFilterOptions(authContext?.auth.userToken,constants.filterOptions.type.ProdOrder,targetDB)
                .then((data: DropDownGridOptions[]) => {
                    (data ?? []).map(x => {
                        x.id = x.code;
                        return x;
                    });
                    setAllProdOrders(data);
                    setProdOrders(data);
                    return resolve(true);
                });
        });
    };
    const loadFilterOptionWarehouses = (productionPlanningFilterOptionTriggers: IProductionPlanningFilterOptionsTrigger | null) => {
        return new Promise((resolve) => {
            if (productionPlanningFilterOptionTriggers !== null){
            getIDS();
            getFilterOptionsByDates(authContext?.auth.userToken,{
                option: constants.filterOptions.type.Warehouse,
                prodOrders: selectedProdOrders,
                items: selectedItems,
                statusCodes: selectedStatuses,
                customers: selectedCustomers,
                warehouses: productionPlanningFilterOptionTriggers.option === constants.filterOptions.type.Warehouse
                             ? getSelectedIDS(productionPlanningFilterOptionTriggers.selectedIds as GridRowId[],productionPlanningFilterOptionTriggers.option) 
                             : selectedWarehouses,
                startDateFromStr: productionPlanningFilterOptionTriggers.startDateFrom,
                startDateToStr: productionPlanningFilterOptionTriggers.startDateTo,
                targetDB: productionPlanningFilterOptionTriggers.targetDB,
            })
            .then((data: DropDownGridOptions[]) => {
                (data ?? []).map(x => {
                    x.id = x.code;
                    return x;
                });
                setAllWarehouses(data);
                setWarehouses(data)
                return resolve(true);
            });
        }
        else
            getFilterOptions(authContext?.auth.userToken,constants.filterOptions.type.Warehouse,targetDB)
            .then((data: DropDownGridOptions[]) => {
                (data ?? []).map(x => {
                    x.id = x.code;
                    return x;
                });
                setAllWarehouses(data);
                setWarehouses(data)
                return resolve(true);
            });
        });
    }
    return {
        loadFilterOptionCustomers,
        loadFilterOptionItems,
        loadFilterOptionStatus,
        loadFilterOptionProdOrders,
        loadFilterOptionWarehouses,
    }
}