import { FC } from "react";
import { ErrorLog } from "../../../../Types/ErrorLog";
import { useHelpers } from "../../hooks/Helpers";
import { SummaryLogViewerWrapper } from "../styles/SharedStyles"

interface LogSummaryProps {
    errorLogs: ErrorLog[]
}

export const LogSummary: FC<LogSummaryProps> = (props: LogSummaryProps) => {
    const {errorLogs} = props;
    const { renderHTML } = useHelpers();
    return (
        <SummaryLogViewerWrapper>
            {
                errorLogs && errorLogs.length>0 &&
                errorLogs.map(x => 
                    (
                        <>
                            <div><h4>{x.title}</h4></div>
                            <div>{renderHTML(x.errorMessage)}</div>
                            <br /><br />
                        </>
                    )
                )
            }
        </SummaryLogViewerWrapper>
    )
}