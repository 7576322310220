import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from "react";

export interface CustomDialogContextData {
    open: boolean,
    setOpen:  Dispatch<SetStateAction<boolean>>,
}

export const customDialogContextDefaultValue: CustomDialogContextData = {
    open: false,
    setOpen: () => {},
}

export const CustomDialogStore = createContext<CustomDialogContextData>(customDialogContextDefaultValue);

export const useCustomDialogStore = () => useContext(CustomDialogStore);

export const CustomDialogProvider: FC = ({children}) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <CustomDialogStore.Provider
            value={{
                open,
                setOpen,
            }}>
            {children}
        </CustomDialogStore.Provider>
    )
}