import { Box, IconButton, Toolbar } from "@mui/material"
import { Grow } from "../styles/SharedStyles"
import { Icons, UploadAppBar } from "./styles/SharedStyles"
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Dispatch, FC, SetStateAction } from "react";

interface CustomDialogToolbarProps {
    title: string;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export const CustomDialogToolbar: FC<CustomDialogToolbarProps> = (props: CustomDialogToolbarProps) => {
    const {setOpen,title} = props;
    const onClickHandlerClose = () => {
        setOpen(false);
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <UploadAppBar 
                position="static" >
                <Toolbar variant="dense">
                    <h3>{title}</h3>
                    <Grow />
                    <Icons>
                        <IconButton 
                            onClick={onClickHandlerClose}>
                            <CloseOutlinedIcon  />
                        </IconButton>
                    </Icons>
                </Toolbar>
            </UploadAppBar>
        </Box>
    )
}