import { IconButton, Tooltip } from "@mui/material";
import { DataGridPro, GridColumnHeaderParams, GridRenderCellParams, GridSelectionModel, GridState } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { BomHeaderGridContainer, GridHeaderWrapper, GridIcons, HeaderGridIcons } from "./styles/SharedStyles";
import { CustomDialog } from "../dialog/CustomDialog";
import { CustomDialogToolbar } from "../toolbars/CustomDialogToolbar";
import { LogViewer } from "./viewer/LogViewer";
import { LogSummary } from "./viewer/LogSummary";
import { useLoadBomHeaders } from "../hooks/DataLoad";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useBomUploadStore } from "../../../store/BomUploadStore";
import { BomHeader } from "../../../Types/BomHeader";
import { ErrorLog } from "../../../Types/ErrorLog";

export const BomHeaderGrid = () => {
    //const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openSummary, setOpenSummary] = useState(false);
    const [errors, setErrors] = useState(false);
    const {loadBomHeadersByFileIds} = useLoadBomHeaders();
    const {bomHeaders, setBomHeaders, loading, setLoading, setSelectedBomHeaderIds, selectedBomHeaderIds, 
           setAllBomHeaders, setErrorLog, errorLogBomHeaders, setErrorLogBomHeaders, loadGrid,
           selectedBomFileIds } = useBomUploadStore();
    const onClickHandlerLog = (event: React.MouseEvent<HTMLButtonElement | null>, id: string) => {
        const err = errorLogBomHeaders.filter(x => x.id.toString() === id.toString());
        if (err.length>0){
            setErrorLog(err[0]);
            setOpen(true);
        }
    }
    const onClickHandlerLogSummary = () => {
        setOpenSummary(true);
    }
    const hasErrors = (bheaders: BomHeader[]) => {
        const foundErrors = bheaders.filter(x => (x.recipeLog ?? '').length>0 && x.status !== 1);
        setErrors((foundErrors && foundErrors.length>0));
    }
    const cols = [
        {
            field: '',
            headerName: '',
            width: 30,
            sortable: false,
            resizable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            cellClassName: 'bom-header',
            renderHeader: (params: GridColumnHeaderParams) => (
                <>
                {errors && 
                <GridIcons>
                    <Tooltip title="Error Summary Viewer">
                        <HeaderGridIcons>
                            <IconButton onClick={onClickHandlerLogSummary} style={{paddingLeft: '5px'}}>
                                <ErrorOutlineOutlinedIcon  className="view-error error" />
                            </IconButton>
                        </HeaderGridIcons>
                    </Tooltip>
                </GridIcons>}
                </>
            ),
            renderCell: (params: GridRenderCellParams) => (
                <GridIcons>
                    {(params.getValue(params.id,"status")?.toString() !== "1" &&  
                        (params.getValue(params.id,"recipeLog") ?? '').toString().length>0 )  
                    ? <Tooltip title="Error Viewer">
                        <IconButton style={{paddingLeft: '1px'}}
                            onClick={(e) => onClickHandlerLog(e, (params.getValue(params.id,"id")?.toString() ?? ''))}>
                            <ErrorOutlineOutlinedIcon  className="view-error error" />
                        </IconButton>
                      </Tooltip>
                    : <CheckCircleOutlinedIcon className="ok" />}
                </GridIcons>
                
            )
        },
        {field: 'id', hide: true},
        {field: 'itemCode', headerName: 'Item Code', width: 150, headerClassName: 'bom-header-grid'},
        {field: 'mainVersion', headerName: 'Main Version', width: 170, headerClassName: 'bom-header-grid'},
        {field: 'version', headerName: 'Version', width: 150, headerClassName: 'bom-header-grid'},
        {field: 'description', headerName: 'Description', width: 165, headerClassName: 'bom-header-grid'},
        {field: 'costCenter', headerName: 'Cost Center', width: 170, headerClassName: 'bom-header-grid'},
        {field: 'drawingNo', headerName: 'Drawing Number', width: 170, headerClassName: 'bom-header-grid'},
        {field: 'effectiveDate', headerName: 'Effective Date', width: 165, headerClassName: 'bom-header-grid'},
        {field: 'expiredDate', headerName: 'Expired Date', width: 150, headerClassName: 'bom-header-grid'},
        {field: 'leadTime', headerName: 'Lead Time', width: 165, headerClassName: 'bom-header-grid'},
        {field: 'quantity', headerName: 'Quantity', width: 150, headerClassName: 'bom-header-grid'},
        {field: 'status', headerName: 'Status', width: 150, headerClassName: 'bom-header-grid'},
    ];
    const onSelectionModelChangeHandler = (selectionModel: GridSelectionModel, details?: any) => {
        setSelectedBomHeaderIds(selectionModel);
    };
    useEffect(() => {
        if (loadGrid){
            setLoading(true);
            loadBomHeadersByFileIds(selectedBomFileIds, (data: BomHeader[]) => {
                setSelectedBomHeaderIds([]);
                setBomHeaders(data);
                setAllBomHeaders(data);
                setErrorLogBomHeaders(data.filter(x => x.status !== 1 &&  (x.recipeLog ?? '').length>0)
                .map(x => ({
                    id: x.id,
                    code: x.itemCode,
                    title: `Item Code # : ${x.itemCode} ; Version : ${x.version}`,
                    errorMessage: x.recipeLog
                } as ErrorLog)));
                hasErrors(data);
                setLoading(false);
            });
        };
    },[selectedBomFileIds]);
    return (
        <BomHeaderGridContainer>
            <GridHeaderWrapper>
                <DataGridPro 
                    checkboxSelection={true}
                    disableSelectionOnClick={true}
                    rowHeight={30}
                    columns={cols}
                    rows={bomHeaders}
                    loading={loading}
                    onSelectionModelChange={onSelectionModelChangeHandler}
                    onStateChange={(state: GridState, event: {defaultMuiPrevented?: boolean | undefined;}, details: any) => {
                        if (state.selection.length !== selectedBomHeaderIds.length){
                            setSelectedBomHeaderIds(state.selection)
                        }
                    }}
                />
            </GridHeaderWrapper>
            <CustomDialog 
                open={open} 
                setOpen={setOpen}>
                <CustomDialogToolbar 
                    title="Error Viewer"
                    setOpen={setOpen} />
                <LogViewer />
            </CustomDialog>
            <CustomDialog open={openSummary} setOpen={setOpenSummary}>
                <CustomDialogToolbar 
                    title="Error Summary Viewer"
                    setOpen={setOpenSummary} />
                <LogSummary errorLogs={errorLogBomHeaders} />
            </CustomDialog>
        </BomHeaderGridContainer>
    )
}