import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import {FC, useEffect, useState} from "react"
import { constants } from "../../../../config/settings";
import { useProductionPlanningStore } from "../../../../store/ProductionPlanningStore";
import { useExpandCollapseRadioGroupEvents } from "./hooks/ExpandCollapseRadioGroupEvents";
import { StyledFormControl } from "./styles/sharedStyles";

export const TargetDBRadioGroup:FC = () => {    
    const {onChangeHandlerExpandCollapse} = useExpandCollapseRadioGroupEvents();
    const {targetDB,userDatabases,loadingProductionPlannings} = useProductionPlanningStore();
    const [hasProd,setHasProd] = useState<boolean>(false);
    useEffect(() => {
        if (userDatabases){
            setHasProd(userDatabases.some( x => x.databaseCode === constants.databases.prod));
        }
    },[userDatabases])
    return (
        <StyledFormControl>
            <RadioGroup
                aria-labelledby="status-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={targetDB}
                onChange={onChangeHandlerExpandCollapse}>
                <FormControlLabel 
                    disabled={!(hasProd && !constants.isDevelopment)}   //{!(hasProd && !constants.isDevelopment)}  enable this when go live
                    value="110" 
                    label="110 (Production)"
                    control={
                            <Radio 
                                inputProps={{ 'aria-label': '110 (Production)' }} 
                                sx={{'& .MuiSvgIcon-root': {fontSize: 12}}} 
                            />} 
                />

                <FormControlLabel 
                    value="999" 
                    label="999 (Test)"
                    control={
                            <Radio 
                                inputProps={{ 'aria-label': '999 (Test)' }} 
                                sx={{'& .MuiSvgIcon-root': {fontSize: 12}}} />}  
                />
            </RadioGroup>
        </StyledFormControl>
    )
}