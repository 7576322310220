import { useContext } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import { setRequestRefreshData } from "../../../../../services/productionPlanning/productionPlanning";
import { useProdFilterStore } from "../../../../../store/ProdFilterStore";
import { useProductionPlanningStore } from "../../../../../store/ProductionPlanningStore";
import { ResultOptions } from "../../../../../Types/ResultOptions";
import { useProductionPlanningScheduleGanttChart } from "../../../charts/hooks/ProductionPlanningScheduleGanttChart"
import { useProdPlanningFilterDrawerEvents } from "../../../filters/FilterDrawers/hooks/ProdPlanningFilterDrawerEvents";

export const useRefreshDataHelpers = () => {
    const {setLoadingProductionPlannings, setReloadDataInProgress} = useProductionPlanningStore();
    const {displayProgress} = useProductionPlanningScheduleGanttChart();    
    const authContext = useContext(AuthContext);
    
    const {onClickHandlerFilterDrawerApply} = useProdPlanningFilterDrawerEvents();


    const clearCacheRequest = () => {        
        
        try {
            (async()=>{
                setRequestRefreshData(
                authContext?.auth.userToken
                ).then((data:ResultOptions)=>{
                    //   
                    onClickHandlerFilterDrawerApply();               
                }).catch((e:any)=>{
                    console.debug('error:', e);
                    setLoadingProductionPlannings(false);
                    setReloadDataInProgress(false);
                    displayProgress(false);
                }); 
            })(); 
        }catch(e) {
            console.debug('clear-cache-request');
        }
    }

    return {
        clearCacheRequest
    }
}