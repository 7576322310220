import { useBomUploadStore } from "../../../../store/BomUploadStore";
import { useHelpers } from "../../hooks/Helpers";



export const LogViewer = () => {
    const {errorLog} = useBomUploadStore();
    const { renderHTML } = useHelpers();
    return (
        <>
        <div style={{padding: '20px'}}>
            <h3>{errorLog?.title ?? ''}</h3>
            {renderHTML(errorLog?.errorMessage ?? '')}
        </div>
        </>
    )
}