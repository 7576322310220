import { IconButton, styled, Tooltip } from "@mui/material"
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useProductionPlanningStore } from "../../../../store/ProductionPlanningStore";
import { useSaveCancelEvents } from "./hooks/SaveCancelEvents";
import { useEffect, useState } from "react";

export const SaveCancelWrapper = styled('div')({
    display: 'flex',
    flexFlow: 'row',
})

export const SaveCancel = () => {
    const {productionOrders,isResizing,isSaving} = useProductionPlanningStore();
    const {onClickHandlerSave,onClickHandlerCancel} = useSaveCancelEvents();
    const [isShow,setIsShow] = useState<boolean>(true);
    useEffect(() => {
        setIsShow(((isSaving ?? false)===false && (isResizing ?? false)===false && productionOrders && productionOrders.length>0));
    },[isSaving,isResizing,productionOrders])
    return (
        <>
            {isShow &&
            <SaveCancelWrapper>
                <Tooltip title='Save' aria-label="save">    
                <IconButton
                    style={{marginLeft: '1px'}}
                    edge="start" 
                    color="inherit" 
                    aria-label="save"
                    onClick={onClickHandlerSave}
                    >
                    <CheckOutlinedIcon />
                </IconButton>
                </Tooltip>
                <Tooltip 
                title="Cancel" 
                aria-label="cancel">    
                <IconButton 
                    style={{marginLeft: '1px'}} 
                    edge="start" 
                    color="inherit" 
                    onClick={onClickHandlerCancel} 
                    aria-label="close">
                    <CloseOutlinedIcon />
                </IconButton>
                </Tooltip>
            </SaveCancelWrapper>
            }
        </>
      )
}