import { ScheduleRoot } from "./styles/SharedStyles"
import { CircularProgressContainer } from "../ui/CircularProgressContainer";
import { useEffect } from "react";
import { useProductionPlanningTriggers } from "../../hooks/triggers/ProductionPlanningTriggers";
import { ProductionPlanningSchedule } from "./ProductionPlanningSchedule";
import { useProductionPlanningStore } from "../../../store/ProductionPlanningStore";
import { useProdPlanningFilterDrawerStore } from "../../../store/ProdPlanningFilterDrawerStore";
import dayjs from "dayjs";
import { IMultiTriggerFilterOptions } from "../../hooks/interfaces/IMultiTriggerFilterOptions";
import { useProductionPlanningFilterOptionsTriggers } from "../../hooks/triggers/ProductionPlanningFilterOptionsTriggers";

export const ProductPlanningScheduleContainer = () => {
    const {setTriggerLoadFilteredTaskSchedules} = useProductionPlanningTriggers();
    const {planningOptionType,dataLoadedProductionPlanning,loadingProductionPlannings,setDataLoadedProductionPlanning,
        setViewLoadedProductionPlanning, viewLoadedProductionPlanning, targetDB} = useProductionPlanningStore();
    const {dateStartFrom,dateStartTo} = useProdPlanningFilterDrawerStore();
    const {setMultiTriggerFilterOptions} = useProductionPlanningFilterOptionsTriggers();
    useEffect(() => {
        if (viewLoadedProductionPlanning===false && loadingProductionPlannings===false && (dataLoadedProductionPlanning ?? false)===false){
            const fromDateRange = dateStartFrom ? dayjs(dateStartFrom).toDate() : dayjs(new Date()).subtract(90, 'days').toDate();
            const fromDate:dayjs.Dayjs | null | undefined = dayjs(new Date(fromDateRange.getFullYear(),fromDateRange.getMonth(), fromDateRange.getDate()));
            const toDateRange = dateStartTo ? dayjs(dateStartTo).toDate() : dayjs(new Date()).add(30, 'days').toDate();
            if (!dateStartTo)
                toDateRange.setDate(toDateRange.getDate());
            const toDate:dayjs.Dayjs | null | undefined = dayjs(new Date(toDateRange.getFullYear(),toDateRange.getMonth(), toDateRange.getDate()));
            setTriggerLoadFilteredTaskSchedules({
                filterOptions: {
                    dateStartFrom: fromDate,
                    dateStartTo: toDate,
                    targetDB,
                },
                callback: () => {
                    setMultiTriggerFilterOptions({
                        multiple: true,
                        prodOrders: true,
                        items: true,
                        customers: true,
                        status: true,
                        warehouses: true,
                        startDateFrom: fromDate,
                        startDateTo: toDate,
                        targetDB,
                    } as IMultiTriggerFilterOptions);
                }
            });
            setViewLoadedProductionPlanning(true);
        }
        else 
        if (viewLoadedProductionPlanning===true && loadingProductionPlannings===false && dataLoadedProductionPlanning===false){
            setDataLoadedProductionPlanning(true);
        }
    },[])
    useEffect(() => {
        if (viewLoadedProductionPlanning===true && loadingProductionPlannings===false && dataLoadedProductionPlanning===false){
            setDataLoadedProductionPlanning(true);
        }
    },[loadingProductionPlannings])
    return (
        <ScheduleRoot>
            {(dataLoadedProductionPlanning ?? false)===true || (loadingProductionPlannings===false && viewLoadedProductionPlanning===true)
             ? <ProductionPlanningSchedule type={planningOptionType?.option ?? 'month'} />
             : <CircularProgressContainer id="gridProgress" />}
        </ScheduleRoot>
    )
}