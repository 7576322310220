
import GanttChart from "smart-webcomponents-react/ganttchart";
import { useProductionPlanningStore } from "../../../../store/ProductionPlanningStore";
import { TaskIndicatorInfo, TaskItem } from "../../../../Types/TaskItem";
import { useProductionPlanningScheduleHelpers } from "./ProductionPlanningScheduleHelper";
import { useProductionPlanningScheduleResizingHelpers } from "./ProductionPlanningScheduleResizingHelper";
import { isEmpty, setAsyncTimeout, waitForElement } from "../../../../utils/utilities";
import moment from "moment";
import { useProductionPlanningScheduleGanttChart } from "./ProductionPlanningScheduleGanttChart";
import { ProductionOrder } from "../../../../Types/ProductionOrder";
import { MutableRefObject } from "react";
import DateTimePicker from "smart-webcomponents-react/datetimepicker";
import * as ReactDOMClient from "react-dom/client";
import { CircularProgress } from "@mui/material";
import { renderToString } from "react-dom/server";
import { ChartDateTooltip } from "../../ui/tooltip/ChartDateTooltip";
import { useSnackbar } from "notistack";

export const useProductionPlanningScheduleEvents = (props: {ganttChartRef: React.RefObject<GanttChart | null>}) => {
    const {ganttChartRef} = props;
    const {startDraggingSchedule, endDraggingSchedule,statusAsString} = useProductionPlanningScheduleHelpers({ganttChartRef});
    const {resizingEndDate,setStartTaskItem,resetGanttChart,setResizedTask,getProject} = useProductionPlanningScheduleResizingHelpers();
    const {setGridGready, setTotalRows, setLoadingProductionPlannings,setIsResizing, setSelectedTask,expandedTasks,setExpandedTasks, setIsSaving,setProductionOrders,
           taskItems, selectedTaskIds, setSelectedTaskIds, setSelectedTaskId, allTaskItems, filteredTaskItems, viewOption, isSaving, setReloadDataInProgress} = useProductionPlanningStore();    
    const {getTotalRows,formatTimelineWeekAndDay, displayProgress, attachedListener, formatTaskTree, formatCollapsedRow,formatGoodsIsFlagged, toggleGridAndChart} = useProductionPlanningScheduleGanttChart();   
    const {selectBatchTaskItem,checkIfBatchSelectedTaskItem,processBatchSelectedTaskItems,convertDates,rollbackResizedFilteredTaskItems,refreshPageTasks,getTaskItem} = useProductionPlanningScheduleResizingHelpers();
    const { enqueueSnackbar } = useSnackbar();
    const {productionOrders} = useProductionPlanningStore();
    
    let taskId: number;
    let taskDateEnd: Date;
    let taskDateStart: Date;
    let startTaskItem: TaskItem = {} as TaskItem;
    let tooltipInterval: string | number | NodeJS.Timeout | null | undefined = null;
    const onDragStartHandlerChart = (event: any) => {
        startDraggingSchedule(event.detail.item as TaskItem);
    }
    const onDragEndHandlerChart = (event: any, ganttChart: React.RefObject<GanttChart>) => {
        endDraggingSchedule(event.detail.item as TaskItem, () => {
            //ganttChart.current?.refresh(true);
        });
    }

    const resetHoverEffect = (detail: any, action:"hide" | "show") => {       
        const otherCharts = document.querySelectorAll<HTMLDivElement>(`.smart-timeline-task:not([id="${detail?.item.id}"]):not(.main-root)`);

        if (action === "hide") {            
            if (otherCharts){
                otherCharts.forEach( x => {
                    x.style.pointerEvents = 'none';
                })
            }
        }

        if (action === "show") {
            if (otherCharts){
                otherCharts.forEach( x => {
                    x.style.removeProperty('pointer-events');
                })
            }
        }
        
    }

   
    const onResizeStartHandlerChart = (event: any) => {
        const {detail} = event as CustomEvent;
        startTaskItem = structuredClone(event.detail.item as TaskItem);
        
        const updatedItem = productionOrders.filter(x => x.taskItem.project === startTaskItem.project);
        if (!updatedItem){
            const utcStartDate = new Date(startTaskItem.dDateStart);
            const utcEndDate = new Date(startTaskItem.dDateEnd);
    
            const localStartDate = new Date(utcStartDate.getTime() + (utcStartDate.getTimezoneOffset() * 6000));
            const localEndDate = new Date(utcEndDate.getTime() + (utcEndDate.getTimezoneOffset() * 6000));
    
            startTaskItem = {
                ...startTaskItem,
                dateStart: new Date(localStartDate.getFullYear(),localStartDate.getMonth(),localStartDate.getDate(),0,0,0),
                dateEnd: new Date(localEndDate.getFullYear(),localEndDate.getMonth(),localEndDate.getDate(),23,59,59),
            }
        }
        setStartTaskItem(startTaskItem);

        // tooltip information
        // - should display on where it is resize, left or right
        //        
        displayTooltip(detail);
    }

    /**
     * Display the tooltip and update the date-range on interval
     * 
     * @param detail 
     */
    const displayTooltip = (detail:any) => {
        const taskEl = document.querySelector<HTMLDivElement>('[id="' + detail?.item.id+'"]'),
        _originalLeft = taskEl?.style.left,
        _originalWidth = taskEl?.style.width;
        const tooltipEl = document.querySelector<HTMLDivElement>('[id="' + detail?.item.id+'"] .tooltip'),
            startDateEl = tooltipEl?.querySelector<HTMLDivElement>('.start-date'),
            endDateEl = tooltipEl?.querySelector<HTMLDivElement>('.end-date'),
            rowId = taskEl?.getAttribute('row-id'),
            gridRowEl = document.querySelector<HTMLTableRowElement>(`.smart-table-container tbody tr[row-id="${rowId}"]:not([unused])`);

        if (tooltipEl) {
            tooltipEl.classList.add('show');

            // do not show other tooltip
            resetHoverEffect(detail, "hide");

            // tooltip should display below the line
            if (gridRowEl && gridRowEl.rowIndex) {
                if (gridRowEl.rowIndex <= 5) {
                    tooltipEl.style.top = '25px';
                }
            }



            tooltipInterval = setInterval(():void=>{
                const _taskEl = document.querySelector<HTMLDivElement>('[id="' + detail?.item.id+'"]'),
                    _latestLeft = _taskEl?.style.left,
                    _latestWidth = _taskEl?.style.width,
                    _rowId = tooltipEl?.getAttribute('row-id'),
                    _gridRowEl = document.querySelector<HTMLTableRowElement>(`.smart-table-container tbody tr[row-id="${_rowId}"]:not([unused])`),
                    mouseOverTooltip = document.querySelector<HTMLDivElement>('[id="' + detail?.item.id+'"] .tooltip--container'),
                    _values = document.querySelector<HTMLDivElement>('[id="' + detail?.item.id+'"]')?.getAttribute('aria-label')?.split(',');
                let _startDate:string | undefined = "", _endDate:string | undefined ="", rightOrLeft: "left"| "right" = "left";
                if (_values){
                    _values.forEach((item,idx)=>{
                        switch(idx) {
                            case 0:
                                _startDate = item?.split(':').pop()?.toString();
                            break;
                            case 1:
                                _endDate = item?.split(':').pop()?.toString();
                                break;
                        }
                    });
                }
                // change position
                // for easy reading like of longer task
                if (_originalLeft !== _latestLeft){
                    tooltipEl.style.left = '10px';
                    tooltipEl.style.removeProperty('right');
                } else if (_originalWidth !== _latestWidth) {
                    tooltipEl.style.right = '10px';
                    tooltipEl.style.removeProperty('left');
                    rightOrLeft = "right";
                }

                // tooltip should display below the line
                if (_gridRowEl && _gridRowEl.rowIndex) {
                    if (_gridRowEl.rowIndex <= 5) {
                        tooltipEl.style.top = '25px';
                    }
                }

                // forced to hide
                if (mouseOverTooltip)
                    mouseOverTooltip.style.display = 'none';

                tooltipEl.innerHTML = renderToString(<ChartDateTooltip id="toolTipContainer" title={detail?.item.itemDesc} dateRange={{label:"Affected Dates", rightOrLeft: rightOrLeft, startDate: new Date(_startDate), endDate: new Date(_endDate)}} />);

            },200);
            
            tooltipEl.innerHTML = renderToString(<ChartDateTooltip id="toolTipContainer" title={detail?.item.itemDesc} dateRange={{label:"Initial Dates", rightOrLeft: "right",startDate: detail?.item.dateState, endDate: detail?.item.dateEnd}} />);
        }
    }


    const onResizeEndHandlerChart = (event: Event | undefined) => {
        (event as CustomEvent).preventDefault();
        (event as CustomEvent).stopPropagation();
        const detail = (event as CustomEvent).detail;
        let taskItem = structuredClone(detail?.item as TaskItem);
        const tooltipEl = document.querySelector<HTMLElement>('[id="' + taskItem.id +'"] .tooltip'),
            mouseOverTooltip = document.querySelector<HTMLElement>('[id="' + taskItem.id +'"] .tooltip--container');
        convertDates(taskItem);        
        
        // taskItem = {...taskItem, 
        //             dateEnd: new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate(),23,59,59),
        //             dateStart:  new Date(taskItem.dateStart.getFullYear(),taskItem.dateStart.getMonth(),taskItem.dateStart.getDate(),0,0,0) }

        const startDateTimes =  Math.abs(startTaskItem.dateStart.getTime() - taskItem.dateStart.getTime());
        const startDateDays = startDateTimes / (1000 * 60 * 60 * 24);

        const endDateTimes =  Math.abs(startTaskItem.dateEnd.getTime() - taskItem.dateEnd.getTime());
        const endDateDays = endDateTimes / (1000 * 60 * 60 * 24);

        let isStart = startDateDays > endDateDays ;
        if (startDateDays < endDateDays){
            isStart=false;
            taskItem.dateStart = startTaskItem.dateStart;
        }
        if ((taskItem.mainRoot ?? false)===false && 
            !(
                startTaskItem.dateStart.getDate() === taskItem.dateStart.getDate() && 
                startTaskItem.dateEnd.getDate() === taskItem.dateEnd.getDate()
            ) &&
            ((taskItem.flagGoodsIssued ?? false)===false || ((taskItem.flagGoodsIssued ?? false)===true && isStart===false))){

            // check if the resized task is batchSelected
            const isBatchSelected = checkIfBatchSelectedTaskItem(taskItem);
            if (!isBatchSelected) {
                setTimeout(() => {
                    setIsResizing(true);
                },100);
                setSelectedTaskId(taskItem.id);
                const ret = resizingEndDate({
                    ganttChartRef,
                    taskItem: taskItem as TaskItem, 
                    callback: (updatedProdOrders?: ProductionOrder[] | null) => {
                        // check for invalid dates
                        validateResizedForInvalidDates(updatedProdOrders, () => {
                            refreshSelectedTaskItem(ganttChartRef,taskItem.id);
                            setIsResizing(false);
                        });
                    },
                    updateMainTask: false,
                });
                if (ret===false){
                    // reload the failed resized
                    resetGanttChart();
                }
            } else {
                setIsResizing(true);
                // process batch Selected TaskItems
                processBatchSelectedTaskItems({
                    ganttChartRef,
                    startTaskItemRef: startTaskItem,
                    taskItem,
                });
            }
        } else {
            // rollback resized
            taskItem.dateStart = startTaskItem.dateStart;
            taskItem.dateEnd = startTaskItem.dateEnd;
            taskItem = {...taskItem, 
                        dateStart: new Date(taskItem.dateStart.getFullYear(),taskItem.dateStart.getMonth(),taskItem.dateStart.getDate()),
                        dateEnd:  new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate(),23,59,59)};
            ganttChartRef.current?.updateTask(taskItem.id,taskItem);
        }

        if (tooltipEl) {            
            tooltipEl.innerHTML = renderToString(<ChartDateTooltip id="toolTipContainer" title={taskItem.itemDesc} dateRange={{label:"Final Dates", rightOrLeft:"right", startDate: taskItem.dateStart, endDate: taskItem.dateEnd}} />)
              
            tooltipEl.classList.remove('show');            

            //
            // restore the hover
            if (mouseOverTooltip)
                mouseOverTooltip.style.removeProperty('display');

            if (tooltipInterval)
            clearInterval(tooltipInterval);

            // restore the tooltip
            resetHoverEffect(detail, "show");
        }
    }
    const validateResizedForInvalidDates = (updatedProdOrders?: ProductionOrder[] | null, callback?: () => void) => {
        if ((updatedProdOrders ?? []).length>0) {
            const invalidResized = (updatedProdOrders ?? []).filter(x => new Date(x.taskItem.dateStart.getFullYear(),x.taskItem.dateStart.getMonth(),x.taskItem.dateStart.getDate())>
                                                                         new Date(x.taskItem.dateEnd.getFullYear(),x.taskItem.dateEnd.getMonth(),x.taskItem.dateEnd.getDate()));
            const invalidEndDateAndHasMaxGoodsIssuedEndDate = (updatedProdOrders ?? []).filter(x => (x.taskItem.flagGoodsIssued ?? false) === true && 
                                                                                    x.taskItem.maxGoodsIssuedEndDate!==undefined &&
                                                                                   new Date(x.taskItem.dateEnd.getFullYear(),x.taskItem.dateEnd.getMonth(),x.taskItem.dateEnd.getDate())<=
                                                                                   new Date(new Date(x.taskItem.maxGoodsIssuedEndDate).getFullYear(),new Date(x.taskItem.maxGoodsIssuedEndDate).getMonth(),new Date(x.taskItem.maxGoodsIssuedEndDate).getDate()));
            if (invalidResized.length>0){
                invalidResized.map(x => {
                    enqueueSnackbar(`Invalid date range (From: ${x.taskItem.dateStart.getMonth()+1}/${x.taskItem.dateStart.getDate()}/${x.taskItem.dateStart.getFullYear()} ; To : ${x.taskItem.dateEnd.getMonth()+1}/${x.taskItem.dateEnd.getDate()}/${x.taskItem.dateEnd.getFullYear()}).`,{variant: "error", autoHideDuration:10000});
                    // rollback resized items
                    ganttChartRef.current?.updateTask(x.origTaskItem.id,x.origTaskItem);
                });
                setTimeout(() => {
                    setProductionOrders([]);
                    setIsResizing(false);
                    setIsSaving(false);
                },100);      
                // reset 
            }


            if (invalidEndDateAndHasMaxGoodsIssuedEndDate.length>0){
                invalidEndDateAndHasMaxGoodsIssuedEndDate.map(x => {
                    enqueueSnackbar(`Material has been issued. End Date needs to be greater than ${new Date(x.taskItem.maxGoodsIssuedEndDate ?? new Date()).getMonth()+1}/${new Date(x.taskItem.maxGoodsIssuedEndDate ?? new Date()).getDate()}/${new Date(x.taskItem.maxGoodsIssuedEndDate ?? new Date()).getFullYear()}.`,{variant: "error", autoHideDuration:10000});
                    // rollback resized items
                    //ganttChartRef.current?.updateTask(x.origTaskItem.id,x.origTaskItem);
                    rollbackResizedFilteredTaskItems()
                    .then(() => {
                        return refreshPageTasks(ganttChartRef);
                    });
                });
                setTimeout(() => {
                    setProductionOrders([]);
                    setIsResizing(false);
                    setIsSaving(false);
                },100);      
            }


            let invalidTaskItems: [[project: string | null | undefined, resizedEndDate: Date | null | undefined, endDate: Date | null | undefined]] = [[null,null,null]];
            if (invalidResized.length===0 && invalidEndDateAndHasMaxGoodsIssuedEndDate.length===0){
                // validate all resized parents
                (updatedProdOrders ?? []).forEach(x => {
                    const task = getTaskItem(taskItems,x.taskItem);
                    if (task && (task.tasks ?? []).length>0){
                        const parent = structuredClone(task) as TaskItem;
                        parent.dateEnd = x.taskItem.dateEnd
                        parent.dateStart = x.taskItem.dateStart;
                        const invalidEndDate = isSubItemEndDateGreaterThanParentEndDate(parent);
                        if ((invalidEndDate[0])===true){
                            invalidTaskItems.push([x.taskItem.project,x.taskItem.dateEnd,invalidEndDate[1]]);
                        }
                    }
                });
                
                invalidTaskItems.forEach(x => {
                    if (x[0] && x[1] && x[2]){
                        enqueueSnackbar(`Invalid Date : ${x[0]}. Please check the End Date of Production Order's levels.`,{variant: "error", autoHideDuration:10000});
                    }
                })

                if (invalidTaskItems.filter(x => x[0] && x[1] && x[2]).length>0){
                    rollbackResizedFilteredTaskItems()
                    .then(() => {
                        return refreshPageTasks(ganttChartRef);
                    });
                    setTimeout(() => {
                        setProductionOrders([]);
                        setIsResizing(false);
                        setIsSaving(false);
                    },100); 
                } else {

                    invalidTaskItems = [[null,null,null]];
                    // validate all resized children
                   (updatedProdOrders ?? []).forEach(x => {
                        const task = getProject(taskItems,x.taskItem);
                        if (task && (task.tasks ?? []).length>0){
                            const parent = structuredClone(task) as TaskItem;
                            const invalidChilEndDate = isParentEndDateLessThanSubItemEndDate(parent,x.taskItem);
                            if ((invalidChilEndDate[0])===true){
                                invalidTaskItems.push([x.taskItem.project,x.taskItem.dateEnd,invalidChilEndDate[1]]);
                            }
                        }
                    });

                    invalidTaskItems.forEach(x => {
                        if (x[0] && x[1] && x[2]){
                            enqueueSnackbar(`Invalid Date : ${x[0]}. Please check the End Date of Production Order's levels.`,{variant: "error", autoHideDuration:10000});
                        }
                    })
    
                    if (invalidTaskItems.filter(x => x[0] && x[1] && x[2]).length>0){
                        rollbackResizedFilteredTaskItems()
                        .then(() => {
                            return refreshPageTasks(ganttChartRef);
                        });
                        setTimeout(() => {
                            setProductionOrders([]);
                            setIsResizing(false);
                            setIsSaving(false);
                        },100); 
                    } else {
                        if (callback)
                            callback();
                    }


                }
            }
        };
    }
    const isUseStartTaskItemEndDate = (endTaskItem: TaskItem) => {
        const startDateTimeDiff = endTaskItem.dateStart.getTime()-startTaskItem.dateStart.getTime();
        return startDateTimeDiff!==0;
    }
    const refreshSelectedTaskItem = (ganttChartRefObj?: MutableRefObject<GanttChart | null>,  taskId?: number) => {
        if (ganttChartRef && taskId){
            // ganttChartRefObj?.current?.ensureVisible(taskId ?? 0);
            // ganttChartRefObj?.current?.selectTask(taskId ?? 0);

            // do not show the triangle/progress indicator
            setTimeout(() => {
                let _progressIndicator = document.querySelector<HTMLElement>('.smart-timeline-task[id="' + taskId + '"] .smart-timeline-task-progress-thumb');
                if (_progressIndicator){
                    _progressIndicator.style.setProperty('display', 'none');
                    _progressIndicator.style.setProperty('transform', 'scale(0)');
                }
            },1000);
        }
    }
    const onPopupWindowSchedule = (target: any, type: any, targetTask: any) => {
        setTimeout(() => {
            // remove batch Selected 
            (target as HTMLElement).querySelector<HTMLElement>('.smart-gantt-chart-popup-window-editor[value="batchSelected"]')?.remove();

            // hide Delete Button, Dependency Tab & Segments Tab
            let deleteButton = (target as HTMLElement).querySelector<HTMLElement>('.smart-footer smart-button.smart-popup-window-button.delete');
            if (deleteButton)
                deleteButton?.style.setProperty('display','none');
            let dependencyTab = (target as HTMLElement).querySelector<HTMLElement>(`[aria-label=Dependency]`);
            if (dependencyTab)
                dependencyTab?.style.setProperty('display','none');
            let segmentsTab = (target as HTMLElement).querySelector<HTMLElement>(`[aria-label=Segments]`);
            if (segmentsTab)
                segmentsTab?.style.setProperty('display','none');
    
            // fix labels
            let projectLabel = (target as HTMLElement).querySelector<HTMLElement>('.smart-gantt-chart-popup-window-editor[value=project] .smart-gantt-chart-label-container label');
            if (projectLabel)
               projectLabel.innerText = "Production Order";
            
            // project tooltip
            let projectTooltipLabel = (target as HTMLElement).querySelector<HTMLElement>('.smart-gantt-chart-popup-window-editor[value=projectTooltip] .smart-gantt-chart-label-container label');
            if (projectTooltipLabel)
                projectTooltipLabel.innerText = "Production Order";
            
            // update project value
            let projectTooltipInput = (target as HTMLInputElement).querySelector<HTMLInputElement>('.smart-gantt-chart-popup-window-editor[value=projectTooltip] .smart-input');
            if (projectTooltipInput) {
                projectTooltipInput.setAttribute('disabled', 'false');
                projectTooltipInput.value =  targetTask.project;
                projectTooltipInput.setAttribute('disabled', 'true');
            }

            // project tooltip
            let itemTooltipLabel = (target as HTMLElement).querySelector<HTMLElement>('.smart-gantt-chart-popup-window-editor[value=itemTooltip] .smart-gantt-chart-label-container label');
            if (itemTooltipLabel)
                itemTooltipLabel.innerText = "Item";
            
            let itemLabel = (target as HTMLElement).querySelector<HTMLElement>('.smart-gantt-chart-popup-window-editor[value=item] .smart-gantt-chart-label-container label');
            if (itemLabel)
                itemLabel.innerText = "Item";
            
            let itemTooltipInput = (target as HTMLInputElement).querySelector<HTMLInputElement>('.smart-gantt-chart-popup-window-editor[value=itemTooltip] .smart-input');
            if (itemTooltipInput) {
                itemTooltipInput.setAttribute('disabled', 'false');
                itemTooltipInput.value =  targetTask.item;
                itemTooltipInput.setAttribute('disabled', 'true');
            }

            let dateStartInput = (target as HTMLInputElement).querySelector<HTMLInputElement>('.smart-gantt-chart-popup-window-editor[value=dateStart] .smart-date-time-input');
            if (dateStartInput) {
                // remove the time-format                 
                let _dateStart = targetTask.dDateStart.split("T");
                dateStartInput.disabled = false;
                if (_dateStart?.length > 0) {
                    dateStartInput.value = new Date(targetTask.dateStart).toLocaleDateString("en",{year: "numeric", month: "2-digit", day:"2-digit"});
                }                
            }

            let dateEndInput = (target as HTMLInputElement).querySelector<HTMLInputElement>('.smart-gantt-chart-popup-window-editor[value=dateEnd] .smart-date-time-input');
            if (dateEndInput) {
                // remove the time-format                 
                let _dateEnd = targetTask.dDateEnd.split("T");                
                if (_dateEnd?.length > 0) {
                    dateEndInput.value = _dateEnd[0];
                }               
            }

            let dateEndDialog = (target as HTMLInputElement).querySelector<HTMLInputElement>('.smart-gantt-chart-popup-window-editor[value=dateEnd]');
            if (dateEndDialog){
                let dateEndDialogInnerHTML = dateEndDialog.innerHTML.replaceAll(`format-string="yyyy-MMM-dd HH:mm:ss"`,`format-string="yyyy-MM-dd"`)
                                                                    .replaceAll(`drop-down-display-mode="auto"`,`drop-down-display-mode="calendar"`);
                dateEndDialog.innerHTML = dateEndDialogInnerHTML;
            }

            // load values
            let prodOrder = (target as HTMLInputElement).querySelector('.task-project-name input.MuiInputBase-input');
            if (prodOrder){
                prodOrder.setAttribute('value',targetTask.project)
                const prodOrderOuterHtml = prodOrder.outerHTML;
                prodOrder.outerHTML = prodOrderOuterHtml;
            }
            let item = (target as HTMLInputElement).querySelector('.task-item-name input.MuiInputBase-input');
            if (item){
                item.setAttribute('value',targetTask.item);
                const itemOuterHtml = item.outerHTML;
                item.outerHTML = itemOuterHtml;
            }
            let startDate = (target as HTMLInputElement).querySelector('.task-start-date input.MuiInputBase-input');
            if (startDate){
                // MM/dd/yyyy
                const startDateDate = new Date(targetTask.dateStart);
                const startDateValue = `${startDateDate.getMonth()+1}/${startDateDate.getDate()}/${startDateDate.getFullYear()}`;
                startDate.setAttribute('value',startDateValue);
                const startDateHtml = startDate.outerHTML;
                startDate.outerHTML = startDateHtml;
            }
            let endDate = (target as HTMLInputElement).querySelector('.task-end-date.smart-element.smart-date-time-picker');
            if (endDate){
                // MM/dd/yyyy
                const endDateDate = new Date(targetTask.dateEnd);
                const endDateValue = `${endDateDate.getMonth()+1}/${endDateDate.getDate()}/${endDateDate.getFullYear()}`;
                endDate.setAttribute('value',endDateValue);
                if (targetTask.mainRoot===true){
                    endDate.setAttribute('disabled','disabled');
                } else {
                    endDate.removeAttribute('disabled');
                }
                const endDateHtml = endDate.outerHTML;
                endDate.outerHTML = endDateHtml;
            }
            let duration = (target as HTMLInputElement).querySelector('.task-duration input.MuiInputBase-input');
            if (duration){
                const durationValue = Math.ceil((targetTask.duration as number));
                duration.setAttribute('value',durationValue.toString());
                const durationOuterHtml = duration.outerHTML;
                duration.outerHTML = durationOuterHtml;
            }

            let taskStatus = (target as HTMLSpanElement).querySelector('#taskStatusContainer');
            if (taskStatus) {
                let _statuses = targetTask.status.split('|'),
                _statusWithIcon = "",
                _labelContainer = taskStatus.previousElementSibling as HTMLParagraphElement;
                Array.from(_statuses).forEach((value: unknown, index:number)=>{
                    if (!isEmpty(value?.toString().trim())) {
                        _statusWithIcon += statusAsString(value as string, index);
                    }                    
                });                
                if (_statuses.length > 0 && _statusWithIcon.length > 0) {
                    taskStatus.innerHTML = _statusWithIcon;
                    _labelContainer.style.removeProperty('display');
                } else {
                    taskStatus.innerHTML = '';
                    _labelContainer.style.display = 'none';
                }
            }

            let taskQuantity = (target as HTMLInputElement).querySelector<HTMLInputElement>('.smart-gantt-chart-popup-window-editor[value="quantity"] input');
            if (taskQuantity) {
                taskQuantity.setAttribute('disabled', 'false');
                taskQuantity.value = targetTask.quantity;
                taskQuantity.setAttribute('disabled', 'true');
            }

            let taskRealizedQty = (target as HTMLInputElement).querySelector<HTMLInputElement>('.smart-gantt-chart-popup-window-editor[value="realizedQty"] input');
            if (taskRealizedQty) {
                taskRealizedQty.setAttribute('disabled', 'false');
                taskRealizedQty.value=targetTask.realizedQty;
                taskRealizedQty.setAttribute('disabled', 'true');
            }

            let taskCBM = (target as HTMLInputElement).querySelector<HTMLInputElement>('.smart-gantt-chart-popup-window-editor[value="cbm"] input');
            if (taskCBM) {
                const _cbm = Math.round(targetTask.cbm * 100) / 100;
                taskCBM.setAttribute('disabled', 'false');
                taskCBM.value=_cbm.toString();
                taskCBM.setAttribute('disabled', 'true');                
            }

            //salesOrderNo
            let taskSalesOrderNo = (target as HTMLInputElement).querySelector<HTMLInputElement>('.smart-gantt-chart-popup-window-editor[value="salesOrderNo"] input');
            if (taskSalesOrderNo && targetTask.salesOrderNo) {
                taskSalesOrderNo.setAttribute('disabled', 'false');
                taskSalesOrderNo.value = targetTask.salesOrderNo;
                taskSalesOrderNo.setAttribute('disabled', 'true');
            }

            //soFulfillmentDate
            let taskSOFulfillmentDate = (target as HTMLInputElement).querySelector<HTMLInputElement>('.smart-gantt-chart-popup-window-editor[value="soFulfillmentDate"] input');
            if (taskSOFulfillmentDate && targetTask.soFulfillmentDate) {
                const _sofulfillmentDate = new Date(targetTask.soFulfillmentDate).toLocaleDateString(ganttChartRef?.current?.locale ?? "en",{day: "2-digit", month: "2-digit", year: "numeric"});
                taskSOFulfillmentDate.setAttribute('disabled', 'false');
                taskSOFulfillmentDate.value = _sofulfillmentDate;
                taskSOFulfillmentDate.setAttribute('disabled', 'true');
            }
            //customer
            let taskCustomer = (target as HTMLInputElement).querySelector<HTMLInputElement>('.smart-gantt-chart-popup-window-editor[value="customer"] input');
            if (taskCustomer && targetTask.customer) {
                taskCustomer.setAttribute('disabled', 'false');
                taskCustomer.value = targetTask.customer;
                taskCustomer.setAttribute('disabled', 'true');
            }


            setStartTaskItem(targetTask as TaskItem);
            startTaskItem = targetTask as TaskItem;
            taskId = targetTask.id;
            

        },100);

        // for validation of invalid date range
        setTimeout(() => {
            document.querySelector('#taskEndDate')?.addEventListener('change', (e) => {
                validateDateRange(targetTask);
            });
            document.querySelector('#taskStartDate')?.addEventListener('change', (e) => {
                validateDateRange(targetTask);
            })
        },2000);

    }

    /**
     *  Get all the tree id based on selected main-root
     * 
     * @param r 
     * @param taskItems 
     * @param rowId 
     * @returns 
     */
    const getTaskIndicatorsFromTree = (r:TaskIndicatorInfo[],taskItems:TaskItem, rowId:string|null) => {
        const chart = document.querySelector('smart-gantt-chart');
        if (taskItems && taskItems.indicators) {
            if (taskItems.indicators.length > 0) {
                if (taskItems.tasks && taskItems.tasks.length > 0) {
                    taskItems.tasks.forEach( x =>{
                        getTaskIndicatorsFromTree(r,x, null);
                    });
                }

                r.push({
                    taskId: taskItems.id,
                    taskIndex: chart?.getTaskIndex(taskItems) ?? 0,
                    rowIndex: taskItems.$?.id ?? 0,
                });

                console.debug('task-items:', taskItems.$);

                return r;
            }
        } else {
            if (taskItems && taskItems.tasks && taskItems.tasks.length > 0) {
                taskItems.tasks.forEach( x =>{
                    getTaskIndicatorsFromTree(r,x, null);
                });
            }
        }
    }

    /**
     * Show/Hide the stop icon on out-of-range rows
     * 
     * @param show 
     */
    const findOutOfRowIndicators = (show?:boolean) => {
        const _taskRows = document.querySelectorAll('table.smart-table-container tbody tr[row-id]:not([unused])');
        let _taskIds:number[] = [] as number[];
        const _taskIndicators = document.querySelectorAll('.smart-gantt-chart-indicator');
        _taskIndicators.forEach( x => {
            const _taskIndex = x.getAttribute('task-index'),
                _taskIcon = x.querySelector<HTMLDivElement>('.smart-gantt-chart-task-indicator.material-icons.icon');
            
            // if out of range
            if (_taskIndex && (parseInt(_taskIndex ?? "0") >= (_taskRows?.length ?? 0))) {
                _taskIds.push(parseInt(_taskIndex ?? "0"));                
                if (_taskIcon)
                    _taskIcon.style.display = "none";
            } else {
                if (_taskIcon) {
                    _taskIcon.style.removeProperty('display');
                }
            }
        });
    }

    const onExpandedRow = (taskItem:TaskItem,row:HTMLTableRowElement) => {        
        const _timeout = setTimeout(()=>{
            const withHierarchy = row.querySelector('td[data-field="projectTooltip"] div.hierarchy-arrow.smart-arrow.smart-arrow-down'),
                _filteredTaskItem = taskItems?.filter((x) => x.id === taskItem.id) || [];
            let _taskIndicators:TaskIndicatorInfo[] = [] as TaskIndicatorInfo[];

            // get the tree info
            (async()=>{
                getTaskIndicatorsFromTree(_taskIndicators, _filteredTaskItem[0], row?.getAttribute('row-id'));
            })().then((x)=>{                

                // validate if there's the hierarchy arrow
                // then find in taskItem and expand or collapse
                if (row?.ariaExpanded === "true") {
                    // add to list
                    if (withHierarchy) {
                        const _exists = expandedTasks.filter((x:number)=> x === taskItem.id);
                        if (_exists.length === 0) {
                            setExpandedTasks((prevState)=>([...prevState, taskItem.id]));
                            // in taskItems
                        }                        

                        if (_taskIndicators.length> 0) {
                            // // temporary remove the formatting of task indicator
                            // // until the bugs on 
                            // // https://www.htmlelements.com/forums/topic/task-indicator-error-on-scroll-2/
                            // // is fixed.
                            // // remove the display style
                            // _taskIndicators.forEach((x)=>{
                            //     const _taskIndicatorEl = document.querySelector('.smart-gantt-chart-indicator[product-id="' + x.taskId +'"]'),
                            //     _taskIndicatorElIcon = _taskIndicatorEl?.querySelector<HTMLDivElement>('.smart-gantt-chart-task-indicator');                                
                            //     if (_taskIndicatorElIcon) {
                            //         _taskIndicatorElIcon.style.removeProperty('display');
                            //     }
                            // });
                        }
                    }
                } else {
                    // update list
                    if (withHierarchy) { // and should be collapsing not just clicked                    
                        setExpandedTasks((prevState)=>(prevState.filter((x)=> x !== taskItem.id)));
                        //                        

                        if (_taskIndicators.length > 0) {
                            // // temporary remove the formatting of task indicator
                            // // until the bugs on 
                            // // https://www.htmlelements.com/forums/topic/task-indicator-error-on-scroll-2/
                            // // is fixed.
                            // //hide
                            // _taskIndicators.forEach((x)=>{
                            //     const _taskIndicatorEl = document.querySelector('.smart-gantt-chart-indicator[product-id="' + x.taskId +'"]'),
                            //     _taskIndicatorElIcon = _taskIndicatorEl?.querySelector<HTMLDivElement>('.smart-gantt-chart-task-indicator');                                
                            //     if (_taskIndicatorElIcon) {
                            //         _taskIndicatorElIcon.style.display = "none";
                            //     }
                            // });
                        }
                    } 
                } 
            });

                       

            clearTimeout(_timeout);

        }, 100); // needed to have correct value of aria-expanded
        

    }

    const onItemClick = (event: Event | undefined) => {
        const detail = (event as CustomEvent).detail,
              taskItem = detail?.item;

        const target = detail.originalEvent.target;

        const _nearestRow = (target as HTMLElement).closest('tr');
        
        if (taskItem){
            setSelectedTask(taskItem);
        }

        // aria-expanded        
        if (_nearestRow) {
            onExpandedRow(taskItem, _nearestRow as HTMLTableRowElement);
        }

        // if checkbox is clicked and checked 
        if (target.classList.contains('batch-selected')){
            const isChecked: boolean = !target.checked;
            selectBatchTaskItem(taskItem,isChecked);
        }

        // do not show the triangle/progress indicator
        let _progressIndicator = document.querySelector<HTMLElement>('.smart-timeline-task[id="' + taskItem.id + '"] .smart-timeline-task-progress-thumb');
        if (_progressIndicator){
            _progressIndicator.style.setProperty('display', 'none');
            _progressIndicator.style.setProperty('transform', 'scale(0)');
        }

        // check if selected
        const rowAriaSelected = Array.from(_nearestRow?.attributes ?? []).filter(x => x.name==='aria-selected');
        if (rowAriaSelected.length>0 && rowAriaSelected[0].value==='true'){
            // remove from selectedTaskId
            setSelectedTaskIds(selectedTaskIds.filter(x => x!== taskItem.id));
        } else {
            // add to selectedTaskId
            setSelectedTaskIds([...selectedTaskIds,taskItem.id]);
        }

        setTimeout(() => {
            // update also the dates            
            formatTimelineWeekAndDay();

            formatCollapsedRow();

            formatGoodsIsFlagged();

        },100);
    }
    
    const isInViewport = (el: Element) => {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)    
        );
    }

    
    const toggleProgress = (display: boolean) => {
        
        const ganttEl2 = document.querySelector<any>('smart-gantt-chart#productionPlanner');
        let _el = document.querySelector<HTMLDivElement>('#gridProgress');

        if (_el) {
            _el.style.setProperty('display', (display ? 'flex' : 'none'));
        }

        if (display) { // hide the grid
            if (ganttEl2)
                ganttEl2.classList.add("hide");
        } else {
            if (ganttEl2 && ganttEl2.classList.contains('hide')) {
                ganttEl2.classList.remove("hide");
            }
        }        
                                    
        let _totalRowsEl = document.querySelector<HTMLDivElement>('.total-rows--container');
        // show also total
        if (_totalRowsEl) {
            _totalRowsEl.style.setProperty('display', (display ? 'none' : 'flex'));
        }
        
    }    

    const timelineHeaderFormatFunction = (_date:Date,type:string, isHeaderDetails:boolean, dateValue:any, ganttChart: any)=>{
        const that = ganttChart.current;        
        if (isHeaderDetails) {
            if (type === 'month') {
                return _date.toLocaleDateString(that?.locale, {month: 'long', year: 'numeric'});
            }
            if (type === 'week') {
                const startDayOfWeek = new Date(_date),
                endDateOfWeek = new Date(_date), 
                firstDateOfWeek = new Date(moment(startDayOfWeek).startOf('week').toDate());
                endDateOfWeek.setDate(firstDateOfWeek.getDate() + 6);                           

                return firstDateOfWeek.toLocaleString(that?.locale, { day: "numeric", month: "long", year: 'numeric' }) + ' - ' +
                    endDateOfWeek.toLocaleDateString(that?.locale, {day: "numeric", month: "long", year: "numeric"});
            }
        }else {
            if (type === 'day') {
                return _date.toLocaleDateString(that?.locale,{ day: 'numeric'  }) + ' ' + dateValue;
            }

            if (type === 'week') {
                const startDayOfWeek = new Date(_date)
                return moment(startDayOfWeek).week() + "(" + moment(startDayOfWeek).startOf('week').format('DD') + "/" + moment(startDayOfWeek).endOf('week').format('DD') +")";
            }
        }
        
    }

    const onReady = (event?: Event) => {
        try {            

            waitForElement('smart-gantt-chart#productionPlanner .smart-timeline-header-content > .smart-timeline-view-details > .smart-timeline-view-cell').then((element:Element)=>{                
                setTimeout(()=>{
                    if (element) {                        
                        attachedListener();

                        //
                        formatTaskTree();

                        toggleGridAndChart(viewOption || '');

                        // update the date or week no.
                        formatTimelineWeekAndDay();                        

                        //
                        setReloadDataInProgress(false);

                        displayProgress(false);
                        setGridGready(false);                        
                        
                        // update total rows
                        setTotalRows(getTotalRows());
                        
                    }
                }, 100);

            }).catch((reason:any)=>{

                console.log('error?:', reason);
                // maybe failed to fetch
                (async()=>{
                    setReloadDataInProgress(false);

                    displayProgress(false);
                    setGridGready(false);

                    setLoadingProductionPlannings(false);                    
                        
                    // update total rows
                    setTotalRows(0);

                    // update the date or week no.
                    formatTimelineWeekAndDay();
                })();
            });
        }catch(err){
            console.log('error:', err);
        }
    }

    const renderProgressContainer = () => {
        const rootElement = document.createElement('div');
        rootElement.id = "expand-collapse-indicator--container";
        rootElement.classList.add('show-progress');
        ReactDOMClient.createRoot(rootElement).render(<CircularProgress size={12} disableShrink/>);
    }

    const onGridCreate = (event?: Event) => {        
        try{            
            let gridCreateTimer = setTimeout(()=>{
                if ((allTaskItems ?? []).length===0 && (filteredTaskItems ?? []).length===0 && (taskItems ?? []).length===0) {
                    setReloadDataInProgress(false);
                    displayProgress(false);                    
                }else {
                    displayProgress(true);

                    setReloadDataInProgress(true);
                }    
                clearTimeout(gridCreateTimer);
            },100);
        }catch(e) {
            console.debug('on-create-error:', e);
        }
    }

    const onGridRowExpandCollapse = (event?:Event) => {
        const {target, detail, type} = event as CustomEvent,
        rowIndex = detail?.index ?? 0,
        actualIndex =  (rowIndex + 1);

        if (rowIndex != null) {
            // set indicator
            const _selectedRow = document.querySelector('tr[aria-rowindex="'+(actualIndex)+'"]:not([unused])'),
            _itemDescriptionCell = _selectedRow?.querySelector('.smart-table-cell-template .item-desc--container');            
            
            if (_itemDescriptionCell != null  || _itemDescriptionCell != undefined) {

                (async()=>{
                    await setAsyncTimeout(()=>{
                        const _progressIndicatorCell = document.querySelector<HTMLDivElement>('tr[aria-rowindex="'+(actualIndex)+'"]:not([unused]) .smart-table-cell-template .item-desc--container .progress-container'),
                        _withHeirarchyCell = document.querySelector<HTMLDivElement>('tr[aria-rowindex="'+(actualIndex)+'"]:not([unused]) .heirarchy-arrow');
                        _progressIndicatorCell?.classList.remove("off");                       
                    }, 400);
          
                    await setAsyncTimeout(()=>{
                        const _progressIndicatorCell2 = document.querySelector<HTMLDivElement>('tr[aria-rowindex="'+(actualIndex)+'"]:not([unused]) .smart-table-cell-template .item-desc--container .progress-container'),
                        _withHeirarchyCell2 = document.querySelector<HTMLDivElement>('tr[aria-rowindex="'+(actualIndex)+'"]:not([unused]) .heirarchy-arrow');
                        _progressIndicatorCell2?.classList.add("off");                       
                    }, 1000);                            
          
                  })();
                
            }
        }

    }

    const onCloseHandlerChart = (e: any) => {
        // remove all EventListeners
        const clickEvent = document.querySelector('.smart-gantt-chart-popup-window-editor[value=dateEnd] .smart-element.smart-date-time-picker');
        if (clickEvent)
            clickEvent.removeEventListener('click',onClickHandlerCalendar);
        // remove ok / cancel
        const okButton = document.querySelector('.smart-footer .smart-popup-window-button.ok');
        if (okButton){
            okButton.removeEventListener('click',onClickHandlerSave);
        }
        const cancelButton = document.querySelector('.smart-footer .smart-popup-window-button.cancel');
        if (cancelButton){
            cancelButton.removeEventListener('click',onClickHandlerCancel);
        }
        const onChangeTaskEndDate = document.querySelector('#taskEndDate');
        if (onChangeTaskEndDate) 
            onChangeTaskEndDate.removeEventListener('change', (e) => {});
        const onChangeTaskStartDate = document.querySelector('#taskStartDate');
        if (onChangeTaskStartDate) 
            onChangeTaskStartDate.removeEventListener('change', (e) => {});
    }  
    const onClickHandlerCalendar = (e: any) => {
        e.preventDefault();
        e.stopPropagation();        
        const {target} = e
        const dateEndHtml = document.querySelector('.smart-gantt-chart-popup-window-editor[value=dateEnd] .task-end-date');
        let dateEndStr: string | undefined;
        const dateStartHtml = document.querySelector('.smart-gantt-chart-popup-window-editor[value=dateStart] .task-start-date');
        //const taskItem = getStartTaskItem();
        let dateStartStr: string | undefined;
        if (dateStartHtml) 
            dateStartStr = dateStartHtml.getAttribute('value')?.toString();

        if (dateEndHtml)
            dateEndStr = dateEndHtml.getAttribute('value')?.toString();

        if ((dateEndStr ?? '') !== '' && (dateStartStr ?? '') !== ''){
            try{
                const dateEnd = new Date(dateEndStr ?? '');
                const dateStart = new Date(dateStartStr ?? '');
                const timeDiff = dateEnd.getTime()-dateStart.getTime();
                const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

                // update the duration
                const durationHtml = document.querySelector<HTMLInputElement>('.smart-gantt-chart-popup-window-editor[value=duration] input.MuiInputBase-input');
                if (durationHtml)
                    durationHtml.value = days.toString();

                // get values for reference
                //taskId = taskItem.id;
                taskDateStart = dateStart;
                taskDateEnd = dateEnd

            } catch{
                // invalid date
                console.log('invalid date',target?.value ?? '');
            }
        }
    }
    const onOpenHandlerChart = (event?:Event): void => {
        const {detail:{target}={}} = event as CustomEvent;
        let _startDate = document.querySelector<HTMLElement>('smart-date-time-picker.task-start-date'),
            _startDateEl = _startDate as unknown as DateTimePicker,
            isGoodIssuedFlag = target?._target?.flagGoodsIssued ?? false;

        console.debug('on-open:', event);
            
        setTimeout(() => {
            console.debug('on-open,timeout:', event);

            // disable the edit of start-date if flagGoodsIsIssued
            if (_startDateEl) {
                _startDateEl.disabled = isGoodIssuedFlag;
            }

            document.querySelector('.smart-gantt-chart-popup-window-editor[value=dateEnd] .smart-element.smart-date-time-picker')?.addEventListener('click',(e) => onClickHandlerCalendar(e));
            document.querySelector('.smart-gantt-chart-popup-window-editor[value=dateStart] .smart-element.smart-date-time-picker')?.addEventListener('click',(e) => onClickHandlerCalendar(e));

            // hide the up/down arrow of datepicker
            // they are getting error on onClickHandlerCalendar
            const calendarArrows = document.querySelector<HTMLElement>('.smart-gantt-chart-popup-window-editor[value=dateEnd] .smart-element.smart-date-time-picker .smart-spin-buttons-container');
            if (calendarArrows)
                calendarArrows.style.setProperty('display','none');
            

            let dateEndPicker = document.querySelector<HTMLElement>('.smart-content[smart-id=content] .smart-input.smart-date-time-input[smart-id=input]');
            if (dateEndPicker){
                dateEndPicker.setAttribute('style','width:100%!important;padding-top:21px!important');
            }


            let smartDateTimePicker = document.querySelector('.smart-gantt-chart-popup-window-editor .task-end-date.smart-element.smart-date-time-picker[role=presentation]');
            if (smartDateTimePicker){
                smartDateTimePicker.setAttribute('style','height:47px!important;font-size:1.2em!important;');
                //
                let _input = smartDateTimePicker.querySelector('.smart-input.smart-date-time-input');
                if (_input) {
                    _input.setAttribute('style', 'padding-top:21px!important;padding-left:11px;');
                }
            }

            // hijack the ok / cancel button
            const okButton = document.querySelector('.smart-footer .smart-popup-window-button.ok');
            if (okButton && !(isSaving ?? false)){
                okButton.addEventListener('click',onClickHandlerSave);
            }
            if ((isSaving ?? false)===true){
                okButton?.setAttribute('disabled','disabled');
            }
            const cancelButton = document.querySelector('.smart-footer .smart-popup-window-button.cancel');
            if (cancelButton){
                cancelButton.addEventListener('click',onClickHandlerCancel);
            }

        },100);
    }
    const onClickHandlerSave = () => {
        try{
            let taskItem: TaskItem = ganttChartRef.current?.getTask(taskId);
            const dateStartHtml = document.querySelector('.smart-gantt-chart-popup-window-editor[value=dateStart] .task-start-date'),
            dateStartValue = (typeof dateStartHtml !== 'undefined') ? (dateStartHtml?.getAttribute('value') ?? '') : '',
            taskDateStart = (typeof dateStartHtml !== 'undefined') ? new Date(dateStartValue) :  new Date('1901-01-01'),
            dateEndHtml = document.querySelector('.smart-gantt-chart-popup-window-editor[value=dateEnd] .task-end-date'),
            dateEndValue = (typeof dateEndHtml !== 'undefined') ? (dateEndHtml?.getAttribute('value') ?? '') : '',
            taskDateEnd = (typeof dateEndHtml !== 'undefined') ? new Date(dateEndValue) :  new Date('1901-01-01');
            
            startTaskItem = structuredClone(taskItem);
            startTaskItem = {...startTaskItem, 
                            dateEnd: new Date(startTaskItem.dateEnd.getFullYear(),startTaskItem.dateEnd.getMonth(),startTaskItem.dateEnd.getDate(),23,59,59),
                            dateStart: new Date(startTaskItem.dateStart.getFullYear(),startTaskItem.dateStart.getMonth(),startTaskItem.dateStart.getDate())};
            taskItem.dateEnd= new Date(taskDateEnd.getFullYear(),taskDateEnd.getMonth(),taskDateEnd.getDate(),23,59,59);
            taskItem.dateStart =new Date(taskDateStart.getFullYear(),taskDateStart.getMonth(),taskDateStart.getDate(),0,0,0);
            taskItem = {...taskItem, 
                        dateEnd: new Date(taskItem.dateEnd.getFullYear(),taskItem.dateEnd.getMonth(),taskItem.dateEnd.getDate(),23,59,59),
                        dateStart: new Date(taskItem.dateStart.getFullYear(),taskItem.dateStart.getMonth(),taskItem.dateStart.getDate())};


            // validate invalid dates
            if (taskItem.dateEnd<taskItem.dateStart){
                // incase validation missed the invalid date range
                // show notification
                enqueueSnackbar(`Invalid date range (From: ${taskItem.dateStart.getMonth()+1}/${taskItem.dateStart.getDate()}/${taskItem.dateStart.getFullYear()} ; To : ${taskItem.dateEnd.getMonth()+1}/${taskItem.dateEnd.getDate()}/${taskItem.dateEnd.getFullYear()}).`,{variant: "error", autoHideDuration:10000});
            } else {
                if ((taskItem.mainRoot ?? false)===false){
    
                    // check if the resized task is batchSelected
                    const isBatchSelected = checkIfBatchSelectedTaskItem(taskItem);
                    if (!isBatchSelected) {
    
                        setResizedTask(startTaskItem,taskItem,false);
                        setSelectedTaskId(taskItem.id);
                        const ret = resizingEndDate({
                                ganttChartRef,
                                taskItem: taskItem as TaskItem,  
                                callback: (updatedProdOrders?: ProductionOrder[] | null) => {
    
                                    refreshSelectedTaskItem(ganttChartRef,taskItem.id);
                                    setIsResizing(false);
    
                                    // // save after it resized
                                    // if ((updatedProdOrders ?? []).length>0){
                                    //     // reset ganttChart
                                    //     if (ganttChartRef.current){
                                    //         const gantt = ganttChartRef.current;
                                    //         gantt.clearTasks();
                                    //         gantt.dataSource = [];
                                    //     }
    
                                    //     saveResizedSchedules(updatedProdOrders ?? [], () => {
                                    //         refreshSelectedTaskItem(ganttChartRef,taskItem.id);
                                    //     });
                                    // }
                                },
                                updateMainTask: false,
                            });
                        if (ret===false){
                            // reload the failed resized
                            resetGanttChart();
                        }
                    } else {
                        setIsResizing(true);
                        // process batch Selected TaskItems
                        processBatchSelectedTaskItems({
                            ganttChartRef,
                            startTaskItemRef: startTaskItem,
                            taskItem,
                        });
    
                    }
                }
            }
        }catch(err){
            console.error('on-save-error:', err);
        }
    }
    const onClickHandlerCancel = () => {
        //for now do nothing
    }
   
    const validateDateRange = (targetTask: TaskItem) => {
        const fromDateInput = new Date((document.querySelector<HTMLInputElement>('#taskStartDate')?.value ?? '').toString());
        const toDateInput = new Date((document.querySelector<HTMLInputElement>('#taskEndDate')?.value ?? '').toString());
        const okButton = document.querySelector('.smart-footer .smart-popup-window-button.ok');
        if (toDateInput<fromDateInput){
            // disable ok button and notify user
            enqueueSnackbar(`Invalid date range (From: ${fromDateInput.getMonth()+1}/${fromDateInput.getDate()}/${fromDateInput.getFullYear()} ; To : ${toDateInput.getMonth()+1}/${toDateInput.getDate()}/${toDateInput.getFullYear()}).`,{variant: "error", autoHideDuration:10000});
            okButton?.setAttribute('disabled','disabled');
        } else if (startTaskItem && startTaskItem.maxGoodsIssuedEndDate && toDateInput<=new Date(startTaskItem.maxGoodsIssuedEndDate)){
            enqueueSnackbar(`Material has been issued. End Date needs to be greater than ${new Date(startTaskItem.maxGoodsIssuedEndDate ?? new Date()).getMonth()+1}/${new Date(startTaskItem.maxGoodsIssuedEndDate ?? new Date()).getDate()}/${new Date(startTaskItem.maxGoodsIssuedEndDate ?? new Date()).getFullYear()}.`,{variant: "error", autoHideDuration:10000});
            okButton?.setAttribute('disabled','disabled');
        } else {
            let isChildInvalid :[invalid: boolean, endDate: Date | null] = [false,null];
            const task = getTaskItem(taskItems,targetTask);
            if (task && (task.tasks ?? []).length>0){
                const parent = structuredClone(task) as TaskItem;
                parent.dateEnd = toDateInput;
                parent.dateStart = fromDateInput;
                isChildInvalid = isSubItemEndDateGreaterThanParentEndDate(parent);
                if (isChildInvalid.length>0 && isChildInvalid[0]===true && isChildInvalid[1]){
                    if (isChildInvalid[0] && isChildInvalid[1]){
                        enqueueSnackbar(`Invalid Date : ${isChildInvalid[0]}. Please check the End Date of Production Order's levels.`,{variant: "error", autoHideDuration:10000});
                    }
                    okButton?.setAttribute('disabled','disabled');
                }
            } 
            
            if (isChildInvalid.length>0 && isChildInvalid[0]===false && !isChildInvalid[1]){
                let isParentInvalid :[invalid: boolean, endDate: Date | null] = [false,null];
                const parentTask = getProject(taskItems,targetTask);
                    if (parentTask){
                        // validate for parents
                        targetTask.dateEnd = toDateInput;
                        targetTask.dateStart = fromDateInput;
                        isParentInvalid = isParentEndDateLessThanSubItemEndDate(parentTask,targetTask);
                        if (isParentInvalid.length>0 && isParentInvalid[0]===true && isParentInvalid[1]){
                            if (isParentInvalid[0] && isParentInvalid[1]){
                                enqueueSnackbar(`Invalid Date : ${targetTask.project}. Please check the End Date of Production Order's levels.`,{variant: "error", autoHideDuration:10000});
                            }
                            okButton?.setAttribute('disabled','disabled');
                        } else {
                            // enable ok button
                            okButton?.removeAttribute('disabled');
                        }
                    } else {
                        // enable ok button
                        okButton?.removeAttribute('disabled');
                    }
            }
        }
    }
    const isSubItemEndDateGreaterThanParentEndDate = (parent: TaskItem) => {
        let retValue :[invalid: boolean, endDate: Date | null] = [false,null];
        function checkSubItems  (tasks: TaskItem[]) : [invalid: boolean, endDate: Date | null]  {
            tasks.forEach(x => {
                if (parent.dateEnd<=x.dateEnd){
                    const found :[invalid: boolean, endDate: Date | null] = [true,x.dateEnd];
                    retValue = found;
                    return found;
                }
                if ((x.tasks ?? []).length>0){
                    return checkSubItems(x.tasks);
                }
            })
            return retValue;
        }
        if ((parent.tasks ?? []).length>0){
            retValue = checkSubItems(parent.tasks);
        }
        return retValue;
    }
    const isParentEndDateLessThanSubItemEndDate = (parent: TaskItem, child: TaskItem) => {
        let retValue :[invalid: boolean, endDate: Date | null] = [false,null];
        function isChildExists (tasks: TaskItem[]): boolean {
            let found = false;
            tasks.forEach(x => {
                if (x.project === child.project){
                    found = true;
                    return found;
                }
                if (x.tasks.length>0 && !found){
                    found = isChildExists(x.tasks);
                }
            });
            return found;
        }
        function checkSubItems  (tasks: TaskItem[]) : [invalid: boolean, endDate: Date | null]  {
            tasks.forEach(x => {
                let isChild = false;
                // find the target child
                if (x.tasks.length){
                    isChild = isChildExists(x.tasks);
                    if (isChild===true && child.dateEnd>=x.dateEnd){
                        const found :[invalid: boolean, endDate: Date | null] = [true,x.dateEnd];
                        retValue = found;
                        return found;
                    }
                }
                if ((x.tasks ?? []).length>0){
                    return checkSubItems(x.tasks);
                }
            })
            return retValue;
        }
        if ((parent.tasks ?? []).length>0){
            retValue = checkSubItems(parent.tasks);
        }
        return retValue;
    }
    return {
        onDragStartHandlerChart,
        onDragEndHandlerChart,
        onResizeStartHandlerChart,
        onResizeEndHandlerChart,
        onPopupWindowSchedule,
        onItemClick,        
        onReady,
        onGridCreate,
        timelineHeaderFormatFunction,
        toggleProgress,
        refreshSelectedTaskItem,
        onCloseHandlerChart,
        onOpenHandlerChart,
        onClickHandlerSave,
        onClickHandlerCancel,
        onGridRowExpandCollapse
    }
}
