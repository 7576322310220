import {MouseEvent} from "react";
import { styled } from "@mui/material"
import { useExportBOMButtonEvents } from "./hooks/ExportBOMButtonEvents";
import { Button } from 'smart-webcomponents-react/button';

export const ExportButton = styled(Button)({
    padding: '5px',
    '& .export-bom:hover': {
        color: '#fff',
    }
})

export const ExportBOMButton = () => {
    const {onClickHandlerExportBOM} = useExportBOMButtonEvents();
    return (
            <Button 
                style={{padding: '5px', marginRight: '10px'}}
                className="primary"
                onClick={(e) => onClickHandlerExportBOM(e as MouseEvent<HTMLButtonElement>)}
                >Export BOM
            </Button>
    )
}