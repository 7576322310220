import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { constants } from "../config/settings";

export interface ProdPagerContextData {
    pageSize: number,
    setPageSize: Dispatch<SetStateAction<number>>,
    pageSizes: number[],
    setPageSizes: Dispatch<SetStateAction<number[]>>,
    currentPage: number,
    setCurrentPage: Dispatch<SetStateAction<number>>,
    totalPages: number,
    setTotalPages: Dispatch<SetStateAction<number>>,
    totalRecords: number,
    setTotalRecords: Dispatch<SetStateAction<number>>,
    resetPages: boolean,
    setResetPages: Dispatch<SetStateAction<boolean>>,
    refreshPage: boolean,
    setRefreshPage: Dispatch<SetStateAction<boolean>>,
    refreshPage2: boolean,
    setRefreshPage2: Dispatch<SetStateAction<boolean>>,
    refreshPage3: boolean,
    setRefreshPage3: Dispatch<SetStateAction<boolean>>,
}

export const ProdPagerDefaultValue: ProdPagerContextData = {
    pageSize: 20,
    setPageSize: () => {},
    pageSizes: [20,50,100],
    setPageSizes: () => {},
    currentPage: 1,
    setCurrentPage: () => {},
    totalPages: 0,
    setTotalPages: () => {},
    totalRecords: 0,
    setTotalRecords: () => {},
    resetPages: false,
    setResetPages: () => {},
    refreshPage: false,
    setRefreshPage: () => {},
    refreshPage2: false,
    setRefreshPage2: () => {},
    refreshPage3: false,
    setRefreshPage3: () => {},
}

export const ProdPagerStore = createContext<ProdPagerContextData>(ProdPagerDefaultValue);

export const useProdPagerStore = () => useContext(ProdPagerStore);

export const ProdPagerProvider: FC = ({children}) => {
    const [pageSize, setPageSize] = useState<number>(constants.paging.size.twenty);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [pageSizes, setPageSizes] = useState<number[]>([20,50,100]);
    const [resetPages, setResetPages] = useState<boolean>(false);
    const [refreshPage, setRefreshPage] = useState<boolean>(false);
    const [refreshPage2, setRefreshPage2] = useState<boolean>(false);
    const [refreshPage3, setRefreshPage3] = useState<boolean>(false);
    return (
        <ProdPagerStore.Provider
            value={{
                pageSize,
                setPageSize,
                currentPage,
                setCurrentPage,
                totalPages,
                setTotalPages,
                totalRecords,
                setTotalRecords,
                pageSizes,
                setPageSizes,
                resetPages,
                setResetPages,
                refreshPage,
                setRefreshPage,
                refreshPage2,
                setRefreshPage2,
                refreshPage3,
                setRefreshPage3,
            }}>
            {children}
        </ProdPagerStore.Provider>
    )
}