import { DataGridPro, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { BomGridContainer, GridIcons, GridWrapper, HeaderGridIcons } from "./styles/SharedStyles";
import { IconButton, Tooltip } from "@mui/material";
import { CustomDialog } from "../dialog/CustomDialog";
import { LogViewer } from "./viewer/LogViewer";
import { CustomDialogToolbar } from "../toolbars/CustomDialogToolbar";
import { LogSummary } from "./viewer/LogSummary";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useBomUploadStore } from "../../../store/BomUploadStore";
import { useLoadBomLines } from "../hooks/DataLoad";
import { ErrorLog } from "../../../Types/ErrorLog";
import { BomLine } from "../../../Types/BomLine";
import { setGridWidth, setHeight } from "../../../utils/utilities";

export const BomLineGrid = () => {
    //const classes = useStyles();
    const {bomLines, setBomLines, setAllBomLines, selectedBomHeaderIds, loadingDetail, setLoadingDetail, 
           loadGrid, errorLogBomLines, setErrorLogBomLines, setErrorLog} = useBomUploadStore();
    const {loadBomLinesByBomHeaderIds} = useLoadBomLines();
    const [open, setOpen] = useState(false);
    const [openSummary, setOpenSummary] = useState(false);
    const [errors, setErrors] = useState(false);
    const onClickHandlerLog = (event: React.MouseEvent<HTMLButtonElement | null>, id: string) => {
        const err = errorLogBomLines.filter(x => x.id.toString() === id.toString());
        if (err.length>0){
            setErrorLog(err[0]);
            setOpen(true);
        }
    }
    const onClickHandlerLogSummary = () => {
        setOpenSummary(true);
    }
    const hasErrors = (blines: BomLine[]) => {
        const foundErrors = blines.filter(x => (x.recipeLog ?? '').length>0 && x.status !== 1);
        setErrors((foundErrors && foundErrors.length>0));
    }
    const cols = [
        {
            field: '',
            headerName: '',
            width: 30,
            sortable: false,
            resizable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            cellClassName: 'bom-header',
            renderHeader: (params: GridColumnHeaderParams) => (
                <>
                {errors && 
                    <Tooltip title="Error Summary Viewer">
                        <HeaderGridIcons>
                            <IconButton onClick={onClickHandlerLogSummary} style={{paddingLeft: '5px'}}>
                                <ErrorOutlineOutlinedIcon  className="view-error error" />
                            </IconButton>
                        </HeaderGridIcons>
                    </Tooltip>}
                </>
            ),
            renderCell: (params: GridRenderCellParams) => (
                <GridIcons>
                    {(params.getValue(params.id,"status")?.toString() !== "1" &&  
                        (params.getValue(params.id,"recipeLog") ?? '').toString().length>0 )  
                    ? <Tooltip title="Error Viewer">
                        <IconButton style={{paddingLeft: '1px'}}
                            onClick={(e) => onClickHandlerLog(e, (params.getValue(params.id,"sequenceNo")?.toString() ?? ''))}>
                            <ErrorOutlineOutlinedIcon  className="view-error error" />
                            </IconButton>
                        </Tooltip>
                    : <CheckCircleOutlinedIcon className="ok" />}
                </GridIcons>
            )
        },
        {field: 'id', hide: true},
        {field: 'status', hide: true},
        {field: 'recipeLog', hide: true},
        {field: 'itemProd', headerName: 'Item Prod.', width: 150, headerClassName: 'bom-line-header'},
        {field: 'bomVersion', headerName: 'BOM Version', width: 170, headerClassName: 'bom-line-header'},
        {field: 'sequenceNo', headerName: 'Sequence', width: 150, headerClassName: 'bom-line-header'},
        {field: 'step', headerName: 'Step', width: 150, headerClassName: 'bom-line-header'},
        {field: 'operation', headerName: 'Operation', width: 165, headerClassName: 'bom-line-header'},
        {field: 'lineType', headerName: 'Line Type', width: 170, headerClassName: 'bom-line-header'},
        {field: 'itemReq', headerName: 'Item Req', width: 165, headerClassName: 'bom-line-header'},
        {field: 'quantity', headerName: 'Quantity', width: 165, headerClassName: 'bom-line-header'},
        {field: 'warehouse', headerName: 'Warehouse', width: 150, headerClassName: 'bom-line-header'},
        {field: 'costCenter', headerName: 'Cost Center', width: 150, headerClassName: 'bom-line-header'},
        {field: 'backflush', headerName: 'Backflush', width: 165, headerClassName: 'bom-line-header'},
        {field: 'byProduct', headerName: 'By Product', width: 165, headerClassName: 'bom-line-header'},
        {field: 'fromDay', headerName: 'From Day', width: 165, headerClassName: 'bom-line-header'},
        {field: 'toDay', headerName: 'To Day', width: 165, headerClassName: 'bom-line-header'},
        {field: 'planAutomatically', headerName: 'Plan Automatically', width: 165, headerClassName: 'bom-line-header'},
        {field: 'position', headerName: 'Position', width: 150, headerClassName: 'bom-line-header'},
        {field: 'resourceID', headerName: 'Resource Id', width: 165, headerClassName: 'bom-line-header'},
        {field: 'variant', headerName: 'Variant', width: 165, headerClassName: 'bom-line-header'},
        {field: 'machine', headerName: 'Machine', width: 165, headerClassName: 'bom-line-header'},     
    ];
    useEffect(() => {
        if (loadGrid){
            setLoadingDetail(true);
            loadBomLinesByBomHeaderIds(selectedBomHeaderIds, (data: BomLine[]) => {
                setBomLines(data);
                setAllBomLines(data);
                setLoadingDetail(false);
                hasErrors(data);
                setErrorLogBomLines(data.filter(x => x.status !== 1 &&  (x.recipeLog ?? '').length>0)
                        .map(x => ({
                            id: x.sequenceNo,
                            code: x.sequenceNo.toString(),
                            title: `Item Prod. : ${x.itemProd} ; BOM Version : ${x.bomVersion} ; Sequence # : ${x.sequenceNo} ; `,
                            errorMessage: x.recipeLog
                        } as ErrorLog)));
            });
        };
        setHeight({target: '.root-grid'});
        setGridWidth({target: '.root-grid .MuiDataGrid-renderingZone',style: '100%!important'});
    },[selectedBomHeaderIds]);
    return (
        <BomGridContainer>
            <GridWrapper className="root-grid">
                <DataGridPro 
                    rowHeight={30}
                    columns={cols}
                    rows={bomLines}
                    loading={loadingDetail}
                />
            </GridWrapper>
            <CustomDialog 
                open={open} 
                setOpen={setOpen}>
                <CustomDialogToolbar 
                    title="Error Viewer"
                    setOpen={setOpen} />
                <LogViewer />
            </CustomDialog>
            <CustomDialog open={openSummary} setOpen={setOpenSummary}>
                <CustomDialogToolbar 
                    title="Error Summary Viewer"
                    setOpen={setOpenSummary} />
                <LogSummary errorLogs={errorLogBomLines} />
            </CustomDialog>
        </BomGridContainer>
    )
}