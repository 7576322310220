import { useFilterDrawerStore } from "../../../store/FilterDrawerStore";

export const useFilterDrawerEvents = () => {
    const {setOpenFilterDrawer} = useFilterDrawerStore();
    const onCloseHandlerFilterDrawer = () => {
        setOpenFilterDrawer(false);
    }
    return {
        onCloseHandlerFilterDrawer,
    }
}