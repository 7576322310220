import  React, { useEffect, FC } from "react"
import { GanttChart } from 'smart-webcomponents-react/ganttchart';
import 'smart-webcomponents-react/source/styles/smart.default.css';
import { useProductionPlanningStore } from "../../../store/ProductionPlanningStore";
import { CircularProgressContainer } from "../ui/CircularProgressContainer";
import { useProductionPlanningScheduleEvents } from "./hooks/ProductionPlanningScheduleEvents";
import { useProductionPlanningScheduleHelpers } from "./hooks/ProductionPlanningScheduleHelper";
import 'smart-webcomponents-react/source/smart.ganttchart';
import { useProductionPlanningScheduleGanttChart } from "./hooks/ProductionPlanningScheduleGanttChart";
import { ScheduleRoot } from "./styles/SharedStyles";
import { ProductionPlanningFooter } from "../footer/ProductionPlanningFooter";
import { Total } from "../footer/items/Total";
import { Grow } from "../../styles/SharedStyles";
import { ProdPager } from "../footer/items/ProdPager";
import { useProdPagerStore } from "../../../store/ProdPagerStore";
import { useProdFilterStore } from "../../../store/ProdFilterStore";
import { useSearchHelper } from "../toolbar/items/hooks/SearchHelper";
import { TaskItem } from "../../../Types/TaskItem";
import './styles/ganttChart.css';
import { useProductionPlanningScheduleResizingHelpers } from "./hooks/ProductionPlanningScheduleResizingHelper";
import { constants } from "../../../config/settings";
import { useProdPlanningFilterDrawerStore } from "../../../store/ProdPlanningFilterDrawerStore";

interface ProductionPlanningScheduleProps {
    type: 'week' | 'month';
}

export const ProductionPlanningSchedule:FC<ProductionPlanningScheduleProps> = (props:ProductionPlanningScheduleProps) => {
    const {type} = props;
    const {setTaskItems,refreshChart,setRefreshChart, loadingProductionPlannings,
           dataLoadedProductionPlanning,setResetGanttChart,resetGanttChart,pageTaskItems, setLoadingProductionPlannings, selectedTask,
           expandedTasks, isResetResizedTasks,setIsResetResizedTasks,selectedTaskIds,
           isRefreshResizedTasks, setIsRefreshResizedTasks,setProductionOrders,setBatchTaskItems,productionOrders, 
           expandOrCollapseAll, viewOption, isSaving, chartDateEnd, chartDateStart} = useProductionPlanningStore();
    const ganttChartRef = React.useRef<GanttChart | null>(null); // should be used for functional component?
    const {onResizeStartHandlerChart,onResizeEndHandlerChart,onPopupWindowSchedule, 
            onItemClick, onReady, onGridCreate, onCloseHandlerChart, onOpenHandlerChart, onGridRowExpandCollapse} = useProductionPlanningScheduleEvents({ganttChartRef});
    const {planningColumns,applySelectedIds,setChartStartAndEndDates} = useProductionPlanningScheduleHelpers({ganttChartRef});
    const {attachedListener, detachedListener,formatTaskWithPendingForResize, clearFormatTaskWithPendingForResize, toggleGridAndChart} = useProductionPlanningScheduleGanttChart();
    const {rollbackResizedTasks,updateGanttChartFromSavedTaskItems,updateFilteredTaskItems,rollbackResizedAllTaskItems,refreshPageTasks,
           updateTaskItems,updateAllTaskItems,rollbackResizedFilteredTaskItems,rollbackResizedTaskItems,updateRollbackAllTaskItems} = useProductionPlanningScheduleResizingHelpers();
    const {setResetPages,refreshPage,setRefreshPage,currentPage,totalPages,refreshPage2,setRefreshPage2,refreshPage3,setRefreshPage3} = useProdPagerStore();
    const {showRelated} = useProdPlanningFilterDrawerStore();
    const {expandCollapseRows, searchText} = useProdFilterStore();
    const {searchProdItems} = useSearchHelper();

	const shadeUntilCurrentTime = true;
	const currentTimeIndicator = true;
	const currentTimeIndicatorInterval = 60;
    const durationUnit = 'day';
    const treeSize = 1067; //61%'; //560

    useEffect(() => {
        if (ganttChartRef.current && loadingProductionPlannings===false &&  dataLoadedProductionPlanning === true ){
            setTimeout(() => {
                const gantt = ganttChartRef.current;
                if (gantt){
                    gantt.clearTasks();
                    if ((totalPages ?? 0)>0)
                        // reload page
                        setRefreshPage(true)
                    else
                        // this will set page 1
                        setResetPages(true);
                    //gantt.dataSource = taskItems;
                }
            },100);
            applySelectedIds();
        }
    },[dataLoadedProductionPlanning]);
    useEffect(() => {
        if (resetGanttChart===true){
            setResetGanttChart(false);
            setTimeout(() => {
                const gantt = ganttChartRef.current;
                if (gantt){
                    gantt.clearTasks();
                    gantt.dataSource = [];
                }
            },100);
        }
    },[resetGanttChart])
    useEffect(() => {
        if (refreshChart===true){
            setRefreshChart(false);
            const gantt = ganttChartRef?.current ?? null;
            if (gantt){
                setTimeout(() => {
                    gantt.clearTasks();
                    gantt.dataSource = [];
                    if (loadingProductionPlannings===false){
                        //gantt.dataSource = taskItems;                
                        setResetPages(true);
                        //applySelectedIds();
                    }
                },100);
            }
        }
    },[refreshChart])
    useEffect(() => {
        if (refreshPage===true || refreshPage2===true || refreshPage3){
            setRefreshPage(false);
            setRefreshPage2(false);
            setRefreshPage3(false);

            const gantt = ganttChartRef?.current ?? null;
            if (gantt){
                setLoadingProductionPlannings(true);
                //setTimeout(() => {
                    gantt.clearTasks();
                    gantt.dataSource = [];
                    // per page 
                    const tasks = pageTaskItems ? pageTaskItems[currentPage-1] : [];
                    if (tasks){
                        let _tasks:TaskItem[] = [] as TaskItem[];
                        if (searchText.length > 0) {           
                            _tasks = searchProdItems(tasks, searchText,{checked:showRelated ?? false, expand: expandCollapseRows});
                            setTaskItems(_tasks);
                        } else {
                            _tasks = tasks.map(item =>{return {...item, expanded:(expandCollapseRows === 'expanded' ? true: false)}});
                        }

                        // update also the task for specifically expanded task,only if not from expand/collapse all
                        if (!expandOrCollapseAll) {
                            if (expandedTasks.length > 0 && _tasks.length > 0) {                            
                                expandedTasks.forEach( item => {
                                    _tasks.map( prev => {
                                        if (prev.id === item) {
                                            prev.expanded = true;
                                        }
                                        return prev;
                                    });
                                });
                            }
                        }
                        setChartStartAndEndDates(_tasks);
                        setTaskItems(_tasks);
                        setTimeout(() => {
                            gantt.dataSource = _tasks;
                        },1000)
                        if ((selectedTaskIds ?? []).length>0) gantt.selectedTaskIds = selectedTaskIds;
                        applySelectedIds();
                        
                        toggleGridAndChart(viewOption || "");

                        // set row for modified row
                        formatTaskWithPendingForResize(productionOrders,ganttChartRef);
                    }
                    setLoadingProductionPlannings(false);
                    refreshPageTasks(ganttChartRef);
                //},500);
            }
        }
    },[refreshPage,refreshPage2,refreshPage3])
    useEffect(()=>{
        attachedListener();
        return ()=>{
            detachedListener();
        }
    },[]);
    useEffect(() => {
        if (isResetResizedTasks===true){
            setIsResetResizedTasks(false);
            rollbackResizedTasks(ganttChartRef)
            .then(() => {
                return rollbackResizedFilteredTaskItems();
            }).then(() => {
                return rollbackResizedTaskItems();
            }).then(() => {
                return rollbackResizedAllTaskItems();
            }).then(() => {
                setTimeout(() => {
                    clearFormatTaskWithPendingForResize();
                    setProductionOrders([]);
                    setBatchTaskItems([]);
                },100);
            });
        }
    },[isResetResizedTasks])
    useEffect(() => {
        if (isRefreshResizedTasks===true){
            setIsRefreshResizedTasks(false);
            updateGanttChartFromSavedTaskItems(ganttChartRef)
            .then(() => {
                return updateFilteredTaskItems();
            }).then(() => {
                return updateAllTaskItems();
            }).then(() => {
                return updateRollbackAllTaskItems();
            }).then(() => {
                return updateTaskItems()
            })
            // setTimeout(() => {
            //     //     setResetPages(true);
            //     // },100);
            // .then(() => {
            //     //return refreshPageTasks(ganttChartRef);
            // })
            // }).then(() => {
            //     // setTimeout(() => {
            //     //     setResetPages(true);
            //     // },100);
            // });
        }
    },[isRefreshResizedTasks]);
    useEffect(()=>{
        if (viewOption === "chart") {
            ganttChartRef?.current?.refresh(true);            
        }
    },[viewOption]);
    return (
        <>        
        <ScheduleRoot>
            <CircularProgressContainer id="gridProgress" style={{display:'none'}} />
            <div style={{
                width: 'auto',
                overflow: 'hidden',
            }}>
            <GanttChart 
                className={"scheduler-chart"}
                id="productionPlanner"
                style={{width: 'calc(100vw - 267px )',height: 'calc(100vh - 237px)'}} // 267 and 237 manually measured
                dayFormat="short"
                weekFormat="long"
                monthFormat="long"
                yearFormat="numeric"
                locale="en"
                treeSize={viewOption ==="chart" ? constants.chartSettings.treeSize.chart : constants.chartSettings.treeSize.grid} // with batch-selected 406
                ref={ganttChartRef}
                disableSelection={false}
                adjustToNonworkingTime={true}
                hideResourcePanel={true}
                infiniteTimeline={(chartDateStart && chartDateStart) ? true : false}
                shadeUntilCurrentTime={shadeUntilCurrentTime}
                durationUnit={durationUnit}
                currentTimeIndicator={currentTimeIndicator}
                currentTimeIndicatorInterval={currentTimeIndicatorInterval}
                view={type ?? 'week'}
                horizontalScrollBarVisibility={viewOption === 'grid' ? 'hidden': 'visible'}
                snapToNearest={false}
                nonworkingDays={[0]}
                disableTaskProgressChange={true}
                showProgressLabel={false}
                taskColumns={planningColumns}
                disableTaskResize={(isSaving ?? false)===true}
                dateStart={chartDateStart ?? Date()}
                dateEnd={chartDateEnd ?? new Date()}
                // for now no draggable
                // onDragStart = {(e) => onDragStartHandlerChart(e)}
                // onDragEnd = {(e) => onDragEndHandlerChart(e, ganttChart)}
                onResizeStart = {(e) => onResizeStartHandlerChart(e)}
                onResizeEnd = {(e) => onResizeEndHandlerChart(e)}
                popupWindowCustomizationFunction={onPopupWindowSchedule}
                onItemClick = {(e)=>onItemClick(e)}
                onReady = {(e)=>onReady(e)}
                onCreate = {(e)=>onGridCreate(e)}
                onClose = {onCloseHandlerChart}
                onOpen = {onOpenHandlerChart}
                onExpand = {(e)=>onGridRowExpandCollapse(e)}
                onCollapse = {(e)=>onGridRowExpandCollapse(e)}
                onOpening={(e)=>{
                    if (selectedTask) {
                        if (selectedTask.mainRoot === true && e) {
                            e.preventDefault();
                        }
                    }
                }} 
                onAbort = {(e) => {
                    console.log('onAbort',e);
                }}
            />
            </div>
            <ProductionPlanningFooter>
                <ProdPager />
                <Grow />
                <Total />
            </ProductionPlanningFooter>
        </ScheduleRoot>
        </>
    )
}
