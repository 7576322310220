import { FC, useContext } from "react"
import { AuthContext } from "../context/AuthContext";
import { hasAccessRights } from "../utils/utilities";

interface AuthorizedAccessProps {
    componentCode: string;
    actionCode: string;
    children?: React.ReactNode;
    readonly?: string;
}

export const AuthorizedAccess: FC<AuthorizedAccessProps> = (props: AuthorizedAccessProps) => {
    const {componentCode, actionCode, children} = props;
    const auth = useContext(AuthContext);
    const hasAccess = hasAccessRights(auth?.roleAccess, componentCode, actionCode, auth?.auth.admin);
    return (
        <>
            {hasAccess ? children : <></>}
        </>
    )
}