import { FormControl, styled } from "@mui/material";

export const StyledFormControl = styled(FormControl)({
    width: '14ch',
    padding:'4px 4px 4px 20px',
    '.MuiFormLabel-root':{
        fontSize: '12px',              
    },
    '& .MuiFormGroup-root': {
        '& .MuiFormControlLabel-root': {
            fontSize: '12px',
            '& .MuiButtonBase-root':{
                '&.MuiRadio-root':{
                    padding: '2px 5px'
                }
            },
            
        },  
        '& .MuiTypography-root': {
            fontSize: '12px'
        }
    }
});