import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { isProductDuplicate } from "../../../services/inventor/product";
import { useSnackbar } from "notistack";
import { useExportBOMStore } from "../../../store/ExportBOMStore";
import { ResultOptions } from "../../../Types/ResultOptions";

export interface ILoadProductByItemCodeAndVersion {
    itemCode: string;
    version?: string;
}

export const useProductLoaders = () => {
    const authContext = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const {setIsDuplicate,setIsSaved} = useExportBOMStore();
    const isProductDuplicateByItemCodeAndVersion = (params: ILoadProductByItemCodeAndVersion) => {
        const {itemCode, version} = params;
        setIsSaved(false);
        isProductDuplicate(authContext?.auth.userToken, itemCode, version)
        .then((data?: ResultOptions) => {
            if ((data?.success ?? false) === true){
                setIsDuplicate(true);
                enqueueSnackbar(`Item code : ${itemCode} ; Version: ${version} already exist, please update your Inventor file version.`,{variant: "error", autoHideDuration:60000});
            } else {
                setIsDuplicate(false);
            }
        })
    }
    return {
        isProductDuplicateByItemCodeAndVersion
    }
}