import { TextField, styled } from "@mui/material"
import { useExportBOMStore } from "../../../store/ExportBOMStore"

export const ImportBOMHeaderRoot = styled('div')({
    display: 'flex',
    flexFlow: 'row',
    paddingTop: '10px',
})

export const FieldsWrapper = styled('div')({
    margin: '5px 5px 0',
})

export const ImportBomTextField = styled(TextField)({
    marginRight: '10px',
    marginBottom: '10px',
})

export const ImportBomTextFieldShort = styled(TextField)({
    marginRight: '10px',
    width: '150px',
    marginBottom: '10px',
})

export const ImportBomTextFieldLong = styled(TextField)({
    marginRight: '10px',
    width: '350px',
    marginBottom: '10px',
})

export const PreviewBOMExportHeader = () => {
    const {inventorBomHeader} = useExportBOMStore();
    return (
        <ImportBOMHeaderRoot>
            <FieldsWrapper>
                <ImportBomTextField 
                    id="itemCode" 
                    label="Item Code" 
                    variant="outlined"
                    size="small"
                    disabled={true}
                    value={inventorBomHeader?.itemCode ?? ''} />
                <ImportBomTextField 
                    id="legacySKU" 
                    label="Legacy SKU" 
                    variant="outlined"
                    size="small"
                    disabled={true}
                    value={inventorBomHeader?.legacySKU ?? ''} />
                <ImportBomTextFieldLong 
                    id="itemDescription" 
                    label="Item Description and Name" 
                    variant="outlined"
                    size="small"
                    disabled={true}
                    value={inventorBomHeader?.itemDescription ?? ''} />
                <ImportBomTextFieldShort 
                    id="version" 
                    label="BOM Version" 
                    variant="outlined"
                    size="small"
                    disabled={true}
                    value={inventorBomHeader?.version ?? ''} />
                <ImportBomTextField 
                    id="madeBy" 
                    label="Made By" 
                    variant="outlined"
                    size="small"
                    disabled={true}
                    value={inventorBomHeader?.madeBy ?? ''} />
                <ImportBomTextField 
                    id="madeOn" 
                    label="Made On" 
                    variant="outlined"
                    size="small"
                    disabled={true}
                    value={inventorBomHeader?.madeOn ?? ''} />
            </FieldsWrapper>
        </ImportBOMHeaderRoot>
    )
}