import GanttChart, { GanttChartTaskColumn } from "smart-webcomponents-react/ganttchart";
import { useProductionPlanningStore } from "../../../../store/ProductionPlanningStore"
import { TaskItem } from "../../../../Types/TaskItem";
import {renderToString} from "react-dom/server";
import TextField  from "@mui/material/TextField/TextField";
import ReactDOM from "react-dom";
import { DateTimePicker } from 'smart-webcomponents-react/datetimepicker';
import { useTaskItemStore } from "../../../../store/TaskItemStore";
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { ChangeEvent } from "react";
import { isEmpty } from "../../../../utils/utilities";
import { CircularProgress } from "@material-ui/core";

export const useProductionPlanningScheduleHelpers = (props: {
    ganttChartRef: React.RefObject<GanttChart | null>
}) => {
    const {ganttChartRef} = props;
    const {setTaskItems,searchSelectedTaskIds,selectedTaskId,selectedTaskIds,setChartDateStart,setChartDateEnd} = useProductionPlanningStore();
    const [,setTaskItemInput] = useTaskItemStore();
    let startTaskItem: TaskItem = {} as TaskItem;
    let endTaskItem: TaskItem = {} as TaskItem;
    const startDraggingSchedule = (taskItem: TaskItem) => {
        startTaskItem = taskItem;
    }
    const endDraggingSchedule = (taskItem: TaskItem, callback: () => void) => {
        endTaskItem = taskItem;
        const startDateTime = endTaskItem.dateStart.getTime()-startTaskItem.dateStart.getTime();
        let days = Math.ceil(startDateTime / (1000 * 60 * 60 * 24));

        // dragged forward
        setTaskItems(prev => {

            function countSundays(task: TaskItem){
                let sundays = 0;
                let taskCopy = {
                    startDate: new Date(task.dateStart.getFullYear(),task.dateStart.getMonth(),task.dateStart.getDate(),task.dateStart.getHours(),task.dateStart.getMinutes()),
                    endDate: new Date(task.dateEnd.getFullYear(),task.dateEnd.getMonth(),task.dateEnd.getDate(),task.dateEnd.getHours(),task.dateEnd.getMinutes())
                };
                const endDate = taskCopy.endDate;
                let runningDate = taskCopy.startDate;
                while(runningDate<endDate){
                    if (runningDate.getDay() === 0){
                        sundays++;
                    }
                    runningDate.setDate(runningDate.getDate() + 1);
                }
                return sundays;
            }
            function updateDates(task: TaskItem){
                // skipt the one that was dragged
                if (task.project !== taskItem.project){
                    
                    task.dateStart.setDate(task.dateStart.getDate() + days);
                    task.dateEnd.setDate(task.dateEnd.getDate() + days);
                    

                    if (task.dateEnd.getDay() === 0 && days>0){
                        task.dateEnd.setDate(task.dateEnd.getDate() + 1);
                    }
                    if (task.dateEnd.getDay() === 0 && days<0){
                        task.dateEnd.setDate(task.dateEnd.getDate() - 1);
                    }
                    if (task.dateStart.getDay() === 0 && days>0){
                        task.dateStart.setDate(task.dateStart.getDate() + 1);
                    }
                    if (task.dateStart.getDay() === 0 && days<0){
                        task.dateStart.setDate(task.dateStart.getDate() - 1);
                    }
                    const sundays = countSundays(task);
                    if (sundays>0 && task.dateStart.getDay() === 0 && task.dateEnd.getDay() === 0){
                        if (days>0){
                            task.dateEnd.setDate(task.dateEnd.getDate() + 1);
                        } else {
                            task.dateStart.setDate(task.dateStart.getDate() - 1);
                        }
                    }
                }

            }
            function updateTasks(tasks: TaskItem[]){
                tasks.forEach(task => {
                    updateDates(task);
                    if ((task.tasks ?? []).length>0){
                        return updateTasks(task.tasks);
                    }
                })
            }
            let project: TaskItem = {} as TaskItem;
            let foundProject = false;
            function findProject(tasks: TaskItem[]){
                tasks.forEach(task => {
                    if (task.project !== taskItem.project && task.type === 'project' && !foundProject){
                        // save the first project
                        if ((task.parent ?? '').length===0)
                            project = task;
                        
                        return findProject(task.tasks);
                    } if ((task.parent ?? '').length === 0 && 
                            task.project === taskItem.project && 
                            task.type === 'project') {
                        project = task;
                        foundProject = true;
                    } else {
                        if (task.project === taskItem.project){
                            foundProject = true;
                        }
                    }

                });
            }
            // step 1
            findProject(prev);
            // step 2
            updateDates(project);
            // step 3
            updateTasks(project.tasks)
            return prev;
        });    
        if (callback)
            callback();
    }
    const onChangeHandlerBatchSelected = (event:  ChangeEvent<HTMLInputElement | undefined>) => {
        console.log('onClickHandlerBatchSelected',event);
    }
    const planningColumns: GanttChartTaskColumn[] = [{
		label: 'Prod. Order',
		value: 'projectTooltip',
        size: 198,
        minWidth: 198,
        disableEdit: true,
        formatFunction:(data:string) =>{
            try {
                const code = data?.split("|");
                if (code?.length > 0)                
                return renderToString(<div className="item-desc--container"><div className="progress-container off"><CircularProgress size={10} disableShrink/></div><div className="title--container" title={code[1]}>{code[0]}</div></div>);
            }catch(e:any) {
                console.debug('error:',e);
            }
        },
        customEditor: function(label: string, value: string): DocumentFragment {
            const fragment = document.createDocumentFragment();
            const project = (value ?? '').split('|')[0];
            ReactDOM.render(<><br /><br /><br /><br /><TextField 
                                disabled={true}
                                size="small"
                                label="Prod. Order"
                                className='task-project-name' 
                                value={project} /></>,fragment,() => {});
            return fragment;
        },
        setCustomEditorValue: function(editor: HTMLInputElement,label: string,value:string){
            const project = (value ?? '').split('|')[0];
            const editorHtml = editor.querySelector('.task-project-name input.MuiInputBase-input');
            if (editorHtml)
                editorHtml.setAttribute('value',project);
        },
        getCustomEditorValue: function(editor: any) {
            return editor.querySelector('.task-project-name input.MuiInputBase-input').value;
        }
	} as GanttChartTaskColumn,
    // {
    //     label: 'Batch',
    //     value: 'batchSelected',
    //     size: 40,
    //     minWidth: 40,
    //     disableEdit: true,
    //     formatFunction: (data:string) => {
    //         if (data.toLowerCase() !== 'unassigned')
    //             return renderToString(
    //                 <input className="batch-selected" type="checkbox" defaultChecked={data === 'true'}/>
    //             )

    //         return '';
    //     }
    // } as GanttChartTaskColumn,
    {
		label: 'Item',
		value: 'itemTooltip',
        size: 180,
        minWidth: 180,
        disableEdit: true, 
        formatFunction:(data:string) =>{
            try {
                const code = data?.split("|");
                if (code?.length > 0)
                    return renderToString(<div title={code[1]}>{code[0]}</div>);                
            }catch(e:any) {
                console.debug('error:',e);
            }
        },
        customEditor: function(label: string, value: string): DocumentFragment {
                const fragment = document.createDocumentFragment();
                const item = (value ?? '').split('|')[0];
                ReactDOM.render(<><br /><br /><br /><br /><TextField 
                                    disabled={true}
                                    size="small"
                                    label="Item"
                                    className='task-item-name' 
                                    value={item} /></>,fragment,() => {});
                return fragment;
            
        },
        setCustomEditorValue: function(editor: HTMLInputElement,label: string,value:string){
            const item = (value ?? '').split('|')[0];
            const editorHtml = editor.querySelector('.task-item-name input.MuiInputBase-input');
            if (editorHtml)
                editorHtml.setAttribute('value',item);
        },
        getCustomEditorValue: function(editor: any) {
            return editor.querySelector('.task-item-name input.MuiInputBase-input').value;
        }
	} as GanttChartTaskColumn,
    {
		label: 'Start Date',
		value: 'dateStart',
        size: 65,
        minWidth: 65,
        //disableEdit: true,
        formatFunction: (date:any) => {
			return new Date(date).toLocaleDateString('en', {day: "2-digit", month: "2-digit", year: "2-digit"});
		},
        customEditor: function(label: string, value: string): DocumentFragment {            
            const fragment = document.createDocumentFragment();
            const dateStart = new Date(value);
            const dateStartStr = `${dateStart.getFullYear()}/${dateStart.getMonth()+1}/${dateStart.getDate()}`;
            ReactDOM.render(
                <div style={{position:'relative', marginTop: '10px'}}><br />
                <label style={{position:'absolute',
                               top: '10px', 
                               left: '11px',
                               color: 'rgba(0, 0, 0, 0.38)',
                               backgroundColor: '#fff',
                               fontSize: '.9em',
                               padding: '0 3px 0'}}>Start Date</label>
                <DateTimePicker
                    id="taskStartDate"
                    style={{paddingLeft: '10px'}}
                    className="task-start-date"
					calendarButton 
                    formatString="d"
                    enableMouseWheelAction 
                    dropDownDisplayMode="calendar"
                    interval="new smartUtilities.TimeSpan(24, 0, 0)"
					dropDownPosition="overlay-center"
                    value={dateStartStr}
					editMode="full"
                    auto-close
                    footer={false} />
            </div>,fragment,() => {});
            return fragment;
        },
        setCustomEditorValue: function(editor: HTMLInputElement,label: string,value:string){            
            editor.querySelector('.task-start-date')?.setAttribute('value',value);
        },
        getCustomEditorValue: function(editor: any) {
            return editor.querySelector('.task-start-date').getAttribute('value');
        }
	} as GanttChartTaskColumn,
	{
		label: 'End Date',
		value: 'dateEnd',
        size: 65,
        minWidth: 65,
        formatFunction: (date:any) => {
			return new Date(date).toLocaleDateString(ganttChartRef?.current?.locale ?? "en", {day: "2-digit", month: "2-digit", year: "2-digit"});
		},
        customEditor: function(label: string, value: string): DocumentFragment {
            const fragment = document.createDocumentFragment();
            const dateEnd = new Date(value);
            const dateEndStr = `${dateEnd.getFullYear()}/${dateEnd.getMonth()+1}/${dateEnd.getDate()}`;
            ReactDOM.render(
                <div style={{position:'relative', marginTop: '10px'}}><br />
                <label style={{position:'absolute',
                               top: '10px', 
                               left: '11px',
                               color: 'rgba(0, 0, 0, 0.38)',
                               backgroundColor: '#fff',
                               fontSize: '.9em',
                               padding: '0 3px 0'}}>End Date</label>
                <DateTimePicker
                    id="taskEndDate"
                    style={{paddingLeft: '10px'}}
                    className="task-end-date"
					calendarButton 
                    formatString="d"
                    enableMouseWheelAction 
                    dropDownDisplayMode="calendar"
                    interval="new smartUtilities.TimeSpan(24, 0, 0)"
					dropDownPosition="overlay-center"
                    value={dateEndStr}
					editMode="full"
                    auto-close
                    footer={false} />
            </div>,fragment,() => {});
            return fragment;
        },
        setCustomEditorValue: function(editor: HTMLInputElement,label: string,value:string){
            editor.querySelector('.task-end-date')?.setAttribute('value',value);
        },
        getCustomEditorValue: function(editor: any) {
            return editor.querySelector('.task-end-date').getAttribute('value');
        }
	} as GanttChartTaskColumn,
	{
		label: 'Days',
		value: 'duration',
        size: 40,
        minWidth: 40,
        customEditor: function(label: string, value: string): DocumentFragment {
            const fragment = document.createDocumentFragment();
            ReactDOM.render(<><br /><TextField 
                                disabled={true}
                                size="small"
                                label="Duration"
                                className='task-duration' 
                                value={value} /><br /></>,fragment,() => {});
            return fragment;
        },
        setCustomEditorValue: function(editor: HTMLInputElement,label: string,value:string){
            editor.querySelector<HTMLInputElement>('.task-duration')?.setAttribute('value',value);
        },
        getCustomEditorValue: function(editor: any) {
            return editor.querySelector('.task-duration').getAttribute('value');
        }
	} as GanttChartTaskColumn,
    {
        label: 'Qty',
		value: 'quantity',
        size: 80,
        minWidth: 80,
        disableEdit: true,
        formatFunction: function(data:string) {
            return data;
        },
        customEditor: function(label:string, value:string) {
            const fragment = document.createDocumentFragment();
            ReactDOM.render(<><TextField 
                                disabled={true}
                                size="small"
                                label="Quantity"
                                className="task-quantity"
                                style={{marginTop: '26px'}}
                                value={value} /><br /></>,fragment,() => {});
            return fragment;           
        },
    } as GanttChartTaskColumn,
    {
        label: 'Realized Qty',
		value: 'realizedQty',
        size: 100,
        minWidth: 100,
        disableEdit: true,
        formatFunction: function(data:string) {                
            return data;
        },
        customEditor: function(label:string, value:string) {
            const fragment = document.createDocumentFragment();
            ReactDOM.render(<><TextField 
                                disabled={true}
                                size="small"
                                label="Realized Qty"
                                className="task-realized-qty"
                                value={value} /><br /></>,fragment,() => {});
            return fragment;
        },        
    } as GanttChartTaskColumn,
    {
        label: 'CBM',
		value: 'cbm',
        size: 60,
        minWidth: 60,
        disableEdit: true,
        formatFunction: function(data:string) {                
            return (Math.round(parseFloat(data) * 100) / 100).toString();
        },
        customEditor: function(label:string, value:string) {
            const fragment = document.createDocumentFragment();
            ReactDOM.render(<><TextField 
                                disabled={true}
                                size="small"
                                label="CBM"
                                className="task-cbm"
                                value={value} /><br /></>,fragment,() => {});
            return fragment;           
        },        
    } as GanttChartTaskColumn,
    {
        label: 'SO',
		value: 'salesOrderNo',
        size: 60,
        minWidth: 60,
        disableEdit: true,
        formatFunction: function(data:string) {
            if (data.toString().toLowerCase() === 'unassigned')
                return '';
                
            return data;
        },
        customEditor: function(label:string, value:string) {
            const fragment = document.createDocumentFragment();
            ReactDOM.render(<><TextField 
                                disabled={true}
                                size="small"
                                label="Sales Order No"
                                className="task-sales-order-no"
                                value={value} /><br /></>,fragment,() => {});
            return fragment;           
        },        
    } as GanttChartTaskColumn,
    {
        label: 'Fulfillment Date',
		value: 'soFulfillmentDate',
        //size: '22%',
        //minWidth: '22%',
        disableEdit: true,
        formatFunction: function(dateAsString:any) {            
            if (isEmpty(dateAsString) || dateAsString.toString().toLowerCase() === 'unassigned')
                return '';

            const _dateTime = (new Date(dateAsString)).toLocaleDateString((ganttChartRef?.current?.locale ?? "en"), {day: "2-digit", month: "2-digit", year: "2-digit"});            

            if (_dateTime === "01/01/01") { // default of null
                return "";
            }

            return _dateTime;
        },
        customEditor: function(label:string, value:string) {
            let _date = new Date(value).toLocaleDateString((ganttChartRef?.current?.locale ?? "en"), {day: "2-digit", month: "2-digit", year: "numeric"});
            const fragment = document.createDocumentFragment();
            ReactDOM.render(<><TextField 
                                disabled={true}
                                size="small"
                                label="Fulfillment Date"
                                className="task-fulfillment-date"
                                value={_date} /><br /></>,fragment,() => {});
            return fragment;           
        },        
    } as GanttChartTaskColumn,
    {
        label: 'Warehouse',
		value: 'warehouse',
        size: 90,
        minWidth: 90,
        disableEdit: true,
        customEditor: function(label:string, value:string) {
            const fragment = document.createDocumentFragment();
            ReactDOM.render(<><TextField 
                                disabled={true}
                                size="small"
                                label="Warehouse"
                                className="task-warehouse"
                                value={value} /><br /></>,fragment,() => {});
            return fragment;           
        },        
    } as GanttChartTaskColumn,    
    {
        label: 'Customer',
		value: 'customer',
        //size: '22%',
        //minWidth: '22%',
        disableEdit: true,
        formatFunction: function(data:string) {
            return data;
        },
        customEditor: function(label:string, value:string) {
            const fragment = document.createDocumentFragment();
            ReactDOM.render(<><TextField 
                                disabled={true}
                                size="small"
                                label="Customer"
                                className="task-customer"
                                value={value} /><br /></>,fragment,() => {});
            return fragment;           
        }        
    } as GanttChartTaskColumn,
    {
		label: 'Status',
		value: 'status',
        size: 104,
        minWidth: '7%',
        disableEdit: true,
        formatFunction: function(data:string) {
            let _statuses = data.split('|'),
            _statusWithIconInString = "";
            Array.from(_statuses).forEach((item:string, index:number)=>{
                _statusWithIconInString += statusAsString(item,index);
            });

            return _statusWithIconInString.length > 0 ? `<span>${_statusWithIconInString}</span>`: '';
        },
        customEditor: function(label: string, value: string): any {
            let _statuses = value.split("|"),
            _label = label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
            const fragment = document.createDocumentFragment();
            ReactDOM.render(<div><p style={{marginTop:'0px', marginBottom: '8px', color:'rgba(0,0,0,0.38)'}}>{_label}</p><span id="taskStatusContainer">
                {_statuses[0] !== undefined ? statusAsElement(_statuses[0], 0) : <></>}
                {_statuses[1] !== undefined ? statusAsElement(_statuses[1], 1) : <></>}
                {_statuses[2] !== undefined ? statusAsElement(_statuses[2], 2) : <></>}
                {_statuses[3] !== undefined ? statusAsElement(_statuses[3], 3) : <></>}
                {_statuses[4] !== undefined ? statusAsElement(_statuses[4], 4) : <></>}
                </span><input id="task-status" hidden value={value}/></div>,fragment,() => {});

            return _statuses.length > 0 ? fragment : '';
        },        
	} as GanttChartTaskColumn];
    const statusAsElement = (item:string, index:number) => {
        const renderOpen = (item: string) => {
            if (item === "0")
                return (
                    <i title="Close"><LockOpenOutlinedIcon htmlColor="grey" /></i>
                )

            return (
                <i title="Open"><LockOutlinedIcon htmlColor="green" /></i>
            )

        }

        const renderAuthorized = (item: string) => {
            if (item === "0")
                return (
                    <i title="Not Authorized"><KeyOutlinedIcon htmlColor="red" /></i>
                )

            return (
                <i title="Authorized"><KeyOutlinedIcon htmlColor="green" /></i>
            )

        }

        const renderReleased = (item: string) => {
            if (item === "0")
                return (
                    <i title="Not Released"><NewReleasesOutlinedIcon htmlColor="red" /></i>
                )

            return (
                <i title="Released"><NewReleasesOutlinedIcon htmlColor="green" /></i>
            )

        }

        const renderPrinted = (item: string) => {
            if (item === "0")
                return (
                    <i title="Not Printed"><PrintOutlinedIcon htmlColor="red" /></i>
                )

            return (
                <i title="Printed"><PrintOutlinedIcon htmlColor="green" /></i>
            )

        }

        const renderRejected = (item: string) => {
            if (item === "0")
                return (
                    <i title="Not Rejected"><ThumbDownOutlinedIcon htmlColor="red" /></i>
                )

            return (
                <i title="Rejected"><ThumbDownOutlinedIcon htmlColor="green" /></i>
            )

        }

        if(index === 0) {
            return renderOpen(item);
        }

        if(index === 1) {
            return renderAuthorized(item);
        }
        if(index === 2) {
            //Release
            return renderReleased(item);
        }
        if(index === 3) {
            //Printed
           return renderPrinted(item);
        }
        if(index === 4) {
            //Rejected
            return renderRejected(item);
        }
    }
    const statusAsString = (item:string, index:number) => {
        let returnString = "";

        if(index === 0) {
            //Open
            switch(item){
                case "0":
                    returnString = renderToString(<i title="Close"><LockOutlinedIcon htmlColor="grey" /></i>);
                    break;
                case "1":
                    returnString = renderToString(<i title="Open"><LockOpenOutlinedIcon htmlColor="green" /></i>);
                    break;
            }
        }

        if(index === 1) {
            //Authorized
            switch(item){
                case "0":
                    returnString = renderToString(<i title="Not Authorized"><KeyOutlinedIcon htmlColor="grey" /></i>);
                    break;
                case "1":
                    returnString = renderToString(<i title="Authorized"><KeyOutlinedIcon htmlColor="green" /></i>);
                    break;
            }
        }
        if(index === 2) {
            //Release
            switch(item){
                case "0":
                    returnString = renderToString(<i title="Not Released"><NewReleasesOutlinedIcon htmlColor="grey" /></i>);
                    break;
                case "1":
                    returnString = renderToString(<i title="Released"><NewReleasesOutlinedIcon htmlColor="green" /></i>);
                    break;
            }
        }
        if(index === 3) {
            //Printed
            switch(item){
                case "0":
                    returnString = renderToString(<i title="Not Printed"><PrintOutlinedIcon htmlColor="grey" /></i>);
                    break;
                case "1":
                    returnString = renderToString(<i title="Printed"><PrintOutlinedIcon htmlColor="green" /></i>);
                    break;
            }
        }
        if(index === 4) {
            //Rejected
            switch(item){
                case "0":
                    returnString = renderToString(<i title="Not Rejected"><ThumbDownOutlinedIcon htmlColor="grey" /></i>);
                    break;
                case "1":
                    returnString = renderToString(<i title="Rejected"><ThumbDownOutlinedIcon htmlColor="red" /></i>);
                    break;
            }
        }

        return returnString;
    }
    const getTotalItems = (taskItems: TaskItem[]): number => {
        const _task = taskItems.filter( x => x.tasks.length === 0);
        const _innerTasks = taskItems.filter(x => x.tasks.length > 0);
        const _count = _innerTasks.reduce(( item, {tasks}:TaskItem)=>{                
            return item += tasks.length;
        },0);

        return (_task?.length ?? 0) + _count;
    }
    const setModifiedTaskItem = (taskItem: TaskItem) => {
        setTaskItemInput({...taskItem});
    }
    const applySelectedIds = () => {
        if (ganttChartRef.current){
            const gantt = ganttChartRef.current;
            setTimeout(() => {
                // after individual task
                if (searchSelectedTaskIds && searchSelectedTaskIds.length > 0) {
                    gantt.selectedTaskIds = [...searchSelectedTaskIds,...selectedTaskIds];
                }
            }, 1000);

            setTimeout(() => {
                if (selectedTaskId && selectedTaskId>0){
                    gantt.selectTask(selectedTaskId);
                    gantt.ensureVisible(selectedTaskId);
                }
            }, 1000);
        }
    }
    const setChartStartAndEndDates = (tasks: TaskItem[]) => {
        const startDates = tasks.map(x => new Date(x.dateStart));
        const sortedStartDates = startDates.sort((a,b) => new Date(a).getTime() - new Date(b).getTime());
        const endDates = tasks.map(x => new Date(x.dateEnd));
        const sortedEndDates = endDates.sort((a,b) => new Date(a).getTime() - new Date(b).getTime());
        let startDate = sortedStartDates[0];
        let endDate = sortedEndDates[endDates.length-1];
        const timeDiffEnd = (new Date(endDate.getFullYear(),endDate.getMonth(),endDate.getDate())).getTime()-(new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate(),0,0,0)).getTime();
        const timeDiff = timeDiffEnd; 
        let diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        if (diffDays<600){
            endDate.setDate(startDate.getDate() + 600);
        }
        startDate.setDate(startDate.getDate() - 10);
        setChartDateStart(startDate);
        setChartDateEnd(endDate);
    }
    return {
        startDraggingSchedule,
        endDraggingSchedule,
        planningColumns,
        getTotalItems,
        setModifiedTaskItem,
        applySelectedIds,
        statusAsString,
        setChartStartAndEndDates,
    }
}