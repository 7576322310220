import { IconButton, styled, Tooltip } from "@mui/material";
import { GridColumnHeaderParams, GridColumns, GridLinkOperator, GridRenderCellParams } from "@mui/x-data-grid-pro";
// import { getImageByFileType } from "../../../utils/plmUtilities";
import { DocTypeName, DocumentCell, FileDate, FileFooterInfo, FileFooterRow, Filename, ImageItem, ImageWrapper } from "../DocumentCell";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { DocumentFile } from "../../../../Types/Plm/DocumentFile";
import { getImageByFileType } from "../../../../utils/plmUtilities";
import { useUploadGridEvents } from "./UploadGridEvents";
import { useUploadStore } from "../../../../Types/Plm/UploadStore";

export const GridHeaderTitle = styled('div')({
    backgroundColor: '#fff',
    height: '64px',
    fontSize: '1em',
    lineHeight: '64px',
    fontWeight: 600,
})

export const ImageWrapperSmall = styled('div')({
    height: '25px',
    width: '25px',
    padding: '10px 10px 10px 0',
    display: 'flex',
    flexFlow: 'row',
})

export const ImageItemSmall = styled('img')({
    flex: 1,
    height: '25px',
    width: '25px',
})

export const FileDetailWrapper = styled('div')({
    display: 'flex',
    flexFlow: 'column',
    '& .hide': {
        display: 'none',
    },
    width: '100%',
    paddingTop: '10px',
    lineHeight: '1.5rem',
    position: 'relative',
})

export const useUploadGridHelper = () => {
    const {searchDocumentText} = useUploadStore();
    const {onClickHandlerDownload} = useUploadGridEvents();
    const gridColumns = [
        { field: 'id', hide: true },
        { field: 'referenceId', hide: true },
        { field: 'filename', hide: true },
        { field: 'filenameGuid', hide: true },
        { field: 'fileExt', hide: true },
        { field: 'url', hide: true },
        { field: 'documentCode', hide: true },
        { field: 'documentCodeDesc', hide: true },
        { field: 'collectionId', hide: true },
        { field: 'collectionCode', hide: true },
        { field: 'collectionName', hide: true },
        { field: 'styleId', hide: true },
        { field: 'styleCode', hide: true },
        { field: 'styleName', hide: true },
        { field: 'sku', hide: true },
        { field: 'productId', hide: true },
        { field: 'productName', hide: true },
        { field: 'createdBy', hide: true },
        { field: 'createdDateStr', hide: true },
        {
            field: '',
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <GridHeaderTitle>Files</GridHeaderTitle>
                )
            },
            headerClassName: 'document-grid-header',
            flex: 1,
            style: {},
            sortable: false,
            resizable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <>
                        <DocumentCell
                            file={{
                                id: parseInt(params.getValue(params.id, 'id')?.toString() ?? '0'),
                                referenceId: parseInt(params.getValue(params.id, 'referenceId')?.toString() ?? '0'),
                                filename: params.getValue(params.id, 'filename')?.toString() ?? '',
                                filenameGuid: params.getValue(params.id, 'filenameGuid')?.toString() ?? '',
                                fileExt: params.getValue(params.id, 'fileExt')?.toString() ?? '',
                                url: params.getValue(params.id, 'url')?.toString() ?? '',
                                documentCode: params.getValue(params.id, 'documentCode')?.toString() ?? '',
                                documentCodeDesc: params.getValue(params.id, 'documentCodeDesc')?.toString() ?? '',
                                collectionId: parseInt(params.getValue(params.id, 'collectionId')?.toString() ?? '0'),
                                collectionCode: parseInt(params.getValue(params.id, 'collectionCode')?.toString() ?? '0'),
                                collectionName: params.getValue(params.id, 'collectionName')?.toString(),
                                styleId: parseInt(params.getValue(params.id, 'styleId')?.toString() ?? '0'),
                                styleCode: params.getValue(params.id, 'styleCode')?.toString(),
                                styleName: params.getValue(params.id, 'styleName')?.toString(),
                                sku: params.getValue(params.id, 'sku')?.toString(),
                                productId: parseInt(params.getValue(params.id, 'productId')?.toString() ?? '0'),
                                productName: params.getValue(params.id, 'productName')?.toString(),
                                createdBy: params.getValue(params.id, 'createdBy')?.toString() ?? '',
                                createdDateStr: params.getValue(params.id, 'createdDateStr')?.toString() ?? '',
                                isImportant: params.getValue(params.id, 'isImportant') ?? false,
                            } as DocumentFile}
                        />
                    </>
                )
            }
        },
    ] as GridColumns;
    const renderFilenameDetail = (filename: string, createdBy: string, createdDate: string, docTypeDesc: string) => {
        return (
            <FileDetailWrapper>
                <Filename>{filename}</Filename>
                <FileFooterInfo>
                    <FileFooterRow>
                        <FileDate>
                            {`${createdDate} - ${createdBy}`}
                        </FileDate>
                        {/* <Grow />
                        <DocTypeName>
                            {docTypeDesc}
                        </DocTypeName> */}
                    </FileFooterRow>
                </FileFooterInfo>
            </FileDetailWrapper>
        )
    }
    const uploadGridColumns = [
        { field: 'id', hide: true },
        {
            field: ' ',
            width: 100,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <>
                        <Tooltip title="Download">
                            <IconButton
                                aria-label="download"
                                style={{ color: '#1976d2', height: '40px', width: '40px' }}
                            onClick={() => onClickHandlerDownload(params.getValue(params.id,'url')?.toString() ?? '',
                              params.getValue(params.id,'filename')?.toString() ?? '')}
                            >
                                <DownloadOutlinedIcon />
                            </IconButton>
                        </Tooltip>&nbsp;&nbsp;
                        <ImageWrapperSmall key={parseInt(params.getValue(params.id, 'id')?.toString() ?? '0')}>
                            <ImageItemSmall src={getImageByFileType(params.getValue(params.id, 'fileExt')?.toString() ?? '', params.getValue(params.id, 'url')?.toString() ?? '')} />
                        </ImageWrapperSmall>

                    </>
                )
            }
        },
        {
            field: 'File',
            width: 700,
            renderCell: (params: GridRenderCellParams) => {
                return renderFilenameDetail(params.getValue(params.id, 'filename')?.toString() ?? '',
                    params.getValue(params.id, 'createdBy')?.toString() ?? '',
                    params.getValue(params.id, 'createdDateStr')?.toString() ?? '',
                    params.getValue(params.id, 'documentCodeDesc')?.toString() ?? '');
            }
        },
        { field: 'filename', hide: true },
        { field: 'documentCodeDesc', hide: true },
        { field: 'createdBy', hide: true },
        { field: 'createdDateStr', hide: true },
    ] as GridColumns;
    const GridFilterModel = {
        items: [
            { id: 2, columnField: 'filename', operatorValue: 'contains', value: searchDocumentText },
            { id: 3, columnField: 'documentCodeDesc', operatorValue: 'contains', value: searchDocumentText },
            { id: 4, columnField: 'createdBy', operatorValue: 'contains', value: searchDocumentText },
            { id: 5, columnField: 'createdDateStr', operatorValue: 'contains', value: searchDocumentText },
        ],
        linkOperator: GridLinkOperator.Or
    }
    return {
        gridColumns,
        uploadGridColumns,
        GridFilterModel,
    }
}