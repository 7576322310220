import { constants } from '../../config/settings';
import { getLocaleLanguage } from '../../utils/utilities';

export const getBOMExport = async (token,itemCode) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );

    const options = {
        method: "GET",
        headers,
    };

    const res = await window.fetch(`${constants.getInventorIntegrationWebUrl()}${constants.inventorGetBOMExport}?itemCode=${itemCode}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}

export const setBOMExport = async (bom) => {
    const headers = new Headers();
    const bearer = `Bearer ${"5BD55231-6707-42E3-A2BA-B57E8032B7A8"}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );
    headers.append("Content-Type","application/json");

    const options = {
        method: "POST",
        headers,
        body: JSON.stringify(bom),
    };

    const res = await window.fetch(`${constants.getInventorIntegrationWebUrl()}${constants.inventorSetBOMExport}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}


export const saveBOMExport = async (token,itemCode) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );
    headers.append("Content-Type","application/json");

    const options = {
        method: "GET",
        headers,        
    };

    const res = await window.fetch(`${constants.getInventorIntegrationWebUrl()}${constants.inventorSaveBOMExport}?itemCode=${itemCode}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}

export const removeBOMExport = async (token,itemCode) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );
    headers.append("Content-Type","application/json");

    const options = {
        method: "GET",
        headers,        
    };

    const res = await window.fetch(`${constants.getInventorIntegrationWebUrl()}${constants.inventorRemoveBOMExport}?itemCode=${itemCode}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}