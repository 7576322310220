import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import {FC} from "react"
import { useProdFilterStore } from "../../../../store/ProdFilterStore";
import { useProductionPlanningStore } from "../../../../store/ProductionPlanningStore";
import { useExpandCollapseRadioGroupEvents } from "./hooks/ExpandCollapseRadioGroupEvents";
import { StyledFormControl } from "./styles/sharedStyles";

export const ExpandCollapseRadioGroup:FC = () => {
    const {expandCollapseRows} = useProdFilterStore();
    const {onChangeHandlerExpandCollapse} = useExpandCollapseRadioGroupEvents();
    const {loadingProductionPlannings} = useProductionPlanningStore();

    return (        
        <StyledFormControl>
            <RadioGroup
                aria-labelledby="status-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={expandCollapseRows}
                onChange={onChangeHandlerExpandCollapse}
            >
                <FormControlLabel value="expanded" control={<Radio inputProps={{ 'aria-label': 'Expanded All' }} sx={{
                    '& .MuiSvgIcon-root': {
                        fontSize: 12,
                    },
                }} />} label="Expanded All" />
                <FormControlLabel value="collapsed" control={<Radio inputProps={{ 'aria-label': 'Collapsed All' }} sx={{
                    '& .MuiSvgIcon-root': {
                        fontSize: 12,
                    },
                }} />} label="Collapsed All" />
            </RadioGroup>
        </StyledFormControl>
    )
}