import { ChangeEvent } from "react";
import { useProdFilterStore } from "../../../../../store/ProdFilterStore";
import { useProdPlanningFilterDrawerStore } from "../../../../../store/ProdPlanningFilterDrawerStore";
import { useProductionPlanningStore } from "../../../../../store/ProductionPlanningStore";
import { useSearchHelper } from "./SearchHelper";

export const useSearchEvents = () => {
    const {searchProd} = useSearchHelper();    
    const {allTaskItems,setResetGanttChart, filteredTaskItems} = useProductionPlanningStore();
    const {searchText, setSearchText, expandCollapseRows} = useProdFilterStore();
    const {setShowRelated, showRelated,} = useProdPlanningFilterDrawerStore();
    
    const onChangeHandlerSearch = (event: ChangeEvent<HTMLInputElement>) => {
        // const {value} = event.target;
        // setSearchText(event.target.value.toLowerCase());
        // ignore this event
    }
    const onKeyUpHandlerSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === "Enter" || event.nativeEvent.code === "Enter" || event.code === "NumpadEnter" || event.nativeEvent.code === "NumpadEnter"){
            const _input = event.target as HTMLInputElement,
                  _value = _input.value?.toLowerCase() ?? '';
            
                // set to default
                if (_value && _value.length === 0) { 
                    setShowRelated(true);
                }

                setSearchText(_value);
                
                setResetGanttChart(true);
                if (filteredTaskItems.length>0)
                    searchProd(filteredTaskItems.map(item =>{return {...item, expanded:(expandCollapseRows === 'expanded') ? true: false}}),_value,showRelated ?? false);                    
                else
                    searchProd(allTaskItems.map(item =>{return {...item, expanded:(expandCollapseRows === 'expanded') ? true: false}}),_value,showRelated ?? false);
        }
    }

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {        

        setTimeout(()=>{
            setShowRelated(checked);
        }, 200);
        
        if (searchText.length > 0) {
            setResetGanttChart(true);
            if (filteredTaskItems.length>0)
                searchProd(filteredTaskItems.map(item =>{return {...item, expanded:(expandCollapseRows === 'expanded') ? true: false}}),searchText, checked);
            else
                searchProd(allTaskItems.map(item =>{return {...item, expanded:(expandCollapseRows === 'expanded') ? true: false}}),searchText, checked);
        }
    }
    return {
        onChangeHandlerSearch,
        onKeyUpHandlerSearch,
        onChangeHandler
    }
}