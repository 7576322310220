import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getUserDatabases } from "../../services/userDatabase";
import { useBomUploadStore } from "../../store/BomUploadStore";
import { UrmUserDatabase } from "../../Types/UserService/UrmUserDatabase";
import { BomFileGrid } from "./grid/BomFileGrid";
import { BomHeaderGrid } from "./grid/BomHeaderGrid";
import { LeftBody, LeftGrid, RightBody, RightGrid, Separator, UploadContainerBody, UploadContianerRoot } from "./styles/SharedStyles";
import { TabDetail } from "./TabDetail";
import { BomFileToolbar } from "./toolbars/BomFileToolbar";
import { BomHeaderToolbar } from "./toolbars/BomHeaderToolbar";
import { UploadingProgressToolbar } from "./toolbars/UploadingProgressToolbar";

export const UploadBody = () => {
    const authContext = useContext(AuthContext);
    const { userDatabases, setUserDatabases, uploadingBOM} = useBomUploadStore();
    useEffect(() => {
        getUserDatabases(authContext?.auth.userToken,authContext?.auth.userEmail)
        .then((data: UrmUserDatabase[]) => {
            setUserDatabases(data);
        });
    },[])
    return (
        <>
            {userDatabases && userDatabases.length>0 && 
                <UploadContianerRoot>
                    <UploadContainerBody>
                        <LeftBody>
                            {uploadingBOM 
                                ? <UploadingProgressToolbar />
                                : <BomFileToolbar />}
                            <LeftGrid>
                                <BomFileGrid />
                            </LeftGrid>
                        </LeftBody>
                        <Separator />
                        <RightBody>
                            <BomHeaderToolbar />
                            <BomHeaderGrid />
                            <RightGrid>
                                <TabDetail />
                            </RightGrid>
                        </RightBody>
                    </UploadContainerBody>
                </UploadContianerRoot>}
        </>
    )
}