import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (window.location.origin === 'https://arda-vanthiel.azurewebsites.net')
  window.location.href = window.location.href.replace('https://arda-vanthiel.azurewebsites.net','https://arda.vanthiel.com');
if (window.location.origin === 'https://arda-vanthiel-dev.azurewebsites.net')
  window.location.href = window.location.href.replace('https://arda-vanthiel-dev.azurewebsites.net','https://dev-arda.vanthiel.com');

const params = new URLSearchParams(window.location.search)
sessionStorage.setItem('localhost',params.getAll('localhost').toString());

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
