import { useEffect } from "react"
import { setHeight } from "../../../utils/utilities";
import { ReportContainer } from "../styles/Reports"

export const BOMOverview = () => {
    useEffect(() => {
        setHeight({target: '.report-viewer', lessPx: 45});
    },[])
    return (
        <>
            <ReportContainer className="report-viewer">
                <iframe 
                    width="98%" 
                    height="100%" 
                    src="https://app.powerbi.com/reportEmbed?reportId=2e8e55de-a930-47a1-ba6b-801f778bc1c0&autoAuth=true&ctid=615b1736-361b-480f-950c-b3d6ff7b952d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWVhc3QtYXNpYS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" 
                    //src="https://app.powerbi.com/reportEmbed?reportId=9cec03df-f5d0-433d-93d0-4a2233b9e962&autoAuth=true&ctid=615b1736-361b-480f-950c-b3d6ff7b952d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWVhc3QtYXNpYS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D"
                    allowFullScreen
                    frameBorder="0" />
            </ReportContainer>
        </>
    )
}