import { useBomUploadStore } from "../../../store/BomUploadStore";

export const useDataSearch = () => {
    const { setBomStepRelations, allBomStepRelations, allBomHeaders,
            allBomFiles, setBomFiles, setBomHeaders, 
            setBomLines, allBomLines } = useBomUploadStore();

    function searchBomFiles (searchText: string){
        setBomFiles(allBomFiles.filter(bom => 
            ((bom.fileName ?? '').toString().toLowerCase().indexOf(searchText) > -1) ||
            ((bom.uploadBy ?? '').toString().toLowerCase().indexOf(searchText) > -1) ||
            ((bom.uploadDate ?? '').toString().toLowerCase().indexOf(searchText) > -1) ||
            ((bom.database ?? '').toString().toLowerCase().indexOf(searchText) > -1) ||
            ((bom.status ?? '').toString().toLowerCase().indexOf(searchText) > -1)
        ));
    };
    function searchBomFilesByIds (fileIds: number[]){
        setBomFiles(allBomFiles.filter(bom => 
            fileIds.indexOf(bom.id) > -1
        ));
    }
        
    function filterBomHeaders (searchText: string) {
        return allBomHeaders.filter(bom => 
            ((bom.itemCode?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.costCenter?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.description?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.drawingNo?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.effectiveDate?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.effectiveDateStr?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.expiredDate?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.expiredDateStr?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.leadTime?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.mainVersion?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.quantity?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) || 
            ((bom.version?.toLowerCase() ?? '').indexOf(searchText) > -1) || 
            //((bom.uploadDate?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) || 
            //((bom.uploadBy?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            //((bom.fileName?.toLowerCase() ?? '').indexOf(searchText) > -1) || 
            ((bom.status?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) 
        );
    }
    function searchBomHeaders(searchText: string){
        setBomHeaders(filterBomHeaders(searchText));
    }
    function searchBomHeadersAndOthers(searchText: string){
        const bomHeaders = filterBomHeaders(searchText);
        const bomHeaderIds = bomHeaders.map(x => x.id);
        const bomFileIds = bomHeaders.map(x => x.fileID);
        setBomHeaders(bomHeaders);
        // search also in BomFile, BomLine, BomStepRelation
        searchBomFilesByIds(bomFileIds);
        searchBomLinesByBomHeaderIds(bomHeaderIds);
        searchBomStepRelationsByBomHeaderIds(bomHeaderIds);
    }
    function searchBomHeadersByBomHeaderIds (bomHeaderIds: number[]){
        setBomHeaders(allBomHeaders.filter(bom => 
            bomHeaderIds.indexOf(bom.id) > -1
        ));
    }

    function filterBomLines (searchText: string) {
        return allBomLines.filter(bom => 
            ((bom.recipeLog?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.sequenceNo?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.itemProd?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.bomVersion?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.version?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.step?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.operation?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.lineType?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.itemReq?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.quantity?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.warehouse?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.costCenter?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.backflush?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.byProduct?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.fromDay?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.toDay?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.planAutomatically?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.position?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.resourceID?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.variant?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((bom.machine?.toLowerCase() ?? '').indexOf(searchText) > -1)
            )
    }
    function searchBomLines (searchText: string) {
        setBomLines(filterBomLines(searchText));

    }
    function searchBomLinesAndOthers(searchText: string){
        const bomLines = filterBomLines(searchText);
        setBomLines(bomLines);
        // get ids
        const bomHeaderIds = bomLines.map(x => x.headerID)
        const bomHeaders = allBomHeaders.filter(x => bomHeaderIds.indexOf(x.id) > -1);
        const bomFileIds = bomHeaders.map(x => x.fileID);
        // then apply the search
        searchBomFilesByIds(bomFileIds);
        searchBomHeadersByBomHeaderIds(bomHeaderIds);
    }
    function searchBomLinesByBomHeaderIds (bomHeaderIds: number[]) {
        setBomLines(allBomLines.filter(bom => 
            bomHeaderIds.indexOf(bom.headerID) > -1
        ));
    }

    function filterBomStepRelations (searchText: string) {
        return allBomStepRelations.filter(step => 
            ((step.itemCode?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((step.version?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((step.step?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((step.predecessor?.toString()?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((step.dependecyType?.toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((step.lag?.toString().toLowerCase() ?? '').indexOf(searchText) > -1) ||
            ((step.lagType?.toLowerCase() ?? '').indexOf(searchText) > -1)
        )
    }
    function searchBomStepRelations (searchText: string){
        setBomStepRelations(filterBomStepRelations(searchText));
    }
    function searchBomStepRelationsAndOthers (searchText: string){
        const bomStepRelations = filterBomStepRelations(searchText);
        setBomStepRelations(bomStepRelations);
        // get Ids
        const bomHeaderIds = bomStepRelations.map(x => x.headerID)
        const bomHeaders = allBomHeaders.filter(x => bomHeaderIds.indexOf(x.id) > -1);
        const bomFileIds = bomHeaders.map(x => x.fileID);
        // search also in BomFile and BomHeader
        searchBomFilesByIds(bomFileIds);
        searchBomHeadersByBomHeaderIds(bomHeaderIds);
    }
    function searchBomStepRelationsByBomHeaderIds (bomHeaderIds: number[]) {
        setBomStepRelations(allBomStepRelations.filter(bom => 
            bomHeaderIds.indexOf(bom.headerID) > -1
        ));
    }
    function searchAllGrids (searchText: string) {
        searchBomFiles(searchText);
        searchBomHeaders(searchText);
        searchBomLines(searchText);
        searchBomStepRelations(searchText);
    }

    return {
        searchBomFiles,
        searchBomFilesByIds,

        searchBomHeaders,
        searchBomHeadersByBomHeaderIds,
        searchBomHeadersAndOthers,

        searchBomLines,
        searchBomLinesByBomHeaderIds,
        searchBomLinesAndOthers,

        searchBomStepRelations,
        searchBomStepRelationsByBomHeaderIds,
        searchBomStepRelationsAndOthers,

        searchAllGrids
        
   }
}

