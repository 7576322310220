import { useFilterDrawerStore } from "../../../../../store/FilterDrawerStore";
import { useProductionPlanningStore } from "../../../../../store/ProductionPlanningStore";

export const useFilterDrawerIconEvents = () => {
    const {setOpenFilterDrawer} = useFilterDrawerStore();
    const {setExpandOrCollapseAll} = useProductionPlanningStore();
    const onClickHandlerFilterDrawer = () => {        
        setOpenFilterDrawer(true);
        setExpandOrCollapseAll(false);
    }
    return {
        onClickHandlerFilterDrawer,
    }
}