import { useRefreshDataHelpers } from "./RefreshDataHelpers"

export const useRefreshDataEvents = () => {
    const {clearCacheRequest} = useRefreshDataHelpers();
    
    const onClickHandlerRefresh = () => {
        clearCacheRequest();
    }
    return {
        onClickHandlerRefresh
    }
}