import { styled } from "@mui/material"
import { IWindowSizeProps } from "../hooks/interfaces/IWindowSizeProps"
import { useWindowResize } from "../hooks/helpers/WindowResize"
import { PreviewBOMExportToobar } from "./toolbars/PreviewBOMExportToolbar"
import { PreviewBOMExportHeader } from "./header/PreviewBOMExportHeader"
import { useEffect } from "react"
import { useExportBOMStore } from "../../store/ExportBOMStore"
import { ILoadBOMExport, useInventorLoaders } from "./hooks/InventorLoaders"
import { PreviewBOMExportGrid } from "./grids/PreviewBOMExportGrid"
import {  useProductLoaders } from "./hooks/ProductLoaders"
import { useParams } from "react-router-dom"

export const PreviewBOMExportContainerRoot = styled('div')({
    padding: '20px',
    backgroundColor: '#f6f6f6',
})

export const PreviewBOMExportContainerWrapper = styled('div')({
    backgroundColor: '#fff',
    width: '100%',
    '& #productionPlanner .smart-timeline-cell[nonworking]': {
        backgroundColor: '#FFE799!important'
    }
})

export const PreviewBOMContainer = () => {
    const {itemCode} = useParams();
    const _main = document.querySelector<HTMLDivElement>('#root'),
          _width = `calc(${_main?.offsetWidth} - 270px)`;
    const {setItemCode,setIsPreviewMode,setGridHasRecords,setIsSaved} = useExportBOMStore();
    const {isProductDuplicateByItemCodeAndVersion} = useProductLoaders();
    const {loadBOMExport} = useInventorLoaders();
    const size = useWindowResize({
        xheight: {
            target: '.preview-bom-container',
            lessPx: 20,
        }
    } as IWindowSizeProps);
    useEffect(() => {
        setGridHasRecords(false);
        setIsSaved(false);
        if (itemCode){
            const itemCodeValue = itemCode.split('=');
            if (itemCodeValue.length>0){
                setItemCode(itemCodeValue[1]);
                loadBOMExport({
                    itemCode: itemCodeValue[1],
                    callback: (version?: string) =>{
                        isProductDuplicateByItemCodeAndVersion({itemCode: itemCodeValue[1],version})
                    }
                } as ILoadBOMExport);
                setIsPreviewMode(true);
            }
        }
    },[]);
    return (
        <PreviewBOMExportContainerRoot>
            <PreviewBOMExportContainerWrapper
                style={size ? {height: `${size.height}px`, width: `${_width}`} : {}}
                className='preview-bom-container'>
                <PreviewBOMExportToobar />
                <PreviewBOMExportHeader />
                <PreviewBOMExportGrid />
            </PreviewBOMExportContainerWrapper>
        </PreviewBOMExportContainerRoot>
    )
}


