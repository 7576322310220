import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from "react";

export interface ImportBomFilterContextData {
    searchText: string,
    setSearchText: Dispatch<SetStateAction<string>>,
}

export const ImportBomFilterDefaultValue: ImportBomFilterContextData = {
    searchText: '',
    setSearchText: () => {},
}

export const ImportBomFilterStore = createContext<ImportBomFilterContextData>(ImportBomFilterDefaultValue);

export const useImportBomFilterStore = () => useContext(ImportBomFilterStore);

export const ImportBomFilterProvider: FC = ({children}) => {
    const [searchText, setSearchText] = useState<string>('');
    return (
        <ImportBomFilterStore.Provider
            value={{
                searchText,
                setSearchText,
            }}>
            {children}
        </ImportBomFilterStore.Provider>
    )
}