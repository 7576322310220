import { Box, CircularProgress, Toolbar } from "@mui/material"
import { Icon, Icons, ProgressMessage, UploadAppBar } from "./styles/SharedStyles";
import { Grow } from "../styles/SharedStyles";

export const UploadingProgressToolbar = () => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <UploadAppBar 
                position="static" >
                <Toolbar variant="dense">
                    <Icons>
                        <Icon>
                            <CircularProgress style={{width: '30px', height: '30px', padding: '5px', marginBottom: '-5px'}} />
                        </Icon>
                    </Icons>
                    &nbsp;&nbsp;<ProgressMessage>Uploading data to Exact. Please wait a moment ..</ProgressMessage>
                    <Grow />
                </Toolbar>
            </UploadAppBar>
        </Box>
    )
}