import { useEffect, useState } from "react"
import { useProdPlanningFilterDrawerStore } from "../../../store/ProdPlanningFilterDrawerStore";
import { useProductionPlanningStore } from "../../../store/ProductionPlanningStore";
import { TaskItem } from "../../../Types/TaskItem";
import { IProdPlanningFilterOptions } from "../interfaces/IProdPlanningFilterOptions";
import { IProductionPlanningTriggers } from "../interfaces/IProductionPlanningTriggers";
import { useProductionPlanningLoaders } from "../loaders/ProductionPlanningLoaders";

export const useProductionPlanningTriggers = () => {
    const [productionPlanningTriggers, setProductionPlanningTriggers] = useState<IProductionPlanningTriggers | null>(null);
    const {loadProductionPlannings,loadFilteredProductionPlannings} = useProductionPlanningLoaders();
    const {setDataLoadedProductionPlanning} = useProductionPlanningStore();
    const {dateStartFrom,dateStartTo} = useProdPlanningFilterDrawerStore();

    const triggerLoadTaskSchedules = () => {
        setProductionPlanningTriggers({...productionPlanningTriggers, productionPlanning: false});
        loadProductionPlannings(productionPlanningTriggers);
    }
    const setTriggerLoadTaskSchedules = (callback?: () => void) => {
        setProductionPlanningTriggers({
            ...productionPlanningTriggers, 
            productionPlanning: true,
            callback,
        });
    }

    const triggerLoadFilteredTaskSchedules = () => {
        setProductionPlanningTriggers({...productionPlanningTriggers, 
                                        filteredProductionPlanning: false,
                                        filterOptions: {
                                            ...productionPlanningTriggers?.filterOptions,
                                            dateStartFrom: dateStartFrom ?? null,
                                            dateStartTo: dateStartTo ?? null,
                                        }});
        loadFilteredProductionPlannings(productionPlanningTriggers);
    }
    const setTriggerLoadFilteredTaskSchedules = (props: {
        searchText?: string, 
        callback?: (data: TaskItem[]) => void,
        filterOptions?: IProdPlanningFilterOptions | null | undefined}) => {
        const {searchText,callback,filterOptions} = props;
        setProductionPlanningTriggers({
            ...productionPlanningTriggers,
            filteredProductionPlanning: true,
            searchText,
            callback,
            filterOptions
        });
        setDataLoadedProductionPlanning(false);
    }
    const getTriggerLoadingFilteredTaskSchedules = (): IProductionPlanningTriggers | null => {
        return productionPlanningTriggers;
    }
    const setTriggerLoadedFilteredTaskSchedules = () => {
        setDataLoadedProductionPlanning(true);
    }

    useEffect(() => {
        if (productionPlanningTriggers) {
            if ((productionPlanningTriggers?.productionPlanning ?? false) === true)
                triggerLoadTaskSchedules();

            if ((productionPlanningTriggers?.filteredProductionPlanning ?? false) === true)
                triggerLoadFilteredTaskSchedules();
        }        
    },[productionPlanningTriggers])
    return {
        setTriggerLoadTaskSchedules,
        setTriggerLoadFilteredTaskSchedules,
        getTriggerLoadingFilteredTaskSchedules,
        setTriggerLoadedFilteredTaskSchedules,
    }
}