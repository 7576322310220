import { Paper } from "@material-ui/core";
import { styled } from "@mui/material";
import { FC, ReactNode, useEffect } from "react"
import { setHeight } from "../../../utils/utilities";

export interface IFilterInputFieldsProps {    
    children: ReactNode
}

export const FilterInputFieldsContainer = styled(Paper)({
    textAlign: 'left',
    padding: '20px 20px', 
    overflowY: 'auto',
    '& .MuiPaper-root': {
        height: '95%',
    },
    '& .filter-textbox-short': {
        minWidth: '210px!important',
        maxWidth: '210px!important',
        '& .MuiFormControl-root .MuiTextField-root': {
            marginLeft: 'unset!important',
        }
    },
})

export const FilterInputFields :FC<IFilterInputFieldsProps> = (props) => {
    const {children} = props;
    useEffect(() => {
        setHeight({target: '.filter-input-fields', lessPx: 62});
    },[])
    return (
        <FilterInputFieldsContainer 
            className="filter-input-fields"
            elevation={16}>
            {children}
        </FilterInputFieldsContainer>
    )
}