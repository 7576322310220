import { PopoverOrigin, Typography } from '@material-ui/core';
import { Button, Paper, styled } from '@mui/material';
import Popover from '@mui/material/Popover';
import { usePopoverBoxStore } from '../../store/PopoverBoxStore';

export const FooterButtons = styled('div')({
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
})

export const PopoverRoot = styled('div')({
    padding: '15px 10px 3px',
    margin: '10px 10px 3px',
})

export const PopoverBox = () => {
    const {popoverId,message,popoverOpen,popoverAnchorEl,onCloseHandler,okText,cancelText,
           onClickHandlerOk,onClickHandlerCancel} = usePopoverBoxStore();
    return (
        <Popover
            anchorOrigin={{vertical: 'bottom'} as PopoverOrigin}
            id={popoverId}
            open={popoverOpen}
            anchorEl={popoverAnchorEl}
            onClose={onCloseHandler}>
            <PopoverRoot>
                <Paper elevation={0}>
                    <Typography>{message}</Typography><br />
                    <hr/>
                    <FooterButtons>
                        <Button style={{marginRight: '20px'}} onClick={onClickHandlerOk}>{okText}</Button>
                        <Button onClick={onClickHandlerCancel}>{cancelText}</Button>
                    </FooterButtons>
                </Paper>
            </PopoverRoot>
        </Popover>
    )
}