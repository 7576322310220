import { AppBar, styled } from "@mui/material"
import { Search } from "../../hooks/ui/Search";
import { ToolbarRoot } from "./styles/SharedStyles";
import { useImportBomFilterStore } from "../../../store/ImportBomFilterStore";
import { Grow } from "../../styles/SharedStyles";
import { useExportBOMStore } from "../../../store/ExportBOMStore";
import { ExportBOMButton } from "./items/ExportBOMButton";
import { usePreviewBOMExportSearchEvents } from "./items/hooks/PreviewBOMExportSearchEvents";
import { RedirectToExportButton } from "./items/RedirectToExportButton";

export const ToolbarTitle = styled('div')({
    fontSize: '1.1em',
    padding: '0 20px 0 10px',
    color: '#524b4b',
})

export const PreviewBOMExportToobar = () => {
    const {onChangeHandlerSearch,onKeyUpHandlerSearch} = usePreviewBOMExportSearchEvents();
    const {searchText} = useImportBomFilterStore();
    const {isPreviewMode,isDuplicate,isSaved,gridHasRecords} = useExportBOMStore();
    return (
        <AppBar position="static">
            <ToolbarRoot variant="dense">
                <ToolbarTitle>eBOM Preview</ToolbarTitle>
                <Search 
                    value={searchText}
                    onChangeHandlerSearch={onChangeHandlerSearch}
                    onKeyUpHandlerSearch={onKeyUpHandlerSearch} 
                />
                <Grow />
                {isPreviewMode===true && 
                 isDuplicate!==true && 
                 isSaved!==true && 
                 gridHasRecords && <ExportBOMButton />}
                {/* hide redirect for now */}
                {false && isPreviewMode===true &&  <RedirectToExportButton />}
            </ToolbarRoot>
        </AppBar>
    )
}