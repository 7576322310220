import { Box, Toolbar } from "@mui/material"
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { GridTitle, SearchIconWrapper, SearchWrapper, StyledInputBase, UploadAppBar } from "./styles/SharedStyles";
import { Grow } from "../styles/SharedStyles";
import { ChangeEvent } from "react";
import { useDataSearch } from "../hooks/GridSearch";
import { useBomUploadStore } from "../../../store/BomUploadStore";

export const BomHeaderToolbar = () => {
    const { allBomHeaders, setBomHeaders, setLoadGrid, searchTextBomHeaders, 
            setSearchTextBomHeaders, setBomFiles, allBomFiles, setBomLines, allBomLines,
            setBomStepRelations, allBomStepRelations } = useBomUploadStore();
    const { searchBomHeadersAndOthers } = useDataSearch();
    const onChangeHandlerSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTextBomHeaders(event.target.value.toLowerCase());
    }
    const onKeyUpHandlerSearch = (event: React.KeyboardEvent<HTMLInputElement>): any => {
        if (event.code === "Enter" || 
            event.nativeEvent.code === "Enter" || 
            event.code === "NumpadEnter" || 
            event.nativeEvent.code === "NumpadEnter"){
            if (searchTextBomHeaders.length > 0){
                setLoadGrid(false);
                searchBomHeadersAndOthers(searchTextBomHeaders);
            }
        } else {
            if (searchTextBomHeaders === '') {
                setLoadGrid(true);
                setBomHeaders(allBomHeaders);
                setBomFiles(allBomFiles);
                setBomLines(allBomLines);
                setBomStepRelations(allBomStepRelations);
            }
        }
    }
    return (
        <Box sx={{ flexGrow: 1, maxHeight: '60px' }}>
            <UploadAppBar 
                position="static" >
                <Toolbar variant="dense">
                    <GridTitle>BOM HEADER</GridTitle>
                    <Grow />
                    <SearchWrapper>
                        <SearchIconWrapper>
                            <SearchOutlinedIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            onChange={onChangeHandlerSearch}
                            onKeyUp={onKeyUpHandlerSearch}
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </SearchWrapper>
                </Toolbar>
            </UploadAppBar>
        </Box>
    )
}