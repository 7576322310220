import { Button, Paper, styled } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useConfirmationBoxStore } from "../../../store/ConfirmationBoxStore";

export interface IConfirmationBoxProps {
    style?: {},
    isOpen?: boolean,
    children: React.ReactNode,
}


export const ConfirmationBoxRoot = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: '#fff',
    zIndex: 9999,
    minWidth: '200px',
    height: '100%',
    '& .MuiPaper-root': {
        height: 'unset!important',
    }
})

export const ConfirmationBox : FC<IConfirmationBoxProps> = (props) => {
    const {style,children,isOpen} = props;
    const {confirmationBoxOpen} = useConfirmationBoxStore();
    const [xstyle,setXStyle] = useState({display: 'none'});
    useEffect(() => {
        if (confirmationBoxOpen === false || isOpen === false){
            setXStyle({...style,display: 'none'});
        } else {
            setXStyle({...style,display: 'unset'});
        }
    },[confirmationBoxOpen])
    return (
        <ConfirmationBoxRoot style={xstyle}>
            <Paper style={{backgroundColor:'#fff', padding: '10px'}} elevation={3}>
                {children}
            </Paper>
        </ConfirmationBoxRoot>
    )
}

