import { AppBar } from "@mui/material"
import { useState } from "react";
import { useProdFilterStore } from "../../../store/ProdFilterStore";
import { useProductionPlanningStore } from "../../../store/ProductionPlanningStore";
import { Search } from "../../hooks/ui/Search";
import { Grow } from "../../styles/SharedStyles";
import { ExpandCollapseRadioGroup } from "../ui/RadioGroup/ExpandCollapseRadioGroup";
import { useProductionPlanningToolbarEvents } from "./hooks/ProductionPlanningToolbarEvents";
import { GanttViewMode } from "./items/GanttViewMode";
import { useSearchEvents } from "./items/hooks/SearchEvents";
import { IsResizingProgress } from "./items/IsResizingProgress";
import { SaveCancel } from "./items/SaveCancel";
import { FilterDrawerIcon } from "./items/FilterDrawerIcon";
import { ToolbarRoot } from "./styles/SharedStyles";
import { ProdPlanningFilterDrawer } from "../filters/FilterDrawers/ProdPlanningFilterDrawer";
import { GridChartRadioGroup } from "../ui/RadioGroup/GridChartRadioGroup";
import { RefreshData } from "./items/RefreshData";
import { TargetDBRadioGroup } from "../ui/RadioGroup/TargetDBRadioGroup";
import CircularProgress from '@mui/material/CircularProgress';

export const ProductionPlanningToolbar = () => {
    const [, setSelectionOption] = useState<string>('month');
    const {planningOptionType, viewOption,loadingProductionPlannings} = useProductionPlanningStore();
    const {onChangeHandlerPlanningOption} = useProductionPlanningToolbarEvents({setSelectionOption});
    const {onChangeHandlerSearch,onKeyUpHandlerSearch, onChangeHandler} = useSearchEvents();
    const {searchText} = useProdFilterStore();
    const _selectionOption = planningOptionType?.option ?? "month";

    return (
        <AppBar position="static">
            <ToolbarRoot variant="dense" style={{minHeight:"60.37px"}}>
                <IsResizingProgress />
                <SaveCancel />
                <Search value={searchText}
                    // checkBox={{
                    //     id:"showRelatedCheckbox",
                    //     onChange: onChangeHandler
                    // }}
                    onChangeHandlerSearch={onChangeHandlerSearch}
                    onKeyUpHandlerSearch={onKeyUpHandlerSearch} />
                {loadingProductionPlannings ?
                 <div style={{marginLeft: "20px"}}><CircularProgress size={30} disableShrink /></div>
                : <>
                    <ExpandCollapseRadioGroup />
                    <GridChartRadioGroup />
                    <TargetDBRadioGroup />
                </>}
                {/* <ProdPlanningFilter
                    onClickHandlerRefresh={onClickHandlerFilterRefresh}>
                    <div style={{marginTop: '15px', fontSize: '.8em'}}>Start Date : </div><FromToDateRangePicker />
                </ProdPlanningFilter> */}
                <Grow />
                {viewOption === "chart" &&
                    <GanttViewMode 
                    selectionOption={_selectionOption}
                    onChangeHandlerPlanningOption={onChangeHandlerPlanningOption} />
                }
                {loadingProductionPlannings!==true && <RefreshData />}
                {loadingProductionPlannings!==true && <FilterDrawerIcon />}
            </ToolbarRoot>
            <ProdPlanningFilterDrawer />
        </AppBar>
    )
}