import { Box, Tab, Tabs, Typography } from "@mui/material"
import { ChangeEvent, useState } from "react";
import { BomLineGrid } from "./grid/BomLineGrid";
import { BomStepsRelationGrid } from "./grid/BomStepsRelationGrid";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { SearchIconWrapper, SearchWrapper, StyledInputBase, TabBox } from "./toolbars/styles/SharedStyles";
import { Grow } from "./styles/SharedStyles";
import { useDataSearch } from "./hooks/GridSearch";
import { useBomUploadStore } from "../../store/BomUploadStore";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

function a11yProps(index: number) {
    return {
      id: `bomline-detail-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const TabDetail = () => {
    const [value, setValue] = useState(0);
    const {allBomStepRelations, setBomStepRelations, setBomLines, allBomLines, setLoadGrid,
           searchTextBomDetails, setSearchTextBomDetails, setBomHeaders, setBomFiles, allBomHeaders, allBomFiles  } = useBomUploadStore();
    const { searchBomStepRelations, searchBomLinesAndOthers } = useDataSearch();
    //const classes = useStyles();
    const onChangeHandlerTabs = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
      };
    const onChangeHandlerSearch = (event: ChangeEvent<HTMLInputElement>) => {
      setSearchTextBomDetails(event.target.value.toLowerCase());
    }
    const onKeyUpHandlerSearch = (event: React.KeyboardEvent<HTMLInputElement>): any => {
      if (event.code === "Enter" || 
          event.nativeEvent.code === "Enter" || 
          event.code === "NumpadEnter" || 
          event.nativeEvent.code === "NumpadEnter"){
          if (searchTextBomDetails.length > 0){
              setLoadGrid(false);
              searchBomLinesAndOthers(searchTextBomDetails);
              searchBomStepRelations(searchTextBomDetails);
          }
      } else {
          if (searchTextBomDetails === '') {
              setLoadGrid(true);
              setBomLines(allBomLines);
              setBomHeaders(allBomHeaders);
              setBomFiles(allBomFiles);
              setBomStepRelations(allBomStepRelations);
          }
      }
  }
    return (
        <TabBox>
            <Box sx={{display: 'flex', borderBottom: 1, borderColor: 'divider'}}>
                <Tabs className="tab-details" value={value} onChange={onChangeHandlerTabs} aria-label="tab details">
                    <Tab style={{margin: 0,padding: '0 10px 0', }} label="Bom Lines" {...a11yProps(0)} />
                    <Tab style={{margin: 0,padding: '0 10px 0', }} label="Steps Relation" {...a11yProps(1)} />
                </Tabs>
                <Grow />
                <SearchWrapper style={{height:'40px',marginTop:'3px', marginRight: '24px'}}>
                    <SearchIconWrapper>
                        <SearchOutlinedIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        onChange={onChangeHandlerSearch}
                        onKeyUp={onKeyUpHandlerSearch}
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </SearchWrapper>
            </Box>
            <TabPanel value={value} index={0}>
                <BomLineGrid />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <BomStepsRelationGrid />
            </TabPanel>
        </TabBox>
    )
}