import { useContext } from "react";
import { constants } from "../../../config/settings";
import { AuthContext } from "../../../context/AuthContext";
import { ResultOptions } from "../../../Types/ResultOptions";
import { uploadStyleDoc } from "../../../services/plm/styleDoc";
import { useUploadStore } from "../../../Types/Plm/UploadStore";

export const useUploadToolbarHelpers = () => {
    const authContext = useContext(AuthContext);
    const { setIsReadonly, currentComponent } = useUploadStore();
    function uploadSPOQCReports(
        token: string,
        file: File,
        callback: (data: ResultOptions) => void,
        errCallback: (err: any) => void) {
        uploadStyleDoc(token, file, currentComponent)
            .then((data: ResultOptions) => {
                if (callback)
                    callback(data);
            }).catch((err: any) => {
                if (errCallback)
                    errCallback(err);
            });
    }
    const checkIfReadonly = (componentCode: string) => {
        const roleAccessRights = authContext?.roleAccess ?? [];
        setIsReadonly(false);
        Array.from(roleAccessRights).forEach((access, index) => {
            if (access.componentCode === componentCode &&
                access.actionCode === constants.accessRightsActions.readonly) {
                setIsReadonly(true);
            }
        })
    }
    return {
        uploadSPOQCReports,
        checkIfReadonly
    }
}