import { styled } from "@mui/material"
import dayjs from "dayjs"
import { useContext, useEffect } from "react"
import { IWindowSizeProps } from "../../hooks/utilities/interfaces/IWindowSizeProps"
import { useWindowResize } from "../../hooks/utilities/WindowResize"
import { useProdPlanningFilterDrawerStore } from "../../store/ProdPlanningFilterDrawerStore"
import { useProductionPlanningStore } from "../../store/ProductionPlanningStore"
import { ProductPlanningScheduleContainer } from "./charts/ProductPlanningScheduleContainer"
import { ProductionPlanningToolbar } from "./toolbar/ProductionPlanningToolbar"
import { getUserDatabases } from "../../services/userDatabase";
import { UrmUserDatabase } from "../../Types/UserService/UrmUserDatabase";
import { AuthContext } from "../../context/AuthContext";

export const ProductionPlanningRoot = styled('div')({
    padding: '20px',
    backgroundColor: '#f6f6f6',
})

export const ProductionPlanningWrapper = styled('div')({
    backgroundColor: '#fff',
    width: '100%',
    '& #productionPlanner .smart-timeline-cell[nonworking]': {
        backgroundColor: '#FFE799!important'
    }
})

export const ProductionPlanningBody = styled('div')({
    display: 'flex',
    flexFlow: 'row',
    width: '100%',
    //justifyContent: 'space-between'
})

export const ProductionPlanningLeft = styled('div')({
    
})

export const ProductionPlanningRight = styled('div')({
    
})

export const ProductionPlanningContainer = () => {
    const authContext = useContext(AuthContext);
    const {setSelectedTaskIds,setProductionOrders,
        setSelectedTaskId,setGanttChartRef,setRefreshChart, setUserDatabases} = useProductionPlanningStore();
    const {setDateStartFrom,setDateStartTo,dateStartFrom,dateStartTo} = useProdPlanningFilterDrawerStore();
    const _main = document.querySelector<HTMLDivElement>('#root'),
        _width = `calc(${_main?.offsetWidth} - 270px)`;

    const size = useWindowResize({
        xheight: {
            target: '.production-planning',
            lessPx: 20,
        }
    } as IWindowSizeProps);
    useEffect(() => {
        // set filter dates
        // date from : 90 days ago
        // date to : current date
        if (dateStartFrom === null && dateStartTo === null){
            const fromDateRange = dateStartFrom ? dayjs(dateStartFrom).toDate() : dayjs(new Date()).subtract(90, 'days').toDate();
            const fromDate = dayjs(new Date(fromDateRange.getFullYear(),fromDateRange.getMonth(), fromDateRange.getDate()));
            const toDateRange = dateStartTo ? dayjs(dateStartTo).toDate() : dayjs(new Date()).add(30, 'days').toDate();
            if (!dateStartTo)
                toDateRange.setDate(toDateRange.getDate());
            const toDate = dayjs(new Date(toDateRange.getFullYear(),toDateRange.getMonth(), toDateRange.getDate()));
            setTimeout(() => {
                setDateStartFrom(fromDate);
                setDateStartTo(toDate);
            },100);
        }

        // initial load is true
        //setEnableSnapToNearest(true);
        setSelectedTaskIds([]);
        setProductionOrders([]);
        setGanttChartRef(undefined);
        setSelectedTaskId(0);
        setRefreshChart(false);
        //
        getUserDatabases(authContext?.auth.userToken,authContext?.auth.userEmail)
        .then((data: UrmUserDatabase[]) => {
            setUserDatabases(data);
        });
    },[])
    return (
        <ProductionPlanningRoot>
            <ProductionPlanningWrapper 
                style={size ? {height: `${size.height}px`, width: `${_width}`} : {}} 
                className='production-planning'>
                <ProductionPlanningToolbar/>
                <ProductionPlanningBody>
                    <ProductPlanningScheduleContainer />
                </ProductionPlanningBody>
            </ProductionPlanningWrapper>
        </ProductionPlanningRoot>
    )
}