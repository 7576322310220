import { SelectChangeEvent } from "@mui/material";
import { useProdPagerStore } from "../../../../store/ProdPagerStore";
import { useProductionPlanningStore } from "../../../../store/ProductionPlanningStore";

export const useProdPagerEvents = () => {
    const {setCurrentPage,setRefreshPage,setPageSize,setResetPages,setRefreshPage2} = useProdPagerStore();
    const {setTaskItems,allTaskItems} = useProductionPlanningStore();
    const onChangeHandlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
        setRefreshPage(true);
        setRefreshPage2(true);
    }
    const onChangeHandlerPageSize = (event: SelectChangeEvent) => {
        setPageSize(parseInt(event.target.value));
        setTaskItems(structuredClone(allTaskItems));
        setResetPages(true);
    }
    return {
        onChangeHandlerPager,
        onChangeHandlerPageSize,
    }
}