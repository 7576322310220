import { constants } from '../../config/settings';
import { plm } from '../../config/plmConfig';
import { getLocaleLanguage } from '../../utils/utilities';

export const getAllStyleDocFiles = async (token, docCode) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage);

    const options = {
        method: "GET",
        headers: headers,
    };
    const res = await window.fetch(`${constants.getPLMWebUrl()}${plm.getEcnFiles}?docCode=${docCode}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        console.log('error info', await res.json());
        return Promise.reject(res);
    }
}

export const uploadStyleDoc = async (token, files, docCode) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage);

    const formData = new FormData();
    formData.append('documents', files);
    formData.append('documentcode', docCode);

    const options = {
        method: "POST",
        headers: headers,
        body: formData
    };

    const res = await window.fetch(`${constants.getPLMWebUrl()}${plm.uploadStyleDoc}`, options);
    if (res.ok) {
        const data = await res.json();
        return data;
    } else {
        console.log('error info', await res.json());
        return Promise.reject(res);
    }
}
