import { constants } from "../config/settings";

export const getImageByFileType = (fileExt: string, url: string) => {
    var mimeTypeIndex =  Object.keys(constants.documentThumbnail).findIndex(x => x.toLowerCase() === fileExt.replace(".", "").toLowerCase());

    var mimeTypeValue = Object.values(constants.documentThumbnail)[mimeTypeIndex];   
    if (constants.imageExtensions.findIndex(x => x.toLowerCase().trim() === fileExt.toLowerCase().trim()) > -1) {     
        return url;
    }
    return (typeof(mimeTypeValue) !== 'undefined' ? mimeTypeValue : './images/default.png');
}

