import { alpha, AppBar, Box, Button, IconButton, InputBase, styled, Toolbar } from "@mui/material";

export const TabBox = styled(Box)({
  width: '100%',
  minHeight: '60px',
  maxHeight: '60px',
  marginTop: '5px',
  backgroundColor: '#f6f6f6',
  '& .Mui-selected':{
    backgroundColor: '#FFF!important',
    color: '#1976d2!important',
  }
})

export const UploadAppBar = styled(AppBar)({
    backgroundColor: '#fff',
    color: '#000',
    paddingTop: '5px',
    paddingBottom: '5px',
    width: 'auto',
    minHeight: '60px',
    maxHeight: '60px',
    '& .icon': {
      color: '#1976d2',
    },
});

export const SearchWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'black',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

export const Icons = styled('div')({
    display: 'flex',
    paddingLeft: '10px',
    color: '#1976d2',
    lineHeight: '45px',
    '& .delete': {
      color: 'red',
    },
    '& .hide': {
      display: 'none',
    },
    '& .icon': {
      color: '#1976d2'
    },
    '& .upload-bom: hover': {
      color: '#fff'
    }
})

export const Icon = styled('div')({
  flex: '0 0 auto', 
  paddingLeft: '5px'
})

export const TextButton = styled(Button)({
  padding: '5px'
})

export const LogButtonWrapper = styled(IconButton)({
  color: 'red!important'
});

export const ProgressMessage = styled('div')({
    textAlign: 'left',
    display: 'inline-block',
    width: '400px',
    fontStyle: 'italic',
    fontSize: '1em',
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: '5px',
});

export const GridTitle = styled('div')({
  color: '#1976d2',
  height: '100%',
  width: 'auto',
  padding: '20px',
  marginLeft: '-30px',
})

export const ToolbarRoot = styled(Toolbar)({
  backgroundColor: '#fff',
  color: '#ABABAB',
  paddingLeft: '5px!important',
  paddingRight: '5px!important',
  '& .Mui-selected div': {
      //backgroundColor: '#f6f6f6',
  },
  '& .MuiToggleButtonGroup-root':{
      '& .MuiButtonBase-root': {
          border: 'none',
          borderRadius: 0,
      }
  },
})

export const OptionBox = styled('div')({
  fontSize: '.8em',
  //backgroundColor: '#fff',
  padding: '3px 10px',
  '& .Mui-selected div':{
      backgroundColor: '#0000'
  }
 
})