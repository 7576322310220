import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { prodPlanningClearCacheAll } from "../../services/cache";

export const ProductionPlanningCache = () => {
    const authContext = useContext(AuthContext);
    useEffect(() => {
        prodPlanningClearCacheAll(authContext?.auth.userToken)
        .then(data => {
            console.log(data);
        })
    },[])
    return (
        <>
            <h1>Production Planning Cache Cleared (All)</h1>
        </>
    )
}