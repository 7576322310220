import { useEffect, useState } from "react"
import { useProdPlanningFilterDrawerStore } from "../../../store/ProdPlanningFilterDrawerStore";
import { IMultiTriggerFilterOptions } from "../interfaces/IMultiTriggerFilterOptions";
import { IProductionPlanningFilterOptionsTrigger } from "../interfaces/IProductionPlanningFilterOptionsTriggers";
import { useProductionPlanningFilterOptionsLoaders } from "../loaders/ProductionPlanningFilterOptionsLoaders";

export const useProductionPlanningFilterOptionsTriggers = () => {
    const [productionPlanningFilterOptionTriggers, setProductionPlanningFilterOptionTriggers] = useState<IProductionPlanningFilterOptionsTrigger | null>(null);
    const {loadFilterOptionItems,loadFilterOptionCustomers,loadFilterOptionProdOrders,loadFilterOptionStatus,loadFilterOptionWarehouses} = useProductionPlanningFilterOptionsLoaders();
    const {setRefreshGrid} = useProdPlanningFilterDrawerStore();
    useEffect(() => {
        if (productionPlanningFilterOptionTriggers?.multiple === true){
            triggerMultiTriggerOptions();
        } else {
            if (productionPlanningFilterOptionTriggers?.customers === true)
                triggerFilterOptionsCustomers();
            if (productionPlanningFilterOptionTriggers?.items === true)
                triggerFilterOptionItems();
            if (productionPlanningFilterOptionTriggers?.status === true)
                triggerFilterOptionsStatus();
            if (productionPlanningFilterOptionTriggers?.prodOrders === true)
                triggerFilterOptionsProdOrders();
            if (productionPlanningFilterOptionTriggers?.warehouses === true)
                triggerFilterOptionsWarehouses();
        }
    },[productionPlanningFilterOptionTriggers])
    const setTriggerFilterOptionProdOrders = () => {
        setProductionPlanningFilterOptionTriggers({...productionPlanningFilterOptionTriggers, prodOrders: true});
    };
    const triggerFilterOptionsProdOrders = async () => {
        if (productionPlanningFilterOptionTriggers?.prodOrders === true){
            return loadFilterOptionProdOrders(productionPlanningFilterOptionTriggers);
        }
    };
    const setTriggerFilterOptionItems = () => {
        setProductionPlanningFilterOptionTriggers({...productionPlanningFilterOptionTriggers, items: true});
    };
    const triggerFilterOptionItems = async () => {
        if (productionPlanningFilterOptionTriggers?.items === true){
            return loadFilterOptionItems(productionPlanningFilterOptionTriggers);
        }
    };
    const setTriggerFilterOptionCustomers = () => {
        setProductionPlanningFilterOptionTriggers({...productionPlanningFilterOptionTriggers, customers: true});
    };
    const triggerFilterOptionsCustomers = async () => {
        if (productionPlanningFilterOptionTriggers?.customers === true){
            return loadFilterOptionCustomers(productionPlanningFilterOptionTriggers);
        }
    };
    const setTriggerFilterOptionStatus = () => {
        setProductionPlanningFilterOptionTriggers({...productionPlanningFilterOptionTriggers, status: true});
    };
    const triggerFilterOptionsStatus = async () => {
        if (productionPlanningFilterOptionTriggers?.status === true){
            return loadFilterOptionStatus(productionPlanningFilterOptionTriggers);
        }
    };
    const setTriggerFilterOptionWarehouses = () => {
        setProductionPlanningFilterOptionTriggers({...productionPlanningFilterOptionTriggers, warehouses: true});
    };
    const triggerFilterOptionsWarehouses = async () => {
        if (productionPlanningFilterOptionTriggers?.warehouses === true){
            return loadFilterOptionWarehouses(productionPlanningFilterOptionTriggers);
        }
    };
    const triggerMultiTriggerOptions = () => {
        
        triggerFilterOptionsProdOrders()
        .then(() => {
            return triggerFilterOptionsCustomers()
        }).then(() => {
            return triggerFilterOptionItems();
        }).then(() => {
            return triggerFilterOptionsWarehouses();
        }).then(() => {
            return triggerFilterOptionsStatus();
        }).then(() => {
            setProductionPlanningFilterOptionTriggers({
                option: '',
                multiple: false,
                items: false,
                customers: false,
                status: false,
                prodOrders: false,
                warehouses: false,
                startDateFrom: null,
                startDateTo: null,
                selectedIds: null,
                targetDB: null,
            });
            setRefreshGrid(true);
        });

    };
    const setMultiTriggerFilterOptions = (props: IMultiTriggerFilterOptions) => {
        const {option, multiple,prodOrders,items,customers,warehouses,status,startDateFrom,startDateTo,selectedIds,targetDB} = props;
        setProductionPlanningFilterOptionTriggers({
            option,
            multiple,
            prodOrders,
            items,
            customers,
            status,
            warehouses,
            startDateFrom: startDateFrom,
            startDateTo: startDateTo,
            selectedIds,
            targetDB,
        });
    };
    return {
        setTriggerFilterOptionItems,
        setTriggerFilterOptionCustomers,
        setTriggerFilterOptionStatus,
        setTriggerFilterOptionProdOrders,
        setMultiTriggerFilterOptions,
        setTriggerFilterOptionWarehouses,
    }
}