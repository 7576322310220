
import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import { AppMenuItemComponent } from './AppMenuItemComponent';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { Collapse, Divider, List, ListItemIcon, ListItemText, styled } from '@mui/material';
import { AuthorizedAccess } from '../../authorization/AuthorizedAccess';
import { constants } from '../../config/settings';
import { useProductionPlanningStore } from '../../store/ProductionPlanningStore';

export const AppMenuItemPropTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    componentCode: PropTypes.string.isRequired,
    link: PropTypes.string,
    Icon: PropTypes.elementType,
    items: PropTypes.array,
    admin: PropTypes.bool,
    sub: PropTypes.bool,
    nestedClassName: PropTypes.string,
    isIndent: PropTypes.bool,    
    hide: PropTypes.bool,
}

type AppMenuItemPropTypes = PropTypes.InferProps<typeof AppMenuItemPropTypes>;
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropTypes, 'items'>;

export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
    items?: AppMenuItemProps[]
}

export const ListItemIconWrapper = styled(ListItemIcon)({
    color: 'rgb(0 0 0 / 30%)!important',
    minWidth: '40px',
    marginLeft: '8px',
    marginRight: '-7px',
})

export const AppMenuItem: FC<AppMenuItemProps> = (props) => {
    const {name, link, Icon, items = [], nestedClassName, sub, admin, isIndent} = props;
    //const classes = useStyles();
    const isExpandable = items && items.length > 0 && !sub;
    const [open, setOpen] = useState(true);
    const {loadingProductionPlannings} = useProductionPlanningStore();
    function onClickHandlerAppMenuItem() {
        setOpen(!open);
    }
    const MenuItemRoot = (
        <AppMenuItemComponent 
            key={props.id}
            link={link || null}
            componentCode={props.componentCode}
            onClick={onClickHandlerAppMenuItem}
            inProgress={loadingProductionPlannings}>
            {!!Icon && (
                <ListItemIconWrapper>
                    <Icon />
                </ListItemIconWrapper>
            )}
            <ListItemText style={isIndent ? {paddingLeft: '60px'} : {}} primary={name}  inset={!Icon} />
            {isExpandable && !open && <ChevronRightOutlinedIcon />}
            {isExpandable && open && <ExpandMoreOutlinedIcon />}
        </AppMenuItemComponent>
    )
    const MenuItemChildren = isExpandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <List component="div" disablePadding>
                {items.filter(x => !(x.hide ?? false)).map((item, index) => {
                    item.nestedClassName = ' nested';
                    return (
                        <AuthorizedAccess key={item.id} componentCode={item.componentCode} 
                                          actionCode={constants.accessRightsActions.view}
                                          readonly={constants.accessRightsActions.readonly} >
                            <AppMenuItem {...item} key={item.id} />
                        </AuthorizedAccess>
                    )
                })}
            </List>
        </Collapse>
    ) : null;    
    return (
        <>
            {MenuItemRoot}
            {MenuItemChildren}
        </>
    )
}