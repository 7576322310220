import { constants } from '../config/settings';

export const prodPlanningClearCacheAll = async (token) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers,
    };
    
    const res = await window.fetch(`${constants.getProdPlanningExternalWebUrl()}${constants.productionPlanningCacheClearAll}`, options);
    if (res.ok){
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}