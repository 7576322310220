import { useProductionPlanningStore } from "../../../../../store/ProductionPlanningStore";
import { TaskItem } from "../../../../../Types/TaskItem";
import { useProductionPlanningScheduleGanttChart } from "../../../charts/hooks/ProductionPlanningScheduleGanttChart";
import {setAsyncTimeout} from "../../../../../utils/utilities";
import { useProdFilterStore } from "../../../../../store/ProdFilterStore";
import { useProdPagerStore } from "../../../../../store/ProdPagerStore";

export const useSearchHelper = () => {
    const {setTaskItems, setTotalRows, setSearchSelectedTasksIds, setReloadDataInProgress} = useProductionPlanningStore();
    const {getTotalRows, displayProgress} = useProductionPlanningScheduleGanttChart();
    const {setSearchText, expandCollapseRows} = useProdFilterStore();
    const {setResetPages} = useProdPagerStore();

    const searchProd = (data: TaskItem[], searchText: string, checked?:boolean) => {        
        (async()=>{
            await setAsyncTimeout(()=>{
                setSearchSelectedTasksIds([]);
                setSearchText(searchText);
                displayProgress(true);
                setReloadDataInProgress(true);
            }, 100);
            
            await setAsyncTimeout(()=>{
                if ((searchText ?? '').length>0){
                    let _searchItems:TaskItem[] = [] as TaskItem[];                    
                    if (checked !== undefined && checked === false) {
                        findValueOnly(data,_searchItems, searchText);
                    } else {                  
                        const resTaskItems = findValue(data, searchText);
                        _searchItems = structuredClone(resTaskItems);
                    }

                    setTaskItems(_searchItems.map(item =>{return {...item, expanded:(expandCollapseRows === 'expanded' ? true: false)}}));
                } else {     
                    setTaskItems(data.map(item =>{return {...item, expanded:(expandCollapseRows === 'expanded' ? true : false)}}));
                    // force to checked
                    //setShowRelated(true);
                }
            },1000);
            
            await setAsyncTimeout(()=>{
                //setRefreshChart(true);
                // to reset paging
                setResetPages(true);
                //setRefreshPage(true);                
                setTotalRows(getTotalRows());
            }, 2000).finally(()=>{
                setReloadDataInProgress(false);
                displayProgress(false);                
            });

        })();        
        
        
    }

    const searchProdItems = (data:TaskItem[],searchText:string, options?:{checked?:boolean, expand?:string|null}):TaskItem[] => {
            let _result:TaskItem[] = [] as TaskItem[];

            if (searchText.length > 0) {
                //
                if (options?.checked) {
                    _result = findValue(data, searchText);
                } else {
                    findValueOnly(data, _result, searchText, true);
                }

                if (_result.length > 0) {
                    _result = _result.map(item =>{return {...item, expanded:(options?.expand === 'expanded' ? true : false)}});
                }
                
            } else {
                return data.map(item =>{return {...item, expanded:(options?.expand === 'expanded' ? true : false)}});
            }

            return _result;
    }


    const findValue = (array: TaskItem[], value: string) => {
        return array.reduce((r: TaskItem[], o: TaskItem) => {
            if ((o.project ?? '').toString().toLowerCase().indexOf(value) > -1 || 
                (o.item ?? '').toString().toLowerCase().indexOf(value) > -1 ||
                (o.salesOrderNo ?? '').toString().toLowerCase().indexOf(value) > -1 ||
                (o.customer ?? '').toString().toLowerCase().indexOf(value) > -1) {                    
                    // in context
                    setSearchSelectedTasksIds((prevState)=>[...prevState, o.id]);

                    r.push(o);
                    return r;
            }
            if (Array.isArray(o.tasks)) {
                var subTasks = findValue(o.tasks, value);
                if (subTasks.length) {
                    r.push(Object.assign({}, o, { subTasks }));
                }
            }
            return r;
        }, []);
    }


    /**
     * Get specific row with matching item or project
     * 
     * @param array
     * @param result
     * @param searchText
     * @returns
     */
    const findValueOnly = (array: TaskItem[],result:TaskItem[], searchText:string, exact?:boolean) => {
        for(var i=0, e = array.length; i<e; i++) {
            const value = array[i],
                  valueTasks = array[i].tasks;
            // on main row first
            if ((value.project ?? '').toString().toLowerCase().indexOf(searchText) > -1 ||
                    (value.item ?? '').toString().toLowerCase().indexOf(searchText) > -1 || 
                    (value.salesOrderNo ?? '').toString().toLowerCase().indexOf(searchText) > -1 ||
                    (value.customer ?? '').toString().toLowerCase().indexOf(searchText) > -1) {                        
                result.push(value);

                if (exact) {
                    // remove the details
                    result.forEach(item => {
                        if (item.tasks) {
                            item.tasks = [];
                        }
                    });

                    return result;
                }
            }  else {
                if ((Array.isArray(valueTasks)) && valueTasks.length > 0) {
                    findValueOnly(valueTasks, result, searchText);
                }
            }
        }        
        return result;
    }
    
    return {
        searchProd,
        searchProdItems
    }
}