import { Configuration, EndSessionRequest, PopupRequest, PublicClientApplication } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "13bc147a-c407-48c0-ba28-c5c73b9fb135",
        redirectUri: `${window.location.protocol}//${window.location.host}/`,
        authority: "https://login.microsoftonline.com/615b1736-361b-480f-950c-b3d6ff7b952d",
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            piiLoggingEnabled: false,
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                //console.log(message);
            }
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphMeMessagesEndpoint: "https://graph.microsoft.com/v1.0/me/messages",
    graphQualityMessagesEndpoint2: "https://graph.microsoft.com/v1.0/users/reports@vanthiel.com",
    graphQualityMessagesEndpoint: "https://graph.microsoft.com/v1.0/users/0d38cd48-0b9d-4aa5-aa8f-2fcf9f0368c4",
};

export function loginRequest2() {
    const accessTokenRequest = {
        scopes: ["user.read"]
    }

    let username = "";

    const myMsal = new PublicClientApplication(msalConfig);

    myMsal.loginPopup(loginRequest)
    .then(function (loginResponse) {
        // In case multiple accounts exist, you can select
        const currentAccounts = myMsal.getAllAccounts();
        if (currentAccounts === null) {
            console.log('no accounts detected');
            // no accounts detected
        } else if (currentAccounts.length > 1) {
            console.log('there ARE accounts so grad the token');
            // Add choose account code here
        } else if (currentAccounts.length === 1) {
            console.log('ther is ONLY ONE account so grab the token');
            username = currentAccounts[0].username;
        }

        console.log('username');
    
    }).catch(function (error) {
        //login failure
        console.log(error);
    });
    
}

export function loginRequest3() {
    const accessTokenRequest = {
        scopes: ["user.read"]
    }

    let username = "";

    const myMsal = new PublicClientApplication(msalConfig);

    const handleResponse: any = (response: any) => {
        //handle redirect response
    
        // In case multiple accounts exist, you can select
        const currentAccounts = myMsal.getAllAccounts();
        console.log(response);
    
        if (currentAccounts === null) {
            // no accounts detected
        } else if (currentAccounts.length > 1) {
            // Add choose account code here
        } else if (currentAccounts.length === 1) {
            username = currentAccounts[0].username;
        }
    }    

    myMsal.handleRedirectPromise(handleResponse);

    myMsal.loginRedirect(loginRequest);
}

// export function logout() {
//     const accessTokenRequest = {
//         scopes: ["user.read"]
//     }

//     let username = "";

//     const myMsal = new PublicClientApplication(msalConfig);
//     const currentAccounts = myMsal.getAllAccounts();
//     if (currentAccounts.length === 1){
//         const acct = currentAccounts[0];
//         const logOutRequest: EndSessionRequest = {
//             acct,
//         };
//         myMsal.logout(logOutRequest);
//     }


// }