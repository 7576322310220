import { createContext, Dispatch, MouseEventHandler, SetStateAction, useContext, useState } from "react";

export interface PopoverBoxContextData {
    popoverId: string,
    setPopoverId: Dispatch<SetStateAction<string>>;
    popoverOpen: boolean;
    setPopoverOpen: Dispatch<SetStateAction<boolean>>;
    message: string;
    setMessage: Dispatch<SetStateAction<string>>;
    popoverAnchorEl: HTMLButtonElement | null;
    setPopoverAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
    onCloseHandler: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
    setOnCloseHandler: Dispatch<SetStateAction<(event: {}, reason: "backdropClick" | "escapeKeyDown") => void>>;
    okText: string;
    setOkText: Dispatch<SetStateAction<string>>;
    cancelText: string;
    setCancelText: Dispatch<SetStateAction<string>>;
    onClickHandlerOk:   MouseEventHandler<HTMLButtonElement> | undefined;
    setOnClickHandlerOk: Dispatch<SetStateAction< MouseEventHandler<HTMLButtonElement> | undefined>>;
    onClickHandlerCancel:  MouseEventHandler<HTMLButtonElement> | undefined;
    setOnClickHandlerCancel: Dispatch<SetStateAction< MouseEventHandler<HTMLButtonElement> | undefined>>;
}

export const popoverBoxContextDefaultValue: PopoverBoxContextData = {   
    popoverId: 'popover',
    setPopoverId: () => {},
    popoverOpen: false,
    setPopoverOpen: () => {},
    message: '',
    setMessage: () => {},
    popoverAnchorEl: null,
    setPopoverAnchorEl: () => {},
    onCloseHandler: () => {},
    setOnCloseHandler: () => {},
    okText: 'Ok',
    setOkText: () => {},
    cancelText: 'Cancel',
    setCancelText: () => {},
    onClickHandlerOk: undefined,
    setOnClickHandlerOk: () => {},
    onClickHandlerCancel: undefined,
    setOnClickHandlerCancel: () => {},
}

export const PopoverBoxStore = createContext<PopoverBoxContextData>(popoverBoxContextDefaultValue);

export const usePopoverBoxStore = () => useContext(PopoverBoxStore);

export const PopoverBoxProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = ({ children }) => {
    const [popoverId, setPopoverId] = useState<string>('popover');
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [popoverAnchorEl,setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [onCloseHandler, setOnCloseHandler] = useState<(event: {}, reason: "backdropClick" | "escapeKeyDown") => void>(() => {});
    const [okText, setOkText] = useState<string>('Ok');
    const [cancelText, setCancelText] = useState<string>('Cancel');
    const [onClickHandlerOk, setOnClickHandlerOk] = useState<MouseEventHandler<HTMLButtonElement> | undefined>(undefined);
    const [onClickHandlerCancel, setOnClickHandlerCancel] = useState<MouseEventHandler<HTMLButtonElement> | undefined>(undefined);
    return (
        <PopoverBoxStore.Provider
            value={{
                popoverId,
                setPopoverId,
                popoverOpen,
                setPopoverOpen,
                message,
                setMessage,
                popoverAnchorEl,
                setPopoverAnchorEl,
                onCloseHandler,
                setOnCloseHandler,
                okText,
                setOkText,
                cancelText,
                setCancelText,
                onClickHandlerOk,
                setOnClickHandlerOk,
                onClickHandlerCancel,
                setOnClickHandlerCancel,
            }}>
                {children}
        </PopoverBoxStore.Provider>
    )
}
