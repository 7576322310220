import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { ExportedBomHeader } from "../Types/Inventor/ExportedBomHeader";

export interface MBOMConversionContextData {
    exportedBomHeaders: ExportedBomHeader[],
    setExportedBomHeaders: Dispatch<SetStateAction<ExportedBomHeader[]>>,
    isMBOMLoading: boolean | null | undefined,
    setIsMBOMLoading: Dispatch<SetStateAction<boolean | null | undefined>>,
}

export const MBOMConversionDefaultValue: MBOMConversionContextData = {
    exportedBomHeaders: [],
    setExportedBomHeaders: () => {},
    isMBOMLoading: null,
    setIsMBOMLoading: () => {},
}

export const MBOMConversionStore = createContext<MBOMConversionContextData>(MBOMConversionDefaultValue);

export const useMBOMConversionStore = () => useContext(MBOMConversionStore);

export const MBOMConversionProvider: FC = ({children}) => {
    const [exportedBomHeaders, setExportedBomHeaders] = useState<ExportedBomHeader[]>([]);
    const [isMBOMLoading, setIsMBOMLoading] = useState<boolean | null | undefined>(null);
    return (
        <MBOMConversionStore.Provider
            value={{
                exportedBomHeaders,
                setExportedBomHeaders,
                isMBOMLoading,
                setIsMBOMLoading,
            }}>
            {children}
        </MBOMConversionStore.Provider>
    )
}