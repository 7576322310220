import { styled, Tooltip } from "@mui/material"
import { FC } from "react";
import { DocumentFile } from "../../../Types/Plm/DocumentFile";
import { Grow } from "../../styles/SharedStyles";
// import { Grow } from "../../styles/commonStyles";
// import { DocumentFile } from "../../Types/plm/DocumentFile";
// import { getImageByFileType } from "../../utils/plmUtilities";

export const ImageRow = styled('div')({
    display: 'flex',
    flexFlow: 'row',
    '& .hide': {
        display: 'none',
    },
    width: '100%',
    paddingTop: '10px',
    lineHeight: '1.5rem',
    position: 'relative',
})

export const ImageWrapper = styled('div')({
    height: '50px',
    width: '50px',
    padding: '10px 10px 10px 0',
    display: 'flex',
    flexFlow: 'row',
})

export const ImageItem = styled('img')({
    flex: 1,
    height: '50px',
    width: '50px',
})

export const ImageDetails = styled('div')({
    width: '100%',   
    display: 'flex',
    flexFlow: 'column',
    position: 'relative',
})

export const Filename = styled('div')({
    fontSize: '1em',
    fontWeight: 650,
})

export const FileReferenceDesc = styled('div')({
    fontSize: '.9em',
    flex: 1,
    paddingRight: '5px',
    marginRight: '5px',
    textAlign: 'left',

})

export const FileFooterInfo = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%!important',    
})

export const FileFooterRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    margin:0,
    padding:0,
})

export const FileDate = styled('div')({
    fontSize: '.7em',
    fontStyle: 'italic',
})

export const DocTypeName = styled('div')({
    fontSize: '.7em',
    fontStyle: 'italic',
    paddingRight: '5px',
    marginRight: '5px',
})

interface DocumentCellProps {
    file: DocumentFile;
}

export const DocumentCell: FC<DocumentCellProps> = (props: DocumentCellProps) => {
    const {file} = props;   
    const renderFilename = (filename: string)  => {
        if (filename.length>48)
            return (<Tooltip title={filename} placement="top" >
                        <Filename>{filename.substring(0,48)}...</Filename>
                    </Tooltip>)
        else 
            return (<Filename>{filename}</Filename>)
    }
    const renderFileReferenceName = (file: DocumentFile) => {
        const skucodename = file.sku || file.productName ? `${file.sku} ${file.productName}` : null;
        return (
            <FileReferenceDesc>
                {file.styleName ?? skucodename ?? file.collectionName ?? renderEmpty()}
            </FileReferenceDesc>
            )
    }
    const renderEmpty = () => {
        return (<>&nbsp;&nbsp;&nbsp;</>)
    }
    return (
        <ImageRow className='file-row'>
            <ImageWrapper key={file.id}>
                {/* <ImageItem src={getImageByFileType(file.fileExt,file.url)} /> */}
            </ImageWrapper>
            <ImageDetails>
                <Filename>{file.filename}</Filename>
                {(file.styleName || file.sku || file.productName || file.collectionName) && renderFileReferenceName(file)}
                <FileFooterInfo>
                    <FileFooterRow>
                        <FileDate>
                            {`${file.createdDateStr} - ${file.createdBy}`}
                        </FileDate>
                        <Grow />
                        <DocTypeName>
                            {file.documentCodeDesc}
                        </DocTypeName>
                    </FileFooterRow>
                </FileFooterInfo>
            </ImageDetails>
        </ImageRow>
    )
}