import { useProdFilterStore } from "../../../../store/ProdFilterStore";
import { useProdPagerStore } from "../../../../store/ProdPagerStore";
import { useProdPlanningFilterDrawerStore } from "../../../../store/ProdPlanningFilterDrawerStore";
import { useProductionPlanningStore } from "../../../../store/ProductionPlanningStore";
import { usePagerHelpers } from "../../../hooks/helpers/PagerHelpers";

export const useProdPagerHelpers = () => {
    const {taskItems,setPageTaskItems,setTaskItems,filteredTaskItems,allTaskItems} = useProductionPlanningStore();
    const {setCurrentPage,pageSize,setRefreshPage3,setTotalPages,setTotalRecords} = useProdPagerStore();
    const {searchText} = useProdFilterStore();
    const {getTotalPages} = usePagerHelpers();
    const {showRelated} = useProdPlanningFilterDrawerStore();
    const splitIntoPages = (page: number) => {
        const records = (filteredTaskItems ?? []).length === 0 && (searchText ?? '').length===0 
                        ? allTaskItems 
                        : showRelated===false
                            ? filteredTaskItems
                            : taskItems; //structuredClone(taskItems);
        const batches:any = [];
        for (let i = 0; i < records?.length; i += pageSize) {
            const batch = records?.slice(i, i + pageSize);
            batches.push(batch);
        }
        const _page = batches?.length >= page ? page : 1; 

        setCurrentPage(_page);
        setPageTaskItems(batches);
        const _batchedItem = batches[_page-1];
        setTaskItems(_batchedItem);

        setTotalRecords(records?.length ?? 0);
        const totalPages = getTotalPages(records?.length ?? 0,pageSize);
        setTotalPages(totalPages);
        setRefreshPage3(true);
    }
    return {
        splitIntoPages
    }
}