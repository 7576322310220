import useId from "@mui/utils/useId";
import { useSnackbar } from "notistack";
import { useContext } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import { getSavedTaskItems } from "../../../../../services/productionPlanning/productionPlanning";
import { useProdFilterStore } from "../../../../../store/ProdFilterStore";
import { useProdPagerStore } from "../../../../../store/ProdPagerStore";
import { useProdPlanningFilterDrawerStore } from "../../../../../store/ProdPlanningFilterDrawerStore";
import { useProductionPlanningStore } from "../../../../../store/ProductionPlanningStore";
import { ProductionOrder } from "../../../../../Types/ProductionOrder";
import { TaskItem } from "../../../../../Types/TaskItem";
import { IProdPlanningFilterOptions } from "../../../../hooks/interfaces/IProdPlanningFilterOptions";
import { useProductionPlanningTriggers } from "../../../../hooks/triggers/ProductionPlanningTriggers";
import { useProductionPlanningScheduleGanttChart } from "../../../charts/hooks/ProductionPlanningScheduleGanttChart";
import { useSearchHelper } from "./SearchHelper";

export const useSaveCancelHelpers = () => {
    const {setProductionOrders,setTaskItems,setAllTaskItems,setBatchTaskItems,
           setUpdatedTaskItems,setIsRefreshResizedTasks,setIsSaving,setRollbackAllTaskItems, setReloadDataInProgress} = useProductionPlanningStore();
    const {searchProd} = useSearchHelper();
    const {setTriggerLoadFilteredTaskSchedules} = useProductionPlanningTriggers();
    const {formatTimelineWeekAndDay, displayProgress} = useProductionPlanningScheduleGanttChart();    
    const {searchText} = useProdFilterStore();
    const {setResetPages} = useProdPagerStore();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const snackbarId = useId();
    const authContext = useContext(AuthContext);
    const {dateStartFrom,dateStartTo,prodOrder,items,itemIds,statuses,statusIds,prodOrders,prodOrderIds,customers,
        customerIds,showRelated,fulfillmentDateFrom,fulfillmentDateTo} = useProdPlanningFilterDrawerStore();

    const loadTaskItems = (callback?: () => void) => {        
        const selectedItems: string[] = items.filter(x => itemIds.filter(i => i===x.id).length>0).map(x => x.code);
            const selectedStatuses: string[] = statuses.filter(x => statusIds.filter(i => i===x.id).length>0).map(x => x.id);
            const selectedProdOrders: string[] = prodOrders.filter(x => prodOrderIds.filter(i => i===x.id).length>0).map(x => x.code);
            const selectedCustomers: string[] = customers.filter(x => customerIds.filter(i => i===x.id).length>0).map(x => x.code);
        setTimeout(() => {
            setProductionOrders([]);
            setTaskItems([]);
            setAllTaskItems([]);
            setRollbackAllTaskItems([]);
            setBatchTaskItems([]);
        },100);
        setTimeout(() => {
            setTriggerLoadFilteredTaskSchedules({
                searchText, 
                callback:(data: TaskItem[]) => {
                    if ((searchText ?? '').length>0) {
                        searchProd(data,searchText,true);
                    }

                    setResetPages(true);
                    setTimeout(()=>{
                        formatTimelineWeekAndDay();
                    },100);                                

                    if (callback)
                        callback();

                },
                filterOptions: {
                    dateStartFrom,
                    dateStartTo,
                    prodOrder,
                    prodOrders: selectedProdOrders,
                    items: selectedItems,
                    customers: selectedCustomers,
                    statuses: selectedStatuses,
                    fulfillmentDateFrom,
                    fulfillmentDateTo,
                    showRelated
                } as IProdPlanningFilterOptions});
        },500);
    }
    const loadSavedTaskItems = (resizedProductionOrders: ProductionOrder[]) => {
        setTimeout(() => {
            const saveProdOrders = resizedProductionOrders.map(x => {
                x = {...x, taskItem: {} as TaskItem, origTaskItem: {} as TaskItem};
                return x;
            });
            setBatchTaskItems([]);
            
            enqueueSnackbar("Fetching records, please wait.",{key: snackbarId, variant: "success", persist: true});
            getSavedTaskItems(authContext?.auth.userToken,saveProdOrders)
            .then((data: TaskItem[]) => {
                setUpdatedTaskItems(data);
                setTimeout(() => {
                    setIsSaving(false);
                    setIsRefreshResizedTasks(true);
                    closeSnackbar(snackbarId);
                },500);
            }).catch((reason:any)=>{
                console.error('loading error, loadSavedTaskItems::', reason);
                setTimeout(()=>{
                    displayProgress(false);
                }, 100);
            });
        },500);
    }
 
    return {
        loadTaskItems,
        loadSavedTaskItems,
    }
}