import { styled } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro"
import { useWindowResize } from "../../hooks/helpers/WindowResize";
import { usePreviewBOMExportGridHelpers } from "./hooks/PreviewBOMExportGridHelpers";
import { useExportBOMStore } from "../../../store/ExportBOMStore";
import { CustomDialog } from "../dialog/CustomDialog";
import { CustomDialogToolbar } from "../dialog/CustomDialogToolbar";
import { LogViewer } from "./viewer/LogViewer";
import { LogSummary } from "./viewer/LogSummary";

export const GridWrapper = styled('div')({
    width: 'auto',
    '& .MuiDataGrid-iconButtonContainer .MuiDataGrid-filterIcon': {
        display: 'none!important',
    },
    '& .MuiDataGrid-menuIcon .MuiDataGrid-menuIconButton': {
        display: 'none!important',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#fff!important',
    },
    '& .view-error': {
        cursor: 'pointer',
        color: 'red',
    },
    '& .error': {
        color: 'red',
    },
    '& .ok': {
        color: 'green',  
    },
})

export const PreviewBOMExportGrid = () => {
    const {gridColumns,gridFilterItems} = usePreviewBOMExportGridHelpers();
    const {inventorBomHeader,openLogs,setOpenLogs,openSummaryLogs,setOpenSummaryLogs,errorLogs} = useExportBOMStore();
    const size = useWindowResize({
        xheight: {
            target: '.bom-export-grid',
            addPx: 10,
        }
    });    
    return (
        <GridWrapper
            className="bom-export-grid"
            style={size ? {height: `${size.height}px`} : {}} >
            <DataGridPro 
                rowHeight={30}
                columns={gridColumns}
                rows={inventorBomHeader?.details ?? []}
                filterModel={gridFilterItems}
            />
            <CustomDialog 
                open={openLogs as boolean} 
                setOpen={setOpenLogs}>
                <CustomDialogToolbar 
                    title="Error Viewer"
                    setOpen={setOpenLogs} />
                <LogViewer />
            </CustomDialog>
            <CustomDialog 
                open={openSummaryLogs} 
                setOpen={setOpenSummaryLogs}>
                <CustomDialogToolbar 
                    title="Error Summary Viewer"
                    setOpen={setOpenSummaryLogs} />
                <LogSummary errorLogs={errorLogs} />
            </CustomDialog>
        </GridWrapper>
    )
}