import dayjs from "dayjs"
import { ChangeEvent, useCallback, useEffect } from "react";
import { constants } from "../../../../../../config/settings";
import { useProdPlanningFilterDrawerStore } from "../../../../../../store/ProdPlanningFilterDrawerStore";
import { useProductionPlanningStore } from "../../../../../../store/ProductionPlanningStore";
import { IMultiTriggerFilterOptions } from "../../../../../hooks/interfaces/IMultiTriggerFilterOptions";
import { useProductionPlanningFilterOptionsTriggers } from "../../../../../hooks/triggers/ProductionPlanningFilterOptionsTriggers";

export const useProdPlanningFilterInputFieldsEvents = () => {
    const {setDateStartFrom,setDateStartTo,setProdOrder,setFilterRows,item,items,setFilterIds,itemIds,
           setSelectedFilter,setFilterSearchText,setItem,setItems,allItems,setItemIds,selectedFilter,
           status, statuses, statusIds, allStatuses, setStatus,setStatuses, setStatusIds,
           setCustomerIds, setCustomer,setCustomers,allCustomers,customers,customerIds,customer,setRefreshGrid,
           setFulfillmentDateFrom,setFulfillmentDateTo,allProdOrders,setProdOrders,showRelated,refreshGrid,
           prodOrders,prodOrderIds,prodOrder,setProdOrderIds, dateStartTo, dateStartFrom, enableDateRange,
           setSelectedProdOrderCodes,setSelectedItemCodes,setSelectedStatusCodes,setSelectedCustomerCodes,
           setWarehouse,setWarehouses,allWarehouses,setWarehouseIds,setSelectedWarehouseCodes,warehouses,
           warehouseIds,warehouse, setFilterLoading} = useProdPlanningFilterDrawerStore();
    const {setMultiTriggerFilterOptions} = useProductionPlanningFilterOptionsTriggers();
    const {targetDB} = useProductionPlanningStore();
    useEffect(() => {
        if (refreshGrid===true && (selectedFilter ?? '').length>0){
            refreshFilterGrid(selectedFilter);
            setRefreshGrid(false);
        } else if ((selectedFilter ?? '').length===0){
            setRefreshGrid(false);
        }
    },[refreshGrid])
    const onChangeHandlerDateStartFrom = useCallback((value: dayjs.Dayjs | null, keyboardInputValue?: string | undefined) => {
        setTimeout(() => {
            setDateStartFrom(value);
        },100);
        setTimeout(() => {
            setMultiTriggerFilterOptions({
                multiple: true,
                prodOrders: true,
                items: true,
                customers: true,
                status: true,
                warehouses: true,
                startDateFrom: value,
                startDateTo: dateStartTo,
                targetDB,
            } as IMultiTriggerFilterOptions);  
        },200);
    },[setDateStartFrom,setMultiTriggerFilterOptions]);
    const onChangeHandlerDateStartTo = useCallback((value: dayjs.Dayjs | null, keyboardInputValue?: string | undefined) => {
        setTimeout(() => {
            setDateStartTo(value);
        },100);
        setTimeout(() => {
            setMultiTriggerFilterOptions({
                multiple: true,
                prodOrders: true,
                items: true,
                customers: true,
                status: true,
                warehouses: true,
                startDateFrom: dateStartFrom,
                startDateTo: value,
                targetDB,
            } as IMultiTriggerFilterOptions);  
        },200);
    },[setDateStartTo,setMultiTriggerFilterOptions]);
    const onChangeHandlerFulfillmentDateFrom = (value: dayjs.Dayjs | null, keyboardInputValue?: string | undefined) => {
        setFulfillmentDateFrom(value);
    };
    const onChangeHandlerFulfillmentDateTo = (value: dayjs.Dayjs | null, keyboardInputValue?: string | undefined) => {
        setFulfillmentDateTo(value);
    };
    const onChangeHandlerTextfield = (event:ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setFilterSearchText(value);
        switch (name){
            case constants.filterOptions.name.prodOrder:
                setProdOrder(value);                
                setProdOrders(allProdOrders);
                setFilterRows(allProdOrders);
                break;
            case constants.filterOptions.name.item:
                setItem(value);
                setItems(allItems);
                setFilterRows(allItems);
                break;
            case constants.filterOptions.name.status:
                setStatus(value);
                setStatuses(allStatuses);
                setFilterRows(allStatuses);
                break;
            case constants.filterOptions.name.customer:
                setCustomer(value);
                setCustomers(allCustomers);
                setFilterRows(allCustomers);
                break;
            case constants.filterOptions.name.warehouse:
                setWarehouse(value);
                setWarehouses(allWarehouses);
                setFilterRows(allWarehouses);
                break;
            }
    };
    const onClickHandlerTextfield = (name: string) => {
        refreshFilterGrid(name);
    };
    const onClickHandlerSelectedIDsReset = (field: string) => {
        setFilterIds([]);
        switch(field){
            case constants.filterOptions.name.prodOrder:
                setProdOrderIds([]);
                setProdOrder('');
                setSelectedProdOrderCodes([]);
                break;
            case constants.filterOptions.name.item:
                setItemIds([]);
                setItem('');
                setSelectedItemCodes([]);
                break;
            case constants.filterOptions.name.status:
                setStatusIds([]);
                setStatus('');
                setSelectedStatusCodes([]);
                
                break;
            case constants.filterOptions.name.customer:
                setCustomerIds([]);
                setCustomer('');
                setSelectedCustomerCodes([]);
                break;
            case constants.filterOptions.name.warehouse:
                setWarehouseIds([]);
                setWarehouse('');
                setSelectedWarehouseCodes([]);
                break;
        }

        setTimeout(() => {
            setMultiTriggerFilterOptions({
                option: selectedFilter, //constants.filterOptions.type.Status,
                multiple: true,
                prodOrders: true,
                items: true,
                customers: true,
                status: true,
                warehouses: true,
                startDateFrom: (enableDateRange === true) ? dateStartFrom : '',
                startDateTo: (enableDateRange === true) ? dateStartTo : '',
                selectedIds: [],
                targetDB,
            } as IMultiTriggerFilterOptions);  
        },200);
    };
    const refreshFilterGrid = (name: string) => {
        setFilterLoading(true);
        switch(name){
            case constants.filterOptions.name.prodOrder:                
                setFilterRows(prodOrders);
                setFilterIds(prodOrderIds);
                setSelectedFilter(constants.filterOptions.name.prodOrder);
                setFilterSearchText(prodOrder);
                break;
            case constants.filterOptions.name.item:
                setFilterRows(items);
                setFilterIds(itemIds);
                setSelectedFilter(constants.filterOptions.name.item);
                setFilterSearchText(item);
                break;
            case constants.filterOptions.name.status:
                setFilterRows(statuses);
                setFilterIds(statusIds);
                setSelectedFilter(constants.filterOptions.name.status);
                setFilterSearchText(status);
                break;
            case constants.filterOptions.name.customer:
                setFilterRows(customers);
                setFilterIds(customerIds);
                setSelectedFilter(constants.filterOptions.name.customer);
                setFilterSearchText(customer);
                break;
            case constants.filterOptions.name.warehouse:
                setFilterRows(warehouses);
                setFilterIds(warehouseIds);
                setSelectedFilter(constants.filterOptions.name.warehouse);
                setFilterSearchText(warehouse);
                break;
        }
        setTimeout(()=>{
            setFilterLoading(false);
        },2500);
    }
    return {
        onChangeHandlerDateStartFrom,
        onChangeHandlerDateStartTo,
        onChangeHandlerTextfield,
        onClickHandlerTextfield,
        onClickHandlerSelectedIDsReset,
        onChangeHandlerFulfillmentDateFrom,
        onChangeHandlerFulfillmentDateTo,
    }
}