import { DataGridPro, GridRenderCellParams, GridSelectionModel } from "@mui/x-data-grid-pro";
import { useEffect } from "react";
import { BomGridContainer, GridIcons, GridWrapper } from "./styles/SharedStyles";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useLoadBomFiles } from "../hooks/DataLoad";
import { useBomUploadStore } from "../../../store/BomUploadStore";
import { BomFile } from "../../../Types/BomFile";

export const BomFileGrid = () => {
    //const classes = useStyles();
    const loadBomFiles = useLoadBomFiles();
    const {bomFiles, setBomFiles, loading, setLoading, setSelectedBomFileIds,
           setLoadGrid, setAllBomFiles } = useBomUploadStore();
    const cols = [
        {
            field: '',
            headerName: '',
            width: 30,
            sortable: false,
            resizable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => (
                <GridIcons>
                    {(params.getValue(params.id,"status")?.toString() !== "1" )  
                    ? <ErrorOutlineOutlinedIcon  className="error" />
                    : <CheckCircleOutlinedIcon  className="ok" />}
                </GridIcons>
            )
        },
        {field: 'id', hide: true},
        {field: 'uploadDate', headerName: 'Upload Date', width: 150, headerClassName: 'bom-upload-header'},
        {field: 'uploadBy', headerName: 'Uploaded By', width: 180, headerClassName: 'bom-upload-header'},
        {field: 'fileName', headerName: 'File Name', width: 180, headerClassName: 'bom-upload-header'},
        {field: 'database', headerName: 'Database', width: 150, headerClassName: 'bom-upload-header'},
    ];
    const onSelectionModelChangeHandler = (selectionModel: GridSelectionModel, details?: any) => {
        setSelectedBomFileIds(selectionModel);
    };
    useEffect(() => {
        setLoadGrid(true);
        setLoading(true);
        loadBomFiles((data: BomFile[]) => {
            setSelectedBomFileIds([]);
            setBomFiles(data);
            setAllBomFiles(data);
            setLoading(false);
        });
    },[]);
    return (
        <BomGridContainer>
            <GridWrapper>
                <DataGridPro 
                    checkboxSelection={true}
                    disableSelectionOnClick={true}
                    rowHeight={30}
                    columns={cols}
                    rows={bomFiles}
                    loading={loading}
                    onSelectionModelChange={onSelectionModelChangeHandler}
                />
            </GridWrapper>
        </BomGridContainer>
    )
}