import { GridColumns, GridRowId, GridSelectionModel } from "@mui/x-data-grid-pro";
import { useProdPlanningFilterDrawerStore } from "../../../../../../store/ProdPlanningFilterDrawerStore";

export const useProdPlanningFilterGridHelpers = () => {
    const {showRelated,prodOrders,prodOrderIds,setProdOrderIds,setFilterIds} = useProdPlanningFilterDrawerStore();
    const dropdownGridColumns = [
        {field: 'description', headerName: ' ', width: 475}
    ] as GridColumns;
    const addShowRelated = (prodOrderId: string) => {
        if (showRelated===true){
            const prodOrder = prodOrders.filter(x => x.id.toString() === prodOrderId);
            if (prodOrder.length>0){
                const project = prodOrder[0].code.split('.')[0];
                let selectedProdIds = prodOrders.filter(x => x.code.indexOf(project)>-1).map(x => x.id) as GridRowId[];
                let oldSelectedProdIds = prodOrderIds.filter(x => selectedProdIds.indexOf(x.toString()) === -1) as GridRowId[];
                let finalProdIds = [...oldSelectedProdIds,...selectedProdIds] as GridSelectionModel;
                setProdOrderIds(finalProdIds);
                setFilterIds(finalProdIds);
            }
        }
    }
    const removeShowRelated = (prodOrderId: string) => {
        if (showRelated===true){
            const prodOrder = prodOrders.filter(x => x.id.toString() === prodOrderId);
            if (prodOrder.length>0){
                const project = prodOrder[0].code.split('.')[0];
                let selectedProdIds = prodOrders.filter(x => x.code.indexOf(project)>-1).map(x => x.id.toString()) as string[];
                let otherSelectedProdIds = prodOrderIds.filter(x => selectedProdIds.indexOf(x.toString()) === -1) as GridRowId[];
                let finalProdIds = [...otherSelectedProdIds] as GridSelectionModel;
                setProdOrderIds(finalProdIds);
                setFilterIds(finalProdIds);
            }
        }
    }
    return {
        dropdownGridColumns,
        addShowRelated,
        removeShowRelated,
    }
}