import { AppBar, styled, Toolbar } from "@mui/material";

export const FooterRoot = styled(AppBar)({
    
})

export const FooterContent = styled(Toolbar)({
    backgroundColor: '#fff',
    color: '#ABABAB',
    '& .MuiToggleButtonGroup-root':{
        '& .MuiButtonBase-root': {
            border: 'none',
            borderRadius: 0,
        }
    }
})