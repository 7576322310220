import { styled } from "@mui/material";

export const ScheduleRoot = styled('div')({
    width: '100%',
    '& #productionPlanner':{
        width: '86vmax',
        display: 'inline-block',
        overflow: 'hidden',
        backgroundColor: '#f6f6f6',
        '&.hide': {
            opacity: 0,
            visibility: 'hidden',
            width: '90%'
        },
        borderLeft: 'none',
        borderRight: 'none',
        '--smart-table-indent': '25px',
        '--smart-table-arrow-margin': '5px',
        '--smart-gantt-chart-default-height':'736px',
        '--smart-font-size': '11px',
        '--smart-table-arrow-size': '1px',
        '& .smart-timeline-task': {
            '& .tooltip--container': {
                display: 'none',
                position: 'absolute',
                top: '-168px',  // 50%               
                width: '300px',// 300px
                marginLeft: '-100px',
                //left: '10%', //50%
                zIndex: 999999,
                padding: '10px 10px',
                backgroundColor: '#fff',
                border: '1px solid rgba(171,171,171,0.2)',//border: '0.2px solid #000',
                '&.show': {
                    display: 'block'
                },
                '& .tooltip-content':{
                    '& .main-content': {
                        textAlign: 'left',
                        '& .item': {
                            '& label':{
                                display: 'inline-block',
                                width: '80px',
                                textAlign: 'left',
                            },
                            '& span': {
                                paddingLeft: '10px',
                                '&.status': {
                                    '& svg':{
                                        height: '12px',
                                        width: '12px'
                                    }
                                }
                            }
                        }
                    }
                }
            },
            '&:hover:not(.main-root) .tooltip--container':{
                display: 'block',
            },
            '&.project' : {
                '& .tooltip':{
                    position: 'absolute',
                    display: 'none',
                    top: '-55px',
                    right: '10px',
                    width: '225px',// 300px
                    zIndex: 9999999,
                    padding: '10px 10px',
                    backgroundColor: '#fff',
                    border: '1px solid rgba(171,171,171,0.2)',//border: '0.2px solid #000',
                    '&.show':{
                        display: 'block'
                    },
                    '& h4': {
                        marginBottom: '10px'
                    },
                    '& .date--container':{
                        margin: '0',
                        '& .start-date, .end-date':{                            
                            '&.active': {
                                color: 'red'
                            },
                            '& strong':{
                                fontSize: '12px'
                            },
                        },
                        '& span': {
                            paddingLeft: '10px',
                            fontSize: '16px',
                            fontWeight: '500'
                        }
                    }
                },
                '&.main-root': {
                    '--smart-gantt-chart-project-color': 'rgb(128, 128, 128)', //rgba(0,0,255,1), orange                    
                },
                '&[class*="task-full"]': {
                    '--smart-gantt-chart-project-color': 'rgba(0,128,0,1)', // green
                    '&.material-icons': {
                        '& .smart-task-container':{
                            '& .smart-task-connection-point.start': {
                                display: 'none'
                            }
                        }
                    }
                },
                '&[class*="task-partial"]': {
                    '--smart-gantt-chart-project-color': 'rgba(0,128,0,0.5)', //light-green
                    '&.material-icons': {
                        '& .smart-task-container':{
                            '& .smart-task-connection-point.start': {
                                display: 'none'
                            }
                        }
                    }
                },
                '&[class*="task-only"]': {
                    '--smart-gantt-chart-project-color': 'rgb(154, 205, 50)', // yellow-green
                    '&.material-icons': {
                        '& .smart-task-container':{
                            '& .smart-task-connection-point.start': {
                                display: 'none'
                            }
                        }
                    }
                },
                '&[class*="task-none"]': {
                    '--smart-gantt-chart-project-color': 'rgb(222, 214, 114)', // yellow, rgb(255,255,0)
                    '&.material-icons': {
                        '& .smart-task-container':{
                            '& .smart-task-connection-point.start': {
                                display: 'none'
                            }
                        }
                    }
                },
                '&[class*="task-rejected"]': {
                    '--smart-gantt-chart-project-color': 'rgb(255, 0, 0)', // red
                    '&.material-icons': {
                        '& .smart-task-container':{
                            '& .smart-task-connection-point.start': {
                                display: 'none'
                            }
                        }
                    }
                },
            },
            '&.task' : {
                '& .tooltip':{
                    position: 'absolute',
                    display: 'none',
                    top: '-55px',
                    right: '10px',
                    width: '225px',// 300px
                    zIndex: 9999999,
                    padding: '10px 10px',
                    backgroundColor: '#fff',
                    border: '1px solid rgba(171,171,171,0.2)',
                    '&.show':{
                        display: 'block'
                    },
                    '& h4': {
                        marginBottom: '10px'
                    },
                    '& .date--container':{
                        margin: '0',
                        '& .start-date, .end-date':{                            
                            '&.active': {
                                color: 'red'
                            },
                            '& strong':{
                                fontSize: '12px'
                            },
                        },
                        '& span': {
                            paddingLeft: '10px',
                            fontSize: '16px',
                            fontWeight: '500'
                        }
                    }
                },
                '&[class*="task-full"]': {
                    '--smart-gantt-chart-task-color': 'rgba(0,128,0,1)', // green
                    '&.material-icons': {
                        '& .smart-task-container':{
                            '& .smart-task-connection-point.start': {
                                display: 'none'
                            }
                        }
                    }
                },
                '&[class*="task-partial"]': {
                    '--smart-gantt-chart-task-color': 'rgba(0,128,0,0.5)', // light-green
                    '&.material-icons': {
                        '& .smart-task-container':{
                            '& .smart-task-connection-point.start': {
                                display: 'none'
                            }
                        }
                    }
                },
                '&[class*="task-only"]': {
                    '--smart-gantt-chart-task-color': 'rgb(154, 205, 50)', // yellow-green
                    '&.material-icons': {
                        '& .smart-task-container':{
                            '& .smart-task-connection-point.start': {
                                display: 'none'
                            }
                        }
                    }
                },
                '&[class*="task-none"]': {
                    '--smart-gantt-chart-task-color': 'rgb(222, 214, 114)', //yellow
                    '&.material-icons': {
                        '& .smart-task-container':{
                            '& .smart-task-connection-point.start': {
                                display: 'none'
                            }
                        }
                    }
                },
                '&[class*="task-rejected"]': {
                    '--smart-gantt-chart-task-color': 'rgb(255, 0, 0)', // red
                    '&.material-icons': {
                        '& .smart-task-container':{
                            '& .smart-task-connection-point.start': {
                                display: 'none'
                            }
                        }
                    }
                },
            },
            '& .smart-timeline-task--flag': {
                position: 'absolute',
                left: '-22px',
                height: '20px',
                width: '20px',
                fontSize: '20px',
                top: '5px',
                marginRight: '2px',
                '& i': {

                }
            }
        },        
        '& .smart-table':{
            '--smart-table-arrow-size': '5px',
            '& .pending': {
                color: 'rgb( 0,92,153 )' //red, rgb( 0,92,153 )
            },
            '& tr[aria-expanded]':{
                '&[aria-expanded="false"]':{
                    '& td[data-field="projectTooltip"]': {
                        '&.tree-cell':{
                            paddingLeft: '3px',
                            '& .hierarchy-arrow': {
                                marginRight: '9px'
                            }
                        },
                        '&[class=""]': {
                            paddingLeft: '18px'
                        }
                    }
                }
            },
            '& .tree-cell':{
                '&:has([class*="outline-level"])':{
                    display: 'none',
                },
                paddingLeft: '9px', // 12px
                '&.pl3': {
                    paddingLeft: '3px'
                },
                '& .pl8': {
                    paddingLeft: '8px'
                },
                '&.pl9': {
                    paddingLeft: '9px'
                },
                '& .pl12': {
                    paddingLeft: '12px'
                }
            },
            '& .outline-level-1': {
                paddingLeft:'calc(var(--smart-table-indent) + 6px)!important',
                '& .hierarchy-arrow':{
                    display: 'none'
                }
            },
            '& .outline-level-2': {
                paddingLeft: 'calc(2 * var(--smart-table-indent) - 7px)!important',
                '& .hierarchy-arrow':{
                    display: 'none'
                }
            },
            '& .outline-level-3': {
                paddingLeft: 'calc(3 * var(--smart-table-indent) - 19px)!important',
                '& .hierarchy-arrow':{
                    display: 'none'
                }
            },
            '& .outline-level-4': {
                paddingLeft: 'calc(4 * var(--smart-table-indent) - 29px)!important',
                '& .hierarchy-arrow':{
                    display: 'none'
                }
            },
            '& .outline-level-5': {
                paddingLeft: 'calc(5 * var(--smart-table-indent)  - 40px)!important', 
                '& .hierarchy-arrow':{
                    display: 'none'
                }
            },
            '& .outline-level-6': {
                paddingLeft: 'calc(6 * var(--smart-table-indent)  - 50px)!important', 
                '& .hierarchy-arrow':{
                    display: 'none'
                }
            },
            '& td[data-field="status"]':{
                '& span > i > svg': {
                    width: '14px',
                    height: '14px',
                    marginLeft: '1px',
                    marginRight: '1px',
                }
            },
            '& td[data-field="projectTooltip"]': {
                '&:has([class*="outline-level"])': {
                    '& .hierarchy-arrow': {
                        border: '1px solid red',

                    }
                }
            },
            '& .smart-table-container': {
                '& th[data-field="realizedQty"],th[data-field="soFulfillmentDate"]': {
                    whiteSpace: 'normal',
                },
                '& td[data-field="projectTooltip"]': {
                    '&:has([class*="outline-level"])': {
                        '& .hierarchy-arrow': {
                            border: '1px solid red',
                        }
                    },
                    '& .item-desc--container':{
                        display: 'inline-flex',
                        width: '100%',
                        '& .progress-container':{
                            display: 'flex',
                            width: '16px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '&.off':{
                                display: 'none'
                            }
                        },
                        '& .title--container':{
                            width: '100%'
                        }
                    }, 
                    '&.pl3.pl8':{
                        paddingLeft: '21px'
                    }
                }
            }

        },
        '& .smart-timeline-view-cell[weekend]': {
            backgroundColor: 'var(--smart-surface)',
        },
        '& .smart-timeline-task-progress-thumb': {
            transform: 'scale(0)',
        },
        '& .with-progress': {
            backgroundColor: 'red',
        },
        '& .smart-table-cell-template':{
            display: 'inline-flex',
            '& .item-desc--container':{
                display: 'inline-flex',
                width: '100%',
                '& .progress-container':{
                    display: 'flex',
                    width: '16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&.off':{
                        display: 'none'
                    }
                },
                '& .title--container':{
                    width: '100%'
                }
            },            
            '& i > svg': {
                width: '12px',
                height: '12px',
            }
        },
        '& .merge-task':{
            fontWeight: '600',
            backgroundColor: 'rgb(235,235,235)',
        },        
    },
    '#productionPlanner[view=week] .smart-gantt-chart-task-indicator.material-icons.icon':{
        marginLeft: '48px',
        zIndex: 99,
    },
    '#productionPlanner[view=month] .smart-gantt-chart-task-indicator.material-icons.icon':{
        marginLeft: '-12px',
        zIndex: 99,
    },
    '& .total-rows--container': {
        display: 'none', // default to none
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '20px',
        height: '64px'
    },
   '& .smart-drop-down.smart-date-time-drop-down .smart-drop-down-header>div[smart-id=selectTime]': {
        display: 'none',
    },
})