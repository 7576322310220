import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useProdPlanningFilterDrawerStore } from "../../../../../store/ProdPlanningFilterDrawerStore";
import { setHeight } from "../../../../../utils/utilities";
import { FilterGrid } from "../../../../hooks/ui/FilterGrid";
import { useProdPlanningFilterGridEvents } from "./hooks/ProdPlanningFilterGridEvents";
import { useProdPlanningFilterGridHelpers } from "./hooks/ProdPlanningFilterGridHelpers";

export const ProdPlanningFilterGrid = () => {
    const {dropdownGridColumns} = useProdPlanningFilterGridHelpers();
    const {filterRows,filterLoading,filterIds,selectedFilter,filterSearchText} = useProdPlanningFilterDrawerStore();
    const {onSelectionModelChangeHandlerFilterGrid} = useProdPlanningFilterGridEvents();
    useEffect(() => {
        setHeight({target: '.filter-grid-wrapper', lessPx: 20});
    },[])
    return (
        <div className="filter-grid-wrapper" style={{
            display: 'flex',
            flexFlow: 'column',
            height: 'auto',
        }}>
            <FilterGrid
                columns = {dropdownGridColumns}
                rows = {filterRows}
                loading = {filterLoading}
                selectedIds = {filterIds}
                gridWrapperWidth="525px"
                gridHeight="480px"
                selectedFilter={selectedFilter}
                searchText={filterSearchText}
                onSelectionModelChangeHandlerFilterGrid={onSelectionModelChangeHandlerFilterGrid}
            />
        </div>
    )
}