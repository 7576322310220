import { GridSelectionModel } from "@mui/x-data-grid-pro";
import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { BomFile } from "../Types/BomFile";
import { BomHeader } from "../Types/BomHeader";
import { BomLine } from "../Types/BomLine";
import { BomStepRelation } from "../Types/BomStepRelation";
import { ErrorLog } from "../Types/ErrorLog";
import { UrmUserDatabase } from "../Types/UserService/UrmUserDatabase";

export interface BomUploadContextData {
    searchTextGlobal: string,
    setSearchTextGlobal: Dispatch<SetStateAction<string>>,
    searchTextBomFiles: string,
    setSearchTextBomFiles: Dispatch<SetStateAction<string>>,
    searchTextBomHeaders: string,
    setSearchTextBomHeaders: Dispatch<SetStateAction<string>>,
    searchTextBomDetails: string,
    setSearchTextBomDetails: Dispatch<SetStateAction<string>>,
    errorLog: ErrorLog,
    setErrorLog: Dispatch<SetStateAction<ErrorLog>>,
    errorLogBomLines: ErrorLog[];
    setErrorLogBomLines: Dispatch<SetStateAction<ErrorLog[]>>,
    errorLogBomHeaders: ErrorLog[];
    setErrorLogBomHeaders: Dispatch<SetStateAction<ErrorLog[]>>,
    errorLogStepRelations: ErrorLog[];
    setErrorLogStepRelations: Dispatch<SetStateAction<ErrorLog[]>>,
    loadGrid: boolean,
    setLoadGrid: Dispatch<SetStateAction<boolean>>,
    bomStepRelations: BomStepRelation[],
    setBomStepRelations: Dispatch<SetStateAction<BomStepRelation[]>>,
    allBomStepRelations: BomStepRelation[],
    setAllBomStepRelations: Dispatch<SetStateAction<BomStepRelation[]>>,
    userDatabases: UrmUserDatabase[],
    setUserDatabases: Dispatch<SetStateAction<UrmUserDatabase[]>>,
    uploadingBOM: boolean,
    setUploadingBOM: Dispatch<SetStateAction<boolean>>,
    deleteBomHeaderIds: string[],
    setDeleteBomHeaderIds: Dispatch<SetStateAction<string[]>>,
    processBomHeaderIds: string[],
    setProcessBomHeaderIds: Dispatch<SetStateAction<string[]>>,
    showDelete: boolean,
    setShowDelete: Dispatch<SetStateAction<boolean>>,
    loading: boolean,
    setLoading: Dispatch<SetStateAction<boolean>>;
    loadingDetail: boolean,
    setLoadingDetail: Dispatch<SetStateAction<boolean>>;
    selectedBomHeaderId: number;
    setSelectedBomHeaderId: Dispatch<SetStateAction<number>>;
    selectedBomHeaderIds: GridSelectionModel;
    setSelectedBomHeaderIds: Dispatch<SetStateAction<GridSelectionModel>>;
    selectedBomFileId: number;
    setSelectedBomFileId: Dispatch<SetStateAction<number>>;
    selectedBomFileIds: GridSelectionModel;
    setSelectedBomFileIds: Dispatch<SetStateAction<GridSelectionModel>>;
    bomHeaders: BomHeader[];
    setBomHeaders: Dispatch<SetStateAction<BomHeader[]>>;
    allBomHeaders: BomHeader[];
    setAllBomHeaders: Dispatch<SetStateAction<BomHeader[]>>;
    bomFiles: BomFile[];
    setBomFiles: Dispatch<SetStateAction<BomFile[]>>;
    allBomFiles: BomFile[];
    setAllBomFiles: Dispatch<SetStateAction<BomFile[]>>;
    bomLines: BomLine[];
    setBomLines: Dispatch<SetStateAction<BomLine[]>>;
    allBomLines: BomLine[];
    setAllBomLines: Dispatch<SetStateAction<BomLine[]>>;
}

export const bomUploadContextDefaultValue: BomUploadContextData = {
    searchTextGlobal: '',
    setSearchTextGlobal: () => {},
    searchTextBomFiles: '',
    setSearchTextBomFiles: () => {},
    searchTextBomHeaders: '',
    setSearchTextBomHeaders: () => {},
    searchTextBomDetails: '',
    setSearchTextBomDetails: () => {},
    errorLog: {} as ErrorLog,
    setErrorLog: () => {},
    errorLogBomLines: [],
    setErrorLogBomLines: () => {},
    errorLogBomHeaders: [],
    setErrorLogBomHeaders: () => {},
    errorLogStepRelations: [],
    setErrorLogStepRelations: () => {},
    loadGrid: false,
    setLoadGrid: () => {},
    bomStepRelations: [],
    setBomStepRelations: () => {},
    allBomStepRelations: [],
    setAllBomStepRelations: () => {},
    userDatabases: [],
    setUserDatabases: () => {},
    uploadingBOM: false,
    setUploadingBOM: () => {},
    deleteBomHeaderIds: [],
    setDeleteBomHeaderIds: () => {},
    processBomHeaderIds: [],
    setProcessBomHeaderIds: () => {},
    showDelete: false,
    setShowDelete: () => {},
    loading: false,
    setLoading: () => {},
    loadingDetail: false,
    setLoadingDetail: () => {},
    selectedBomHeaderId: -1,
    setSelectedBomHeaderId: () => {},
    selectedBomHeaderIds: [],
    setSelectedBomHeaderIds: () => {},
    selectedBomFileId: -1,
    setSelectedBomFileId: () => {},
    selectedBomFileIds: [],
    setSelectedBomFileIds: () => {},
    bomHeaders: [],
    setBomHeaders: () => {},
    allBomHeaders: [],
    setAllBomHeaders: () => {},
    bomFiles: [],
    setBomFiles: () => {},
    allBomFiles: [],
    setAllBomFiles: () => {},
    bomLines: [],
    setBomLines: () => {},
    allBomLines: [],
    setAllBomLines: () => {},
}

export const BomUploadStore = createContext<BomUploadContextData>(bomUploadContextDefaultValue);

export const useBomUploadStore = () => useContext(BomUploadStore);

export const BomUploadProvider: FC = ({children}) => {
    const [bomHeaders, setBomHeaders] = useState<BomHeader[]>([]);
    const [allBomHeaders, setAllBomHeaders] = useState<BomHeader[]>([]);
    const [bomFiles, setBomFiles] = useState<BomFile[]>([]);
    const [allBomFiles, setAllBomFiles] = useState<BomFile[]>([]);
    const [bomLines, setBomLines] = useState<BomLine[]>([]);
    const [allBomLines, setAllBomLines] = useState<BomLine[]>([]);
    const [selectedBomHeaderId, setSelectedBomHeaderId] = useState<number>(-1);
    const [selectedBomHeaderIds, setSelectedBomHeaderIds] = useState<GridSelectionModel>([]);
    const [selectedBomFileId, setSelectedBomFileId] = useState<number>(-1);
    const [selectedBomFileIds, setSelectedBomFileIds] = useState<GridSelectionModel>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [deleteBomHeaderIds, setDeleteBomHeaderIds] = useState<string[]>([]);
    const [processBomHeaderIds, setProcessBomHeaderIds] = useState<string[]>([]);
    const [uploadingBOM, setUploadingBOM] = useState<boolean>(false);
    const [userDatabases, setUserDatabases] = useState<UrmUserDatabase[]>([]);
    const [bomStepRelations, setBomStepRelations] = useState<BomStepRelation[]>([]);
    const [allBomStepRelations, setAllBomStepRelations] = useState<BomStepRelation[]>([]);
    const [loadGrid, setLoadGrid] = useState<boolean>(false);
    const [errorLogBomLines, setErrorLogBomLines] = useState<ErrorLog[]>([]);
    const [errorLogBomHeaders, setErrorLogBomHeaders] = useState<ErrorLog[]>([]);
    const [errorLogStepRelations, setErrorLogStepRelations] = useState<ErrorLog[]>([]);
    const [errorLog, setErrorLog] = useState<ErrorLog>({} as ErrorLog);
    const [searchTextGlobal, setSearchTextGlobal] = useState<string>('');
    const [searchTextBomFiles, setSearchTextBomFiles] = useState<string>('');
    const [searchTextBomHeaders, setSearchTextBomHeaders] = useState<string>('');
    const [searchTextBomDetails, setSearchTextBomDetails] = useState<string>('');
    return (
        <BomUploadStore.Provider
            value={{
                bomHeaders,
                setBomHeaders,
                allBomHeaders,
                setAllBomHeaders,
                bomFiles,
                setBomFiles,
                allBomFiles,
                setAllBomFiles,
                bomLines,
                setBomLines,
                allBomLines,
                setAllBomLines,
                selectedBomHeaderId, 
                setSelectedBomHeaderId,
                loading,
                setLoading,
                loadingDetail,
                setLoadingDetail,
                showDelete,
                setShowDelete,
                deleteBomHeaderIds,
                setDeleteBomHeaderIds,
                processBomHeaderIds,
                setProcessBomHeaderIds,
                uploadingBOM,
                setUploadingBOM,
                userDatabases,
                setUserDatabases,
                bomStepRelations,
                setBomStepRelations,
                allBomStepRelations,
                setAllBomStepRelations,
                loadGrid,
                setLoadGrid,
                errorLogBomLines,
                setErrorLogBomLines,
                errorLogBomHeaders,
                setErrorLogBomHeaders,
                errorLogStepRelations,
                setErrorLogStepRelations,
                errorLog,
                setErrorLog,
                selectedBomFileId,
                setSelectedBomFileId,
                selectedBomFileIds,
                setSelectedBomFileIds,
                selectedBomHeaderIds,
                setSelectedBomHeaderIds,
                searchTextGlobal,
                setSearchTextGlobal,
                searchTextBomFiles,
                setSearchTextBomFiles,
                searchTextBomHeaders,
                setSearchTextBomHeaders,
                searchTextBomDetails,
                setSearchTextBomDetails,
            }}
            >
            {children}
        </BomUploadStore.Provider>
    )
}