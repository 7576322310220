import moment from "moment";
import { isEmpty, setAsyncTimeout } from "../../../../utils/utilities";
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import {renderToString} from "react-dom/server";
import GanttChart from "smart-webcomponents-react/ganttchart";
import { TaskItem, TaskRow } from "../../../../Types/TaskItem";
import { ProductionOrder } from "../../../../Types/ProductionOrder";
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import ReactDOM from "react-dom";
import * as ReactDOMClient from "react-dom/client";
import { ChartContentTooltip } from "../../ui/tooltip/ChartContentTooltip";
import React from "react";
import { constants } from "../../../../config/settings";

export const useProductionPlanningScheduleGanttChart = () => {    
    const gantt = document.querySelector('smart-gantt-chart'),
    taskPanelMinWidth = constants.chartSettings.treeSize.chart, // with batch-selected column = 406
    taskPanelMaxWidth = constants.chartSettings.treeSize.grid;
    let _taskRows:TaskRow[]  = [] as TaskRow[];    

    const getView = ():string => {
        const that = gantt;
        const _chart = getSelector('#productionPlanner');

        if (that) {
            return that?.view ?? "";
        }

        if (_chart){
            return _chart.getAttribute('view') ?? "";
        }
        return "";
    }

    const updateTotalElement = (value?:string) => {
        let _totalEl = document.querySelector('.total-rows--container > span > span');        
        if (_totalEl)
            _totalEl.innerHTML = (value) ? value: getTotalRows().toString();
    }

    const displayProgress = (display: boolean) => {
        
        const ganttEl2 = document.querySelector<any>('smart-gantt-chart#productionPlanner');
        let _el = document.querySelector<HTMLDivElement>('#gridProgress');

        if (_el) {
            _el.style.setProperty('display', (display ? 'flex' : 'none'));
        }

        if (display) { // hide the grid
            if (ganttEl2)
                ganttEl2.classList.add("hide");
        } else {
            if (ganttEl2 && ganttEl2.classList.contains('hide')) {
                ganttEl2.classList.remove("hide");
            }
        }        
                                    
        let _totalRowsEl = document.querySelector<HTMLDivElement>('.total-rows--container');
        // show also total
        if (_totalRowsEl) {
            _totalRowsEl.style.setProperty('display', (display ? 'none' : 'flex'));
        }
        
    }

    const formatSplitterBar = (action: 'default'| 'hide' | 'show') => {
        const splitterBarItem = document.querySelector<HTMLElement>('smart-splitter-bar');
        if (splitterBarItem) {
            switch(action) {
                case 'hide':
                    splitterBarItem.style.width = constants.chartSettings.splitterBar.width;
                    splitterBarItem.style.minWidth = constants.chartSettings.splitterBar.minWidth;
                    splitterBarItem.style.opacity = '0';
                    splitterBarItem.style.visibility = 'hidden';
                    break;
                default:
                case 'default':
                    splitterBarItem.style.removeProperty('visibility');
                    splitterBarItem.style.removeProperty('opacity');
                    splitterBarItem.style.removeProperty('minWidth');
                    splitterBarItem.style.removeProperty('width');
                    break;
            }
        }        
    }    
    
    const toggleGridAndChart = (status: string) => {
        const splitter = document.querySelector<any>('#productionPlanner .smart-task-splitter');
        const chart = document.querySelector<any>('smart-gantt-chart#productionPlanner'),
        splitterTableItem = document.querySelector<HTMLElement>('#productionPlanner .smart-task-splitter [smart-id="tableSplitterItem"]'),
        splitterChartItem = document.querySelector<HTMLElement>('#productionPlanner .smart-task-splitter [smart-id="timelineSplitterItem"]'),
        splitterWidth = splitter?.offsetWidth,
        splitterBarItem = document.querySelector<HTMLElement>('smart-splitter-bar'),
        verticalScrollbar = document.querySelector<HTMLElement>('smart-scroll-bar[orientation="vertical"]');

        if (status === 'grid') {
            formatSplitterBar('hide');    
        } else
            formatSplitterBar('default');
            
        if (splitterTableItem && splitterChartItem && splitterBarItem) {

            switch(status)
            {
                default:
                case 'chart':                    
                    splitterTableItem.style.minWidth = taskPanelMinWidth + 'px';
                    splitterTableItem.style.width = taskPanelMinWidth + 'px';

                    splitterChartItem.style.width = (((splitterWidth - taskPanelMinWidth)/splitterWidth) * 100) + '%';

                    // issue with resizing the chart eg. partial task(yellow color).
                    // // to re-render display
                    // chart?.refresh(true);

                    // show the splitter bar
                    formatSplitterBar('show');


                    break;
                case 'grid':
                    // /* with partial of chart and grid */
                    // splitterChartItem.style.minWidth = '0';
                    // splitterChartItem.style.width = (((splitterWidth - taskPanelMaxWidth)/splitterWidth) * 100) + '%';

                    // splitterTableItem.style.width = taskPanelMaxWidth + 'px';
                    // splitterTableItem.style.minWidth = taskPanelMinWidth + 'px';

                    // hide the splitter bar
                    formatSplitterBar('hide');

                    setTimeout(()=>{
                        /* with partial of chart and full-width of grid */
                        splitterChartItem.style.minWidth = constants.chartSettings.timeline.minWidth.toString();
                        splitterChartItem.style.width = constants.chartSettings.timeline.width.toString();
                        splitterChartItem.setAttribute('min', constants.chartSettings.timeline.minWidth.toString());
                        
                        if (verticalScrollbar && verticalScrollbar.classList.contains('smart-hidden')) {
                            splitterTableItem.style.width = constants.chartSettings.treeSize.withoutVerticalScroll;
                        } else 
                            splitterTableItem.style.width = taskPanelMaxWidth;

                        splitterTableItem.style.minWidth = taskPanelMinWidth + 'px'; 
                    },100);                    
                    break;
            }
            
            
        }        
    }
    
    const getTotalRows = ():number =>  {
        const _totalRows = document.querySelector('.smart-table-container')?.getAttribute('aria-rowcount') ?? 0;
        let _dataSource:number = 0;
        const _isGantt = (gantt && gantt.current) ? true : false;
        if (_isGantt){
            _dataSource = gantt?.current?.dataSource?.length ?? 0;
        }
        
        return _isGantt ? _dataSource : parseInt(_totalRows?.toString() ?? 0);
    }

    const getSelectors = (selector: string):any => {
        if (isEmpty(selector)) return null;
        return document.querySelectorAll(selector);
    }

    const getSelector = (selector: string):any => {
        if (isEmpty(selector)) return null;
        return document.querySelector(selector);
    }
    

    /**
     * IntersectionObserver Callback
     *  - check if the list of day/week no. already visible
     * @param entries 
     * @param observer 
     */
     const intersectionCallback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        entries.forEach((entry) => {
            let _view = getView();
            
            if (!isEmpty(_view)) {
                updateTotalElement();
            }            
            
            if (entry.isIntersecting) {
                (async()=>{
                    await setAsyncTimeout(()=>{
                        updateTotalElement();
                    },100);
                })();
            }
          });
    }

    /**
     * Smart Table Intersection Callback
     *  - check if the header columns are already showing
     * @param entries 
     * @param observer 
     */
     const smartTableIntersectionCallback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        entries.forEach((entry) => {            
            
            if (entry.isIntersecting) {
                // option to check resize event of element
            }
          });
    }

    /**
     * On Resize of Element
     * 
     * @param entries 
     * @param observer 
     */
    const timelineResizeObserverCallback = (entries: ResizeObserverEntry[], observer: ResizeObserver) => {
        entries.forEach((entry)=>{
            //            
        });
    }

    const prepareIntersectionObserver = (element:Element, cb:IntersectionObserverCallback, options?:IntersectionObserverInit) => {
        let observer;
        let _options = options ?? {
            root: null,
            rootMargin: "0px",
            threshold: [0, 0.25, 0.5, 0.75]
        };

        observer = new IntersectionObserver(cb, _options);
        observer.observe(element);
    }


    /**
     * Implementation of ResizeObserver
     * 
     * @param element 
     * @param cb 
     */
    const resizeObserver = (element: Element, cb: ResizeObserverCallback) => {
        let observer;

        observer = new ResizeObserver(cb);
        observer.observe(element);
    }

    /**
     * Original implementation of IntersectionObserver
     * with inner callback
     * @param element
     */
    const prepareElementIntersectionObserver = (element:Element) => {
        let observer;
        let options = {
            root: null,
            rootMargin: "0px",
            threshold: [0, 0.25, 0.5, 0.75]
        };

        observer = new IntersectionObserver(intersectionCallback, options);
        observer.observe(element);
    }    

    /**
     * MutationObserver Callback for `timelineViewCells`
     * - change content of day/week no. cell
     * @param mutations 
     * @param observer 
     */
    const timelineHeaderMutationObserverCallback = (mutations: MutationRecord[], observer: MutationObserver) => {
        mutations.forEach((mutation)=>{            
            if (mutation.type === 'childList') {
                let _target = mutation.target,
                    _nodeList:any = _target as unknown as NodeList,
                    _date:Date | null = _nodeList["_date"] ?? null,
                    _view = getView();                    
                                    
                    switch(_view) {
                        case 'week':
                            if (_date !== null) {
                               _target.textContent = _date.toLocaleDateString('en',{ day: 'numeric'  }) + ' ' + _date.toLocaleDateString('en',{ weekday: 'short'  });
                            }
                            break;
                        case 'month':
                            if (_date !== null){
                                _target.textContent = moment(_date).week().toString() + " W";
                            }                            
                            break;
                    }
            }
        });

        //observer.disconnect();
        
    }

    /**
     * MutationObserver Callback for `timelineViewDetails`
     * - change content of day/week no. cell
     * @param mutations 
     * @param observer 
     */
     const timelineHeaderMonthMutationObserverCallback = (mutations: MutationRecord[], observer: MutationObserver) => {
        mutations.forEach((mutation)=>{            
            if (mutation.type === 'childList') {
                let _target = mutation.target,
                    _nodeList:any = _target as unknown as NodeList,
                    _date:Date | null = _nodeList["_date"] ?? null,
                    _view = getView();
                                    
                    switch(_view) {                        
                        case 'month':
                            if (_date !== null){
                                _target.textContent = _date.toLocaleDateString('en',{ month: 'long'  }) + ' ' + _date.toLocaleDateString('en',{ year: 'numeric'  });
                            }                            
                            break;
                    }
            }
        });

        //observer.disconnect();
        
    }

    /**
     * Monitor table.smart-table-taskListContainer,
     * 
     * - changes of aria-rowcount
     * @param mutations 
     * @param observer 
     */
    const tasksMutationObserverCallback = (mutations: MutationRecord[], observer: MutationObserver) => {
        mutations.forEach((mutation)=>{
            
            if (mutation.type === 'attributes') {                
                if (mutation.attributeName === 'aria-rowcount') {
                    let _target = mutation.target,
                    _asTable = _target as HTMLTableElement,
                    _rowCount = mutation.oldValue;
                    if (_rowCount !== null && _rowCount !== "0") {
                        updateTotalElement(_rowCount);
                        displayProgress(false);
                    } else if (_rowCount !== null && _rowCount === "0") {
                        displayProgress(true);
                    }
                }
            }            
        });
    }

    /**
     * Monitor the list when adding the list and format that class
     *  - to extract the product id for proper handling of hide/show
     *  - search the task-indicator by product-id to show/hide
     * @param mutations 
     * @param observer 
     */
    const taskIndicatorContainerMutationObserverCallback = (mutations: MutationRecord[], observer: MutationObserver) => {
        mutations.forEach((mutation)=>{
            if (mutation.type === 'childList') {
                const _target = mutation.target,
                    _childNodes = _target.childNodes;                

                if (_target) {
                    _childNodes.forEach((value:ChildNode, key:number, parent:NodeListOf<ChildNode>)=>{
                        const _chartIndicator = value,
                        _chartIndicatorEl = _chartIndicator as HTMLDivElement,
                        _taskIndicatorEl = _chartIndicatorEl.querySelector('.smart-gantt-chart-task-indicator'),
                        _listClasses = _taskIndicatorEl?.classList;

                        let _materialIcon = "",
                        _productId = "";

                        _listClasses?.forEach((x,i) => {
                            if (x.startsWith('material-icons')) {
                                let rawIcons = x.split('-');
                                if (rawIcons.length > 2) {
                                    _materialIcon = `${rawIcons[0]}-${rawIcons[1]}`;
                                    _productId = rawIcons[2];
                                }
                            }
                        });

                        if (!isEmpty(_materialIcon)) {
                                // try as new class
                                _listClasses?.replace(`${_materialIcon}-${_productId}`, _materialIcon);
                                _chartIndicatorEl.setAttribute('product-id', _productId);
                        }
                    });
                }                
            }
        });
    }

    /**
     * 'smart-timeline-tasks-container'
     *  - extract the task-status for indicator
     *  - on resize of chart, format days as no decimal
     * 
     * @param mutations 
     * @param observer 
     */
    const smartTimelineTasksContainerMutationObserverCallback = (mutations: MutationRecord[], observer: MutationObserver) => {
        mutations.forEach((mutation)=>{
            if (mutation.type === 'attributes' &&
                mutation.attributeName === 'aria-label') {
                const _target:any = mutation.target,
                    _cellObj = _target['_cellObj'] ?? {},
                    _rowId = _target.getAttribute('row-id') ?? "",
                    _trEl = document.querySelector<any>(`.smart-table-container tr:not([unused])[row-id="${_rowId}"]`),
                    _trDurationEl = _trEl?.querySelector('td[data-field="duration"]'),
                    _trDurationText = _trDurationEl?.innerText ?? "";
                    
                if(_cellObj && typeof (_cellObj.task) !== undefined) {                    
                    if (_trEl && _trDurationEl && !isEmpty(_trDurationEl.innerText)) {
                        _trDurationEl.innerText = parseInt(_trDurationText); // without decimal
                    }
                }
            }
            if (mutation.type === 'childList') {
                let _target = mutation.target,
                _el = _target as HTMLElement,
                _classList = _el.classList,
                _taskIndicators = _el.querySelectorAll<HTMLDivElement>('.smart-timeline-task[class*="task"],.smart-timeline-task[class*="project"]'),
                _withIndicator = _el.querySelectorAll<HTMLDivElement>('.smart-timeline-task[class*="|material-icons"]');

                

                if (_withIndicator.length > 0) {
                    
                    formatGoodsIsFlagged();
                }                

                if (_taskIndicators.length > 0) {
                    attachTooltipToChart(_taskIndicators);
                    attachMouseOverTooltipToCharts(_taskIndicators);
                }
            }
        });
    }    

    

    /**
     * Fixed the smart-table tree for expanded parent
     * 
     * @param mutations 
     * @param observer 
     */
    const smartTableMutationObserverCallback = (mutations: MutationRecord[], observer: MutationObserver) => {
        mutations.forEach((mutation)=>{            
            // try to add the smart-arrow-down
            if (mutation.type === 'attributes') {

                // for changes on class eg. smart-arrow-down
                if (mutation.attributeName === 'class') {
                    let _target = mutation.target,
                        _tableRow = _target as HTMLTableRowElement,
                        _rowIndex = _tableRow.getAttribute('row-id'),
                        _tableCell = _target as HTMLTableCellElement,
                        _tableField = _tableCell.getAttribute('data-field');
                                            
                        if (_tableField) {

                            // if the column is projectTooltip
                            if (_target.nodeName.toLowerCase() === 'td' 
                                    && _tableField?.toLowerCase() === 'projecttooltip') {
                                        let _mainRow = _tableCell.closest('tr'),
                                            _rowId = _mainRow?.getAttribute('row-id');
                                        if (_rowId && _rowId?.indexOf(".") > -1) {
                                            //removeHierarchyIconInCellAndReplace(_tableCell);
                                            removeArrowDown(_tableCell);
                                        }
                            }

                            // if the column is endDate
                            if (_target.nodeName.toLowerCase() === 'td'
                                    && _tableField?.toLowerCase() === 'dateend') {
                                        let _tableCellDateEnd = _tableCell.getElementsByClassName('smart-table-cell-template');                                
                                        // _content = _tableCellDateEnd.item(0)?.textContent?.replace(/[\r\n]/gm, '').trim();
                                Array.from(_tableCellDateEnd).forEach((value:Element, index:number, array:Element[])=>{
                                    //let _content = value.textContent?.replace(/[\r\n]/gm, '').trim().split(', ')[0]?.trim() ?? '';
                                    let _content = new Date(value.textContent?.toString() ?? '').toLocaleDateString(gantt?.locale ?? "en",{day:"2-digit", month:"2-digit", year:"2-digit"});
                                    if (_content.length > 0) {
                                        value.textContent = _content;
                                    }
                                });
                            }
                        }

                    // if the target is tr
                    if (_target.nodeName.toLowerCase() === 'tr' && _rowIndex?.indexOf('.') === -1) {
                        let _tableRow = _target as HTMLTableRowElement,
                            _attributes = _tableRow.getAttributeNames(),
                            _expandedAttr = _tableRow.getAttribute('aria-expanded'),
                            _notUnused = _attributes.find(x => x === 'unused'),
                            _showRelatedCheckbox = document.querySelector<HTMLInputElement>('#showRelatedCheckbox'),
                            _projectToolTipCell = _tableRow.querySelector('td[data-field="projectTooltip"]'),
                            _projectToolTipEl = _projectToolTipCell as HTMLDivElement,
                            _nextRowEl = _tableRow.nextElementSibling;
                        
                            if (_projectToolTipCell && _projectToolTipCell?.classList.contains('tree-cell')) {
                                if (_showRelatedCheckbox?.checked) {                            
                                    updateRowStyle(_tableRow,true);                                    
                                } else 
                                    updateRowStyle(_tableRow, false);                                    
                            } else {
                                updateRowStyle(_tableRow, false);                                
                                //_projectToolTipEl.style.paddingLeft = '22px'; // as root
                                _projectToolTipEl.style.paddingLeft = _nextRowEl?.ariaExpanded === "true" ? "19px" : '18px'; // as level-1

                                // if (_projectToolTipEl.classList.contains('pl9') || _projectToolTipEl.classList.contains('pl8')) {
                                //     _projectToolTipEl.classList.remove('pl9');
                                //     _projectToolTipEl.classList.remove('pl8');
                                // }
                                // and should not be bold;
                                _tableRow.style.removeProperty('font-weight');
                            }

                        // on direct click of collapsed
                        if (mutation.oldValue === 'expanded' 
                            &&  _notUnused === undefined 
                            && _expandedAttr === "false") {                            
                            // all
                            formatCollapsedRow();
                        }

                    }
                }

                // expand and collapsed
                if (mutation.attributeName === 'aria-expanded') {
                    formatCollapsedRow();
                }
            }

            // when adding rows
            if (mutation.type === 'childList') {
                if (mutation.addedNodes.length > 0 && mutation.addedNodes[0].nodeName.toLowerCase() === 'tbody') {
                    let _target = mutation.target,
                        _asTable = _target as HTMLTableElement,
                        _rowCount = _asTable.getAttribute('aria-rowcount') ?? 0,
                        _taskIndicators = document.querySelectorAll<HTMLDivElement>('.smart-timeline-task:not(.main-root)');

                        // add style to primary task
                        formatPrimaryRow(_asTable);

                        // update total
                        updateTotalElement(_rowCount.toString());
                        //
                        formatTaskTree();
                        
                        //
                        if (_taskIndicators && _taskIndicators.length > 0)
                            attachMouseOverTooltipToCharts(_taskIndicators);
                }
            }
        });
    }

    const formatGoodsIsFlagged = () => {
        const _withIndicators = document.querySelectorAll('.smart-timeline-task[class*="|material-icons"]');

        _withIndicators.forEach((value:Element, key: number, parent: NodeListOf<Element>)=>{
            const _classList = value.classList,
            _flaggedGoodsEl = value.querySelector('smart-timeline-task--flag');            

            let _taskClass = "",
            _indicatorClass = "",
            _taskOriginalClass = "";
                   
            _classList.forEach((className:string, key:number, parent:DOMTokenList)=>{
                if (className.includes('|material-icons')) {
                    var _taskRawClass = className.split('|');                    
                    if (_taskRawClass.length > 0) {
                        _taskClass = _taskRawClass[0];
                        _taskOriginalClass = className;
                        _indicatorClass = _taskRawClass[1];
                    }
                }
            });

            
            

            if (!isEmpty(_taskOriginalClass)) {                        
                value?.classList.replace(`${_taskOriginalClass}`, _taskClass);
                value?.classList.add(_indicatorClass);

                if (_flaggedGoodsEl === null) {
                    // prepare the icons to insert
                    const divElement = document.createElement('div'),
                    iElement = document.createElement('i');
                    divElement.classList.add('smart-timeline-task--flag');
                    iElement.classList.add('material-icons');                    
                    divElement.appendChild(iElement);
                                                    
                    ReactDOM.render(<i className="material-icons"><DoDisturbAltOutlinedIcon fontSize="small"/></i>, iElement);

                    value?.querySelector('.smart-task-container')?.appendChild(divElement);
                }

            } else {
                if (_flaggedGoodsEl) {
                    _flaggedGoodsEl.remove();
                }
            }

        });   
    }    

    const formatTaskIndicators = () =>  {
        const _list = document.querySelectorAll('.smart-gantt-chart-indicator');
        _list.forEach((value:Element, key: number, parent: NodeListOf<Element>)=>{
            const _taskProdEl = value as HTMLDivElement,
            _taskProductId = _taskProdEl?.getAttribute('product-id'),
            _materialIconEl = _taskProdEl?.querySelector<HTMLDivElement>('.smart-gantt-chart-task-indicator'),
            _classList = _materialIconEl?.classList;

            let _materialIconClass = "",
            _productId = "";

            if (_classList && _classList?.length > 0) {
                _classList?.forEach(x => {
                    if (x.startsWith('material-icons-')) {
                        let rawIcons = x.split('-');
                        if (rawIcons.length > 2) {
                            _materialIconClass = `${rawIcons[0]}-${rawIcons[1]}`;
                            _productId = rawIcons[2];
                        }
                    }
                })
            }            

            if (!isEmpty(_materialIconClass)) {
                    // try as new class
                    _classList?.replace(`${_materialIconClass}-${_productId}`, _materialIconClass);
                    if (_taskProductId !== _productId) {
                        _taskProdEl?.setAttribute('product-id', _productId);
                    }
            }

        });
    }

    const formatSplitterAndScrollBar = () => {
        const verticalScroll = document.querySelector<HTMLElement>('smart-scroll-bar[orientation="vertical"]'),
        taskElement = document.querySelector<HTMLElement>('smart-splitter-item[smart-id="tableSplitterItem"]'),
        gridChartGroup = document.querySelector<HTMLElement>('div[aria-labelledby="grid-chart-controlled-radio-buttons-group"]'),
        checkedValue = gridChartGroup?.querySelector<HTMLInputElement>('span.Mui-checked input[name="grid-chart-controlled-radio-buttons-group"]');
        
        // vertical - scrollbar
        if (verticalScroll && verticalScroll?.getAttribute('max') === "0" && checkedValue?.value === 'grid') {
            if (taskElement) {                
                setTimeout(()=>{
                    taskElement.style.width = constants.chartSettings.treeSize.withoutVerticalScroll;
                },200);
            }
        } else if(verticalScroll && verticalScroll?.getAttribute('max') !=="0" && checkedValue?.value === 'grid') {            
            if (taskElement) {
                setTimeout(()=>{
                    taskElement.style.width = constants.chartSettings.treeSize.grid;
                },200);
            }
        }

    }

    const formatCollapsedRow = () => {
        const _ganttChart = document.querySelector('smart-gantt-chart');
        let _rows = document.querySelectorAll<HTMLTableRowElement>('table.smart-table-container tbody tr:not([unused])');
        Array.from(_rows).forEach((row:HTMLTableRowElement, index:number, rows:HTMLTableRowElement[])=>{
            let _projectTooltipRow = row.querySelector('td[data-field="projectTooltip"]'),
                _divContainer = _projectTooltipRow?.querySelector('.smart-table-cell-template'),
                _projectToolTipCell = _projectTooltipRow as HTMLTableCellElement,
                _projectToolTipEl = _projectToolTipCell as HTMLDivElement,
                _nextRowEl = row.nextElementSibling,
                _rowId = row.getAttribute('row-id'),
                _asPrimaryRow = _rowId?.indexOf('.');

                if (_asPrimaryRow) {
                    if (_asPrimaryRow > -1 ) {
                        // as sub-tasks,
                        // remove the collapsible
                        if (row.ariaExpanded === "true") {
                            removeHierarchyIconInCellAndReplace(_projectTooltipRow as HTMLTableCellElement);
                        }
                    } else {
                        // if (row.ariaExpanded === "false") {

                        //     _projectToolTipCell.classList.add('pl9');

                        //     if (_divContainer && _divContainer?.parentElement) 
                        //         _divContainer.parentElement.classList.add('pl8');
                            
                        // } else {
                        //     _projectToolTipCell.classList.remove('pl9');

                        //     if (_divContainer && _divContainer?.parentElement) 
                        //         _divContainer.parentElement.classList.remove('pl8');
                        // }

                        // force highlight of mainroot
                        if (_ganttChart) {
                            const _task = _ganttChart?.getTask(_rowId?.toString() ?? '') ?? {};

                            if (_task && (_task.mainRoot === true || _task.type === "project")) {
                                if (withHeirarchyArrow(_projectToolTipCell)) {                                
                                    updateRowStyle(row,true);
                                    //
                                    //hideBatchAndStatusElements(row, true);
                                } else  {
                                    // reset the primary row
                                    updateRowStyle(row, false);
                                    //hideBatchAndStatusElements(row, false);
                                }
                            } else {
                                // reset the primary row
                                updateRowStyle(row, false);
                                //hideBatchAndStatusElements(row, false);
                                //
                                // if (_projectToolTipEl && !_projectToolTipEl.classList.contains('tree-cell')) {
                                //     _projectToolTipEl.style.paddingLeft = _nextRowEl?.ariaExpanded === "true" ? "19px" : '21px'; // as level-1

                                //     if (_projectToolTipEl.classList.contains('pl9') || _projectToolTipEl.classList.contains('pl8')) {
                                //         _projectToolTipEl.classList.remove('pl9');
                                //         _projectToolTipEl.classList.remove('pl8');
                                //     }
                                // }
                            }
                        }

                    }
                }                

                // adjust vertical scroll
                // and splitter bar
                formatSplitterAndScrollBar();
        });

        setTimeout(() => {
            formatOtherLevelHierarchyArrow();
        }, 200);
    }

    const attachTooltipToChart = (list:NodeListOf<HTMLDivElement>) => {
        for(var i=0, len = list.length; i < len; i++) {
            const _taskEl = list[i],
                _container = list[i].querySelector<HTMLDivElement>('.smart-task-container'),
                _segment = _container?.querySelector<HTMLDivElement>('.smart-task-connection-point.end'),
                _tooltipEl = _container?.querySelectorAll<HTMLDivElement>('.tooltip');
            
                // prepare the new element
                const _newEl = document.createElement('div');
                _newEl.setAttribute('class', 'tooltip');
                
                if (_tooltipEl && _tooltipEl.length === 0 && _segment) {
                    _container?.insertBefore(_newEl, _segment);
                }
        }
    }

    const attachMouseOverTooltipToChart = (target: HTMLDivElement) => {      
        const _chart = document.querySelector('smart-gantt-chart'),
        _container = target.querySelector<HTMLDivElement>('.smart-task-container'),
        _segment = _container?.querySelector<HTMLDivElement>('.smart-task-connection-point.end'),
        _tooltipContainerEl = _container?.querySelectorAll<HTMLDivElement>('.tooltip--container'),        
        _taskId = target?.getAttribute('id'),
        _task = _chart?.getTask(_taskId || "");

        // prepare the new element
        const _newEl = document.createElement('div');
        _newEl.setAttribute('class', 'tooltip--container');

        // set data
        _newEl.innerHTML = renderToString(<ChartContentTooltip title={_task?.project ?? ""} taskItem={_task}/>);

        if (target) {
            target.addEventListener('mouseover', (event:Event) =>onMouseOverHandler(event));
        }

        if (_tooltipContainerEl && _tooltipContainerEl.length === 0 && _segment) {
            _container?.insertBefore(_newEl, _segment);
        }

    }

    const attachMouseOverTooltipToCharts = (list:NodeListOf<HTMLDivElement>) => {
        const _chart = document.querySelector('smart-gantt-chart');
        const elementsArray = Array.from(list);
        for(let i = 0, len = elementsArray.length; i < len; i++) {
            const _rowEl = elementsArray[i] as HTMLDivElement,
                _container = _rowEl.querySelector<HTMLDivElement>('.smart-task-container'),
                _segment = _container?.querySelector<HTMLDivElement>('.smart-task-connection-point.end'),
                _tooltipContainerEl = _container?.querySelectorAll<HTMLDivElement>('.tooltip--container'),
                _trEl = _container?.closest('div.smart-timeline-task'),
                _taskId = _trEl?.getAttribute('id'),
                _rowId = _trEl?.getAttribute('row-id'),
                _rowGridEl = document.querySelector<HTMLTableRowElement>(`.smart-table-container tbody tr[row-id="${_rowId}"]:not([unused])`),
                _rowIndex = _rowGridEl?.getAttribute('aria-rowindex'),
                _task = _chart?.getTask(_taskId || ""),
                _trRowIndex = _rowGridEl?.rowIndex;

                if (_rowEl) {
                    // prepare the new element
                    const _newEl = document.createElement('div');
                    _newEl.setAttribute('class', 'tooltip--container');

                    if (_task) {
                        if (_task.duration > 0 && _task.duration < 30) {
                            _newEl.style.left = '10%';
                        }
                        if (_task.duration >= 30) {
                            _newEl.style.left = '50%';
                        }
                    }

                    // adjust tooltip to display at bottom of element
                    if(_trRowIndex) {
                        if (_trRowIndex <= 8) {
                            _newEl.style.top = '28px';
                        }
                    }                    

                    _newEl.innerHTML = renderToString(<ChartContentTooltip title={_task?.project ?? ""} taskItem={_task}/>); 

                    if (_rowEl) {
                        _rowEl?.addEventListener('mouseover', (event:Event) =>onMouseOverHandler(event));
                    }

                    if (_tooltipContainerEl && _tooltipContainerEl.length === 0 && _segment) {
                        _container?.insertBefore(_newEl, _segment);
                    }
                }
        }
    }

    const onMouseOverHandler = (event:Event) => {
        event.preventDefault();
        event.stopPropagation();

        const _event = event as MouseEvent,
        _target = event.target as HTMLElement,
        _chart = document.querySelector('smart-gantt-chart'),
        splitterItem = document.querySelector<HTMLElement>('smart-splitter-item[smart-id="timelineSplitterItem"]');

        let _smartTaskContainer = _target?.closest<HTMLDivElement>('.smart-timeline-task:not(.main-root)'),
        _subTaskContainer = _smartTaskContainer?.querySelector<HTMLDivElement>('.smart-task-container'),
        _tooltipContainer = _smartTaskContainer?.querySelector<HTMLDivElement>('.tooltip--container'),
        _segment2 = _smartTaskContainer?.querySelector<HTMLDivElement>('.smart-task-connection-point.end'),
        _taskId = _smartTaskContainer?.getAttribute('id'),
        _task = _chart?.getTask(_taskId || ""),
        _rowId = _smartTaskContainer?.getAttribute('row-id'),
        _rowGridEl = document.querySelector<HTMLTableRowElement>(`.smart-table-container tr[row-id="${_rowId ?? 0}"]:not([unused])`),
        _rowIndex = _rowGridEl?.getAttribute('aria-rowindex'),
        _trRowIndex = _rowGridEl?.rowIndex;

        if (_target) {
            // if no tooltip--container
            if (_tooltipContainer === null && _segment2) {
                const _newEl2 = document.createElement('div');
                _newEl2.setAttribute('class', 'tooltip--container');

                if (_task) {
                    if (_event.clientX < 950) {
                        _newEl2.style.left = '120px';
                    } else {
                        if (_task.duration > 7 && _task.duration < 30) {
                            _newEl2.style.left = '10%';
                        }
                        if (_task.duration >= 30) {
                            _newEl2.style.left = '50%';
                        }
                    }
                    
                }                

                // adjust at the bottom
                if (_trRowIndex) {
                    if (_trRowIndex <= 8) {
                        _newEl2.style.top = '28px';
                    }
                }

                _newEl2.innerHTML = renderToString(<ChartContentTooltip title={_task?.project ?? ""} taskItem={_task}/>);                

                if (_subTaskContainer && _segment2 
                && _subTaskContainer.contains(_segment2))
                {                                        
                    _subTaskContainer.insertBefore(_newEl2, _segment2);
                }
            }
            
            if(_tooltipContainer) {

                if (_trRowIndex) {
                    if (_trRowIndex <= 8) {
                        _tooltipContainer.style.top = '28px';
                    }
                }                

                //
                // if (_event.clientX < 950) {
                //     _tooltipContainer.style.left = '120px';
                // } else {
                    _tooltipContainer.style.left = ((_event.offsetX) + 120) + 'px';
                //}

                //update data
                _tooltipContainer.innerHTML = renderToString(<ChartContentTooltip title={_task?.project ?? ""} taskItem={_task}/>); 
            } 
        } 
    }

    const withHeirarchyArrow = (tableCell:HTMLTableCellElement) => {
        let _withHierarchyArrow = tableCell.getElementsByClassName('hierarchy-arrow smart-arrow smart-arrow-down');
        return ((_withHierarchyArrow && _withHierarchyArrow.length > 0 && _withHierarchyArrow[0]?.classList?.contains('hierarchy-arrow')) ?? false);
    }

    const removeArrowDown = (tableCell:HTMLTableCellElement) => {
        let _list = tableCell.querySelectorAll('td[data-field="projectTooltip"] div.hierarchy-arrow.smart-arrow.smart-arrow-down');
            Array.from(_list).forEach((item:Element, index:number, array: Element[])=>{
                item.classList.remove('smart-arrow-down');
            });
    }

    const removeHierarchyIconInCellAndReplace = (tableCell:HTMLTableCellElement) => {
        const _hierarchyIconEl = tableCell.getElementsByClassName('hierarchy-arrow smart-arrow smart-arrow-down');
        Array.from(_hierarchyIconEl).forEach((el:Element, _index:number, _array:Element[])=>{
            let _nearestTD = el.closest('td'),
                _cellContent = _nearestTD?.getElementsByClassName('smart-table-cell-template');
            if (_nearestTD !== null) {
                _nearestTD.innerHTML = _cellContent?.item(0)?.innerHTML ?? '';
            }
        });
        // changes should be live, no need to return
        //return tableCell;
    }    

    const formatPrimaryRow = (table:HTMLTableElement) => {
        let _tbody = table.getElementsByTagName('tbody'),
            _trows = _tbody.item(0)?.childNodes;

        if (_trows) {
            Array.from(_trows).forEach((row:ChildNode, index:number, array:ChildNode[])=>{
                let _row = row as HTMLTableRowElement,
                    _rowIndex = _row.getAttribute('row-id'),
                    _withShowRelated = document.querySelector<HTMLInputElement>('#showRelatedCheckbox'),
                    _projectToolTipCell = _row.querySelector('td[data-field="projectTooltip"]'),
                    _projectToolTipEl = _projectToolTipCell as HTMLDivElement,
                    _nextRowEl = _row.nextElementSibling;
                    if (_rowIndex?.indexOf('.') === -1) {
                        
                        if (_withShowRelated?.checked){
                            // and find if there a tree-cell class of projectTooltip
                            if (_projectToolTipCell && _projectToolTipCell.classList.contains('tree-cell')) {
                                updateRowStyle(_row, true);
                                //hideBatchAndStatusElements(_row, true);
                            } else {
                                updateRowStyle(_row, false);
                                //hideBatchAndStatusElements(_row, false);
                                // and indent as level-1
                                _projectToolTipEl.style.paddingLeft = _nextRowEl?.ariaExpanded === "true" ? "19px" : '18px';
                                // if (_projectToolTipEl.classList.contains('pl9') || _projectToolTipEl.classList.contains('pl8')) {
                                //     _projectToolTipEl.classList.remove('pl9');
                                //     _projectToolTipEl.classList.remove('pl8');
                                // }
                                _row.style.removeProperty('font-weight');
                            }
                            
                        }else {
                            // and find if there a tree-cell class of projectTooltip
                            if (_projectToolTipCell && _projectToolTipCell.classList.contains('tree-cell')) {
                                updateRowStyle(_row, true);
                                hideBatchAndStatusElements(_row, true);
                            } else {                                
                                updateRowStyle(_row, false);
                                hideBatchAndStatusElements(_row, false);
                                // and indent as level-1
                                _projectToolTipEl.style.paddingLeft = _nextRowEl?.ariaExpanded === "true" ? "19px" : '18px';
                                // if (_projectToolTipEl.classList.contains('pl9') || _projectToolTipEl.classList.contains('pl8')) {
                                //     _projectToolTipEl.classList.remove('pl9');
                                //     _projectToolTipEl.classList.remove('pl8');
                                // }
                                _row.style.removeProperty('font-weight');
                            }
                        }
                    }                
            })
        }
    }

    const getSmartTable = (table:HTMLTableElement, selector:string):any => {
        return findParents(table, selector);
    }

    const formatOtherLevelHierarchyArrow = () => {
        const listHierarchyArrow = document.querySelectorAll('td[data-field="projectTooltip"].outline-level-1:has(.hierarchy-arrow)');
        if (!listHierarchyArrow) return;
        Array.from(listHierarchyArrow).forEach((value:Element, index:number, array:Element[])=>{
            const arrowEl = value.querySelector('.hierarchy-arrow');
            if (arrowEl) {
                // not necessary?
                // css will do
                //arrowEl.remove();
            }
        });
    }

    const updateRowStyle = (row:HTMLTableRowElement,addStyle?:boolean) => {
        let _batchSelected = row.querySelector('td[data-field="batchSelected"]'),
            _status = row.querySelector('td[data-field="status"]'),
            _batchSelectedCellTemplate = _batchSelected?.querySelector<HTMLDivElement>('.smart-table-cell-template'),
            _statusCellTemplate = _status?.querySelector<HTMLDivElement>('.smart-table-cell-template'),
            _rowIndex = row.getAttribute('row-id');
        const primaryRowChart = document.querySelector('.smart-gantt-chart-indicator[task-index="'+ _rowIndex + '"]'),
              materialIcon = primaryRowChart?.querySelector<HTMLElement>('.smart-gantt-chart-task-indicator.material-icons.icon');
        if (addStyle) {
            // // similar to selected row
            // row.style.borderColor = 'var(--smart-ui-state-border-selected)';
            row.style.backgroundColor = 'rgb(235,235,235)';
            // row.style.color = 'var(--smart-ui-state-color-selected)';

            // modify only the font as to distinguish with selected row
            row.style.fontWeight = '600';

            // force hide
            if (_batchSelectedCellTemplate)
                _batchSelectedCellTemplate.style.display = 'none';
            if (_statusCellTemplate)
                _statusCellTemplate.style.display = 'none';
                    
            
            // force hide material-icons for primary-row
            // if (materialIcon) {
            //     materialIcon.style.display = "none";
            // }

        } else {
            row.style.removeProperty('background-color');
            row.style.removeProperty('fontWeight');
            row.style.removeProperty('font-weight');

            if (_batchSelectedCellTemplate)
                _batchSelectedCellTemplate.style.removeProperty('display');
            if (_statusCellTemplate)
                _statusCellTemplate.style.removeProperty('display');
        }

        formatOtherLevelHierarchyArrow();
    }

    /**
     * Handler for filter result with mixed result, not main-root
     * 
     * @param row 
     * @param task 
     */
    const hideBatchAndStatusElements = (row:HTMLTableRowElement, show:boolean):any => {        
        let _batchSelected = row.querySelector('td[data-field="batchSelected"]'),
            _status = row.querySelector('td[data-field="status"]'),
            _batchSelectedCellTemplate = _batchSelected?.querySelector<HTMLDivElement>('.smart-table-cell-template'),
            _statusCellTemplate = _status?.querySelector<HTMLDivElement>('.smart-table-cell-template'),
            _asPrimaryRow = row.style.getPropertyValue('background-color');

            if (_asPrimaryRow.length > 0) { // already set as primary
                if (_batchSelected && _batchSelectedCellTemplate) {
                    _batchSelectedCellTemplate.style.display = 'none';
                }
    
                if (_status && _statusCellTemplate) {
                    _statusCellTemplate.style.display = 'none';
                }
            } else {
                if (_batchSelected && _batchSelectedCellTemplate) {
                    _batchSelectedCellTemplate.style.removeProperty('display');
                }
    
                if (_status && _statusCellTemplate) {
                    _statusCellTemplate.style.removeProperty('display');
                }
            }            
    }

    const updateRowClass = (row:HTMLTableRowElement, add?:boolean) => {
        if (add)
            row.classList.add('merge-task');

        if (row.classList.contains('merge-task'))
            row.classList.remove('merge-task');
    }

    const replaceDivElement = (info:string) => {        
            const _content = info.toString().split('|')[0]?.trim() ?? '';
        return `
        <div class="smart-table-cell-template">
            <div title="${info}">
                ${_content}
            </div>
        </div>
        `
    }
    
    /**
     * Get nearest selector
     * 
     * @param el 
     * @param selector 
     * @returns 
     */
    const findParents = (el:any, selector: string) => {
        var parent_container = el;
        do {
            parent_container = parent_container.parentNode;
        }
        while( !parent_container.matches(selector) && parent_container !== document.body );
    
        return parent_container;
    }

    const prepareMutationObserver = (element:Element, cb:(mutations: MutationRecord[], observer: MutationObserver)=>void,options?:MutationObserverInit) => {
        let observer;

        observer = new MutationObserver(cb);
        observer.observe(element, options);
    }
    

    const attachedListener = () => {
        try{
        
            let myChart = document.querySelector('smart-gantt-chart#productionPlanner');
            if (myChart) {
                // format now?
                formatTaskTree();

                myChart.addEventListener('wheel', formatTaskTree, {passive: true});
                myChart.addEventListener('scroll', formatTaskTree, {passive: true});
            }
            
            let smartTableTaskList = getSelector('.smart-task-table.smart-element.smart-table');
            if (smartTableTaskList) {
                prepareMutationObserver(smartTableTaskList,smartTableMutationObserverCallback,{attributeFilter:['aria-rowcount','class', 'aria-expanded'],attributeOldValue:true,characterData:true,attributes:true,childList:true,subtree:true});
            }

            let  smartTimelineTasksContainer = getSelector('.smart-timeline-tasks-container');
            if (smartTimelineTasksContainer) {
                prepareMutationObserver(smartTimelineTasksContainer, smartTimelineTasksContainerMutationObserverCallback,{attributeFilter:['aria-label'],attributeOldValue:true,characterData:true,attributes:true,childList:true,subtree:true});
            }

            let smartSplitter = document.querySelector("smart-splitter");
            if (smartSplitter) {
                smartSplitter.addEventListener("resizeStart", getBatchSelectedIds);

                smartSplitter.addEventListener("resizeEnd", restoreBatchSelectedIds);

            }
            // // temporary remove the formatting of task indicator
            // // until the bugs on 
            // // https://www.htmlelements.com/forums/topic/task-indicator-error-on-scroll-2/
            // // is fixed.
            // // modify the task-indicator
            // let smartTaskIndicatorContainer = getSelector('.smart-timeline-indicators-container');
            // if (smartTaskIndicatorContainer) {
            //     prepareMutationObserver(smartTaskIndicatorContainer,taskIndicatorContainerMutationObserverCallback,{characterData:true,attributes:true,childList:true,subtree:true});
            // }

            // // initial setting on render
            // let smartSplitterItem = getSelector('smart-splitter-item[smart-id="timelineSplitterItem"]');
            // if (smartSplitterItem) {
            //     // get the width of gantt-chart
            //     const chartSelector = document.querySelector('#productionPlanner'),
            //         chartAsGantt = chartSelector as unknown as GanttChart,
            //         headerEl = document.querySelector('header'),
            //         drawer = document.querySelector('#sideNavigationDrawer'),
            //         splitterBar= chartSelector?.querySelector('smart-splitter[smart-id="taskSplitter"] smart-splitter-bar'),
            //         splitterBarEl = splitterBar as HTMLElement,
            //         paddingSize = 50;

            //         let _size = parseInt(headerEl?.offsetWidth.toString() ?? "0") - 
            //                     parseInt((drawer as HTMLElement)?.offsetWidth.toString() ?? "0") - 
            //                     parseInt(chartAsGantt?.treeSize.toString() ?? '1067') - 
            //                     splitterBarEl.offsetWidth - paddingSize;

            //         // console.debug('--------------------------------------------------------');
            //         // console.debug('chartEl, offsetWidth:', chartEl.offsetWidth);
            //         // console.debug('splitter-bar:', splitterBarEl.offsetWidth);
            //         // console.debug('size:', _size);
            //         // console.debug('header:', headerEl?.offsetWidth, ',drawer-width:', (drawer as HTMLElement)?.offsetWidth);
            //         // console.debug("-----------------------------------------------------------\n");
                    
            //         // smartSplitterItem.max = _size;
            //         // smartSplitterItem.min = _size;
            // }

            let smartContainer = getSelector('.smart-container.hscroll.vscroll');
            if (smartContainer) {
                // format now?
                formatTaskTree();
                // and next event
                smartContainer.addEventListener('wheel', formatTaskTree,{passive:true});
                smartContainer.addEventListener('scroll', formatTaskTree);
                smartContainer.addEventListener('load', formatTaskTree);
            }

            let timelineViewCells0 = document.querySelector<HTMLElement>('#productionPlanner .smart-timeline-view-cells[smart-id="timelineViewCells"]');
            if (timelineViewCells0) {
                prepareIntersectionObserver(timelineViewCells0, intersectionCallback);
                prepareMutationObserver(timelineViewCells0,timelineHeaderMutationObserverCallback,{characterData:true,attributes:true,childList:true,subtree:true});
            }            

            let timelineViewCells1 = document.querySelector('#productionPlanner .smart-timeline-view-cells[smart-id="timelineViewCells"]');            
            if (timelineViewCells1){
                // format now?
                formatTimelineWeekAndDay();
                // and attached event
                timelineViewCells1.addEventListener('load', formatTimelineWeekAndDay);
                timelineViewCells1.addEventListener('change', formatTimelineWeekAndDay);
            }

            // header eg. January
            let timelineViewCells2 = document.querySelector<HTMLElement>('#productionPlanner .smart-timeline-view-details[smart-id="timelineViewDetails"]');
            if (timelineViewCells2) {
                prepareIntersectionObserver(timelineViewCells2, intersectionCallback);
                prepareMutationObserver(timelineViewCells2,timelineHeaderMonthMutationObserverCallback,{characterData:true,attributes:true,childList:true,subtree:true});
            }            

            let timelineViewCells3 = document.querySelector('#productionPlanner .smart-timeline-view-details[smart-id="timelineViewDetails"]');            
            if (timelineViewCells3){
                // format now?
                formatTimelineWeekAndDay();
                // and attached event
                timelineViewCells3.addEventListener('load', formatTimelineWeekAndDay);
                timelineViewCells3.addEventListener('change', formatTimelineWeekAndDay);
            }

            let horizontalBarEl1 = document.querySelector('#productionPlanner .smart-timeline-scroll-bar.smart-element.smart-scroll-bar[smart-id="horizontalScrollBar"] .smart-track');            
            if (horizontalBarEl1) {
                // format now?
                formatTimelineWeekAndDay();
                // and attached event for future action
                horizontalBarEl1.addEventListener('load', formatTimelineWeekAndDay);
                horizontalBarEl1.addEventListener('change', formatTimelineWeekAndDay);
                horizontalBarEl1.addEventListener('click', formatTimelineWeekAndDay);
                horizontalBarEl1.addEventListener('down', formatTimelineWeekAndDay);
                horizontalBarEl1.addEventListener('drag', formatTimelineWeekAndDay);
                horizontalBarEl1.addEventListener('dragstart', formatTimelineWeekAndDay);
                horizontalBarEl1.addEventListener('dragend', formatTimelineWeekAndDay);
                horizontalBarEl1.addEventListener('keydown', formatTimelineWeekAndDay);
                horizontalBarEl1.addEventListener('keyup', formatTimelineWeekAndDay);
                horizontalBarEl1.addEventListener('mouseleave', formatTimelineWeekAndDay);
                horizontalBarEl1.addEventListener('mouseenter', formatTimelineWeekAndDay);
                horizontalBarEl1.addEventListener('mousedown', formatTimelineWeekAndDay);
                horizontalBarEl1.addEventListener('mouseup', formatTimelineWeekAndDay);
                horizontalBarEl1.addEventListener('move', formatTimelineWeekAndDay);
            }

            let thumbBar = document.querySelector('#productionPlanner .smart-timeline-scroll-bar.smart-element.smart-scroll-bar[smart-id="horizontalScrollBar"] .smart-track > .smart-thumb');
            if (thumbBar) {
                thumbBar.addEventListener('load', formatTimelineWeekAndDay);
                thumbBar.addEventListener('change', formatTimelineWeekAndDay);
                thumbBar.addEventListener('click', formatTimelineWeekAndDay);
                thumbBar.addEventListener('down', formatTimelineWeekAndDay);
                thumbBar.addEventListener('drag', formatTimelineWeekAndDay);
                thumbBar.addEventListener('dragstart', formatTimelineWeekAndDay);
                thumbBar.addEventListener('dragend', formatTimelineWeekAndDay);
                thumbBar.addEventListener('dragexit', formatTimelineWeekAndDay);
                thumbBar.addEventListener('keydown', formatTimelineWeekAndDay);
                thumbBar.addEventListener('keyup', formatTimelineWeekAndDay);
                thumbBar.addEventListener('mouseleave', formatTimelineWeekAndDay);
                thumbBar.addEventListener('mouseenter', formatTimelineWeekAndDay);
                thumbBar.addEventListener('mousedown', formatTimelineWeekAndDay);
                thumbBar.addEventListener('mousemove', formatTimelineWeekAndDay);
                thumbBar.addEventListener('mouseover', formatTimelineWeekAndDay);
                thumbBar.addEventListener('mouseout', formatTimelineWeekAndDay);
                thumbBar.addEventListener('mouseup', formatTimelineWeekAndDay);
                thumbBar.addEventListener('move', formatTimelineWeekAndDay);
            }

            // smart-scroll-button
            let scrollButton = document.querySelector('#productionPlanner .smart-timeline-scroll-bar.smart-element.smart-scroll-bar[smart-id="horizontalScrollBar"] .smart-scroll-button');
            if (scrollButton) {
                scrollButton.addEventListener('load', formatTimelineWeekAndDay);
                scrollButton.addEventListener('change', formatTimelineWeekAndDay);
                scrollButton.addEventListener('click', formatTimelineWeekAndDay);
                scrollButton.addEventListener('down', formatTimelineWeekAndDay);
                scrollButton.addEventListener('drag', formatTimelineWeekAndDay);
                scrollButton.addEventListener('dragstart', formatTimelineWeekAndDay);
                scrollButton.addEventListener('dragend', formatTimelineWeekAndDay);
                scrollButton.addEventListener('dragexit', formatTimelineWeekAndDay);
                scrollButton.addEventListener('keydown', formatTimelineWeekAndDay);
                scrollButton.addEventListener('keyup', formatTimelineWeekAndDay);
                scrollButton.addEventListener('mouseleave', formatTimelineWeekAndDay);
                scrollButton.addEventListener('mouseenter', formatTimelineWeekAndDay);
                scrollButton.addEventListener('mousedown', formatTimelineWeekAndDay);
                scrollButton.addEventListener('mouseup', formatTimelineWeekAndDay);
                scrollButton.addEventListener('move', formatTimelineWeekAndDay);
            }

            // smart-scroll-button(Right)
            let scrollButtonRight = document.querySelector('#productionPlanner .smart-timeline-scroll-bar.smart-element.smart-scroll-bar[smart-id="horizontalScrollBar"] .smart-scroll-button.smart-arrow-right');
            if (scrollButtonRight) {
                scrollButtonRight.addEventListener('click', formatTimelineWeekAndDay);                
            }

            // smart-scroll-button(Left)
            let scrollButtonLeft = document.querySelector('#productionPlanner .smart-timeline-scroll-bar.smart-element.smart-scroll-bar[smart-id="horizontalScrollBar"] .smart-scroll-button.smart-arrow-left');
            if (scrollButtonLeft) {
                scrollButtonLeft.addEventListener('click', formatTimelineWeekAndDay);                
            }
            

        }catch(e){
            console.error('failed to add listener, error:', e);
        }
    }

    const detachedListener = () => {
        try {
            let myChart2 = document.querySelector('smart-gantt-chart#productionPlanner');
            if (myChart2) {
                myChart2.removeEventListener('wheel', formatTaskTree);
                myChart2.removeEventListener('scroll', formatTaskTree);
            }

            let smartContainer1 = getSelector('.smart-container.hscroll.vscroll');
            if (smartContainer1) {
                smartContainer1.removeEventListener('wheel', formatTaskTree);
                smartContainer1.removeEventListener('scroll', formatTaskTree);
                smartContainer1.removeEventListener('load', formatTaskTree);
            }            

            let timelineViewCells2 = document.querySelector('#productionPlanner .smart-timeline-view-cells[smart-id="timelineViewCells"]');            
            if (timelineViewCells2){
                timelineViewCells2.removeEventListener('load', formatTimelineWeekAndDay);
                timelineViewCells2.removeEventListener('change', formatTimelineWeekAndDay);
            }

            let timelineViewCells3 = document.querySelector('#productionPlanner .smart-timeline-view-details[smart-id="timelineViewDetails"]');
            if (timelineViewCells3){
                timelineViewCells3.removeEventListener('load', formatTimelineWeekAndDay);
                timelineViewCells3.removeEventListener('change', formatTimelineWeekAndDay);
            }

            let horizontalBarEl2 = document.querySelector('#productionPlanner .smart-timeline-scroll-bar.smart-element.smart-scroll-bar[smart-id="horizontalScrollBar"] .smart-track');            
            if (horizontalBarEl2) {
                horizontalBarEl2.removeEventListener('load', formatTimelineWeekAndDay);
                horizontalBarEl2.removeEventListener('change', formatTimelineWeekAndDay);
                horizontalBarEl2.removeEventListener('click', formatTimelineWeekAndDay);
                horizontalBarEl2.removeEventListener('down', formatTimelineWeekAndDay);
                horizontalBarEl2.removeEventListener('drag', formatTimelineWeekAndDay);
                horizontalBarEl2.removeEventListener('dragstart', formatTimelineWeekAndDay);
                horizontalBarEl2.removeEventListener('dragend', formatTimelineWeekAndDay);
                horizontalBarEl2.removeEventListener('keyup', formatTimelineWeekAndDay);
                horizontalBarEl2.removeEventListener('keydown', formatTimelineWeekAndDay);
                horizontalBarEl2.removeEventListener('mouseleave', formatTimelineWeekAndDay);
                horizontalBarEl2.removeEventListener('mouseenter', formatTimelineWeekAndDay);
                horizontalBarEl2.removeEventListener('mouseup', formatTimelineWeekAndDay);
                horizontalBarEl2.removeEventListener('mousedown', formatTimelineWeekAndDay);
                horizontalBarEl2.removeEventListener('move', formatTimelineWeekAndDay);
            }

            let thumbBar2 = document.querySelector('#productionPlanner .smart-timeline-scroll-bar.smart-element.smart-scroll-bar[smart-id="horizontalScrollBar"] .smart-track > .smart-thumb');
            if (thumbBar2) {
                thumbBar2.removeEventListener('load', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('change', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('click', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('down', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('drag', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('dragstart', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('dragend', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('dragexit', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('keydown', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('keyup', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('mouseleave', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('mouseenter', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('mousedown', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('mousemove', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('mouseover', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('mouseout', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('mouseup', formatTimelineWeekAndDay);
                thumbBar2.removeEventListener('move', formatTimelineWeekAndDay);
            }

            // smart-scroll-button
            let scrollButton2 = document.querySelector('#productionPlanner .smart-timeline-scroll-bar.smart-element.smart-scroll-bar[smart-id="horizontalScrollBar"] .smart-scroll-button');
            if (scrollButton2) {
                scrollButton2.removeEventListener('load', formatTimelineWeekAndDay);
                scrollButton2.removeEventListener('change', formatTimelineWeekAndDay);
                scrollButton2.removeEventListener('click', formatTimelineWeekAndDay);
                scrollButton2.removeEventListener('down', formatTimelineWeekAndDay);
                scrollButton2.removeEventListener('drag', formatTimelineWeekAndDay);
                scrollButton2.removeEventListener('dragstart', formatTimelineWeekAndDay);
                scrollButton2.removeEventListener('dragend', formatTimelineWeekAndDay);
                scrollButton2.removeEventListener('dragexit', formatTimelineWeekAndDay);
                scrollButton2.removeEventListener('keydown', formatTimelineWeekAndDay);
                scrollButton2.removeEventListener('keyup', formatTimelineWeekAndDay);
                scrollButton2.removeEventListener('mouseleave', formatTimelineWeekAndDay);
                scrollButton2.removeEventListener('mouseenter', formatTimelineWeekAndDay);
                scrollButton2.removeEventListener('mousedown', formatTimelineWeekAndDay);
                scrollButton2.removeEventListener('mouseup', formatTimelineWeekAndDay);
                scrollButton2.removeEventListener('move', formatTimelineWeekAndDay);
            }

            // smart-scroll-button(Right)
            let scrollButtonRight2 = document.querySelector('#productionPlanner .smart-timeline-scroll-bar.smart-element.smart-scroll-bar[smart-id="horizontalScrollBar"] .smart-scroll-button.smart-arrow-right');
            if (scrollButtonRight2) {
                scrollButtonRight2.removeEventListener('click', formatTimelineWeekAndDay);                
            }

            // smart-scroll-button(Left)
            let scrollButtonLeft2 = document.querySelector('#productionPlanner .smart-timeline-scroll-bar.smart-element.smart-scroll-bar[smart-id="horizontalScrollBar"] .smart-scroll-button.smart-arrow-left');
            if (scrollButtonLeft2) {
                scrollButtonLeft2.removeEventListener('click', formatTimelineWeekAndDay);                
            }
            
        }catch(e) {
            console.error('failed to detach the listener, error:', e);
        }
    }

    const updateNodeListText = (view: string | null | undefined, list:unknown[], detail:boolean) => {
        try {            
            for(let i=0, e = list.length; i < e; i++) {
                let b:any = list[i],
                    _date:Date = b["_date"],
                    _finalText1 = '',
                    _class = '';

                    //should extract the attributes
                    if (list[i] instanceof HTMLDivElement) {
                        _class = (list[i] as HTMLDivElement).getAttribute('class') ?? '';                        

                        switch(_class) {
                            case 'smart-timeline-view-cell': // month, year
                                if (view === 'month') {
                                    if (_date)
                                     _finalText1 = _date.toLocaleDateString('en',{ month: 'long'  }) + ' ' + _date.toLocaleDateString('en',{ year: 'numeric'  });
                                }
                            break;
                            case 'smart-timeline-view-cells': // day or week
                                if (view === 'week') {
                                    if (_date)
                                        _finalText1 = _date.toLocaleDateString('en',{ day: 'numeric'  }) + ' ' + _date.toLocaleDateString('en',{ weekday: 'short'  });
                                }
                                if (view === 'month') {
                                    if (detail)
                                        _finalText1 = moment(_date).week() + " W";
                                }
                            break;
                        }
                        
                    }
                    
                    if (_finalText1.length > 0) {
                        b.innerHTML = `<div>${_finalText1}</div>`;
                        // if (b.innerText.length === 0) {
                        //     console.debug('WHY!!! b.innerText,0:', b.innerText,',', _finalText1, ',view:',view);
                        // }
                    }
            }
        }catch(e:any){
            console.debug('set innertext, error:', e);
        }
    }
    
    const clearCaret = () => {
        let _list = document.querySelectorAll('tr:not(.expanded) td[data-field="projectTooltip"].tree-leaf div.smart-arrow.smart-arrow-down');        
        Array.from(_list).forEach((item:Element, index:number, array: Element[])=>{
            item.remove();
        });

        // other case
        _list = document.querySelectorAll('tr.expanded td[data-field="projectTooltip"].tree-leaf div.hierarchy-arrow.smart-arrow.smart-arrow-down');        
        Array.from(_list).forEach((item:Element, index:number, array: Element[])=>{
            item.remove();
        });

        // other case 1
        _list = document.querySelectorAll('tr td[data-field="projectTooltip"][class^="outline-level-"].tree-leaf div.hierarchy-arrow.smart-arrow.smart-arrow-down');        
        Array.from(_list).forEach((item:Element, index:number, array: Element[])=>{
            item.remove();
        });

        // remove all smart-arrow-down
        var timer =setTimeout(()=>{
            _list = document.querySelectorAll('tr td[data-field="projectTooltip"] div.hierarchy-arrow.smart-arrow.smart-arrow-down');
            Array.from(_list).forEach((item:Element, index:number, array: Element[])=>{                
                item.classList.remove('smart-arrow-down');
            });

            clearTimeout(timer);
        },100);
    }

    const statusAsString = (item:string,index:number) => {
        let returnString = "";

        if(index === 0) {
            //Authorized
            switch(item){
                case "0":
                    returnString = renderToString(<i title="Not Authorized"><KeyOutlinedIcon htmlColor="grey" /></i>);
                    break;
                case "1":
                    returnString = renderToString(<i title="Authorized"><KeyOutlinedIcon htmlColor="green" /></i>);
                    break;
            }
        }
        if(index === 1) {
            //Release
            switch(item){
                case "0":
                    returnString = renderToString(<i title="Not Released"><NewReleasesOutlinedIcon htmlColor="grey" /></i>);
                    break;
                case "1":
                    returnString = renderToString(<i title="Released"><NewReleasesOutlinedIcon htmlColor="green" /></i>);
                    break;
            }
        }
        if(index === 2) {
            //Printed
            switch(item){
                case "0":
                    returnString = renderToString(<i title="Not Printed"><PrintOutlinedIcon htmlColor="grey" /></i>);
                    break;
                case "1":
                    returnString = renderToString(<i title="Printed"><PrintOutlinedIcon htmlColor="green" /></i>);
                    break;
            }
        }
        if(index === 3) {
            //Rejected
            switch(item){
                case "0":
                    returnString = renderToString(<i title="Not Rejected"><ThumbDownOutlinedIcon htmlColor="grey" /></i>);
                    break;
                case "1":
                    returnString = renderToString(<i title="Rejected"><ThumbDownOutlinedIcon htmlColor="red" /></i>);
                    break;
            }
        }

        return returnString;
    }

    const getDataSource = () => {
        const _table = document.querySelector<any>('smart-table');
        return _table.dataSource?._dataSource;
    }


    const formatStatusWithIcon = (cell:HTMLTableCellElement, value:string) => {
        let _statuses = value.split('|'),
            _statusWithIconInString = "";
        Array.from(_statuses).forEach((item:string, index:number)=>{
            _statusWithIconInString += statusAsString(item,index);
        });

        cell.innerHTML = `<div class="smart-table-cell-template">${_statusWithIconInString}</div> `;
    }

    /**
     * Replace the projecttooltip and itemtooltip with expanded row
     * for not expanded parent
     */
    const formatTaskTree = () => {
        const smartTable = document.querySelector<HTMLTableElement>('table.smart-table-container');
        if (smartTable) {
            const _dataSource = findParents(smartTable, 'smart-table').dataSource._dataSource;            
            let _rows = smartTable.querySelectorAll('tbody > tr:not([unused])'),
                list = Array.from(_rows);

                // with visible rows
                list.forEach((value:Element, index:number, array:Element[])=>{
                    let _rowId = value.getAttribute('row-id') ?? "",
                        _endDateRow = value.querySelector('[data-field="dateEnd"]') ?? null,
                        _durationRow = value.querySelector('[data-field="duration"]') ?? null,
                        _statusRow = value.querySelector('[data-field="status"]'),
                        _projectTooltipRow = value.querySelector('[data-field="projectTooltip"]') ?? null,
                        _dataSourceByItemId = _dataSource.find((x:any) => x.itemId === _rowId);

                        
                        if (_dataSourceByItemId) {
                            // format the dateEnd
                            if (_endDateRow) {
                                var _onlyDate = new Date(_dataSourceByItemId.dateEnd).toLocaleDateString(gantt?.locale ?? "en",{day: "2-digit", month:"2-digit", year:"2-digit"});
                                _endDateRow.innerHTML = `<div class="smart-table-cell-template">${_onlyDate}</div>`;
                                // let originalDate = new Date(_dataSourceByItemId.dateEnd);
                                // let utcTimestamp = Date.UTC(originalDate.getUTCFullYear(), originalDate.getUTCMonth(), originalDate.getUTCDate(),
                                //                                 originalDate.getUTCHours(), originalDate.getUTCMinutes(), originalDate.getUTCSeconds(),
                                //                                 originalDate.getUTCMilliseconds());
                                // let utcDate = new Date(utcTimestamp);                                
                                // _endDateRow.innerHTML = `<div class="smart-table-cell-template">${moment(utcDate).utc().format('MM/DD/YY')}</div>`;
                            }

                            // format status
                            if (_statusRow) {                                
                                //formatStatusWithIcon(_statusRow as HTMLTableCellElement,_dataSourceByItemId.status);
                            }

                            // duration
                            if (_durationRow) {
                               // console.debug(`duration:${_dataSourceByItemId.duration}`);
                            }
                        }
                        


                        // only for not primary tasks                        
                        if (value.ariaExpanded === "true" &&_rowId?.indexOf('.') > -1) {
                            if (_dataSourceByItemId) {
                                if (_projectTooltipRow !== null)
                                    _projectTooltipRow.innerHTML = replaceDivElement(_dataSourceByItemId.projectTooltip ?? '');
                            }
                        }
                });

                // hidden rows, maybe in anticipation of scroll down, or wheel down
                Array.from(smartTable.querySelectorAll('tbody tr[aria-hidden="true"]:not([unused])')).forEach((row:Element, index:number, array:Element[])=>{
                    let _rowId = row.getAttribute('row-id'),
                        _projectTooltipRow = row.querySelector('[data-field="projectTooltip"]') ?? null,
                        _itemTooltipRow = row.querySelector('[data-field="itemTooltip"]') ?? null,
                        _datasourceByRowId = _dataSource.find((x:any) => x.itemId === _rowId);
                        if (_rowId && _rowId.indexOf(".") > -1 ) {
                            if (_datasourceByRowId) {
                                if (_projectTooltipRow !== null)
                                    _projectTooltipRow.innerHTML = replaceDivElement(_datasourceByRowId.projectTooltip ?? '');
                                    
                                        
                                if (_itemTooltipRow !== null) 
                                    _itemTooltipRow.innerHTML = replaceDivElement(_datasourceByRowId.itemTooltip ?? '');
                            }
                        }
                });
        }

        // final clearing
       var _timer = setTimeout(()=>{
            //clearCaret();
            formatCollapsedRow();

            // different approach to have indicator
            formatGoodsIsFlagged();

            // temporary remove the formatting of task indicator
            // until the bugs on 
            // https://www.htmlelements.com/forums/topic/task-indicator-error-on-scroll-2/
            // is fixed.
            //formatTaskIndicators();

            formatSplitterAndScrollBar();

            clearTimeout(_timer);
        },100);// 100, so not to show that is clearing the caret
    }



    const formatTimelineWeekAndDay = () => { 
        try {
            const chartEl = document.querySelector('smart-gantt-chart#productionPlanner'),
                    _view = chartEl?.getAttribute('view');
            
            if (chartEl) {
                // console.debug('format-timeline-with-chartEl::', _view);
                /*
                 * doesn't work to set gantt.timelineHeaderFormatFunction in here
                 * with error something like ..undefined reading ('label')
                 */
                // will try to modify the DOM
                let _timeDetailsAtMonth1 = getSelectors('#productionPlanner .smart-timeline-header .smart-timeline-header-content .smart-timeline-view-cells .smart-timeline-view-cell'),
                listArray1 = Array.from(_timeDetailsAtMonth1);
                if (listArray1.length > 0) {
                    updateNodeListText(_view?.toString(), listArray1, true);
                }

                //header
                let _timeDetailsAtMonth3 = getSelectors('#productionPlanner .smart-timeline-header .smart-timeline-header-content .smart-timeline-view-details .smart-timeline-view-cell'),
                listArray3 = Array.from(_timeDetailsAtMonth3);
                if (listArray3.length > 0) {
                    updateNodeListText(_view?.toString(), listArray3, false);
                }
            }

            if (gantt) {
                // console.debug('format-timeline-with-gant::',_view);
                /*
                 * doesn't work to set gantt.timelineHeaderFormatFunction in here
                 * with error something like ..undefined reading ('label')
                 */
                // will try to modify the DOM
                let _timeDetailsAtMonth2 = getSelectors('#productionPlanner .smart-timeline-header .smart-timeline-header-content .smart-timeline-view-cells .smart-timeline-view-cell'),
                listArray2 = Array.from(_timeDetailsAtMonth2);
                if (listArray2.length > 0) {
                    updateNodeListText(gantt.view, listArray2, true);
                }

                //header
                let _timeDetailsAtMonth4 = getSelectors('#productionPlanner .smart-timeline-header .smart-timeline-header-content .smart-timeline-view-details .smart-timeline-view-cell'),
                listArray4 = Array.from(_timeDetailsAtMonth4);
                if (listArray4.length > 0) {
                    updateNodeListText(_view?.toString(), listArray4, false);
                }                
            }
        }catch(e){            
            console.debug('formatTimelineWeekAndDay,error:', e);
        }
    }

    const formatTaskWithPendingForResize = (productionOrders:ProductionOrder[], ganttChartRef?:React.RefObject<GanttChart|null>) => {
        const _gantt = ganttChartRef?.current ?? null,
        _tasks = _gantt?.getTasks();
        if (_gantt && productionOrders.length > 0) {            
            productionOrders.forEach((value:ProductionOrder, index:number, array:ProductionOrder[])=>{
                const _taskIndex = _tasks.findIndex((x:any) => x.id === value.id),
                    _selectedRow = document.querySelector('tr:not([unused])[row-id="' + (_taskIndex > -1 ? _taskIndex : 0) + '"]');                
                if (_taskIndex > -1) {
                    // update the row with pending class
                    if (_selectedRow)
                    _selectedRow?.classList.add('pending');
                }
            });
        }
    }

    const clearFormatTaskWithPendingForResize = () => {
        const _tRowsWithPending = document.querySelectorAll('tr:not([unused])[row-id][class*="pending"');
        if (_tRowsWithPending) {
            _tRowsWithPending.forEach((value:Element, key:number, parent:NodeListOf<Element>)=>{
                value?.classList.remove('pending');
            });
        }
    }

    const getBatchSelectedIds = (event:Event) => {
        const _gantt = document.querySelector('smart-gantt-chart');
        const _splitter1 = event.target,
            _splitterEl1 = _splitter1 as HTMLElement,
            _taskTree = _splitterEl1.querySelector('[smart-id="tableSplitterItem"]'),
            _tableContainer = _taskTree?.querySelector('table[smart-id="tableContainer"]'),
            _batchSelectedRows = _tableContainer?.querySelectorAll('.batch-selected[type="checkbox"]:checked');

            // reset
            _taskRows = [];

            if (_batchSelectedRows && _batchSelectedRows.length > 0) {
                _batchSelectedRows.forEach((value: Element, key: number, parent:NodeListOf<Element>)=>{
                    const _tr = value.closest('tr'),
                    _rowIndex = _tr?.getAttribute('aria-rowIndex'),
                    _rowId = _tr?.getAttribute('row-id'),
                    _taskItem = _gantt?.getTask(_rowId ?? '');
                    _taskRows.push({rowId: _rowId ?? 0, rowIndex: _rowIndex ?? 0, taskItem:_taskItem});
                });
            }
            
            
    }

    const restoreBatchSelectedIds = (event:Event) => {
        const _gantt = document.querySelector('smart-gantt-chart');
        const _splitter = event.target,
            _splitterEl = _splitter as HTMLElement,
            _taskTree1 = _splitterEl.querySelector('[smart-id="tableSplitterItem"]');
        if (_taskRows) {
            _taskRows.forEach((value:TaskRow, index:number, array:TaskRow[])=>{
                const _checkboxInput = _taskTree1?.querySelector('tr[aria-rowIndex="' + value.rowIndex + '"][row-id="'+ value.rowId +'"] .batch-selected[type=checkbox]');
                if (_checkboxInput) {
                    (_checkboxInput as HTMLInputElement).checked = true;
                }
            });
        }
    }

    return {
        getTotalRows,
        formatTimelineWeekAndDay,
        formatTaskIndicators,
        attachedListener,
        detachedListener,
        prepareIntersectionObserver,
        displayProgress,
        formatTaskTree,
        formatCollapsedRow,
        formatTaskWithPendingForResize,
        clearFormatTaskWithPendingForResize,
        formatGoodsIsFlagged,
        toggleGridAndChart
    }
}
