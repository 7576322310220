import { styled } from "@mui/material"
import { ShowRelated } from "./Items/ShowRelated";

export const FilterGridToolbarItems = styled('div')({
    height: '25px',
})

export const FilterGridToolbar = () => {
    return (
        <FilterGridToolbarItems>
            {false && <ShowRelated />}
        </FilterGridToolbarItems>
    )
}