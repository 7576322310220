import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { InventorBomHeader } from "../Types/Inventor/InventorBomHeader";
import { ErrorLog } from "../Types/ErrorLog";


export interface ExportBOMContextData {
    inventorBomHeader: InventorBomHeader | null | undefined,
    setInventorBomHeader: Dispatch<SetStateAction<InventorBomHeader | null | undefined>>,
    itemCode: string | null | undefined,
    setItemCode: Dispatch<SetStateAction<string | null | undefined>>,
    isPreviewMode: boolean | null | undefined,
    setIsPreviewMode: Dispatch<SetStateAction<boolean | null | undefined>>,
    isDuplicate: boolean | null | undefined;
    setIsDuplicate: Dispatch<SetStateAction<boolean | null | undefined>>,
    isSaved: boolean | null | undefined;
    setIsSaved: Dispatch<SetStateAction<boolean | null | undefined>>,
    gridHasRecords: boolean | null | undefined,
    setGridHasRecords: Dispatch<SetStateAction<boolean | null | undefined>>,
    openSummaryLogs: boolean | null | undefined,
    setOpenSummaryLogs: Dispatch<SetStateAction<boolean | null | undefined>>,
    openLogs: boolean | null | undefined,
    setOpenLogs: Dispatch<SetStateAction<boolean | null | undefined>>,
    errorLogs: ErrorLog[],
    setErrorLogs: Dispatch<SetStateAction<ErrorLog[]>>,
}

export const ExportBOMDefaultValue: ExportBOMContextData = {
    inventorBomHeader: null,
    setInventorBomHeader: () => {},
    itemCode: null,
    setItemCode: () => {},
    isPreviewMode: null,
    setIsPreviewMode: () => {},
    isDuplicate: null,
    setIsDuplicate: () => {},
    isSaved: null,
    setIsSaved: () => {},
    gridHasRecords: null,
    setGridHasRecords: () => {},
    openSummaryLogs: null,
    setOpenSummaryLogs: () => {},
    openLogs: null,
    setOpenLogs: () => {},
    errorLogs: [],
    setErrorLogs: () => {},
}

export const ExportBOMStore = createContext<ExportBOMContextData>(ExportBOMDefaultValue);

export const useExportBOMStore = () => useContext(ExportBOMStore);

export const ExportBOMProvider: FC = ({children}) => {
    const [inventorBomHeader, setInventorBomHeader] = useState<InventorBomHeader | null | undefined>(null);
    const [itemCode, setItemCode] = useState<string | null | undefined>(null);
    const [isPreviewMode, setIsPreviewMode] = useState<boolean | null | undefined>(null);
    const [isDuplicate, setIsDuplicate] = useState<boolean | null | undefined>(null);
    const [isSaved, setIsSaved] = useState<boolean | null | undefined>(null);
    const [gridHasRecords, setGridHasRecords] = useState<boolean | null | undefined>((inventorBomHeader?.details ?? []).length>0);
    const [openSummaryLogs, setOpenSummaryLogs] = useState<boolean | null | undefined>(null);
    const [openLogs, setOpenLogs] = useState<boolean | null | undefined>(null);
    const [errorLogs, setErrorLogs] = useState<ErrorLog[]>([]);
    return (
        <ExportBOMStore.Provider
            value={{
                inventorBomHeader,
                setInventorBomHeader,
                itemCode,
                setItemCode,
                isPreviewMode,
                setIsPreviewMode,
                isDuplicate,
                setIsDuplicate,
                isSaved,
                setIsSaved,
                gridHasRecords,
                setGridHasRecords,
                openSummaryLogs,
                setOpenSummaryLogs,
                openLogs,
                setOpenLogs,
                errorLogs,
                setErrorLogs,
            }}>
            {children}
        </ExportBOMStore.Provider>
    )
}