import { DateRange } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";
import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from "react";

export interface ProdFilterContextData {
    dateStart: Dayjs | null,
    setDateStart: Dispatch<SetStateAction<Dayjs | null>>,
    dateEnd: Dayjs | null,
    setDateEnd: Dispatch<SetStateAction<Dayjs | null>>,
    dateRange: DateRange<Dayjs> | [null,null],
    setDateRange: Dispatch<SetStateAction<DateRange<Dayjs> | [null,null]>>,
    searchText: string,
    setSearchText: Dispatch<SetStateAction<string>>,
    expandCollapseRows: string | null | undefined,
    setExpandCollapseRows: Dispatch<SetStateAction<string | null | undefined>>,    
}

export const ProdFilterDefaultValue: ProdFilterContextData = {
    dateStart: null,
    setDateStart: () => {},
    dateEnd: null,
    setDateEnd: () => {},
    dateRange: [null,null],
    setDateRange: () => {},
    searchText: '',
    setSearchText: () => {},
    expandCollapseRows: '',
    setExpandCollapseRows: () => {},    
}

export const ProdFilterStore = createContext<ProdFilterContextData>(ProdFilterDefaultValue);

export const useProdFilterStore = () => useContext(ProdFilterStore);

export const ProdFilterProvider: FC = ({children}) => {
    const [dateStart, setDateStart] = useState<Dayjs | null>(null);
    const [dateEnd, setDateEnd] = useState<Dayjs | null>(null);
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null,null]);
    const [searchText, setSearchText] = useState<string>('');
    const [expandCollapseRows, setExpandCollapseRows] = useState<string | null | undefined>('collapsed');    
    return (
        <ProdFilterStore.Provider
            value={{
                dateStart,
                setDateStart,
                dateEnd,
                setDateEnd,
                dateRange,
                setDateRange,
                searchText,
                setSearchText,
                expandCollapseRows,
                setExpandCollapseRows,
            }}>
            {children}
        </ProdFilterStore.Provider>
    )
}