import moment from "moment";
import {FC, useEffect} from "react";
import { TaskItem } from "../../../../../Types/TaskItem";
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { isEmpty } from "../../../../../utils/utilities";

interface ChartContentTooltipInterface {
    title: string;
    extraClass?: string;
    taskItem:TaskItem;
}

export const ChartContentTooltip:FC<ChartContentTooltipInterface> = (props:ChartContentTooltipInterface)  => {
    const {title, extraClass, taskItem} = props;
    const _sofulfillmentDate = moment(taskItem?.soFulfillmentDate ?? '0001-01-01'),
    minDate = moment('0001-01-01'),
    statuses = taskItem.status?.split('|') ?? [];    

    const statusAsString = (status:string[]) => {        
        return (
            <>
            <i title={status[0] === "0" ? "Not Close" : "Close"}><LockOutlinedIcon htmlColor={status[0] === "0" ? "grey": "green"} /></i>
            <i title={status[1] === "0" ? "Not Authorized" : "Authorized"}><KeyOutlinedIcon htmlColor={status[1] === "0" ? "grey" : "green"} /></i>
            <i title={status[2] === "0" ? "Not Released" :"Released"}><NewReleasesOutlinedIcon htmlColor={status[2] === "0" ? "grey" : "green"} /></i>
            <i title={status[3] === "0" ? "Not Printed" :"Printed"}><PrintOutlinedIcon htmlColor={status[3] === "0" ? "grey" : "green"} /></i>
            <i title={status[4] === "0" ? "Not Rejected" :"Rejected"}><ThumbDownOutlinedIcon htmlColor={status[4] === "0" ? "grey" : "red"} /></i>
            </>
        )
    }    
    
    return (
        <div className={"tooltip-content " + (extraClass || "")}>            
            <div className="main-content">
                <div className="item">
                    <label>Prod. Order:</label>
                    <span style={{paddingLeft:'10px'}}>{taskItem.project}</span>
                </div>
                <div className="item">
                    <label>Item:</label>
                    <span>{taskItem.item}</span>
                </div>                
                <div className="item">
                    <label>Start Date:</label>
                    <span>{moment(taskItem.dateStart).format('MM/DD/YYYY')}</span>
                </div>
                <div className="item">
                    <label>End Date:</label>
                    <span>{moment(taskItem.dateEnd).utc().format('MM/DD/YYYY')}</span>
                </div>
                <div className="item">
                    <label>Days:</label>
                    <span>{Math.round(taskItem.duration)}</span>
                </div>
                <div className="item">
                    <label>Qty:</label>
                    <span>{taskItem.quantity}</span>
                </div>
                <div className="item">
                    <label>Realized Qty:</label>
                    <span>{taskItem.realizedQty}</span>
                </div>
                <div className="item">
                    <label>CBM:</label>
                    <span>{taskItem.cbm}</span>
                </div>
                <div className="item">
                    <label>SO:</label>
                    <span>{taskItem.salesOrderNo}</span>
                </div>
                <div className="item">
                    <label>Fulfillment Date:</label>
                    <span>{_sofulfillmentDate.isAfter(minDate) ? _sofulfillmentDate.format('MM/DD/YYYY') : ""}</span>
                </div>
                <div className="item">
                    <label>Customer</label>
                    <span>{taskItem.customer}</span>
                </div>
                <div className="item">
                    <label>Status</label>
                    <span className="status">
                    {(statuses.length > 0  && statuses.length <= 5) &&
                        (
                            statusAsString(statuses)
                        ) 
                    }
                    </span>
                </div>
            </div>
        </div>
    )
}