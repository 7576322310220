import {MouseEvent} from "react";
import { useProdPlanningFilterDrawerStore } from "../../../../../../store/ProdPlanningFilterDrawerStore";
import { useProductionPlanningStore } from "../../../../../../store/ProductionPlanningStore";
import { usePopoverBoxHelper } from "../../../../../../ui/popovers/hooks/PopoverBoxHelper";
import { IMultiTriggerFilterOptions } from "../../../../../hooks/interfaces/IMultiTriggerFilterOptions";
import { useProductionPlanningFilterOptionsTriggers } from "../../../../../hooks/triggers/ProductionPlanningFilterOptionsTriggers";

export const useCheckboxEnabledDatesEvents = () => {
    const {setItemIds,setStatusIds,setCustomerIds,setProdOrderIds,setFilterIds,setFilterRows,
        setSelectedItemCodes,setSelectedStatusCodes,setSelectedCustomerCodes,setSelectedProdOrderCodes,
        dateStartFrom,dateStartTo,enableDateRange,setEnableDateRange,itemIds,statusIds,customerIds,prodOrderIds,
        warehouseIds,setWarehouseIds,setSelectedWarehouseCodes} = useProdPlanningFilterDrawerStore();
    const {setMultiTriggerFilterOptions} = useProductionPlanningFilterOptionsTriggers();
    const {initializationPopoverBox} = usePopoverBoxHelper();
    const {targetDB} = useProductionPlanningStore();
    const processFilter = (isEnabledDateRange: boolean) => {
        setMultiTriggerFilterOptions({
            multiple: true,
            prodOrders: true,
            items: true,
            customers: true,
            status: true,
            warehouses: true,
            startDateFrom: (isEnabledDateRange === true) === true ? dateStartFrom : null,
            startDateTo: (isEnabledDateRange === true) === true ? dateStartTo : null,
            targetDB,
        } as IMultiTriggerFilterOptions);
    }
    const onClickCheckboxEnableDates = (event: MouseEvent<HTMLButtonElement>) => {
        if (itemIds.length>0 || statusIds.length>0 || customerIds.length>0 || prodOrderIds.length>0 || warehouseIds.length>0){
            initializationPopoverBox({
                message: "Selected filters will be cleared.",
                anchorEl: event.currentTarget,
                okButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                okCallback: () => {
                    setTimeout(() => {
                        setItemIds([]);
                        setSelectedItemCodes([]);
                        setStatusIds([]);
                        setSelectedStatusCodes([]);
                        setCustomerIds([]);
                        setSelectedCustomerCodes([]);
                        setProdOrderIds([]);
                        setSelectedProdOrderCodes([]);
                        setWarehouseIds([]);
                        setSelectedWarehouseCodes([]);
                        setFilterIds([]);
                        setFilterRows([]);
                        setWarehouseIds([]);
                        setSelectedWarehouseCodes([]);
                        setEnableDateRange(!enableDateRange);
                    },100);
                    setTimeout(() => {
                        processFilter(!enableDateRange);
                    },500);
                    return true
                },
                cancelCallback() {
                    return false;
                },
            });
        } else {
            setTimeout(() => {
                setItemIds([]);
                setSelectedItemCodes([]);
                setStatusIds([]);
                setSelectedStatusCodes([]);
                setCustomerIds([]);
                setSelectedCustomerCodes([]);
                setWarehouseIds([]);
                setSelectedWarehouseCodes([]);
                setProdOrderIds([]);
                setSelectedProdOrderCodes([]);
                setFilterIds([]);
                setFilterRows([]);
                setEnableDateRange(!enableDateRange);
            },100);
            setTimeout(() => {
                processFilter(!enableDateRange);
            },500);
        }
    }
    return {
        onClickCheckboxEnableDates
    }
}