import { Box, styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useProductionPlanningStore } from '../../../../store/ProductionPlanningStore';

export const ProgressIconWrapper = styled('div')({
    display: 'flex',
    height: '50px',
    width: '50px',
})

export const IsResizingProgress = () => {
    const {isResizing,isSaving} = useProductionPlanningStore();
    return (
        <Box sx={{ display: 'flex' }}>
            {(isResizing===true || isSaving===true) &&
                <CircularProgress disableShrink size={30} color="success" />
            }
        </Box>
    )
}