import PropTypes from 'prop-types';
import { Box, List, ListItem, ListItemText, styled } from "@mui/material";
import { NavLink, NavLinkProps } from "react-router-dom";
import { forwardRef, useMemo } from "react";
import { AppMenuItem } from "./AppMenuItem";
import { AuthorizedAccess } from '../../authorization/AuthorizedAccess';
import { sitemaps } from '../../config/sitemap';
import { constants } from '../../config/settings';

export const MenuItem = styled('li')({
    padding: '0',
    '& a': {
        height: '48px',
        maxHeight: '48px',
    },
    [`& .MuiListItemText-root .MuiListItemText-primary`]: {
        marginLeft: '25px',
    },
    [`& .active`]: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    [`& a, a:active`]: {
        color: 'rgba(0, 0, 0, 0.87)',
    }
})

export const MainList = styled(List)({
    '& .active': {
        background: 'rgb(0 0 0 / 30%)!important',
    },
    '& .hover': {
        color: 'red!important',
    },
    '& .nested': {
        paddingLeft: '15px'
    },
   //opacity: '.9',
   '& .MuiTypography-body1': {
        fontSize: '.875rem!important',
        //color: 'rgb(0 0 0 / 30%)!important',
   },
   '& .MuiListItemText-inset': {
       paddingLeft: '40px'
   },
})


function ListItemLink(props: any) {
    const { icon, primary, to } = props;    

    const renderLink = useMemo(
        () =>
        forwardRef((itemProps:NavLinkProps, ref: any) =>
            <NavLink 
                style={{paddingTop: '8px', paddingBottom: '8px'}}
                ref={ref} {...itemProps} />),
        [to]
    );

    return (
        <MenuItem
            style={{height: '48px', maxHeight: '48px'}}>
            <ListItem
                alignItems="center"
                component={renderLink}
                to={to}>
                    <ListItemText primary={primary} />
            </ListItem>
        </MenuItem>        
    );
}

ListItemLink.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};

export const AppMenu = () => {
    const appMenuItems = sitemaps;
    return (
        <Box sx={{ width: '100%' }}>
            <MainList disablePadding>
                {
                    appMenuItems.filter(x => !x.hide).map((item, index) => {
                        return <AuthorizedAccess key={item.id} componentCode={item.componentCode} 
                                          actionCode={constants.accessRightsActions.view}
                                          readonly={constants.accessRightsActions.readonly} >
                            <AppMenuItem {...item} key={item.id} />
                        </AuthorizedAccess>
                    })
                }
            </MainList>
        </Box>
    )
}