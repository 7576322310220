import { Dispatch, SetStateAction } from "react";
import { IPlanningOptionType } from "../../../../interfaces/IPlanningOptionType";
import { useProdFilterStore } from "../../../../store/ProdFilterStore";
import { useProdPagerStore } from "../../../../store/ProdPagerStore";
import { useProdPlanningFilterDrawerStore } from "../../../../store/ProdPlanningFilterDrawerStore";
import { useProductionPlanningStore } from "../../../../store/ProductionPlanningStore";
import { TaskItem } from "../../../../Types/TaskItem";
import { setAsyncTimeout } from "../../../../utils/utilities";
import { useProductionPlanningTriggers } from "../../../hooks/triggers/ProductionPlanningTriggers";
import { useProductionPlanningScheduleGanttChart } from "../../charts/hooks/ProductionPlanningScheduleGanttChart";
import { useSearchHelper } from "../items/hooks/SearchHelper";

export const useProductionPlanningToolbarEvents = (props: {
    setSelectionOption: Dispatch<SetStateAction<string>>
}) => {
    const {setSelectionOption} = props;
    const {setPlanningOptionType,setEnableSnapToNearest,setProductionOrders,
           setLoadingProductionPlannings, setExpandOrCollapseAll, setReloadDataInProgress} = useProductionPlanningStore();
    const {setTriggerLoadFilteredTaskSchedules} = useProductionPlanningTriggers();
    const {formatTimelineWeekAndDay, displayProgress} = useProductionPlanningScheduleGanttChart();
    const {searchText,dateRange,} = useProdFilterStore();
    const {showRelated} = useProdPlanningFilterDrawerStore();
    const {setResetPages,setRefreshPage} = useProdPagerStore();
    const {searchProd} = useSearchHelper();
    const onChangeHandlerPlanningOption = (
        event: React.MouseEvent<HTMLElement>,
        value: string | null,
      ) => {
        
        (async()=>{
          await setAsyncTimeout(()=>{
            setLoadingProductionPlannings(true);
            setReloadDataInProgress(true);
            displayProgress(true);            
            setSelectionOption(value || '');
            setEnableSnapToNearest(false);            
          }, 100);

          await setAsyncTimeout(()=>{
            setPlanningOptionType({option: value} as IPlanningOptionType);
          }, 200);

          await setAsyncTimeout(()=>{
            setRefreshPage(true);
          }, 500);

          await setAsyncTimeout(()=>{
            formatTimelineWeekAndDay();
          }, 200);

          await setAsyncTimeout(()=>{
            setReloadDataInProgress(false);
            displayProgress(false);            
            setLoadingProductionPlannings(false);
          },100);

          setExpandOrCollapseAll(false);

        })();
    }
    const onClickHandlerFilterRefresh = () => {
      setTriggerLoadFilteredTaskSchedules({
        searchText, 
        callback: (data: TaskItem[]) => {
            if ((searchText ?? '').length>0) {
                searchProd(data,searchText,showRelated ?? false);
            }
            setResetPages(true);
            setTimeout(()=>{
                formatTimelineWeekAndDay();
            },100);
        }});
      setProductionOrders([]);
      displayProgress(true);
      setReloadDataInProgress(true);
    }
    return {
      onChangeHandlerPlanningOption,
      onClickHandlerFilterRefresh
    }
}