import { CircularProgress, IconButton, styled, Tooltip } from "@mui/material"
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { useProductionPlanningStore } from "../../../../store/ProductionPlanningStore";
import { useRefreshDataEvents } from "./hooks/RefreshDataEvents";

export const RefreshDataWrapper = styled('div')({
    display: 'flex',
    flexFlow: 'row',
    width: 48,
    height: 48,
    alignItems: 'center',
    justifyContent: 'center'
})

export const RefreshData = () => {
    const {reloadDataInProgress} = useProductionPlanningStore();
    const {onClickHandlerRefresh} = useRefreshDataEvents();
    return (
        <RefreshDataWrapper id="reloadDataWrapper">
            {!reloadDataInProgress
              ? <Tooltip title="Reload the Latest Data">
                    <IconButton onClick={onClickHandlerRefresh} id="reloadBtn"  aria-label="Reload the Latest Data" size="large">
                        <RefreshOutlinedIcon />
                    </IconButton>
                </Tooltip>
              : <CircularProgress size={24} disableShrink/>
            }
        </RefreshDataWrapper>
    )
}