import { CircularProgress, ListItem, styled } from "@mui/material";
import { FC, forwardRef } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

export interface AppMenuItemComponentProps {
    className?: string
    link?: string | null
    onClick: (event: React.MouseEvent<HTMLElement>) => void
    componentCode: string
    inProgress?: boolean
}

const StyledProgressWrapper = styled('div')({
    display: 'flex', 
    width: '227px',
    minHeight: '45px',                
    alignItems: 'center', 
    backgroundColor:'rgba(0,0,0,0.3)',
    '& .progress--container':{
        height: '25px',
        width: '100%',
        paddingLeft: '18px',
        fontSize: '14px',
        lineHeight: '21px',
        marginTop: '2px',
        '& .label': {
            paddingLeft: '10px',
            fontSize: '14px',
            lineHeight: '21px',
            letterSpacing: '0.00938em',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
        }
    }
});


export const AppMenuItemComponent: FC<AppMenuItemComponentProps> = (props) => {
    const {onClick, link, children, inProgress} = props;    
    if (!link || typeof link !== 'string'){
        return (
            <ListItem
                style={{paddingBottom: '8px', paddingTop: '8px', paddingLeft: 0, paddingRight: '8px'}}
                button
                className={"list-item"}
                children={children}
                onClick={onClick}/>
        )
    }
    return (
        <>
        {(link === '/planning' && inProgress)
        ?<StyledProgressWrapper>
                    <div className="progress--container"><CircularProgress size={12} disableShrink /><span className="label">Scheduler</span></div>
         </StyledProgressWrapper>
        :<ListItem 
            style={{paddingBottom: '8px', paddingTop: '8px', paddingLeft: 0, paddingRight: '8px'}}
            button
            className={`list-item ${props.componentCode}`}
            children={children}
            component={
                forwardRef((props: NavLinkProps, ref: any) => 
                <NavLink {...props} ref={ref} />)
            }
            to={link}
        />}
        </>
    )
}