import { userServiceConfig } from '../config/userService';
import { getLocaleLanguage } from '../utils/utilities';

export const getUserDatabases = async (token,email) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const localeLanguage = getLocaleLanguage();

    headers.append("Authorization", bearer);
    headers.append("Accept-Language", localeLanguage );

    const options = {
        method: "GET",
        headers: headers,
    };

    const res = await window.fetch(`${userServiceConfig.getEmailServiceUrl()}${userServiceConfig.getUserDatabases}?email=${email}`, options);
    if (res.ok) {
        const ret = await res.json();
        return ret;
    } else {
        return Promise.reject(res);
    }
}
