import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { getFilteredSchedules, getSchedules } from "../../../services/productionPlanning/productionPlanning";
import { useProdFilterStore } from "../../../store/ProdFilterStore";
import { useProductionPlanningStore } from "../../../store/ProductionPlanningStore";
import { FilteredTaskItems, TaskItem } from "../../../Types/TaskItem";
import { useProductionPlanningScheduleGanttChart } from "../../productionPlanning/charts/hooks/ProductionPlanningScheduleGanttChart";
import { useSearchHelper } from "../../productionPlanning/toolbar/items/hooks/SearchHelper";
import { IProductionPlanningTriggers } from "../interfaces/IProductionPlanningTriggers";

export const useProductionPlanningLoaders = () => {
    const authContext = useContext(AuthContext);
    const {setTaskItems,setAllTaskItems,setRollbackAllTaskItems,setLoadingProductionPlannings,loadingProductionPlannings,
          setDataLoadedProductionPlanning, setFilteredTaskItems, setSearchSelectedTasksIds, setReloadDataInProgress} = useProductionPlanningStore();
    const {displayProgress} = useProductionPlanningScheduleGanttChart();
    const {searchProd} = useSearchHelper();
    const {searchText} = useProdFilterStore();

    const loadProductionPlannings = (trigger?: IProductionPlanningTriggers | null) => {
        if (loadingProductionPlannings===false){
            setLoadingProductionPlannings(true);
            setDataLoadedProductionPlanning(false);
            setTaskItems([]);
            setAllTaskItems([]);
            setRollbackAllTaskItems([]);
            getSchedules(authContext?.auth.userToken)
            .then((data: TaskItem[]) => {

                const searchHtml = document.querySelector('.MuiToolbar.root .prod-search-text');
                const searchHtmlValue = searchHtml ? searchHtml.getAttribute('value') : '';

                if ((trigger?.searchText ?? '').length===0 && (searchHtmlValue ?? '').length===0)
                    setTaskItems(structuredClone(data));

                setAllTaskItems(structuredClone(data));            
                setRollbackAllTaskItems(structuredClone(data));  
                setLoadingProductionPlannings(false);
                setDataLoadedProductionPlanning(true);
                //setTriggerLoadedFilteredTaskSchedules()
                setTimeout(()=>{
                    displayProgress(false);
                }, 100);
                if (trigger?.callback)
                    trigger.callback([...data]);
            });
        }
    };

    const loadFilteredProductionPlannings = (trigger?: IProductionPlanningTriggers | null | undefined) => {
        if (loadingProductionPlannings===false){
            setLoadingProductionPlannings(true);
            setDataLoadedProductionPlanning(false);
            setTaskItems([]);
            setAllTaskItems([]);
            setRollbackAllTaskItems([]);
            getFilteredSchedules(authContext?.auth.userToken, {
                dateStartFrom: trigger?.filterOptions?.dateStartFrom,
                dateStartTo: trigger?.filterOptions?.dateStartTo,
                prodOrders: trigger?.filterOptions?.prodOrders,
                items: trigger?.filterOptions?.items,
                statusCodes: trigger?.filterOptions?.statuses,
                customers: trigger?.filterOptions?.customers,
                warehouses: trigger?.filterOptions?.warehouses,
                fulfillmentDateFrom: trigger?.filterOptions?.fulfillmentDateFrom,
                fulfillmentDateTo: trigger?.filterOptions?.fulfillmentDateTo,
                showRelated: trigger?.filterOptions?.showRelated,
                targetDB: trigger?.filterOptions?.targetDB,
            })
            .then((data: FilteredTaskItems) => {
                const searchHtml = document.querySelector<HTMLInputElement>('.MuiToolbar-root .prod-search-text input');
                const searchHtmlValue = searchHtml ? searchHtml.value : '';

                // no need for now
                // applyUTCToDates(data.filteredTasks)
                // .then(() => {
                //     return applyUTCToDates(data.tasks)
                // }).then(() => {

                    // to avoid loading all before it will do search
                    if ((trigger?.searchText ?? '').length===0 && (searchHtmlValue ?? '').length===0){
                        if ((data.filteredTasks ?? []).length>0)
                            setTaskItems(structuredClone(data.filteredTasks));            
                        else
                            setTaskItems(structuredClone(data.tasks));            
                    } else             
                        searchProd(structuredClone(data.tasks), searchText, false);
                    
                    setFilteredTaskItems(structuredClone(data.filteredTasks ?? []));
                    setAllTaskItems(structuredClone(data.tasks));
                    setRollbackAllTaskItems(structuredClone(data.tasks));
                    setLoadingProductionPlannings(false);
                    setDataLoadedProductionPlanning(true);
                    if ((data.selectedTasksIds ?? []).length>0 && trigger?.filterOptions?.showRelated===true && 
                         (trigger?.filterOptions?.prodOrders ?? []).length>0){
                        setSearchSelectedTasksIds(data.selectedTasksIds);
                    }
                    
                    if (trigger?.callback){
                        if ((data.filteredTasks ?? []).length>0)
                            trigger.callback(structuredClone(data.filteredTasks));
                        else
                            trigger.callback(structuredClone(data.tasks));
                    }
                //});

                setTimeout(()=>{                        
                    displayProgress(false);
                    setReloadDataInProgress(false);
                }, 100);

            }).catch((reason:any)=>{
                console.debug('loading error:', reason);
                setLoadingProductionPlannings(false);                
                setTimeout(()=>{
                    displayProgress(false);
                    setReloadDataInProgress(false);
                }, 100);
            });
        }
    };

    const applyUTCToDates = (taskItems: TaskItem[]) => {
        return new Promise((resolve,reject) => {
            function taskDatesToUTC(tasks: TaskItem[]){
                tasks.forEach((task) => {
                  const utcStartDate = new Date(task.dDateStart);
                  const utcEndDate = new Date(task.dDateEnd);
                  const localStartDate = new Date(utcStartDate.getTime() + (utcStartDate.getTimezoneOffset() * 6000));
                  const localEndDate = new Date(utcEndDate.getTime() + (utcEndDate.getTimezoneOffset() * 6000));
                  task.dateStart = new Date(localStartDate.getFullYear(),localStartDate.getMonth(),localStartDate.getDate(),0,0,0); //moment.utc(task.dateStart).utc(true).toDate();
                  task.dateEnd = new Date(localEndDate.getFullYear(),localEndDate.getMonth(),localEndDate.getDate(),23,59,59); //moment.utc(task.dateEnd).utc(true).toDate();
            
                  if ((task.tasks ?? []).length>0){
                    taskDatesToUTC(task.tasks);
                  }
                });
              }
            
              if ((taskItems ?? []).length>0) {
                taskDatesToUTC(taskItems);
              }
            return resolve(taskItems);
        })
       
    }

    const getTotalItems = (taskItems: TaskItem[]): number => {
        const _task = taskItems.filter( x => x.tasks.length === 0);
        const _innerTasks = taskItems.filter(x => x.tasks.length > 0);
        const _count = _innerTasks.reduce(( item, {tasks}:TaskItem)=>{                
            return item += tasks.length;
        },0);

        return (_task?.length ?? 0) + _count;
    }

    return {
        loadProductionPlannings,
        loadFilteredProductionPlannings,
    }
}